const CheckoutLineItem = {
  name: 'CheckoutLineItem',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customAttributes: 'Attribute',
    discountAllocations: 'DiscountAllocation',
    id: 'ID',
    quantity: 'Int',
    title: 'String',
    unitPrice: 'MoneyV2',
    variant: 'ProductVariant',
  },
  implementsNode: true,
};
export default CheckoutLineItem;
