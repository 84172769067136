const Image = {
  name: 'Image',
  kind: 'OBJECT',
  fieldBaseTypes: {
    altText: 'String',
    height: 'Int',
    id: 'ID',
    originalSrc: 'URL',
    src: 'URL',
    transformedSrc: 'URL',
    width: 'Int',
  },
  implementsNode: false,
};
export default Image;
