import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKey } from '@merchstores/admin/types/reactQuery';
import { getMemberGiftCards } from '@merchstores/admin/api/merchstores/giftCards';
import {
  IGetMemberGiftCardsParameters,
  IGetMemberGiftCardsResult,
} from '@merchstores/admin/types/giftCards';

type QueryOptions = UseQueryOptions<
  IGetMemberGiftCardsResult,
  unknown,
  IGetMemberGiftCardsResult,
  QueryKey.MEMBERS_GIFT_CARD_ORDERS
>;

const useMemberGiftCards = (
  params: IGetMemberGiftCardsParameters,
  options?: QueryOptions
): UseQueryResult<IGetMemberGiftCardsResult> => {
  return useQuery(
    QueryKey.MEMBERS_GIFT_CARD_ORDERS,
    () => getMemberGiftCards(params),
    options
  );
};

export default useMemberGiftCards;
