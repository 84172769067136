import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IIndividualOrderListProps } from '@merchstores/admin/types/shopifyIndividualOrder';
import { FormInputNumber } from '@merchstores/shared/elements/FormInputNumber';
import { Gallery } from '@merchstores/shared/elements/Gallery';
import { Logo } from '@merchstores/shared/elements/Logo';
import { MdClose } from 'react-icons/md';
import { Badge, BadgeType } from '@merchstores/shared/elements/Badge';
import { Tooltip } from '@merchstores/shared/elements/Tooltip';
import {
  findLineItemFulfillment,
  generateOutOfStockMessage,
  isOutOfStock,
} from './LineItemFulfillment';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

interface IOrderProduct {
  id?: string;
  sku?: string;
  productTitle: string;
  productImage?: string;
  artwork?: string;
  decoType?: string;
  decoLocation?: string;
}

interface ILineItem {
  title: string;
  variantTitle: string;
  quantity: number;
  currentQuantity: number;
  variant: {
    id: string;
    price: string;
  };
}

export const IndividualOrderDetailsList: React.FC<IIndividualOrderListProps> = (
  props: IIndividualOrderListProps
) => {
  const {
    orderItems,
    orderItemsFulfillment,
    variantQuantityData,
    canEditOrder,
    setVariantQuantityData,
    handleVariantQuantityEdit,
  } = props;
  const {
    register,
    formState: { errors },
  } = useForm();
  // const [items, setItems] = useState([])
  const [products, setProducts] = useState([]);
  const [expandArtwork, setExpandArtwork] = useState(false);

  useEffect(() => {
    const products: IOrderProduct[] = [];

    for (const item of orderItems) {
      let decoType = '-';
      let decoLocation = '-';
      let productImage = '';
      let artwork = '';
      for (const attribute of item.customAttributes) {
        attribute.key = attribute.key.replace(/_/g, '');
        if (attribute.key === 'Decoration type') {
          decoType = attribute.value;
        }
        if (attribute.key === 'Select decoration location') {
          decoLocation = attribute.value;
        }
        if (attribute.key === 'Logo Mockup Image') {
          productImage = attribute.value;
        }
        if (attribute.key === 'Upload your logo (2MB max)') {
          artwork = attribute.value;
        }
      }
      if (
        products.filter((product) => product.productTitle === item.title)
          .length === 0
      ) {
        products.push({
          sku: item.sku,
          productTitle: item.title,
          productImage: productImage,
          artwork: artwork,
          decoType: decoType,
          decoLocation: decoLocation,
          id: item.variant.id,
        });
      }
    }
    setProducts(products);
  }, [orderItems]);

  const removeItem = (itemId: string) => {
    if (orderItems.length <= 1) {
      toast.error('Orders must have at least one item');
      return;
    }
    handleVariantQuantityChange(0, itemId);
  };

  const expandFunction = () => {
    setExpandArtwork(true);
  };

  const closeExpandFunction = () => {
    setExpandArtwork(false);
  };

  const calcuateTotalProductQty = (product: IOrderProduct) => {
    let totalQty = 0;

    for (const variant of variantQuantityData) {
      if (variant.title === product.productTitle) {
        totalQty += variant.quantity;
      }
    }

    return totalQty;
  };

  const calculateTotalProductPrice = (product: IOrderProduct) => {
    let totalProductPrice = new BigNumber(0);

    for (const variant of variantQuantityData) {
      if (variant.title === product.productTitle) {
        const variantPrice = new BigNumber(variant.price);
        const variantQty = new BigNumber(variant.quantity);
        const totalVariantPrice = variantPrice.multipliedBy(variantQty);

        totalProductPrice = totalProductPrice.plus(totalVariantPrice);
      }
    }

    return totalProductPrice.toString();
  };

  const calculateTotalItems = () => {
    let totalItems = 0;

    for (const variant of variantQuantityData) {
      totalItems += variant.quantity;
    }

    return `${totalItems} ${totalItems > 1 ? 'items' : 'item'}`;
  };

  const calculateTotalStyles = () => {
    const totalStyles = products.length;

    return `${totalStyles} ${totalStyles > 1 ? 'styles' : 'style'}`;
  };

  const handleVariantQuantityChange = (quantity: number, variantId: string) => {
    if (
      variantId ===
      (process.env.REACT_APP_ENV === 'staging'
        ? 'gid://shopify/ProductVariant/46032476078330'
        : 'gid://shopify/ProductVariant/43549215391941')
    ) {
      toast.error('You cannot change the quantity of this item');
      return;
    }
    if (quantity < 0) {
      quantity = 0;
    }
    const originalVariantQuantityData = orderItems?.map((item) => {
      return { id: item.variant.id, quantity: item.currentQuantity };
    });
    const currentVariantQuantityData = [...variantQuantityData];
    currentVariantQuantityData[
      currentVariantQuantityData.findIndex((entry) => entry.id === variantId)
    ].quantity = quantity;
    const newOrderQty = currentVariantQuantityData.reduce(
      (sum, variant) => sum + variant.quantity,
      0
    );
    if (newOrderQty <= 0) {
      toast.error(
        'Orders must have at least one item. Contact support to cancel this order.'
      );
      currentVariantQuantityData[
        currentVariantQuantityData.findIndex((entry) => entry.id === variantId)
      ].quantity = 1;
    }
    let originalDataMatch = true;
    currentVariantQuantityData.forEach((entry) => {
      const originalComparison = originalVariantQuantityData.find(
        (orignialEntry) => orignialEntry.id === entry.id
      );
      if (
        originalComparison &&
        originalComparison.quantity !== entry.quantity
      ) {
        originalDataMatch = false;
      }
    });
    if (originalDataMatch) {
      handleVariantQuantityEdit(false);
    } else {
      handleVariantQuantityEdit(true);
    }
    setVariantQuantityData(currentVariantQuantityData);
  };

  return (
    <div className="border rounded-xl border-gray w-full h-full p-7">
      <p className="mb-5 text-14">
        {calculateTotalStyles()} ({calculateTotalItems()})
      </p>
      <div>
        {products.map((product: IOrderProduct) => {
          let galleryImages = product.artwork ? [product.artwork] : [];
          galleryImages = product.productImage
            ? [product.productImage]
            : galleryImages;

          return (
            <div
              key={product.id}
              className={`mb-12 flex ${
                expandArtwork ? 'flex-col' : 'flex-col lg:flex-row'
              }`}
            >
              <div className={`${expandArtwork ? 'w-full' : 'lg:w-1/6'} `}>
                <div className="border rounded-2xl border-gray-30 p-2">
                  <Gallery
                    images={galleryImages.map((imageUrl) => new URL(imageUrl))}
                    hasThumbnails={false}
                    expandFunction={expandFunction}
                    closeExpandFunction={closeExpandFunction}
                  />
                </div>
              </div>
              <div className="mt-8 lg:px-8 lg:mt-0 lg:w-5/6 ">
                <h3 className="font-bold text-2xl flex-col">
                  {product.productTitle}
                </h3>

                <div className="grid grid-cols-3 gap-4 mt-4">
                  <div>
                    <div className="text-14">Logo</div>
                    {product.artwork ? (
                      <Logo size="small" imgUrl={product.artwork} />
                    ) : (
                      <span className="font-bold text-14"> - </span>
                    )}
                  </div>

                  <div>
                    <div className="text-14">Location</div>
                    <span className="font-bold text-14">
                      {product.decoLocation ? product.decoLocation : '-'}
                    </span>
                  </div>

                  <div>
                    <div className="text-14">Decoration Type</div>
                    <span className="font-bold text-14">
                      {product.decoType ? product.decoType : '-'}
                    </span>
                  </div>
                </div>

                <hr className="border-solid mt-4 mb-2" />

                <div className="flex flex-col">
                  {orderItems.map((item: ILineItem) => {
                    const currentItemQuantityData = variantQuantityData.find(
                      (entry) => entry.id === item.variant.id
                    );
                    const itemFulfillment = findLineItemFulfillment(
                      item,
                      orderItemsFulfillment
                    );

                    if (item.title === product.productTitle) {
                      return (
                        <div key={item.variant.id || item.title}>
                          <div className="grid grid-cols-3 gap-4 mt-4">
                            <div>
                              <div className="text-14">Size</div>
                              <span className="font-bold text-14">
                                {item.variantTitle}
                              </span>
                            </div>

                            <div>
                              <div className="text-14">Price</div>
                              <span className="font-bold text-14">
                                ${item.variant.price}
                              </span>
                            </div>

                            <div className="flex flex-row justify-between">
                              <div className="self-center flex-shrink">
                                {canEditOrder ? (
                                  <FormInputNumber
                                    name={`qty-${item.variant.id}`}
                                    defaultValue={
                                      currentItemQuantityData
                                        ? currentItemQuantityData.quantity
                                        : item.quantity
                                    }
                                    onChange={handleVariantQuantityChange}
                                    positiveOnly={true}
                                    integerOnly={true}
                                    required={true}
                                    register={register}
                                    errors={errors}
                                    id={item.variant.id}
                                  />
                                ) : (
                                  <>
                                    <div className="text-14">Qty</div>
                                    <span className="font-bold text-14">
                                      {currentItemQuantityData
                                        ? currentItemQuantityData.quantity
                                        : item.currentQuantity}
                                    </span>
                                  </>
                                )}
                              </div>

                              {itemFulfillment &&
                                isOutOfStock(itemFulfillment) && (
                                  <div className="flex">
                                    <Tooltip
                                      place={isMobile ? 'left' : 'top'}
                                      tipClassName="tooltip-tip-out-of-stock"
                                      content={
                                        <div className="flex-col">
                                          {generateOutOfStockMessage(
                                            item,
                                            itemFulfillment
                                          )}
                                        </div>
                                      }
                                    >
                                      <Badge
                                        className="self-center justify-self-end ml-20 h-25"
                                        type={BadgeType.issue}
                                      >
                                        Issue
                                      </Badge>
                                    </Tooltip>
                                  </div>
                                )}

                              {canEditOrder ? (
                                <div
                                  className="remove-item-btn self-center cursor-pointer"
                                  onClick={() => removeItem(item.variant.id)}
                                >
                                  <MdClose />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <hr className="border-solid my-4" />

                <div className="grid grid-cols-3 gap-4 mt-4">
                  <div className="col-start-2">
                    <div className="text-14">Total</div>
                    <span className="font-bold text-14">
                      {/* PRICE */}${calculateTotalProductPrice(product)}
                    </span>
                  </div>

                  <div className="text-left">
                    <div className="text-14">Qty</div>
                    <span className="font-bold text-14">
                      {calcuateTotalProductQty(product)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
