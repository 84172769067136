/* eslint-disable max-len */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MdAdd, MdClear, MdDelete } from 'react-icons/md';
import { Modal } from 'react-responsive-modal';
import {
  FormSelect,
  IFormOptionProps,
} from '@merchstores/shared/elements/FormSelect';
import { CTA } from '@merchstores/shared/elements/Cta';
import { generateReactKey } from '@merchstores/shared/react/ReactKey';
import { toast } from 'react-toastify';
import { IMemberListAdminHeaderProps } from '.';
import { sendEmail } from '@merchstores/shared/components/Mailer';
import { useForm } from 'react-hook-form';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';

export const MemberListAdminHeader: React.FC<IMemberListAdminHeaderProps> = (
  props: IMemberListAdminHeaderProps
) => {
  const { merchStore, setMerchStoreDetails } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [adminList, setAdminList] = useState([]);
  const [addAdminModalOpen, setAddAdminModalOpen] = useState(false);
  const [editAdminModalOpen, setEditAdminModalOpen] = useState(false);
  const [deleteAdminModalOpen, setDeleteAdminModalOpen] = useState(false);
  const [adminEmail, setAdminEmail] = useState('');
  const [primaryAdmin, setPrimaryAdmin] = useState('');
  const [primaryAdminSelected, setPrimaryAdminSelected] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [contactInfoEmail, setContactInfoEmail] = useState('');
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  useEffect(() => {
    const admins: string[] = merchStore.customerAdmins || [];
    const primaryAdmin: string | undefined = merchStore.primaryAdmin;
    let updatedAdmins: string[] = [];
    const addedAdminsSet: Set<string> = new Set();

    if (admins.length) {
      admins.forEach((admin) => {
        const lowerCaseAdmin = admin.toLowerCase();
        if (!addedAdminsSet.has(lowerCaseAdmin)) {
          updatedAdmins.push(admin);
          addedAdminsSet.add(lowerCaseAdmin);
        }
      });

      if (primaryAdmin) {
        const lowerCasePrimaryAdmin = primaryAdmin.toLowerCase();
        if (!addedAdminsSet.has(lowerCasePrimaryAdmin)) {
          updatedAdmins = [primaryAdmin, ...updatedAdmins];
          addedAdminsSet.add(lowerCasePrimaryAdmin);
        }
      }

      setAdminList(updatedAdmins);
      setPrimaryAdmin(primaryAdmin ? primaryAdmin : updatedAdmins[0]);
      setContactInfoEmail(
        merchStore.contactInfo?.email ? merchStore.contactInfo.email : ''
      );
    }
  }, [merchStore]);

  const handleAdminModalClose = () => {
    setAddAdminModalOpen(false);
    setEditAdminModalOpen(false);
    setDeleteAdminModalOpen(false);
    setPrimaryAdminSelected(false);
  };

  const handleAdminEmailChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setAdminEmail(target.value);
  };

  const handleSelectedAdmin = (selectedOption: IFormOptionProps) => {
    setSelectedAdmin(selectedOption);
  };

  const handlePrimaryAdminState = () => {
    setPrimaryAdminSelected(!primaryAdminSelected);
  };

  const formatAdminListOptions = () => {
    const options: IFormOptionProps[] = [];
    adminList.forEach((admin: string) => {
      options.push({
        value: admin,
        displayText: admin,
      });
    });
    return options;
  };

  const handleAdminSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentAdmins = merchStore.customerAdmins || [];
    if (!currentAdmins.includes(adminEmail)) {
      console.log('axios post here');
      axios
        .post('/.netlify/functions/updateShopifyCustomerTagsByEmail', {
          customerEmail: adminEmail,
          tags: ['merch_store_admin'],
        })
        .then((resp) => {
          if (resp.data.customer.length) {
            const updatedAdminList = [...adminList, adminEmail];
            const newPrimaryAdmin = primaryAdminSelected
              ? adminEmail
              : primaryAdmin;
            handleAdminEdit(updatedAdminList, newPrimaryAdmin);
            sendAdminInviteEmail(adminEmail);
            toast.success('Admin added to MerchStore.');
            handleAdminModalClose();
          } else {
            toast.error(
              'The selected email does not have a Merchology login. ' +
                'Please direct them to create a login at merchology.com prior to adding them as an admin.'
            );
          }
        })
        .catch((err) => {
          toast.error(
            'There was an error adding the admin. Please try again or contact support.'
          );
          console.error(err);
        });
    } else {
      toast.warning(
        'The selected email is already assigned as an admin. Please select a different email.'
      );
    }
  };

  const sendAdminInviteEmail = (adminEmail: string) => {
    const storeCode = merchStore.storeCode ? merchStore.storeCode : '';
    const storeName = merchStore.name ? merchStore.name : 'Untitled';
    const storeLogo = merchStore.storeLogo ? merchStore.storeLogo : '';
    const siteUrl =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://merchologysolutions.com'
        : 'https://staging.merchologysolutions.com';
    const email = {
      templateId: 'merchstore-admin-invite',
      subject: `Welcome to ${storeName}’s New MerchStore`,
      text: 'You’ve been assigned the Admin.',
      templateContent: [
        {
          name: 'storeCode',
          content: storeCode,
        },
        {
          name: 'storeName',
          content: storeName,
        },
        {
          name: 'storeLogo',
          content: storeLogo,
        },
        {
          name: 'siteUrl',
          content: siteUrl,
        },
      ],
    };
    sendEmail(email, [adminEmail]);
  };

  const handleAdminEdit = (
    updatedAdminList: string[],
    newPrimaryAdmin: string
  ) => {
    const hasPrimaryAdminChanged = primaryAdmin !== newPrimaryAdmin;
    setIsAwaitingResponse(true);
    axios
      .post('/.netlify/functions/updateMerchStoreAdmins', {
        mutationType: 'updateMerchStoreAdmins',
        storeCode: merchStore.storeCode,
        adminData: updatedAdminList,
        primaryAdmin: newPrimaryAdmin,
      })
      .then(() => {
        setAdminList(updatedAdminList);
        const updatedMerchStoreDetails = {
          ...merchStore,
          primaryAdmin: newPrimaryAdmin,
          customerAdmins: updatedAdminList,
        };
        setMerchStoreDetails(updatedMerchStoreDetails);
        if (hasPrimaryAdminChanged) {
          toast.success(`${newPrimaryAdmin} is now the primary admin.`);
        }
        handleAdminModalClose();
      })
      .catch((err) => {
        console.error(err);
        toast.warning(
          'There was an error updating your merchstore, please try again or contact support.'
        );
      })
      .finally(() => {
        setIsAwaitingResponse(false);
      });
  };

  const setNewPrimaryAdmin = () => {
    if (selectedAdmin.value && primaryAdminSelected === true) {
      const newPrimaryAdmin = selectedAdmin.value;
      setPrimaryAdmin(newPrimaryAdmin);
      handleAdminEdit(adminList, newPrimaryAdmin);
    }
  };

  const handleAdminDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const lowerCaseSelectedAdmin = selectedAdmin.value.toLowerCase();
    const updatedAdminList = adminList.filter(
      (admin: string) => admin.toLowerCase() !== lowerCaseSelectedAdmin
    );

    setAdminList(updatedAdminList);

    const newPrimaryAdmin = updatedAdminList.includes(primaryAdmin)
      ? primaryAdmin
      : updatedAdminList[0];
    setPrimaryAdmin(newPrimaryAdmin);

    handleAdminEdit(updatedAdminList, newPrimaryAdmin);
  };

  const headerCardStyles = `
    h-60 px-4 flex items-center text-sm font-medium w-full 
    ${
      adminList.length === 1
        ? 'lg:w-admin-header-card-2/3'
        : 'lg:w-create-order-header-card'
    } shadow-buttons border border-gray-300 rounded-xl p-0.5 mb-3 lg:mb-0 justify-between
  `;

  const sortedAdminList = [...adminList].sort((a, b) =>
    a === primaryAdmin ? -1 : b === primaryAdmin ? 1 : 0
  );

  return (
    <div className="mb-30">
      {/* Begin add admin modal */}
      <Modal
        center
        open={addAdminModalOpen}
        onClose={handleAdminModalClose}
        classNames={{ modal: 'shadow-confirmation-modal rounded-3xl' }}
        closeIcon={<MdClear />}
      >
        <div className="flex flex-col p-3 max-w-400">
          <div className="text-xl font-bold">Add Admin</div>
          <div className="my-5">
            <p>
              An automated notification email with additional information will
              be sent to this user.
            </p>
          </div>
          <form onSubmit={handleAdminSave}>
            <input
              type="email"
              placeholder="Email Address"
              className="w-full text-sm rounded-lg border border-gray-300 px-3 h-44 input-type-two"
              value={adminEmail}
              onChange={handleAdminEmailChange}
              required
            ></input>
            <Checkbox
              label="Set as primary admin"
              className="items-center mt-5"
              onChange={handlePrimaryAdminState}
              isSelected={primaryAdminSelected}
              disabled={adminEmail.length === 0}
              tooltip="The primary admin will receive all order notifications."
            />
            <CTA
              size="standard"
              type="primary"
              stretch={true}
              classes="h-44 mt-5"
              formSubmit={true}
              disabled={adminEmail.length === 0}
            >
              Confirm
            </CTA>
            <CTA
              size="standard"
              type="clear-background"
              stretch={true}
              classes="h-44"
              onClick={handleAdminModalClose}
            >
              Cancel
            </CTA>
          </form>
        </div>
      </Modal>
      {/* Begin edit admin modal */}
      <Modal
        center
        open={editAdminModalOpen}
        onClose={handleAdminModalClose}
        classNames={{ modal: 'shadow-confirmation-modal rounded-3xl' }}
        closeIcon={<MdClear />}
      >
        <div className="flex flex-col p-3 max-w-400">
          <div
            className="text-xl font-bold"
            style={{ width: '270px', marginBottom: '18px' }}
          >
            Edit Admin
          </div>
          <form onSubmit={handleSubmit(setNewPrimaryAdmin)}>
            <FormSelect
              name="edit-admin"
              classes="w-full"
              register={register}
              errors={errors}
              setValue={setValue}
              label="If this admin is assigned to a new user, they will receive an automated notification email with additional information."
              default={selectedAdmin}
              options={formatAdminListOptions()}
              onChange={handleSelectedAdmin}
              disabled={merchStore && merchStore.status === 'closed'}
            />
            <Checkbox
              label="Set as primary admin"
              className="items-center mt-5"
              onChange={handlePrimaryAdminState}
              isSelected={
                primaryAdminSelected || selectedAdmin.value === primaryAdmin
              }
              disabled={selectedAdmin.value === primaryAdmin}
              tooltip="The primary admin will receive all order notifications."
            />
            <div className="flex flex-row justify-between align-center mt-5">
              <div
                className={
                  'flex ' +
                  'rounded-md ' +
                  'font-bold ' +
                  'items-center ' +
                  'justify-center ' +
                  'bg-merch-orange ' +
                  'text-white ' +
                  'text-14 ' +
                  'shadow-sm ' +
                  'min-w-standard-button ' +
                  'mr-3 ' +
                  'px-2 ' +
                  'w-full'
                }
              >
                <CTA
                  size="standard"
                  type="primary"
                  stretch={true}
                  classes="h-44"
                  disabled={isAwaitingResponse}
                  formSubmit={true}
                >
                  Save
                </CTA>
              </div>
              <div
                className={
                  'flex ' +
                  'rounded-md ' +
                  'font-bold ' +
                  'items-center ' +
                  'justify-center ' +
                  'bg-white ' +
                  'text-merch-dark-gray ' +
                  'border ' +
                  'border-gray-200 ' +
                  'text-12 ' +
                  'hover:bg-gray-50 ' +
                  'shadow-sm ' +
                  'px-2'
                }
              >
                <CTA
                  size="small"
                  type="clear-background"
                  disabled={adminList.length === 1}
                  onClick={() => {
                    setEditAdminModalOpen(false);
                    setDeleteAdminModalOpen(true);
                  }}
                >
                  <MdDelete />
                </CTA>
              </div>
            </div>
            <CTA
              size="standard"
              type="clear-background"
              stretch={true}
              classes="h-44 mt-2 mb-0"
              disabled={isAwaitingResponse}
              onClick={handleAdminModalClose}
            >
              Cancel
            </CTA>
          </form>
        </div>
      </Modal>
      {/* Begin delete admin modal */}
      <Modal
        center
        open={deleteAdminModalOpen}
        onClose={handleAdminModalClose}
        classNames={{ modal: 'shadow-confirmation-modal rounded-3xl' }}
        closeIcon={<MdClear />}
      >
        <div className="flex flex-col p-3 max-w-400">
          <div className="text-xl font-bold">Delete Admin</div>
          <div className="my-5">
            {contactInfoEmail !== selectedAdmin.value ? (
              <p>Are you sure you want to delete this admin?</p>
            ) : (
              <p>
                This admin is listed as the store contact. To delete this admin,
                first update the store contact information.
              </p>
            )}
          </div>
          <form onSubmit={handleAdminDelete}>
            {contactInfoEmail !== selectedAdmin.value ? (
              <CTA
                size="standard"
                type="primary"
                stretch={true}
                classes="h-44 mt-5"
                formSubmit={true}
              >
                Confirm & Delete
              </CTA>
            ) : (
              <CTA
                size="standard"
                type="primary"
                stretch={true}
                classes="h-44 mt-5"
                formSubmit={false}
                onClick={async () => {
                  handleAdminModalClose();
                  await props.onContactInfoRequired();
                }}
              >
                Update Store Contact Info
              </CTA>
            )}

            <CTA
              size="standard"
              type="clear-background"
              stretch={true}
              classes="h-44"
              onClick={handleAdminModalClose}
            >
              Cancel
            </CTA>
          </form>
        </div>
      </Modal>
      <div className="flex justify-between align-center items-start dropdowns-container px-4 md:px-0 mb-3">
        <div>
          <p className="font-bold text-md">Store Admins</p>
        </div>
        <div className="text-xs opacity-50 italic">Maximum 3 Store Admins</div>
      </div>
      <div
        className={`${
          adminList.length === 1 ? 'w-full lg:w-2/3' : 'w-full'
        } flex flex-col lg:flex-row justify-between mt-3 px-5 md:px-0`}
      >
        {sortedAdminList.map((admin: string, index: number) => {
          const isPrimaryAdmin = admin === primaryAdmin;

          return (
            <div
              key={generateReactKey(`admin-card-${index}`)}
              className={`${headerCardStyles} pt-1`}
            >
              <div className="flex flex-col justify-center items-start gap-0 flex-1 max-w-200 relative truncate w-200">
                <div className="text-xs italic text-merch-dark-gray opacity-50 left-0">
                  {isPrimaryAdmin ? 'Primary Admin' : 'Admin'}
                </div>
                <div className="w-full flex justify-between whitespace-nowrap overflow-x-hidden">
                  <div className="truncate w-200">{admin}</div>
                </div>
              </div>
              {adminList.length >= 1 && (
                <div
                  className="text-xs cursor-pointer font-bold text-merch-orange text-right"
                  onClick={() => {
                    setEditAdminModalOpen(true);
                    setSelectedAdmin(admin);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          );
        })}
        {adminList.length < 3 && (
          <div className={`${headerCardStyles}`}>
            <div
              className="w-full overflow-x-hidden flex justify-between items-center opacity-50 cursor-pointer"
              onClick={() => setAddAdminModalOpen(true)}
            >
              <div>Add Admin</div>
              <div>
                <MdAdd />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
