const CustomerAddressCreatePayload = {
  name: 'CustomerAddressCreatePayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customerAddress: 'MailingAddress',
    customerUserErrors: 'CustomerUserError',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CustomerAddressCreatePayload;
