import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import Modal from 'react-responsive-modal';
import { MdAddToPhotos, MdClose, MdFullscreen } from 'react-icons/md';
import {
  cloudinaryResizeLarge,
  cloudinaryApplyWidthHeight,
} from '@merchstores/shared/components/Cloudinary';
import { LogoMockupImage } from '@merchstores/shared/elements/LogoMockupImage';
import { IProductMediaProps } from '.';
import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';
import { toast } from 'react-toastify';
import { Context } from '@merchstores/admin/context';
import './styles.scss';

export const ProductMedia: React.FC<IProductMediaProps> = (
  props: IProductMediaProps
) => {
  const {
    product,
    artworkOptions,
    onArtworkSelectedChanged,
    onProductImagesChanged,
  } = props;
  const [, setDummyState] = useState(undefined);
  const [imagePreviewModal, setImagePreviewModal] = useState(undefined);
  const [selectedArtwork, setSelectedArtwork] = useState(1);
  const [images, setImages] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [fileInput1, setFileInput1] = useState(undefined);
  const [fileInput2, setFileInput2] = useState(undefined);
  const [fileInput3, setFileInput3] = useState(undefined);
  const [fullscreenImageIndex, setFullscreenImageIndex] = useState(0);
  const { userRole } = useContext(Context);
  const { isViewMode } = props;
  const enableProductImageEdit =
    !isViewMode &&
    userHasRole(new Role(userRole), [ROLE_SUPERUSER, ROLE_MERCHOLOGIST]);
  const MAX_IMAGES = 3;

  useEffect(() => {
    setSelectedArtwork(product.artworkSelected);
  }, [product.artworkSelected]);

  useEffect(() => {
    setImages(Object.assign([], product.images));
  }, [product.images]);

  useEffect(() => {
    setFileInput1(document.getElementById(`product-image-1-hidden-input`));
    setFileInput2(document.getElementById(`product-image-2-hidden-input`));
    setFileInput3(document.getElementById(`product-image-3-hidden-input`));
  }, [fileInput1, fileInput2, fileInput3]);

  const onImageFullscreenClick = (image: string, index: number) => {
    setImagePreviewModal(image);
    setFullscreenImageIndex(index);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImagePreviewModal(undefined);
  };

  const onArtworkClick = (artworkOption: number) => {
    setSelectedArtwork(artworkOption);
    onArtworkSelectedChanged(artworkOption);
  };

  const onDeleteImageClick = (
    imageIndex: number,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    images.splice(imageIndex, 1);
    const fileInput = document.getElementById(
      `product-image-${imageIndex + 1}-hidden-input`
    ) as HTMLInputElement;
    fileInput.value = '';
    setDummyState({});
    onProductImagesChanged(images);
    event?.stopPropagation();
  };

  const handleFileInput = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files.length) {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('upload_preset', 'group-order-tool');
        axios
          .post(
            'https://api.cloudinary.com/v1_1/merchology/image/upload',
            formData
          )
          .then((resp) => {
            images.push({
              id: resp.data.asset_id,
              src: resp.data.secure_url,
              altText: resp.data.original_filename,
            });
            setDummyState({});
            onProductImagesChanged(images);
          })
          .catch((err) => {
            console.error(err);
            toast.error(
              'An error occurred uploading your image, please try again.'
            );
          });
      }
    }
  };

  const renderImage = (
    index: number,
    maxFiles: boolean,
    fileInput1: HTMLInputElement
  ) => {
    const hasImage = images && images[index];
    return (
      <div
        className="image-container relative p-10 md:mr-15 mb-10 cursor-pointer"
        onClick={() => {
          if (hasImage) onImageFullscreenClick(images[index].src, index);
        }}
      >
        <div
          className={`absolute w-full h-full p-10 top-0 left-0`}
          onClick={() => {
            if (!hasImage && fileInput1 && !maxFiles) {
              fileInput1.onchange = handleFileInput;
              fileInput1.click();
            }
          }}
        >
          {hasImage ? (
            index === 0 ? (
              <LogoMockupImage
                tags={product.tags}
                logoUrl={
                  artworkOptions &&
                  artworkOptions.length &&
                  artworkOptions[selectedArtwork - 1]
                    ? artworkOptions[selectedArtwork - 1]
                    : ''
                }
                imgUrl={images[index].src}
                classes="rounded-md p-0.5 h-auto"
              />
            ) : (
              <img
                className="rounded-md p-0.5 h-auto"
                src={cloudinaryResizeLarge(images[index].src)}
                alt={images[index].altText}
              ></img>
            )
          ) : (
            <div className="text-4xl opacity-30 flex items-center justify-center h-full">
              <MdAddToPhotos />
            </div>
          )}
        </div>
        {hasImage ? (
          <div
            className={`text-xl absolute p-10 z-10 top-0 ${enableProductImageEdit ? 'left-0' : 'right-0'}`}
          >
            <MdFullscreen />
          </div>
        ) : (
          ''
        )}
        {enableProductImageEdit && hasImage && (
          <div
            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
              onDeleteImageClick(index, event)
            }
            className="text-l absolute m-10 z-20 top-0 right-0 rounded-full flex bg-gray-200 w-20 h-20 items-center justify-center"
          >
            <MdClose />
          </div>
        )}
      </div>
    );
  };

  const maxFiles = images && images.length >= MAX_IMAGES;

  return (
    <div className="view-edit-product-container w-full">
      <div className="flex flex-col w-full bg-merch-background-gray border border-gray-200 rounded-lg pl-4 pr-4 pt-4 mt-6">
        <div className="title mb-30">Media</div>
        <div className="subtitle mb-10">Selected Artwork</div>
        <div className="mb-10 md:flex">
          {artworkOptions && artworkOptions.length > 0 ? (
            artworkOptions.map((artwork, index) => {
              const artworkUrl = artwork.toString();
              const resizedArtworkUrl = cloudinaryApplyWidthHeight(
                artworkUrl,
                30,
                30
              );
              return (
                <div
                  className={`artwork-container flex p-10 mb-10 md:mr-15 ${selectedArtwork === index + 1 ? 'selected' : ''}`}
                  key={`artwork_${index}`}
                  onClick={() => onArtworkClick(index + 1)}
                >
                  <div className="artwork-thumbnail flex items-center justify-center">
                    <img src={resizedArtworkUrl}></img>
                  </div>
                  <div className="artwork-title flex items-center overflow-hidden ml-10">
                    {artworkUrl.substring(artworkUrl.lastIndexOf('/') + 1)}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-sm mb-10">No artwork options available.</div>
          )}
        </div>
        <div className="subtitle mb-10">Product Images</div>
        <div className="md:flex md:justify-around">
          <Modal
            open={imageModalOpen}
            onClose={handleImageModalClose}
            center
            classNames={{ modal: 'image-modal' }}
          >
            <div className="image-modal">
              {fullscreenImageIndex === 0 ? (
                <LogoMockupImage
                  tags={product.tags}
                  logoUrl={
                    artworkOptions ? artworkOptions[selectedArtwork - 1] : ''
                  }
                  imgUrl={imagePreviewModal ? imagePreviewModal : ''}
                  classes="rounded-md p-0.5 h-auto"
                />
              ) : (
                <img loading="lazy" src={imagePreviewModal}></img>
              )}
            </div>
          </Modal>
          <input
            type="file"
            accept="image/png, image/jpeg, image/svg, image/gif"
            id={`product-image-1-hidden-input`}
            name={`product-image-1`}
            className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
            onChange={() => handleFileInput}
          ></input>
          <input
            type="file"
            accept="image/png, image/jpeg, image/svg, image/gif"
            id={`product-image-2-hidden-input`}
            name={`product-image-2`}
            className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
            onChange={() => handleFileInput}
          ></input>
          <input
            type="file"
            accept="image/png, image/jpeg, image/svg, image/gif"
            id={`product-image-3-hidden-input`}
            name={`product-image-3`}
            className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
            onChange={() => handleFileInput}
          ></input>
          {renderImage(0, maxFiles, fileInput1)}
          {renderImage(1, maxFiles, fileInput2)}
          {renderImage(2, maxFiles, fileInput3)}
        </div>
      </div>
    </div>
  );
};
