import { useMutation, UseMutationResult } from 'react-query';
import { unassignMerchStoreMemberGiftCards } from '@merchstores/admin/api/merchstores/giftCards';
import {
  IUnassignMemberGiftCardsParameters,
  IUnassignMemberGiftCardsResult,
} from '@merchstores/admin/types/giftCards';

type UseUnassignMemberGiftCardsResult = UseMutationResult<
  IUnassignMemberGiftCardsResult,
  unknown,
  IUnassignMemberGiftCardsParameters,
  unknown
>;

const useUnassignMemberGiftCards = (): UseUnassignMemberGiftCardsResult => {
  return useMutation((params: IUnassignMemberGiftCardsParameters) =>
    unassignMerchStoreMemberGiftCards(params)
  );
};

export default useUnassignMemberGiftCards;
