const CommentAuthor = {
  name: 'CommentAuthor',
  kind: 'OBJECT',
  fieldBaseTypes: {
    email: 'String',
    name: 'String',
  },
  implementsNode: false,
};
export default CommentAuthor;
