const OrderLineItemEdge = {
  name: 'OrderLineItemEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'OrderLineItem',
  },
  implementsNode: false,
};
export default OrderLineItemEdge;
