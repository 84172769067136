import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Context } from '../../context';
import { Card } from '@merchstores/shared/elements/Card';
import { Table } from '@merchstores/shared/elements/Table';
import { getLocalDateText } from '../DateTime';
import { IMerchStoreProps } from './index';
import { buildColumns, getColumnOptions } from './MerchStoreListColumnOptions';
import { AdminHeader } from '../AdminHeader';
import './styles.scss';
import { CTA } from '@merchstores/shared/elements/Cta';
import { toast } from 'react-toastify';
import { MdStore } from 'react-icons/md';
import { FaTools } from 'react-icons/fa';
import { CreateMerchstore, rebuildMerchstore } from '../CreateMerchstore';
import { useHistory } from 'react-router-dom';
import { ReassignMerch } from '../ReassignMerch';
import { MultiDropdown } from '@merchstores/admin/shared/elements/MultiDropdown';
import { FilterDropdown } from '@merchstores/shared/elements/FilterDropdown';
import { SortDropdown } from '@merchstores/shared/elements/SortDropdown';
import { Footer } from '@merchstores/shared/elements/Footer';
import { FilterIndicator } from '@merchstores/shared/elements/FilterIndicator';
import { Loading } from '@merchstores/shared/components/Loading';
import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';
import {
  buildPaymentsDropdownFilterOptions,
  buildStatusFilterOptions,
  createCustomerContactFilters,
  createMerchologistFilters,
  getFilterValue,
} from './MerchStoreFilterOptions';
import { buildSortOptions, getSortValue } from './MerchStoreSortOptions';
import { handleFiltersChosen } from './handleFiltersChosen';
import {
  sendOrderClosed,
  queueMerchStoreEmails,
} from '../CreateEditMerchStore/component';
import { makeMerchStoreRow } from './MerchStoresTable';

import { SearchBox } from '@merchstores/shared/elements/SearchBox';
import { clearQueryCache, queryMerchstores } from './MerchStoresQuery';
import { isPastDate } from '@merchstores/admin/lib/DateTime';
import { ConfirmationModal } from '@merchstores/shared/elements/ConfirmationModal';
import { deleteMerchstore } from './MerchStoreDelete';

const STORES_PER_PAGE = 50;

export const MerchStoreList = (): JSX.Element => {
  const { userRole, userEmail } = useContext(Context);
  const [merchstores, setMerchStores] = useState([]);

  const sortOptions = buildSortOptions(new Role(userRole));
  const defaultSort = sortOptions[0];

  // Pagination
  const [hasNextPage, setHasNextPage] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  // End Pagination

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [newMerchstoreModalOpen, setNewMerchstoreModalOpen] = useState(false);
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [currentMerch, setCurrentMerch] = useState('');
  const [selectedMerchstoreCode, setSelectedMerchstoreCode] = useState('');
  const [selectedMerchstore, setSelectedMerchstore] = useState({});

  const [statusFilterOptions] = useState(buildStatusFilterOptions(userRole));
  const [customerContactFilterOptions, setCustomerContactFilterOptions] =
    useState([]);
  const [merchologistFilterOptions, setMerchologistFilterOptions] = useState(
    []
  );
  const [paymentsFilterOptions] = useState(
    buildPaymentsDropdownFilterOptions()
  );

  const [statusFilterChosen, setStatusFilterChosen] = useState(null);
  const [customerContactFilterChosen, setCustomerContactFilterChosen] =
    useState(null);
  const [merchologistFilterChosen, setMerchologistFilterChosen] =
    useState(null);
  const [paymentsFilterChosen, setPaymentsFilterChosen] = useState(null);
  const [sortOptionChosen, setSortOptionChosen] = useState(defaultSort);
  const columnOptions = getColumnOptions(userRole);
  const [columnPreferences, setColumnPreferences] = useState(columnOptions);
  const [searchTerms, setSearchTerms] = useState('');

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const history = useHistory();

  const isMerchologist = userHasRole(new Role(userRole), [
    ROLE_SUPERUSER,
    ROLE_MERCHOLOGIST,
  ]);
  const isSuperUser = userHasRole(new Role(userRole), [ROLE_SUPERUSER]);

  const iconSize = '20px';

  const onPagination = async (pageNumber: number) => {
    // For legacy compatibility, pageNumber starts on 0
    return fetchMerchstores(pageNumber * STORES_PER_PAGE);
  };

  const onSearchAction = (searchTerms: string): void => {
    setSearchTerms(searchTerms);
    setTableLoading(true);
  };

  const refreshMerchstores = async (pageOffset?: number) => {
    clearQueryCache();
    return fetchMerchstores(pageOffset);
  };

  const resolveColumnViewModes = () => {
    const isStatusPendingOrder =
      (statusFilterChosen && statusFilterChosen.value) === 'pending_order';
    const isPendingSort = [
      'sort_revenue_pending_most',
      'sort_revenue_pending_least',
      'sort_orders_pending_most',
      'sort_orders_pending_fewest',
    ].includes(sortOptionChosen && sortOptionChosen.id);

    const pendingOrderViewMode = isStatusPendingOrder || isPendingSort;
    const allOrdersViewMode = !pendingOrderViewMode;

    return { pendingOrderViewMode, allOrdersViewMode };
  };

  const resolveVisibleTableColumns = () => {
    const { pendingOrderViewMode, allOrdersViewMode } =
      resolveColumnViewModes();

    const colPrefs = new Set(columnPreferences.map((col: any) => col.id));

    console.log('colPrefs', colPrefs);

    const tableColumns = {
      logo: true,
      groupAdmin: colPrefs.has('groupAdmin'),
      customerContact: colPrefs.has('groupAdmin'),
      assignedTo: colPrefs.has('assignedTo'),
      merchologyContact: colPrefs.has('assignedTo'),
      payments: colPrefs.has('payments'),
      orderCount: allOrdersViewMode && colPrefs.has('orderCount'),
      pendingOrdersCount:
        pendingOrderViewMode && colPrefs.has('pendingOrdersCount'),
      revenue: allOrdersViewMode && colPrefs.has('revenue'),
      pendingRevenue: pendingOrderViewMode && colPrefs.has('pendingRevenue'),
      closeDate: true,
      status: true,
      pendingOrdersIndicator: true,
      actions: true,
    };

    return tableColumns;
  };

  const fetchMerchstores = async (pageOffset?: number) => {
    const fetchResult = await queryMerchstores({
      email: userEmail,
      role: userRole,
      status: getFilterValue(statusFilterChosen),
      search: searchTerms,
      sorting: getSortValue(sortOptionChosen),
      limit: STORES_PER_PAGE,
      customerContact: getFilterValue(customerContactFilterChosen),
      merchologist: getFilterValue(merchologistFilterChosen),
      payerType: getFilterValue(paymentsFilterChosen),
      offset: pageOffset ? pageOffset : 0,
    });

    if (!fetchResult) {
      toast.error(
        'There was an error fetching the Merchstore data. Please try again or contact support.'
      );
      return {
        merchStores: [],
      };
    }

    setHasNextPage(fetchResult.hasNextPage);
    setTotalCount(fetchResult.totalCount);

    const merchStores = fetchResult.merchStores;
    const customerContacts = fetchResult.customerContacts;
    const merchologistContacts = fetchResult.merchologists;

    const visibleTableColumns = resolveVisibleTableColumns();

    const merchStoreRows = fetchResult.merchStores.map(
      (merchstore: IMerchStoreProps) => {
        const hasPendingIndividualOrders =
          (merchstore.individualOrdersPendingCount || 0) > 0;
        const hasPendingOrder =
          hasPendingIndividualOrders &&
          merchstore.nextProcessingDate &&
          isPastDate(merchstore.nextProcessingDate);

        merchstore.hasPendingOrder = hasPendingOrder || false;

        const reassignAction = () => {
          setSelectedMerchstoreCode(merchstore.storeCode);
          setCurrentMerch(merchstore.merchologist);
          setReassignModalOpen(true);
        };

        const closeStoreAction = async () => {
          try {
            await closeMerchStore(
              merchstore.storeCode,
              merchstore.name,
              merchstore.storeLogo,
              merchstore.customerAdmins,
              merchstore.merchologist
            );
            toast.success('Your store has been closed.');
            refreshMerchstores();
          } catch (error) {
            toast.error(
              'An error occurred closing your store, please try again.'
            );
          }
        };

        const reOpenAction = async () => {
          try {
            await reOpenMerchStore(merchstore.storeCode);
            queueMerchStoreEmails(merchstore.storeCode);
            toast.success('Your store has been re-opened.');
            refreshMerchstores();
          } catch (error) {
            toast.error(
              'An error occurred reopening your store, please try again.'
            );
          }
        };

        const rebuildMerchstoreAction = async () => {
          console.log('rebuild triggered');
          rebuildMerchstore({ subdomain: merchstore.subdomain });
          refreshMerchstores();
        };

        const deleteMerchstoreAction = async () => {
          setSelectedMerchstore(merchstore);
          setDeleteConfirmationModalOpen(true);
        };

        const storeActions = {
          viewEditStore: () => {
            history.push(`/admin/merchstores/${merchstore.storeCode}`);
          },
          viewPendingOrder: () => {
            history.push(
              `/admin/merchstores/${merchstore.storeCode}/orders/active`
            );
          },
          closeStore: closeStoreAction,
          reOpenStore: reOpenAction,
          reassignMerchologist: reassignAction,
          rebuildMerchstore: rebuildMerchstoreAction,
          deleteMerchstore: deleteMerchstoreAction,
        };

        return makeMerchStoreRow(
          merchstore,
          userRole,
          storeActions,
          visibleTableColumns
        );
      }
    );

    setMerchologistFilterOptions(
      createMerchologistFilters(merchologistContacts)
    );

    setCustomerContactFilterOptions(
      createCustomerContactFilters(customerContacts)
    );

    const applyFilters = handleFiltersChosen(
      merchStoreRows,
      statusFilterChosen,
      customerContactFilterChosen,
      merchologistFilterChosen
    );

    if (applyFilters) {
      setMerchStores(applyFilters);
    } else {
      setMerchStores(merchStoreRows);
    }

    if (merchStores) {
      setTimeout(() => setLoading(false), setTableLoading(false), 500);
    }
  };

  const closeMerchStore = async (
    storeCode: string,
    storeName: string,
    storeLogo: string,
    customerAdmins: string[],
    merchologist?: string
  ) => {
    await axios.post('/.netlify/functions/closeMerchStore', { storeCode });
    const recipients = customerAdmins.concat(merchologist ? merchologist : []);
    sendOrderClosed(recipients, storeName, storeLogo, storeCode);
  };

  const reOpenMerchStore = async (storeCode: string) => {
    await axios.post('/.netlify/functions/reOpenMerchStore', { storeCode });
  };

  useEffect(() => {
    if (userRole && userEmail) {
      fetchMerchstores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userRole,
    userEmail,
    statusFilterChosen,
    customerContactFilterChosen,
    merchologistFilterChosen,
    paymentsFilterChosen,
    sortOptionChosen,
    searchTerms,
    columnPreferences,
  ]);

  /// Assign Filter & Sort Actions
  useEffect(() => {
    for (const statusFilter of statusFilterOptions) {
      statusFilter.action = () => {
        setTableLoading(true);
        setStatusFilterChosen(statusFilter);
      };
    }
    for (const customerContact of customerContactFilterOptions) {
      customerContact.action = () => {
        setTableLoading(true);
        setCustomerContactFilterChosen(customerContact);
      };
    }
    for (const merchologistFilter of merchologistFilterOptions) {
      merchologistFilter.action = () => {
        setTableLoading(true);
        setMerchologistFilterChosen(merchologistFilter);
      };
    }
    for (const paymentFilter of paymentsFilterOptions) {
      paymentFilter.action = () => {
        setTableLoading(true);
        setPaymentsFilterChosen(paymentFilter);
      };
    }
    for (const sortOption of sortOptions) {
      sortOption.action = () => {
        setTableLoading(true);
        setSortOptionChosen(sortOption);
      };
    }
  });

  useEffect(() => {
    const localColumnPrefs = window.localStorage.getItem('columnPreferences');
    if (localColumnPrefs !== null) {
      const localColumnPrefsArr = JSON.parse(localColumnPrefs);
      setColumnPreferences([...localColumnPrefsArr]);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      'columnPreferences',
      JSON.stringify(columnPreferences)
    );
  }, [columnPreferences]);

  const setReassignModalClose = () => {
    setReassignModalOpen(false);
  };

  const handleDeleteMerchstoreConfirmationModalClose = () => {
    setDeleteConfirmationModalOpen(false);
  };

  const columns = buildColumns(userRole, resolveVisibleTableColumns());

  const removeFilter = (clickedFilter: string) => {
    setTableLoading(true);

    const filterSetters = new Map<string, CallableFunction>(
      Object.entries({
        status: setStatusFilterChosen,
        customerContact: setCustomerContactFilterChosen,
        merchologist: setMerchologistFilterChosen,
        payments: setPaymentsFilterChosen,
      })
    );

    const filterSetter = filterSetters.get(clickedFilter);

    if (filterSetter) {
      filterSetter(null);
    }
  };

  if (loading) {
    return <Loading isLoading={loading as boolean} />;
  }

  return (
    <>
      <AdminHeader
        title="MerchStores"
        subtitle={getLocalDateText(new Date().toISOString())}
      >
        <div className="flex flex-row">
          <CTA
            classes="mr-2"
            disabled={false}
            size="standard"
            type="secondary"
            icon={<FaTools size={'14px'} />}
            mobileIconView={true}
            onClick={() =>
              (window.location.pathname = '/admin/internal/merchstore-tools')
            }
          >
            {'Tools'}
          </CTA>
          <CTA
            disabled={false}
            size="standard"
            type="primary"
            icon={<MdStore size={iconSize} />}
            mobileIconView={true}
            onClick={() => setNewMerchstoreModalOpen(true)}
          >
            {'New MerchStore'}
          </CTA>
        </div>
      </AdminHeader>

      <div className="dropdowns-container flex justify-between">
        <div className="filter-dropdowns">
          <FilterDropdown
            title="Status"
            list={statusFilterOptions}
            chosenFilter={statusFilterChosen}
            btnClass={
              !isMerchologist && !isSuperUser
                ? 'status-admin'
                : 'status-orders-page'
            }
          />
          {isMerchologist && (
            <FilterDropdown
              title="Customer Contact"
              list={customerContactFilterOptions}
              chosenFilter={customerContactFilterChosen}
              searchable={true}
              btnClass={
                isSuperUser ? 'super-admin-orders' : 'admin-orders-page'
              }
            />
          )}
          {isMerchologist && (
            <FilterDropdown
              title="Merchology Contact"
              list={merchologistFilterOptions}
              chosenFilter={merchologistFilterChosen}
              searchable={true}
              btnClass={
                isSuperUser ? 'super-admin-orders' : 'admin-orders-page'
              }
            />
          )}
          <FilterDropdown
            title="Payments"
            list={paymentsFilterOptions}
            chosenFilter={paymentsFilterChosen}
            btnClass="assigned-orders-page"
          />
        </div>

        <div className="flex search-box-container ml-4 w-1/4">
          <SearchBox onSearch={onSearchAction} />
        </div>

        <div className="sort-orders-container ml-auto">
          <SortDropdown
            title="Sort"
            list={sortOptions}
            defaultSort={defaultSort.id}
          />
          <MultiDropdown
            title="Columns"
            subTitle="Show/Hide Columns"
            className="ml-2"
            list={columnOptions}
            checkedItems={columnPreferences}
            setCheckedItems={setColumnPreferences}
          />
        </div>
      </div>
      <div className="filters-chosen-container">
        {statusFilterChosen && (
          <FilterIndicator
            title={statusFilterChosen.title}
            onRemove={() => removeFilter('status')}
          />
        )}
        {customerContactFilterChosen && (
          <FilterIndicator
            title={customerContactFilterChosen.title}
            onRemove={() => removeFilter('customerContact')}
          />
        )}
        {merchologistFilterChosen && (
          <FilterIndicator
            title={merchologistFilterChosen.title}
            onRemove={() => removeFilter('merchologist')}
          />
        )}
        {paymentsFilterChosen && (
          <FilterIndicator
            title={paymentsFilterChosen.title}
            onRemove={() => removeFilter('payments')}
          />
        )}
      </div>
      <div className="merchstores-container">
        <Card data-class="merchstores">
          {tableLoading ? (
            <div className="py-150">
              <Loading isLoading={tableLoading as boolean} />
            </div>
          ) : (
            <Table
              itemsName="merchstores"
              name="merchstores"
              filter={true}
              columns={columns}
              data={merchstores}
              elementsPerPage={STORES_PER_PAGE}
              hasNextPage={hasNextPage}
              externalPagination={true}
              onPagination={onPagination}
              totalCount={totalCount}
            />
          )}
        </Card>
      </div>
      <CreateMerchstore
        modalOpen={newMerchstoreModalOpen}
        onModalClose={() => setNewMerchstoreModalOpen(false)}
      />
      <Modal
        center
        open={reassignModalOpen}
        onClose={setReassignModalClose}
        classNames={{ modal: 'reassign-merch-modal' }}
      >
        <ReassignMerch
          currentMerch={currentMerch}
          setCurrentMerch={setCurrentMerch}
          currentOrderId={selectedMerchstoreCode}
          open={open}
          setOpen={setReassignModalOpen}
          isMerchstore={true}
          fetchMerchstores={refreshMerchstores}
        />
      </Modal>
      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        onClose={handleDeleteMerchstoreConfirmationModalClose}
        image={selectedMerchstore.storeLogo}
        headlineText="Are you sure you want to delete this Merchstore?"
        bodyText="Once you delete a MerchStore it cannot be restored."
        onConfirmation={() => {
          toast.info(`Deleting merchstore, please wait.`);
          setDeleteConfirmationModalOpen(false);
          deleteMerchstore(selectedMerchstore)
            .then((results) => {
              refreshMerchstores();
              if (results.success) {
                toast.success(`Merchstore deleted`);
              }
            })
            .catch((error) => {
              console.error(`Error while deleting merchstore: ${error}`);
            });
        }}
        onCancellation={handleDeleteMerchstoreConfirmationModalClose}
      />
      <Footer />
    </>
  );
};
