const Model3dSource = {
  name: 'Model3dSource',
  kind: 'OBJECT',
  fieldBaseTypes: {
    filesize: 'Int',
    format: 'String',
    mimeType: 'String',
    url: 'String',
  },
  implementsNode: false,
};
export default Model3dSource;
