const PageInfo = {
  name: 'PageInfo',
  kind: 'OBJECT',
  fieldBaseTypes: {
    hasNextPage: 'Boolean',
    hasPreviousPage: 'Boolean',
  },
  implementsNode: false,
};
export default PageInfo;
