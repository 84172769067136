import axios from 'axios';
import { useCallback, useState } from 'react';
import { IMerchStore, IMerchStoreIdProps } from '..';
import { cloudinaryApplyWidthHeight } from '@merchstores/shared/components/Cloudinary';
import { CloudinaryFileInput } from '@merchstores/shared/elements/CloudinaryFileInput';
import { CTA } from '@merchstores/shared/elements/Cta';
import { ColorPickerInput } from '@merchstores/shared/elements/ColorPickerInput';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useEffect } from 'react';

export default function ThemeTabContent(): JSX.Element {
  const { id: storeCode } = useParams<IMerchStoreIdProps>();
  const [merchStoreFirstLoad, setMerchStoreFirstLoad] = useState(
    {} as IMerchStore
  );
  const [merchStore, setMerchStore] = useState({} as IMerchStore);
  const [themeEditActive, setThemeEditActive] = useState(false);
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const fetchMerchStore = useCallback((id: string): Promise<IMerchStore> => {
    return new Promise((resolve, reject) => {
      axios
        .post('/.netlify/functions/fetchMerchStoreById', {
          storeCode: id,
        })
        .then((res) => {
          if (res.data) {
            if (!res.data.headerColor) {
              res.data.headerColor = '#FFFFFF';
            }
            if (!res.data.backgroundColor) {
              res.data.backgroundColor = '#EBEBF0';
            }
            if (!res.data.accentColor) {
              res.data.accentColor = '#000000';
            }
            if (!res.data.footerLinks) {
              res.data.footerLinks = [
                { name: '', url: '' },
                { name: '', url: '' },
                { name: '', url: '' },
              ];
            }
            setMerchStore(res.data);
            setMerchStoreFirstLoad(res.data);
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, []);

  useEffect(() => {
    fetchMerchStore(storeCode);
  }, [fetchMerchStore, storeCode]);

  useEffect(() => {
    if (merchStore !== merchStoreFirstLoad) {
      setThemeEditActive(true);
    } else {
      setThemeEditActive(false);
    }
  }, [merchStore, merchStoreFirstLoad]);

  async function updateMerchStore(e: React.SyntheticEvent) {
    e.preventDefault();
    setThemeEditActive(false);
    return new Promise((resolve, reject) => {
      const updatedMerchStore: any = merchStore;

      if (updatedMerchStore.bannerImage) {
        const newBannerImage = cloudinaryApplyWidthHeight(
          updatedMerchStore.bannerImage,
          2880,
          640
        );
        updatedMerchStore.bannerImage = newBannerImage;
      }

      axios
        .post('/.netlify/functions/updateMerchStore', {
          mutationType: 'updateMerchStore',
          merchStoreData: updatedMerchStore,
        })
        .then((merchStoreResp) => {
          resolve(merchStoreResp);
          toast.success('Theme updated successfully');
        })
        .catch((err) => {
          reject(err);
          setThemeEditActive(true);
          toast.error('Error updating theme');
        });
    });
  }

  function isCloseToWhite(colorCode: string) {
    if (colorCode.startsWith('#')) {
      colorCode = colorCode.substring(1);
    }
    if (colorCode.length === 3) {
      colorCode =
        colorCode[0] +
        colorCode[0] +
        colorCode[1] +
        colorCode[1] +
        colorCode[2] +
        colorCode[2];
    }
    const red = parseInt(colorCode.substring(0, 2), 16);
    const green = parseInt(colorCode.substring(2, 4), 16);
    const blue = parseInt(colorCode.substring(4, 6), 16);
    return !(
      Math.abs(red - 255) > 32 ||
      Math.abs(green - 255) > 32 ||
      Math.abs(blue - 255) > 32
    );
  }

  function onAccentColorChange(newColor: string) {
    if (isCloseToWhite(newColor)) {
      setError('store-accent-color', {
        type: 'manual',
        message: `The color ${newColor} cannot be selected.`,
      });
    } else {
      clearErrors('store-accent-color');
      setMerchStore({ ...merchStore, accentColor: newColor } as IMerchStore);
    }
  }

  function onFooterLinkChange(newLink: any) {
    const fieldArr = newLink.nativeEvent.srcElement.name.split('-');
    const field = fieldArr[0];
    const index = parseInt(fieldArr[1]);
    const fieldContent = newLink.nativeEvent.srcElement.value;
    const footerLinks = merchStore.footerLinks;
    const newFooterLinks = [...footerLinks];
    newFooterLinks[index][field] = fieldContent;
    setMerchStore({ ...merchStore, footerLinks: newFooterLinks });
  }

  return (
    <div className="w-full px-4 md:p-0">
      <form
        onSubmit={(e) => updateMerchStore(e)}
        className="w-full"
        id="edit-theme-form"
      >
        <div className="flex xl:flex-row flex-col w-full xl:space-x-4 mt-4">
          <CloudinaryFileInput
            name="store-logo"
            type="one"
            updateContent={(data) => {
              setMerchStore({ ...merchStore, storeLogo: data[0] });
            }}
            content={merchStore.storeLogo ? [merchStore.storeLogo] : []}
            maxFiles={1}
            label="Store Logo"
            placeholder="JPEGs, PNGs or SVGs"
            tooltip={
              <>
                <p className="mb-3">
                  Logo should have a minimum height of 90px.
                </p>
                <a
                  href="https://www.merchology.com/pages/faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  Artwork Guidelines
                </a>
              </>
            }
            helpText="Min. height of 88px"
            disabled={merchStore && merchStore.status === 'closed'}
            cloudinarySource="merchstores"
          />
          <CloudinaryFileInput
            name="store-banner-image"
            type="one"
            updateContent={(imgArr) => {
              setMerchStore({ ...merchStore, bannerImage: imgArr[0] });
            }}
            content={merchStore.bannerImage ? [merchStore.bannerImage] : []}
            maxFiles={1}
            label="Banner Image"
            placeholder="JPEGs, PNGs or SVGs"
            tooltip={
              <>
                <p className="mb-3">
                  Recommended banner image size is 2880 pixels wide by 640
                  pixels tall. For best results keep important elements of your
                  image centered within the middle third of the banner image.
                </p>
                <a
                  href="https://www.merchology.com/pages/faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  Artwork Guidelines
                </a>
              </>
            }
            helpText="Recommended: 2880px x 640px"
            disabled={merchStore && merchStore.status === 'closed'}
            cloudinarySource="merchstores"
          />
        </div>
        <div className="flex xl:flex-row flex-col w-full xl:space-x-4">
          <ColorPickerInput
            label="Store Header Color"
            id="store-header-color"
            onChange={(color: string) => {
              setMerchStore({ ...merchStore, headerColor: color });
            }}
            register={register}
            errors={errors}
            required={false}
            name="store-header-color"
            defaultValue={
              merchStore && merchStore.headerColor
                ? merchStore.headerColor
                : null
            }
            tooltip="Select the color of your header. If your logo has a background, use the same color."
          />
          <ColorPickerInput
            label="Store Background Color"
            id="store-background-color"
            onChange={(color: string) => {
              setMerchStore({ ...merchStore, backgroundColor: color });
            }}
            register={register}
            errors={errors}
            required={false}
            name="store-background-color"
            defaultValue={
              merchStore && merchStore.backgroundColor
                ? merchStore.backgroundColor
                : null
            }
            tooltip="Select the color of your stores background."
          />
          <ColorPickerInput
            label="Store Accent Color"
            id="store-accent-color"
            onChange={onAccentColorChange}
            register={register}
            errors={errors}
            required={false}
            name="store-accent-color"
            defaultValue={
              merchStore && merchStore.accentColor
                ? merchStore.accentColor
                : null
            }
            tooltip="Select the color of your call to action buttons and text links."
          />
        </div>
        <div className="flex xl:flex-row flex-col w-full xl:space-x-4">
          <FormInput
            textArea={true}
            inputStyle="two"
            name="store-message"
            defaultValue={merchStore ? merchStore.storeMessage : ''}
            onChange={(e) =>
              setMerchStore({ ...merchStore, storeMessage: e.target.value })
            }
            placeholder="Header Message"
            type="text"
            label="Store Message Header"
            required={false}
            errors={errors}
            register={register}
            tooltip="This messaging displays on your MerchStore and provides some context as to what the user can do and see."
            disabled={merchStore && merchStore.status === 'closed'}
          />
          <FormInput
            textArea={true}
            inputStyle="two"
            name="store-sub-message"
            defaultValue={merchStore ? merchStore.storeSubMessage : ''}
            onChange={(e) =>
              setMerchStore({ ...merchStore, storeSubMessage: e.target.value })
            }
            placeholder="Message"
            type="text"
            label="Store Message"
            required={false}
            errors={errors}
            register={register}
            tooltip="This messaging displays on your MerchStore and provides some context as to what the user can do and see."
            disabled={merchStore && merchStore.status === 'closed'}
          />
          <FormInput
            textArea={true}
            inputStyle="two"
            name="footer-message"
            defaultValue={merchStore ? merchStore.footerMessage : ''}
            onChange={(e) =>
              setMerchStore({ ...merchStore, footerMessage: e.target.value })
            }
            maxlength="250"
            placeholder="Message"
            type="text"
            label="Footer Message"
            required={false}
            errors={errors}
            register={register}
            tooltip="This message displays in the footer of your MerchStore."
            disabled={merchStore && merchStore.status === 'closed'}
          />
        </div>
        {merchStore &&
          merchStore.footerLinks?.map(
            (link: { name: string; url: string }, index: number) => (
              <div
                className="flex xl:flex-row flex-col w-full xl:space-x-4"
                key={index}
              >
                <FormInput
                  textArea={false}
                  inputStyle="two"
                  name={`name-${index}`}
                  defaultValue={link.name}
                  onChange={(e) => onFooterLinkChange(e)}
                  placeholder="Title"
                  type="text"
                  label={`Link #${index + 1} Title`}
                  required={false}
                  errors={errors}
                  register={register}
                  tooltip="This link displays in the footer of your MerchStore."
                  disabled={merchStore && merchStore.status === 'closed'}
                />
                <FormInput
                  textArea={false}
                  inputStyle="two"
                  name={`url-${index}`}
                  defaultValue={link.url}
                  onChange={(e) => onFooterLinkChange(e)}
                  placeholder="url"
                  type="text"
                  label={`Link #${index + 1} url`}
                  required={false}
                  errors={errors}
                  register={register}
                  tooltip="This link displays in the footer of your MerchStore."
                  disabled={merchStore && merchStore.status === 'closed'}
                />
              </div>
            )
          )}
        <div className="flex xl:flex-row flex-col w-full justify-center items-center mt-4">
          <div className="w-1/3">
            <CTA
              disabled={!themeEditActive}
              stretch={true}
              formSubmit={true}
              size="standard"
              type="primary"
            >
              {'Save Changes'}
            </CTA>
          </div>
        </div>
      </form>
    </div>
  );
}
