import { DateTime } from 'luxon';
import * as storefrontQuery from '@merchstores/admin/queries/storefront-graphql';
import { buildLineItemAttributes } from '@merchstores/admin/components/MerchStoreOrderCheckout';
import { resolveMerchStoreDomain } from '@merchstores/admin/components/MerchStore/Domain';
import { resolveMerchstoreUrl } from '@merchstores/admin/components/MerchStore/Url';
import { customAttr } from '@merchstores/admin/components/MerchStoreOrderCheckout';
import { IOrderItem } from '@merchstores/admin/types/merchstoreOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { merchstoreStorefrontClient } from '@merchstores/admin/App';
import {
  OrderType,
  PayerType,
} from '@merchstores/shared/components/MerchStore';
import {
  ICheckoutFields,
  ICheckoutUserError,
  ICustomAttribute,
} from '@merchstores/admin/types/shopifyCheckout';

import { PaymentMethod } from '@merchstores/admin/types/merchstoreOrder';

import { IMerchStoreGiftCardsStatus } from '@merchstores/admin/types/merchstore';

const SHOPIFY_CHECKOUT_DOMAIN = process.env.REACT_APP_SHOPIFY_CHECKOUT_DOMAIN;

export interface IGiftCardSelection {
  giftCardQuantity: number;
  giftCardValue: string;
}

export function buildCheckoutFields(
  orderItems: Array<IOrderItem>,
  merchstore: IMerchStore,
  giftCardsStatus: IMerchStoreGiftCardsStatus,
  cardSelection: IGiftCardSelection
): ICheckoutFields {
  const storeDomain = resolveMerchStoreDomain(merchstore);

  const storeUrl = resolveMerchstoreUrl(merchstore);

  const baseLineItemAttributes: Array<ICustomAttribute> = [];

  const paymentMethods: Array<PaymentMethod> = [PaymentMethod.CREDIT_CARD];

  if (
    giftCardsStatus?.preApprovedPaymentMethod ||
    giftCardsStatus?.preApprovedPaymentMethodLongTerm
  ) {
    paymentMethods.push(PaymentMethod.PRE_APPROVED_OTHER);
  }

  const lineItems = orderItems.map((item) => {
    const lineItemAttributes = baseLineItemAttributes.concat(
      buildLineItemAttributes(item /*, merchstore*/)
    );

    const isGid = /gid:/.test(item.variantId);
    const variantGid = isGid
      ? item.variantId
      : `gid://shopify/ProductVariant/${item.variantId}`;

    const encodedVariantId = Buffer.from(variantGid).toString('base64');

    return {
      variantId: encodedVariantId,
      quantity: item.quantity,
      customAttributes: lineItemAttributes,
    };
  });

  const checkoutCustomizationAttributes = [
    customAttr('payer_type', PayerType.INDIVIDUAL),
    customAttr('order_type', OrderType.GIFT_CARD),
    customAttr(
      'ship_to_office',
      String(String(merchstore.shipToOffice) === 'true')
    ),
    /* Giftcards must not have shipping */
    customAttr('charge_shipping', 'false'),
    customAttr('gift_card_quantity', String(cardSelection.giftCardQuantity)),
    customAttr('gift_card_value', String(cardSelection.giftCardValue)),
    customAttr('payment_methods', paymentMethods.map(String).join(',')),
  ];

  if (lineItems.length) {
    lineItems[0].customAttributes = lineItems[0].customAttributes.concat(
      checkoutCustomizationAttributes.map((attr) => {
        return {
          key: `_${attr.key}`,
          value: attr.value,
        };
      })
    );
  }

  if (lineItems.length > 1) {
    const lastElem = lineItems.length - 1;
    lineItems[lastElem].customAttributes = lineItems[
      lastElem
    ].customAttributes.concat(checkoutCustomizationAttributes);
  }

  const closeDate = DateTime.now().toISODate();

  const cartAttributes: Array<ICustomAttribute> = [
    customAttr('subdomain', merchstore.subdomain),
    customAttr('merchstore_domain', storeDomain),
    customAttr('merchstore_url', storeUrl),
    customAttr('merchstore_code', merchstore.storeCode + ''),
    customAttr('payer_type', PayerType.INDIVIDUAL),
    customAttr('order_type', OrderType.GIFT_CARD),
    customAttr('ship_to_office', 'false'),
    customAttr('merchstore_logo', merchstore.storeLogo + ''),
    /* Giftcards must not have shipping */
    customAttr('charge_shipping', 'false'),
    customAttr('close_date', closeDate),
    customAttr('gift_card_quantity', String(cardSelection.giftCardQuantity)),
    customAttr('gift_card_value', String(cardSelection.giftCardValue)),
    customAttr('payment_methods', paymentMethods.map(String).join(',')),
  ].filter((attr) => typeof attr.value !== 'undefined');

  const checkoutFields: ICheckoutFields = {
    lineItems,
    customAttributes: cartAttributes,
  };

  if (merchstore.shipToOffice && merchstore.officeAddress) {
    checkoutFields.shippingAddress = {
      company: merchstore.officeAddress.company,
      firstName: merchstore.officeAddress.firstName,
      lastName: merchstore.officeAddress.lastName,
      address1: merchstore.officeAddress.address1,
      address2: merchstore.officeAddress.address2,
      province: merchstore.officeAddress.province,
      zip: merchstore.officeAddress.zip,
      city: merchstore.officeAddress.city,
      country: merchstore.officeAddress.country,
      phone: merchstore.officeAddress.phone
        ? merchstore.officeAddress.phone
        : '',
    };
  }

  return checkoutFields;
}

export interface ICheckoutStatus {
  checkoutUrl: string;
  errors: Array<ICheckoutUserError>;
}

export async function requestCheckoutUrl(
  checkoutFields: ICheckoutFields
): Promise<ICheckoutStatus> {
  const checkoutCreateMutation =
    storefrontQuery.getCheckoutCreateMutation(checkoutFields);

  const createResponse = await merchstoreStorefrontClient.send(
    checkoutCreateMutation
  );

  if (createResponse.errors) {
    throw new Error(JSON.stringify(createResponse.errors));
  }

  const createData = createResponse.data.checkoutCreate;

  const createErrors = createData.checkoutUserErrors;

  // const errors = await extractCheckoutErrors(createErrors, checkoutFields);

  const checkoutData = createData.checkout;
  let checkoutUrl = checkoutData ? checkoutData.webUrl : '';

  if (SHOPIFY_CHECKOUT_DOMAIN) {
    checkoutUrl = checkoutUrl.replace(
      /https:\/\/[^.]+\.myshopify\.com\//,
      `https://${SHOPIFY_CHECKOUT_DOMAIN}/`
    );
  }

  return {
    checkoutUrl: checkoutUrl,
    errors: createErrors,
  };
}
