const CheckoutCompleteWithTokenizedPaymentPayload = {
  name: 'CheckoutCompleteWithTokenizedPaymentPayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    checkout: 'Checkout',
    checkoutUserErrors: 'CheckoutUserError',
    payment: 'Payment',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CheckoutCompleteWithTokenizedPaymentPayload;
