import { useState } from 'react';
import axios from 'axios';
import Collapsible from 'react-collapsible';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { MdFileUpload } from 'react-icons/md';
import { CTA } from '@merchstores/shared/elements/Cta';

import { toast } from 'react-toastify';
import { parse } from 'papaparse';

import { IMemberProps } from '../CreateEditGroupOrder';
import { InviteGroupOrderMemberProps } from '.';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { isValidEmail } from '@merchstores/admin/components/GroupOrderMemberSubmission/EmailValidation';

export const InviteGroupOrderMember = (
  props: InviteGroupOrderMemberProps
): JSX.Element => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [expand, setExpand] = useState(props.display);
  const [submitting, setSubmitting] = useState(false);

  const iconSize = '20px';

  //Checks if the url is for creating a new order, and expands if true.
  if (props.order && window.location.href.indexOf('group-orders/new') > -1) {
    setExpand(true);
  }

  useEffect(() => {
    if (expand !== props.display) {
      setExpand(props.display);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.display]);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // validate that the CSV is a single column of emails
    const validateCSV = (csvData: any) => {
      let valid = true;
      csvData.every((row: any) => {
        if (row.length > 1) {
          valid = false;
          toast.error(
            'Uploaded CSV files should be a single column of email addresses. ' +
              'Please check the format of your CSV and re-upload'
          );
          return false;
        } else {
          return true;
        }
      });
      return valid;
    };
    const files = e.target.files;
    if (files?.length) {
      parse(files[0], {
        complete: (results: any) => {
          if (validateCSV(results.data)) {
            const currentValue = getValues('emailInput').trim();
            let emailStr = '';
            results.data.forEach((emailEntry: any, index: number) => {
              if (index === 0) {
                if (currentValue.length) {
                  if (currentValue.indexOf(emailEntry[0]) === -1) {
                    if (currentValue[currentValue.length - 1] === ',') {
                      emailStr += `${currentValue} ${emailEntry[0]}`;
                    } else {
                      emailStr += `${currentValue}, ${emailEntry[0]}`;
                    }
                  } else {
                    emailStr += currentValue;
                  }
                } else {
                  emailStr += emailEntry[0];
                }
              } else {
                if (currentValue.length) {
                  if (currentValue.indexOf(emailEntry[0]) === -1) {
                    emailStr += `, ${emailEntry[0]}`;
                  }
                } else {
                  emailStr += `, ${emailEntry[0]}`;
                }
              }
            });
            setValue('emailInput', emailStr);
          }
        },
      });
    }
  };

  const addToString = (targetString: string, stringToAdd: string) => {
    if (!targetString.length) {
      targetString += stringToAdd;
    } else {
      targetString += `, ${stringToAdd}`;
    }
    return targetString;
  };

  const checkEmails = (emailList: Array<string>) => {
    let emailErrors = false;
    let invalidEmails = '';

    emailList.forEach((email: string) => {
      if (!isValidEmail(email)) {
        emailErrors = true;
        invalidEmails = addToString(invalidEmails, email);
      }
    });
    // if there are invalid emails, allow the user to remove or correct them
    if (invalidEmails.length) {
      toast.error(
        `The following emails are invalid, please correct or remove before resubmitting: ${invalidEmails}`
      );
      setSubmitting(false);
    }
    return emailErrors;
  };

  const removeDuplicates = (emailList: Array<string>) => {
    let duplicateEmails = '';
    let parsedEmailString = '';
    const memberList = props.memberAndSubmissionDetails.map(
      (member: IMemberProps) => {
        return member.email;
      }
    );
    const parsedEmails = emailList.filter((email: string) => {
      if (memberList.includes(email)) {
        duplicateEmails = addToString(duplicateEmails, email);
      } else {
        parsedEmailString = addToString(parsedEmailString, email);
        return email;
      }
    });
    // if any of the emails are already added to the order, remove them automatically before sending to API
    if (duplicateEmails.length) {
      toast.warning(
        `The following emails have already been invited to the order and were automatically removed: ${duplicateEmails}`
      );
      setValue('emailInput', parsedEmailString);
    }

    const uniqueParsedEmails = Array.from(new Set(parsedEmails));

    return uniqueParsedEmails;
  };

  const onSubmit = ($form: any) => {
    setSubmitting(true);
    const emailInput = $form.emailInput;

    if (emailInput === '') {
      toast.error('Email field cannot be empty.');
      setSubmitting(false);
      return false;
    }

    const emailArray: Array<string> = emailInput
      .split(',')
      .map(function (email: string) {
        return email.trim().toLowerCase();
      })
      .filter(Boolean);
    const emailErrors = checkEmails(emailArray);

    const parsedEmails = removeDuplicates(emailArray);

    if (!emailErrors && !parsedEmails.length) {
      toast.error('Email field must contain at least one email.');
      setSubmitting(false);
      return false;
    }

    if (parsedEmails.length && !emailErrors) {
      axios
        .post('/.netlify/functions/uploadGroupMember', {
          params: {
            emailArray: parsedEmails,
            groupOrderId: props.order.groupOrderId,
            verificationCode: props.order.verificationCode === true,
          },
        })
        .then((res) => {
          // if any members were unable to add, reset the input values to those values so a user can attempt to resubmit
          if (res.data.memberErrors.length) {
            toast.error(
              'Some members were unable to be added, please try again.'
            );
          } else {
            if (props.onMembersAdded) {
              props.onMembersAdded(res.data.membersAdded);
            }
            toast.success('Members added successfully');
            setSubmitting(false);
            clearForm();
          }
        })
        .catch((err) => {
          toast.error(
            'An unexpected error has occurred, please try again in a few minutes.',
            { autoClose: false }
          );
          console.error(err);
        });
    }
  };

  const clearForm = () => {
    setValue('emailInput', '');
  };

  return (
    <div>
      {/* In order for Collapsible to close, it must have a transition time greater than 1. */}
      {/* eslint-disable */}
      {/* @ts-ignore */}
      <Collapsible open={expand} transitionTime={1}>
        <div>
          <div className="flex w-full justify-end mb-4">
            <input
              type="file"
              accept=".csv"
              id="member-hidden-input"
              className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
              onChange={(e) => handleFileInput(e)}
            ></input>
            <div className="flex flex-col">
              <CTA
                type="tertiary"
                size="standard"
                icon={<MdFileUpload size={iconSize} />}
                onClick={() => {
                  const fileInput = document.getElementById(
                    'member-hidden-input'
                  ) as HTMLInputElement;
                  fileInput.click();
                }}
              >
                Upload CSV &#160;
              </CTA>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              type="textarea"
              textArea={true}
              name="emailInput"
              id="emailInput"
              register={register}
              errors={errors}
              required={false}
              placeholder="Add email addresses separated by commas"
              inputStyle="two"
            />
            <br />
            <div className="float-right flex gap-4 mb-4">
              <CTA
                type={'primary'}
                size={'standard'}
                formSubmit={true}
                disabled={submitting}
              >
                {submitting ? 'Saving...' : 'Save'}
              </CTA>
              <CTA
                type={'secondary'}
                size={'standard'}
                icon={<RiDeleteBin5Line />}
                onClick={clearForm}
              />
            </div>
          </form>
        </div>
      </Collapsible>
    </div>
  );
};
