import { ISearchBarProps } from './index';
import './styles.scss';
import { MdSearch } from 'react-icons/md';
import { useState, useEffect } from 'react';

export const SearchBar: React.FC<ISearchBarProps> = (
  props: ISearchBarProps
) => {
  const [productSearch, setProductSearch] = useState('');
  const [query, setQuery] = useState('');

  const clearInput = (e: any) => {
    e.preventDefault();
    setProductSearch('');
    setQuery('');
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setProductSearch(e.target.value);
    setQuery(e.target.value.length > 2 ? e.target.value : '');
  };

  useEffect(() => {
    props.onChange(query);
  }, [query]);

  return (
    <div className="flex search-bar-container mb-15">
      <div className="w-full">
        <input
          className="textbox w-full"
          type="text"
          placeholder={props.placeholder}
          value={productSearch}
          onChange={handleChange}
        />
      </div>
      <div className="relative w-0">
        <div className="search-icon">
          <MdSearch size={'24px'} />
        </div>
      </div>
      <div className="flex flex-wrap content-center">
        <a
          href="#"
          className="hidden md:block clearBtn ml-15"
          onClick={clearInput}
        >
          Clear
        </a>
      </div>
    </div>
  );
};
