const __EnumValue = {
  name: '__EnumValue',
  kind: 'OBJECT',
  fieldBaseTypes: {
    deprecationReason: 'String',
    description: 'String',
    isDeprecated: 'Boolean',
    name: 'String',
  },
  implementsNode: false,
};
export default __EnumValue;
