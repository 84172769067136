import { useEffect } from 'react';
import { useState } from 'react';
import { IDropdownProps } from '.';
import './styles.scss';
import {
  MdOutlineViewColumn,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
} from 'react-icons/md';
import { generateReactKey } from '@merchstores/shared/react/ReactKey';
import { useRef } from 'react';

export function MultiDropdown({
  title,
  subTitle,
  className: overrides,
  list,
  checkedItems,
  setCheckedItems,
}: IDropdownProps) {
  const [listOpen, setListOpen] = useState(false);
  const wrapperRef = useRef(null);

  list = list.filter((item) => item.id != null || item.title != null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setListOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousemove', handleClickOutside);
    };
  }, [checkedItems, list]);

  const selectItem = (item: any) => {
    const newCheckedItems = checkedItems.some(
      (checkedItem) =>
        checkedItem.id === item.id && checkedItem.title === item.title
    )
      ? checkedItems.filter(
          (checkedItem) =>
            checkedItem.id !== item.id || checkedItem.title !== item.title
        )
      : [...checkedItems, item];

    setCheckedItems(newCheckedItems);
  };

  return (
    <div
      className={`multidrop-container ${overrides} ${listOpen ? 'open' : ''}`}
      id={''}
      ref={wrapperRef}
    >
      <button
        type="button"
        className="multidrop-header text-merch-dark-gray font-bold rounded-md"
        onClick={() => setListOpen(!listOpen)}
      >
        <div className="multidrop-header-title">{title || ''}</div>
        <div className="desktop">
          <MdOutlineViewColumn size={20} />
        </div>
        <div className="mobile">
          <MdOutlineViewColumn size={20} />
        </div>
      </button>
      {listOpen && (
        <div role="list" className="multidrop-list pb-2">
          <span
            onClick={() => setListOpen(!listOpen)}
            className="multidrop-list-title p-3"
          >
            {subTitle || ''}
          </span>
          {list.map((item) => (
            <div
              onClick={() => selectItem(item)}
              className="multidrop-list-item hover:bg-merch-dark-gray hover:bg-opacity-5 py-1 px-3"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              key={generateReactKey(item.id)}
            >
              {checkedItems.some(
                (checkedItem) =>
                  item.id === checkedItem.id && item.title === checkedItem.title
              ) ? (
                <div className="filter-select-button">
                  <MdOutlineCheckBox />
                </div>
              ) : (
                <MdOutlineCheckBoxOutlineBlank color="#4B4B55" />
              )}
              <span style={{ marginLeft: '8px', color: '#4B4B55' }}>
                {' '}
                {item.title}{' '}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
