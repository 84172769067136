const AutomaticDiscountApplication = {
  name: 'AutomaticDiscountApplication',
  kind: 'OBJECT',
  fieldBaseTypes: {
    allocationMethod: 'DiscountApplicationAllocationMethod',
    targetSelection: 'DiscountApplicationTargetSelection',
    targetType: 'DiscountApplicationTargetType',
    title: 'String',
    value: 'PricingValue',
  },
  implementsNode: false,
};
export default AutomaticDiscountApplication;
