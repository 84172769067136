import { IFormOptionProps } from '@merchstores/shared/elements/FormSelect';
import { IShippingPackageProps } from './MemberSubmission';
import { extractArtworkFileName } from './Artwork';

export function makeArtworkOptions(
  selectedPackage: IShippingPackageProps
): IFormOptionProps[] {
  if (!selectedPackage.artwork) {
    return [];
  }

  return selectedPackage.artwork.map((artworkItem) => {
    const artworkItemFileName = extractArtworkFileName(artworkItem);

    return {
      value: artworkItem.logo,
      displayText: artworkItemFileName,
    };
  });
}
