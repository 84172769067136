const CustomerAccessTokenDeletePayload = {
  name: 'CustomerAccessTokenDeletePayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    deletedAccessToken: 'String',
    deletedCustomerAccessTokenId: 'String',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CustomerAccessTokenDeletePayload;
