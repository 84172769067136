const CreditCardPaymentInput = {
  name: 'CreditCardPaymentInput',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    amount: 'Money',
    idempotencyKey: 'String',
    billingAddress: 'MailingAddressInput',
    vaultId: 'String',
    test: 'Boolean',
  },
};
export default CreditCardPaymentInput;
