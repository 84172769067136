/* eslint-disable max-len */
import axios from 'axios';
import { DateTime } from 'luxon';
import { useState, useEffect, useContext, useCallback } from 'react';
import { IconContext } from 'react-icons';
import {
  MdClose,
  MdDone,
  MdEdit,
  MdNotificationsNone,
  MdOpenInNew,
  MdAdd,
} from 'react-icons/md';
import { IoMdPersonAdd } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card } from '@merchstores/shared/elements/Card';
import { Tab, Tabs, TabBr, Content } from '@merchstores/shared/elements/Tabs';
import { CTA } from '@merchstores/shared/elements/Cta';
import {
  FormSelect,
  IFormOptionProps,
} from '@merchstores/shared/elements/FormSelect';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';
import { ConfirmationModal } from '@merchstores/shared/elements/ConfirmationModal';
import { SchedulerInput } from '@merchstores/shared/elements/SchedulerInput';
import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import { Context } from '@merchstores/admin/context';
import { IMerchStore, IMerchStoreIdProps } from './index';
import { MerchStoreMemberList } from '../MerchStoreMemberList';
import { ProductSearch } from '../MerchStore/ProductSearch/ProductSearch';
import {
  IMerchStoreProduct,
  IProductImage,
  IProductVariant,
  MerchStoreProductList,
} from './MerchStoreProductList';
import { MerchStoreProductEdit } from './MerchStoreProductEdit';
import { OrderDetailsPage } from '@merchstores/admin/components/MerchStoreOrderDetails';
import { cloudinaryApplyWidthHeight } from '@merchstores/shared/components/Cloudinary';
import { MerchStoreTiles } from './MerchStoreTiles';
import {
  newMerchStoreStatusOptions,
  buildMerchStoreStatusOptions,
} from './MerchStoreStatusOptions';
import { storeProductsCache } from '@merchstores/admin/context/Cache';
import { Loading } from '@merchstores/shared/components/Loading';
import { OrderIndividualDetailsPage } from '../MerchStoreOrderIndividualDetails';
import { MerchstoreShipToOffice } from './MerchstoreShipToOffice';
import { CustomSubdomainModal } from '../CustomSubdomainModal';
import { isEmpty, isUndefined } from 'lodash';
import {
  userHasRole,
  Role,
  ROLE_GROUPADMIN,
  ROLE_MERCHOLOGIST,
  ROLE_SUPERUSER,
} from '@merchstores/admin/context/Roles';
import { sendEmail } from '@merchstores/shared/components/Mailer';
import { resolveMerchstoreUrl } from '@merchstores/admin/components/MerchStore/Url';
import {
  defaultScheduleOption,
  generateMerchStoreScheduleOptions,
} from './MerchStoreScheduleOptions';
import { newMerchStorePaymentOptions } from './MerchstorePaymentOptions';
import { newMerchStoreTypeOptions } from './MerchstoreStoreTypeOptions';
import { chargeShippingUnlessExplicitFalse } from '@merchstores/shared/components/Shipping';
import { ContactInfoModal } from '@merchstores/admin/components/CreateEditMerchStore/ContactInfo/ContactInfoModal';
import { IMerchStoreContactInfo } from '../MerchStore';
import { isAutoCloseOrdersAllowed } from './AutoCloseOrders';
import { BuyGiftCardButton, GiftCardsOrders } from './MerchStoreGiftCardsTab';
import { OrderType } from '@merchstores/shared/components/MerchStore';
import { StoreContext } from '@merchstores/admin/context/Store';
import GiftCardOrderDetail from './MerchStoreGiftCardsTab/GiftCardOrderDetail';
import useGiftCardsStatus from '@merchstores/admin/hooks/giftCards/useGiftCardsStatus';
import MerchStoreOrdersTab from './MerchStoreOrdersTab';
import {
  buildTrackingNotificationOptions,
  resolveTrackingNotification,
} from './TrackingNotificationOptions';
import { fetchFilteredCollectionProducts } from '@merchstores/admin/api/products/MerchStoreProducts';
import { IProductResult } from '@merchstores/admin/api/products/MerchStoreProducts';
import { Footer } from '@merchstores/shared/elements/Footer';
import ThemeTabContent from './MerchStoreThemeTab/ThemeTabContent';

export const CreateEditMerchStore: React.FC = () => {
  const { id: storeCode, orderId } = useParams<IMerchStoreIdProps>();
  const history = useHistory();
  const { userRole, userEmail } = useContext(Context);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const [, setSelectedStatus] = useState(undefined);
  const [active, setActive] = useState(0);
  const [customSubdomainModalOpen, setCustomSubdomainModalOpen] =
    useState(false);
  const [addProductsModalOpen, setAddProductsModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [disabledPasswords, setDisabledPassword] = useState(false);
  const [disabledProductIds, setDisabledProductIds] = useState(undefined);
  const [formData, setFormData] = useState(false);
  const [formFound, setFormFound] = useState(false);
  const [invite, setInvite] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [merchstoreCollection, setMerchstoreCollection] = useState(undefined);
  const [members, setMembers] = useState([]);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [merchStore, setMerchStoreDetails] = useState(undefined);
  const [merchStoreEditActive, setMerchStoreEditActive] = useState(false);
  const [merchStoreError, setMerchStoreError] = useState(false);
  const [merchStoreLogo, setMerchStoreLogo] = useState(null);
  const [merchStoreName, setMerchStoreName] = useState('');
  const [merchStoreStatusOptions, setMerchStoreStatusOptions] = useState(
    newMerchStoreStatusOptions()
  );
  const [changeToActive, setChangeToActive] = useState(false);
  const [changeToClosed, setChangeToClosed] = useState(false);
  const [nameEditActive, setNameEditActive] = useState(false);
  const [newMerchStoreName, setNewMerchStoreName] = useState('');
  const [passwordSelected, setPasswordSelected] = useState(false);
  const [productListLoading, setProductListLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sortOptionChosen, setSortOptionChosen] = useState('nameDesc');
  const [storeBannerImage, setStoreBannerImage] = useState(null);
  const [storeLogoError, setStoreLogoError] = useState(false);
  const [tempMerchStoreUrl, setTempStoreUrl] = useState(undefined);
  const [shipToOfficeModal, setShipToOfficeModal] = useState(false);
  const [shipToOffice, setShipToOffice] = useState(
    merchStore && merchStore.shipToOffice
  );
  const [baggedLabeled, setBaggedLabeled] = useState(
    merchStore && merchStore.baggedLabeled
  );
  const [shouldToggleOverflow, setShouldToggleOverflow] = useState(false);
  const [officeAddress, setOfficeAddress] = useState(
    merchStore && merchStore.officeAddress ? merchStore.officeAddress : {}
  );
  const [merchStoreAlwaysOpen, setMerchStoreAlwaysOpen] = useState(
    merchStore && merchStore.alwaysOpen ? merchStore.alwaysOpen : 'true'
  );
  const [merchStorePayerType, setMerchStorePayerType] = useState(
    merchStore && merchStore.payerType ? merchStore.payerType : 'admin_payer'
  );
  const [chargeShipping, setChargeShipping] = useState(
    merchStore
      ? chargeShippingUnlessExplicitFalse(merchStore.chargeShipping)
      : chargeShippingUnlessExplicitFalse()
  );

  const [trackingNotification, setTrackingNotification] = useState(
    resolveTrackingNotification(merchStore && merchStore.trackingNotification)
  );

  useGiftCardsStatus(storeCode);

  const { state, setGiftCardsStatus } = useContext(StoreContext);

  const [isGiftCardsEnabled, setIsGiftCardsEnabled] = useState();
  const [isGiftCardsEnabledLocked, setIsGiftCardsEnabledLocked] = useState();

  // Auto-Process
  const [autoProcessOrders, setAutoProcessOrders] = useState(
    merchStore && merchStore.autoProcessOrders
  );
  // ContactInfo
  const [contactInfoModal, setContactInfoModal] = useState(false);
  // End states

  const paymentMethodTooltip = (
    <span>
      <b>Administrator Pays:</b> Store payments will be grouped and paid by the
      Store Administrator.
      <br />
      <br />
      <b>Individual Pays:</b> Store payments will be handled individually and
      paid by each employee.
    </span>
  );
  const storeTypeTooltip = (
    <span>
      <b>Always Open:</b> The store remains open (it does not have a close date)
      and orders that are submitted by members are processed on a timeline /
      schedule that the store admin selects.
      <br />
      <br />
      <b>Limited Time:</b> Store is only open for a limited time. The store
      admin will select the date that the store should close, and then the
      combined order will be submitted.
    </span>
  );

  const role = new Role(userRole);

  const permissions = {
    allowChangePayerType: userHasRole(role, [
      ROLE_MERCHOLOGIST,
      ROLE_SUPERUSER,
    ]),
    allowChangeStoreType: userHasRole(role, [
      ROLE_MERCHOLOGIST,
      ROLE_SUPERUSER,
    ]),
    allowChangeChargeShipping: userHasRole(role, [
      ROLE_MERCHOLOGIST,
      ROLE_SUPERUSER,
    ]),
    allowBaggedLabeled: userHasRole(role, [ROLE_SUPERUSER]),
    allowChangeGiftCardsStatus:
      userHasRole(role, [ROLE_MERCHOLOGIST, ROLE_SUPERUSER]) &&
      merchStorePayerType === 'individual_payer' &&
      String(merchStoreAlwaysOpen) === 'true',
    manageCustomDomain: userHasRole(role, [ROLE_MERCHOLOGIST, ROLE_SUPERUSER]),
  };

  const [merchStorePaymentOptions] = useState(newMerchStorePaymentOptions());
  const [paymentChanged, setPaymentChanged] = useState(false);

  const [merchStoreTypeOptions] = useState(newMerchStoreTypeOptions());
  const [storeTypeChanged, setStoreTypeChanged] = useState(false);

  const domainName = 'merchologysolutions.com';

  const iconSize = isMobile ? '16px' : '20px';

  const minCloseDate = DateTime.now().plus({ days: 1 }).toISODate();
  const maxCloseDate = DateTime.now().plus({ days: 30 }).toISODate();

  const handleTabSelect = (e: Event) => {
    const target = e.target as HTMLButtonElement;
    const index = target.id ? parseInt(target.id, 0) : null;
    if (index !== active) {
      setActive(index);
    }
  };

  const handleMerchStoreNameChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setMerchStoreName(target.value);
    setMerchStoreEditActive(true);
  };

  const handleMerchStoreNameSubmit = () => {
    if (merchStoreName) {
      setNewMerchStoreName(merchStoreName);
      setNameEditActive(false);
      setMerchStoreName('');
    } else {
      setNameEditActive(false);
    }
  };

  const handleLogoChange = (logoData: [string]) => {
    if (logoData.length) {
      const artworkOptions = merchStore.artworkOptions || [];
      if (!artworkOptions.length) {
        const updatedMerchStore = merchStore;
        updatedMerchStore.artworkOptions = logoData;
        setMerchStoreDetails(updatedMerchStore);
      }
      setMerchStoreLogo(logoData);
      if (storeLogoError) {
        setStoreLogoError(false);
      }
    } else {
      setMerchStoreLogo(null);
    }
  };

  const openAddProductsModal = () => {
    setAddProductsModalOpen(!addProductsModalOpen);
  };

  const openCustomSubdomainModal = () => {
    setCustomSubdomainModalOpen(!customSubdomainModalOpen);
  };

  const onModalClose = () => {
    setAddProductsModalOpen(false);
  };

  // Start Auto Process / Contact Info react hooks
  const handleAutoProcessOrdersChange = (newValue: boolean) => {
    setAutoProcessOrders(newValue);
    setContactInfoModal(newValue);
    if (merchStore && newValue !== merchStore.autoProcessOrders) {
      setMerchStoreEditActive(true);
    }
  };

  const onSaveContactInfo = async (
    submittedContactInfo: IMerchStoreContactInfo
  ) => {
    const appliesAutoProcessOrders = isAutoCloseOrdersAllowed(merchStore);
    const firstTimeContactInfo =
      !merchStore.autoProcessOrders && !merchStore.contactInfo;

    /*
      If the store is eligible for auto-process orders and contact information
      was submitted for first time, we enable the feature by default here.
    */
    const resolvedAutoProcessOrders =
      appliesAutoProcessOrders && firstTimeContactInfo
        ? true
        : autoProcessOrders;

    setAutoProcessOrders(resolvedAutoProcessOrders);

    await updateMerchStore({
      contactInfo: submittedContactInfo,
      autoProcessOrders: resolvedAutoProcessOrders,
    }).catch((error) => {
      console.error(error);
    });

    setContactInfoModal(false);
  };

  // End Auto Process / Contact Info react hooks

  const handleShipToOfficeChange = (newValue: boolean) => {
    setShipToOffice(newValue);
    setShipToOfficeModal(newValue);
    if (merchStore && newValue !== merchStore.shipToOffice) {
      setMerchStoreEditActive(true);
    }
  };

  const handleBaggedLabeledChange = (newValue: boolean) => {
    setBaggedLabeled(newValue);
    if (merchStore && newValue !== merchStore.baggedLabeled) {
      setMerchStoreEditActive(true);
    }
  };

  const handleChargeShippingChange = (newValue: boolean) => {
    setChargeShipping(newValue);
    if (merchStore && merchStore.chargeShipping !== newValue) {
      setMerchStoreEditActive(true);
    }
  };

  const handleShipToOfficeAddressSave = async (address: any) => {
    setOfficeAddress(address);
    setMerchStoreEditActive(true);
    setShipToOfficeModal(false);
  };

  const handleGiftCardStatusChange = (newValue: boolean) => {
    setIsGiftCardsEnabled(newValue);
    setMerchStoreEditActive(true);
  };

  const countStoreProducts = async (storeCode: string): Promise<number> => {
    const productsResults = await fetchFilteredCollectionProducts({
      storeCode: storeCode,
    });

    return productsResults.length;
  };

  async function onContactInfoRequired() {
    setContactInfoModal(true);
  }

  useEffect(() => {
    const elements = Array.from(
      document.querySelectorAll('[id^="collapsible-content-"]')
    );
    elements.forEach((element) => {
      if (element instanceof HTMLElement) {
        if (shouldToggleOverflow) {
          setTimeout(() => {
            element.style.overflow = 'visible';
          }, 500);
        } else {
          element.style.overflow = 'hidden';
        }
      }
    });
  }, [shouldToggleOverflow]);

  const fetchMerchStore = useCallback(
    (id: string): Promise<IMerchStore> => {
      return new Promise((resolve, reject) => {
        axios
          .post('/.netlify/functions/fetchMerchStoreById', {
            storeCode: id,
          })
          .then(async (res) => {
            if (res.data) {
              // correct products count based on the real shopify count
              countStoreProducts(id)
                .then((shopifyProductCount) => {
                  if (shopifyProductCount !== res.data.productsCount) {
                    res.data.productsCount = shopifyProductCount;
                  }
                })
                .catch((err) => console.error(err));

              if (res.data.password) {
                setPasswordSelected(true);
              }
              setMerchStoreStatusOptions(
                buildMerchStoreStatusOptions(res.data.status)
              );
              setMerchStoreDetails(res.data);
              if (res.data.storeLogo) {
                setMerchStoreLogo([res.data.storeLogo]);
              }
              if (res.data.bannerImage) {
                setStoreBannerImage([res.data.bannerImage]);
              }
              reset();
              resolve(res.data);
            } else {
              toast.error(`No MerchStore found with for ID: ${id}`);
              history.push('/');
              setMerchStoreError(true);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [reset]
  );

  const fetchCollection = async (storeCode: string, forceFetch?: false) => {
    const cachedResult = storeProductsCache.get(storeCode);

    if (cachedResult && !forceFetch) {
      setProducts(storeCode, cachedResult);
      return {
        products: cachedResult,
        storeCode: storeCode,
      };
    } else {
      const productsResults = await fetchFilteredCollectionProducts({
        storeCode: storeCode,
        sortChosen: 'sort_name_a-z',
      });

      setProducts(storeCode, productsResults);
      storeProductsCache.set(storeCode, productsResults);

      return {
        products: productsResults,
        storeCode: storeCode,
      };
    }
  };

  const setProducts = (
    storeCode: string,
    productNodes: IProductResult[]
  ): void => {
    const collectionProducts = productNodes.map((productNode) =>
      fetchProductNode(productNode)
    );

    const removedProductTag = `merchstore_deletion_code=${storeCode}`;

    // exclude soft-deleted items from the active item list
    const activeProducts: Array<IMerchStoreProduct> = collectionProducts.filter(
      (product) => {
        return !product.tags.includes(removedProductTag);
      }
    );

    setMerchstoreCollection(activeProducts);
    setDisabledProductIds(getBaseProductIds(activeProducts));
    setProductListLoading(false);
  };

  const getBaseProductIds = (products: IMerchStoreProduct[]): string[] => {
    const result = [] as string[];
    products.forEach((product) => {
      const productTags = product.tags;
      const tag = productTags.find((tag: string) =>
        tag.includes('merchstore_base_product_id=')
      );
      if (tag) {
        const baseProductId = tag.substring(tag.indexOf('=') + 1);
        result.push(baseProductId);
      }
    });
    return result;
  };

  const fetchProductNode = (
    productNode: IProductResult
  ): IMerchStoreProduct => {
    const artworkTag = productNode.tags.find((tag: string) =>
      tag.includes('artwork_option=')
    );

    const product = {
      id: productNode.id,
      title: productNode.title,
      handle: productNode.handle,
      createdAt: productNode.createdAt,
      descriptionHtml: productNode.descriptionHtml,
      tags: productNode.tags,
      productType: productNode.productType,
      vendor: productNode.vendor,
      artworkSelected: artworkTag ? parseInt(artworkTag.split('=')[1]) : 1,
      images:
        productNode.images && Array.isArray(productNode.images)
          ? productNode.images.map((imageNode) => {
              return {
                id: imageNode.id,
                src: imageNode.src ? imageNode.src : '',
                altText: imageNode.altText,
              } as IProductImage;
            })
          : [],
      variants:
        productNode.variants && productNode.variants
          ? productNode.variants.map((variantNode) => {
              return {
                id: variantNode.id,
                productId:
                  productNode.id /*variantNode.product ? variantNode.product.id : ''*/,
                title: variantNode.title,
                price: variantNode.price.amount,
                compareAtPrice: variantNode.compareAtPrice,
                available: variantNode.available,
                quantityAvailable: variantNode.quantityAvailable
                  ? variantNode.quantityAvailable
                  : 0,
                image:
                  Array.isArray(variantNode.images) && variantNode.images.length
                    ? variantNode.images[0]
                    : { id: '', src: '', altText: '' },
                sku: variantNode.sku,
              } as IProductVariant;
            })
          : [],
    };

    return product;
  };

  const convertDate = (date: string) => {
    let dt;
    if (date.indexOf('/') > -1) {
      dt = DateTime.fromFormat(date, 'MM/dd/yyyy', { zone: 'utc' });
    } else {
      dt = DateTime.fromISO(date, { zone: 'utc' });
    }
    return dt.setZone('utc').toISODate();
  };

  const updateEmails = (
    storeCode: string,
    openDate: DateTime,
    closeDate: DateTime
  ) => {
    axios
      .post('/.netlify/functions/updateEmails', {
        entryId: storeCode,
        openDate: openDate,
        closeDate: closeDate,
      })
      .then(() => toast.success('Email queue updated successfully'))
      .catch((err) => {
        console.error(err);
        toast.error('Error updating email queue.');
      });
  };

  const updateMerchStore = async (data: any) => {
    return new Promise((resolve, reject) => {
      const updatedMerchStore: any = {
        storeCode: merchStore.storeCode,
        name: newMerchStoreName.length ? newMerchStoreName : merchStore.name,
        status:
          data['merch-store-status'] && data['merch-store-status'].length
            ? data['merch-store-status']
            : merchStore.status,
        customerAdmins: merchStore.customerAdmins || [],
        subdomain: merchStore.subdomain,
        baseCollectionHandle: merchStore.baseCollectionHandle,
        customDomain: merchStore.customDomain,
        revenue: merchStore.revenue,
        merchologist: merchStore.merchologist ? merchStore.merchologist : '',
        payerType: merchStorePayerType,
        alwaysOpen: merchStoreAlwaysOpen,
        processingSchedule:
          data['store-processing-schedule'] &&
          data['store-processing-schedule'].length
            ? data['store-processing-schedule']
            : merchStore.processingSchedule,
        nextProcessingDate: data['store-next-processing-date']
          ? convertDate(data['store-next-processing-date'])
          : merchStore.nextProcessingDate,
        password: passwordSelected ? data['store-password'] : '',
        artworkOptions:
          merchStore.artworkOptions && merchStore.artworkOptions.length > 0
            ? merchStore.artworkOptions
            : merchStoreLogo && merchStoreLogo.length
              ? [merchStoreLogo[0]]
              : [],
        shipToOffice: shipToOffice,
        baggedLabeled: baggedLabeled,
        openDate: merchStore.openDate,
        productsCount: !isUndefined(data['productsCount'])
          ? data['productsCount']
          : merchStore.productsCount
            ? merchStore.productsCount
            : 0,
        individualOrdersCount: merchStore.individualOrdersCount || 0,
        individualOrdersPendingCount:
          merchStore.individualOrdersPendingCount || 0,
        individualOrdersTotalCount: merchStore.individualOrdersTotalCount || 0,
        chargeShipping: chargeShippingUnlessExplicitFalse(chargeShipping),
        contactInfo: !isUndefined(data['contactInfo'])
          ? data['contactInfo']
          : merchStore.contactInfo || null,
        autoProcessOrders: !isUndefined(data['autoProcessOrders'])
          ? data['autoProcessOrders']
          : merchStore.autoProcessOrders || false,
        giftCardsEnabled: isGiftCardsEnabled,
        trackingNotification: trackingNotification,
        isDeleted: merchStore.isDeleted,
      };

      if (state?.giftCardsStatus) {
        setGiftCardsStatus({
          ...state.giftCardsStatus,
          enabled: isGiftCardsEnabled,
        });
      }

      if (!isEmpty(officeAddress)) {
        officeAddress.phone = officeAddress.phone || '';
        updatedMerchStore.officeAddress = officeAddress;
      }

      const isSetActive =
        merchStore.status === 'active' ||
        data['merch-store-status'] === 'active';
      if (!merchStore.openDate && isSetActive) {
        updatedMerchStore.openDate = DateTime.now().toISODate();
      }

      if (updatedMerchStore.bannerImage) {
        const newBannerImage = cloudinaryApplyWidthHeight(
          updatedMerchStore.bannerImage,
          2880,
          640
        );
        updatedMerchStore.bannerImage = newBannerImage;
      }

      axios
        .post('/.netlify/functions/updateMerchStore', {
          mutationType: 'updateMerchStore',
          merchStoreData: updatedMerchStore,
        })
        .then((merchStoreResp) => {
          setMerchStoreStatusOptions(
            buildMerchStoreStatusOptions(updatedMerchStore.status)
          );
          setMerchStoreDetails(updatedMerchStore);

          const isStatusChangedToActive =
            updatedMerchStore.status === 'active' &&
            merchStore.status !== 'active';
          const isStoreTypeChanged =
            String(updatedMerchStore.alwaysOpen) !==
            String(merchStore.alwaysOpen);

          if (isStatusChangedToActive || isStoreTypeChanged) {
            queueMerchStoreEmails(
              merchStore.storeCode,
              updatedMerchStore.nextProcessingDate
            );
          }

          if (
            merchStore.status === 'active' &&
            merchStore.nextProcessingDate !==
              updatedMerchStore.nextProcessingDate
          ) {
            updateEmails(
              merchStore.storeCode,
              merchStore.openDate,
              updatedMerchStore.nextProcessingDate
            );
          }
          resolve(merchStoreResp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchMembers = (storeCode: string) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/.netlify/functions/getMemberInfoByMerchStore', {
          storeCode: storeCode,
          sortOptionChosen: sortOptionChosen,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.length) {
              setMembers(res.data);
            }
            resolve({ members: res.data, storeCode: storeCode });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // check custom fields that don't get submitted through react-hooks-form, return true if any errors, false if none
  const checkCustomParams = () => {
    if (!merchStoreLogo) {
      setStoreLogoError(true);
    }

    return false;
  };

  const checkFormErrors = () => {
    if (merchStore.individualOrdersPendingCount > 0) {
      if (paymentChanged) {
        toast.error('Submit your pending orders to change the payment method.');
        return true;
      }

      if (storeTypeChanged) {
        toast.error('Submit your pending orders to change the store type.');
        return true;
      }
    }

    return false;
  };

  /**
   * because this logic needs to be gated behind a confirmation modal if
   * the MerchStore is set to active, we need to make it it's own function
   *  */
  const processMerchStore = async (data: any) => {
    if (merchStore) {
      await updateMerchStore(data)
        .then(async () => {
          setMerchStoreEditActive(false);
          // if the order status change from "Draft" to "Active" -> send Members' invites
          if (changeToActive) {
            const membersResults = (await fetchMembers(
              merchStore.storeCode
            )) as any;
            const recipients = membersResults.members.map(
              (member: any) => member.email
            );
            let invitationType = 'regular';
            if (passwordSelected) {
              invitationType = 'password';
            }
            sendMembersInvites(
              merchStore.storeCode,
              invitationType,
              recipients,
              merchStore.name,
              merchStoreLogo && merchStoreLogo.length > 0
                ? merchStoreLogo[0]
                : '',
              data['store-password'],
              resolveMerchstoreUrl(merchStore)
            );
          } else if (changeToClosed && merchStorePayerType === 'admin_payer') {
            const recipients = merchStore.customerAdmins.concat(
              merchStore.merchologist
            );
            sendOrderClosed(
              recipients,
              merchStore.name,
              merchStoreLogo && merchStoreLogo.length > 0
                ? merchStoreLogo[0]
                : '',
              merchStore.storeCode
            );
          }
          toast.success('Your MerchStore was successfully updated');
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            'There was an error updating your MerchStore. Please try again or contact support.'
          );
        });

      if (!merchStore.contactInfo) {
        // force to enter contact info
        // TODO: enable auto-process
        setContactInfoModal(true);
      }
    }
  };

  const sendMembersInvites = (
    storeCode: string,
    invitationType: string,
    recipients: Array<string>,
    storeName: string,
    storeLogo: string,
    password: string,
    siteUrl: string
  ) => {
    const email = {
      templateId: `merchstore-member-invite-${invitationType}`,
      subject: `You’ve Been Invited to Shop ${storeName}’s MerchStore!`,
      text: 'Get excited, it’s time to select your new custom merch.',
      templateContent: [
        {
          name: 'storeCode',
          content: storeCode,
        },
        {
          name: 'storeName',
          content: storeName,
        },
        {
          name: 'storeLogo',
          content: storeLogo,
        },
        {
          name: 'password',
          content: password,
        },
        {
          name: 'siteUrl',
          content: siteUrl,
        },
      ],
    };
    sendEmail(email, recipients);
  };

  // since the top button lives outside the form
  const handleMerchStoreSave = async (data: any) => {
    // check for errors outside the form data
    const outsideErrors = checkCustomParams();

    // Checkbox component doesn't supports true/false value
    // we set the values here.
    data.autoProcessOrders = autoProcessOrders;

    // check for inconsistencies errors inside the form
    const formErrors = checkFormErrors();
    if (!outsideErrors && !formErrors) {
      if (data['merch-store-status'] === 'active') {
        if (!merchStore || merchStore.status !== 'active') {
          setFormData(data);
          setConfirmationModalOpen(true);
        } else {
          processMerchStore(data);
        }
      } else {
        processMerchStore(data);
      }
    }
  };

  const onMembersAdded = (membersAdded: Array<{ email: string }>) => {
    let invitationType = 'regular';
    if (passwordSelected) {
      invitationType = 'password';
    }
    if (isGiftCardsEnabled) {
      invitationType += '-gift-card';
    }
    if (merchStore.status !== 'draft') {
      sendMembersInvites(
        merchStore.storeCode,
        invitationType,
        membersAdded.map((memberAdded: { email: string }) => memberAdded.email),
        merchStore.name,
        merchStoreLogo && merchStoreLogo.length > 0 ? merchStoreLogo[0] : '',
        merchStore.password,
        resolveMerchstoreUrl(merchStore)
      );
    }

    fetchMembers(merchStore.storeCode);
  };

  const onProductsAdded = async (products: IProductResult[]) => {
    setProductListLoading(true);
    const cachedResult = storeProductsCache.get(merchStore.storeCode);
    if (cachedResult) {
      products.map((product: IProductResult) => {
        cachedResult.push(product);
      });
      storeProductsCache.set(merchStore.storeCode, cachedResult);
    }
    try {
      await updateMerchStore({
        productsCount: merchStore.productsCount + products.length,
      });
      await fetchCollection(merchStore.storeCode);
      localStorage.setItem(
        `productsAdded${merchStore.storeCode}`,
        JSON.stringify(products)
      );
    } catch (err) {
      console.error(err);
    } finally {
      setProductListLoading(false);
    }
  };

  const onProductsDeleted = (productId: string) => {
    setProductListLoading(true);
    const cachedResult = storeProductsCache.get(merchStore.storeCode);
    if (cachedResult) {
      const newList = cachedResult.filter(
        (product: IProductResult) => product.id !== productId
      );
      storeProductsCache.set(merchStore.storeCode, newList);
    }
    fetchCollection(merchStore.storeCode);
  };

  const handlePasswordSelect = () => {
    // Set merchstoreEditActive to true only when switching from selected to unselected.
    // merchstoreEditActive will be set to active when user types in password input
    if (passwordSelected) {
      setMerchStoreEditActive(true);
    }
    setPasswordSelected(!passwordSelected);
  };

  const handleStatusChange = (status: IFormOptionProps) => {
    const currentStatus = merchStore ? merchStore.status : 'default';
    if (status.value !== currentStatus) {
      if (currentStatus === 'draft' && status.value === 'active') {
        setChangeToActive(true);
      } else if (
        (currentStatus === 'draft' || currentStatus === 'active') &&
        status.value === 'closed'
      ) {
        setChangeToActive(false);
        setChangeToClosed(true);
      } else {
        setChangeToActive(false);
      }
      setMerchStoreEditActive(true);
    }
  };

  const handlePaymentChange = (paymentOption: IFormOptionProps) => {
    /*
    Never use a react state hook for currentPayment. 
    The static property merchStore.payerType preserves the original value
    with no unexpected side-effects on the validation.
    */
    const currentPayment = merchStore.payerType;

    setMerchStorePayerType(paymentOption.value);

    if (paymentOption.value !== currentPayment) {
      setPaymentChanged(true);
      setMerchStoreEditActive(true);
    } else {
      setPaymentChanged(false);
    }
  };

  const handleTrackingNotificationChange = (
    trackingNotificationOption: IFormOptionProps
  ) => {
    if (!merchStore) {
      return;
    }

    const currentTracking = resolveTrackingNotification(
      merchStore?.trackingNotification ?? null
    );

    if (trackingNotificationOption.value !== currentTracking) {
      setTrackingNotification(trackingNotificationOption.value);
      setMerchStoreEditActive(true);
    }
  };

  const handleStoreTypeChange = (storeTypeOption: IFormOptionProps) => {
    /*
    Never use a react state hook for alwaysOpen. 
    The static property merchStore.alwaysOpen preserves the original value
    with no unexpected side-effects on the validation.
    */
    const currentStoreType = merchStore.alwaysOpen;

    setMerchStoreAlwaysOpen(storeTypeOption.value);

    if (storeTypeOption.value !== currentStoreType) {
      setStoreTypeChanged(true);
      setMerchStoreEditActive(true);
    } else {
      setStoreTypeChanged(false);
    }
  };

  const handleProcessingSchedule = (type: IFormOptionProps) => {
    const currentSchedule = merchStore ? merchStore.processingSchedule : '';
    if (type.value !== currentSchedule) {
      setMerchStoreEditActive(true);
    }
  };

  const handleProcessingDate = (ev: any) => {
    const currentSchedule = merchStore ? merchStore.processingSchedule : '';
    if (ev.target.value < minCloseDate || ev.target.value > maxCloseDate) {
      setError('store-next-processing-date', {
        type: 'invalid',
        message: `The date must be between ${minCloseDate} and ${maxCloseDate}`,
      });
    } else {
      clearErrors('store-next-processing-date');
    }
    if (ev.target.value !== currentSchedule) {
      setValue('store-next-processing-date', ev.target.value);
      setMerchStoreEditActive(true);
    }
  };

  const deleteProduct = (productId: string) => {
    axios
      .post('/.netlify/functions/removeMerchStoreProduct', {
        productId: productId,
        storeCode: merchStore.storeCode,
      })
      .then(async () => {
        const newProductCount = merchStore.productsCount - 1;
        merchStore.productsCount = newProductCount;
        toast.success(
          'The product has been successfully deleted from your store, ' +
            'but it may take a few moments for it to delete from your product list'
        );
        updateMerchStore({ productsCount: newProductCount });
        onProductsDeleted(productId);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error deleting product');
      });
  };

  useEffect(() => {
    if (storeCode) {
      return fetchMerchStore(storeCode)
        .then((merchStore: IMerchStore) => {
          return Promise.all([
            fetchCollection(merchStore.storeCode),
            fetchMembers(merchStore.storeCode),
          ]);
        })
        .catch((err: unknown) => {
          console.error('Malformed MerchStore ID', err);
          setMerchStoreError(true);
        })
        .then(() => setTimeout(() => setLoading(false), 200));
    } else {
      setTimeout(() => setLoading(false), 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeCode, fetchMerchStore, sortOptionChosen]);

  // listen for page changes until the form is rendered before adding the change event listener
  useEffect(() => {
    const form = document.getElementById('create-edit-merch-store-form');
    if (form && !formFound) {
      form.addEventListener('input', function (e) {
        if ((e.target as HTMLInputElement).name === 'store-url') {
          setTempStoreUrl((e.target as HTMLInputElement).value);
        }
        if (
          (e.target as HTMLInputElement).value !==
          (e.target as HTMLInputElement).defaultValue
        ) {
          setMerchStoreEditActive(true);
        }
      });
      setFormFound(true);
    }
  });

  // listen for custom form element changes
  useEffect(() => {
    // If user navigates to a specific order
    if (merchStore) {
      if (
        userRole &&
        userEmail &&
        storeCode &&
        userHasRole(role, [ROLE_GROUPADMIN])
      ) {
        const adminList = merchStore.customerAdmins || [];
        if (!adminList.includes(userEmail)) {
          history.push('/');
        }
      }

      if (merchStoreLogo && merchStoreLogo[0] !== merchStore.storeLogo) {
        setMerchStoreEditActive(true);
      }
      if (merchStore.status !== 'draft') {
        setDisabledPassword(true);
      }
      if (merchStore.status === 'closed') {
        setMerchStoreEditActive(false);
      }

      setAutoProcessOrders(merchStore.autoProcessOrders);
      setShipToOffice(merchStore.shipToOffice);
      setOfficeAddress(merchStore.officeAddress);
      setBaggedLabeled(merchStore.baggedLabeled);
      setSelectedStatus(merchStore.status);
      setMerchStoreAlwaysOpen(merchStore.alwaysOpen);
      setMerchStorePayerType(merchStore.payerType);

      setChargeShipping(merchStore.chargeShipping);
      setTrackingNotification(
        resolveTrackingNotification(merchStore.trackingNotification)
      );

      setLoading(false);
    }

    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;
    setIsMobile(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    merchStore,
    merchStoreLogo,
    passwordSelected,
    userRole,
    userEmail,
    isMobile,
  ]);

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };
  const handleConfirmationModalConfirm = () => {
    setConfirmationModalOpen(false);
    processMerchStore(formData);
  };
  const handleCustomSubdomainModalClose = () => {
    setCustomSubdomainModalOpen(false);
  };

  useEffect(() => {
    setIsGiftCardsEnabled(merchStore?.giftCardsStatus?.enabled);
    setIsGiftCardsEnabledLocked(merchStore?.giftCardsStatus?.enabled);
  }, [merchStore?.giftCardsStatus?.enabled]);

  if (loading) {
    return <Loading isLoading={loading as boolean} />;
  }

  return (
    <div className="relative flex justify-center">
      <CustomSubdomainModal
        open={customSubdomainModalOpen}
        onClose={handleCustomSubdomainModalClose}
        merchStore={merchStore}
        setMerchStoreDetails={setMerchStoreDetails}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        image={merchStoreLogo}
        headlineText="Are you sure you want to set your MerchStore to active?"
        bodyText="Setting your store to Active will notify your members that your MerchStore is open for business."
        onConfirmation={handleConfirmationModalConfirm}
        onCancellation={handleConfirmationModalClose}
      />
      <div className="flex flex-col p-0 lg:px-20 lg:py-8 w-full items-center">
        <div className="mb-7 pt-3 px-5 lg:px-7 lg: pt-0 w-full flex justify-center flex-0">
          <div className="w-full max-w-7xl">
            {merchStore && (
              <p className="px-7 ml-4 md:ml-0 lg:px-0 text-xs lg:text-sm text-merch-dark-gray font-bold opacity-50">
                {merchStore.storeCode}
              </p>
            )}
            <div className={'flex items-center ml-4 md:ml-0 justify-between'}>
              {nameEditActive ? (
                <div className="px-7 lg:px-0 flex items-center">
                  <input
                    autoFocus
                    type="text"
                    value={merchStoreName}
                    className="bg-transparent text-lg lg:text-3xl text-merch-dark-gray font-bold pl-0.5"
                    placeholder={
                      newMerchStoreName
                        ? newMerchStoreName
                        : merchStore
                          ? merchStore.name
                          : 'Untitled'
                    }
                    onChange={(e) => {
                      handleMerchStoreNameChange(e);
                    }}
                    onBlur={() => {
                      handleMerchStoreNameSubmit();
                    }}
                  />
                  <button
                    disabled={merchStoreName ? false : true}
                    className={`opacity-50 ml-1.5 ${
                      merchStoreName ? '' : 'cursor-default'
                    }`}
                    onClick={() => {
                      handleMerchStoreNameSubmit();
                    }}
                  >
                    <IconContext.Provider
                      value={
                        merchStoreName
                          ? { color: 'limeGreen' }
                          : { color: 'grey' }
                      }
                    >
                      <div>
                        <MdDone />
                      </div>
                    </IconContext.Provider>
                  </button>
                </div>
              ) : (
                <div className="px-7 lg:px-0 flex items-center">
                  <div
                    onClick={() => {
                      setNameEditActive(true);
                    }}
                    className="cursor-pointer text-lg lg:text-3xl text-merch-dark-gray font-bold"
                  >
                    {newMerchStoreName
                      ? newMerchStoreName
                      : merchStore
                        ? merchStore.name
                        : 'Untitled'}
                  </div>
                  <div
                    onClick={() => {
                      setNameEditActive(true);
                    }}
                    className="cursor-pointer flex ml-1.5 h-full"
                  >
                    <MdEdit />
                  </div>
                </div>
              )}
              <div className="hidden">
                <div className="md:hidden">
                  {merchStore && merchStore.status !== 'closed' && (
                    <ActionDropdown
                      title="Actions"
                      list={[
                        {
                          title: `Reassign`,
                          id: `reassign`,
                          action: () => alert('Reassign'),
                        },
                        {
                          title: `Close Store`,
                          id: `closeStore`,
                          action: () => alert('Close Store'),
                        },
                        {
                          title: `Delete Store`,
                          id: `deleteStore`,
                          action: () => alert('Delete Store'),
                        },
                      ]}
                    />
                  )}
                </div>
                <div className="hidden md:flex">
                  {userRole === 'ADMIN' ? (
                    <CTA
                      size="standard"
                      type="secondary"
                      classes="text-13 h-45 mr-3 rounded-lg"
                      icon={<MdNotificationsNone size={iconSize} />}
                      onClick={() => alert('Send Reminder!')}
                    >
                      Send Reminder
                    </CTA>
                  ) : (
                    <ActionDropdown
                      className="mr-3 text-13 rounded-lg py-2 px-3 h-full"
                      title="Actions"
                      list={[
                        {
                          title: `Reassign`,
                          id: `reassign`,
                          action: () => alert('Reassign'),
                        },
                        {
                          title: `Close Store`,
                          id: `closeStore`,
                          action: () => alert('Close Store'),
                        },
                        {
                          title: `Delete Store`,
                          id: `deleteStore`,
                          action: () => alert('Delete Store'),
                        },
                      ]}
                    />
                  )}
                  <CTA
                    size="standard"
                    type="secondary"
                    classes="rounded-lg "
                    icon={<MdOpenInNew size="18px" />}
                    onClick={() => alert('Share?')}
                  ></CTA>
                </div>
              </div>
            </div>
            <MerchStoreTiles merchStore={merchStore} members={members} />
          </div>
        </div>
        <Card>
          {merchStoreError ? (
            <h3>TODO: BUILD AN ERROR VIEW</h3>
          ) : selectedProduct ? (
            <MerchStoreProductEdit
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              isMobile={isMobile}
              artworkOptions={merchStore.artworkOptions}
              userRole={userRole}
              storeCode={merchStore.storeCode}
              subdomain={merchStore.subdomain}
              fetchCollection={fetchCollection}
              deleteProduct={deleteProduct}
            />
          ) : selectedOrderDetails &&
            selectedOrderDetails.viewType === 'combined_order' ? (
            <OrderDetailsPage
              merchStore={merchStore}
              order={selectedOrderDetails.combinedOrder}
              setSelectedOrderDetails={setSelectedOrderDetails}
              userRole={userRole}
            />
          ) : selectedOrderDetails &&
            selectedOrderDetails.viewType === 'individual_order' ? (
            <OrderIndividualDetailsPage
              merchStore={merchStore}
              combinedOrder={selectedOrderDetails.combinedOrder}
              order={selectedOrderDetails.individualOrder}
              setSelectedOrderDetails={setSelectedOrderDetails}
              userRole={userRole}
            />
          ) : selectedOrderDetails?.viewType === OrderType.GIFT_CARD ? (
            <GiftCardOrderDetail
              merchStore={merchStore}
              order={selectedOrderDetails.individualOrder}
              setSelectedOrderDetails={setSelectedOrderDetails}
            />
          ) : (
            <div className="flex flex-col lg:flex-row w-full">
              <div className="md:p-8 p-0 w-full">
                <div className="md:p-0 p-6 flex items-start justify-between mb-4 md:mb-6">
                  <Tabs>
                    <Tab onClick={handleTabSelect} active={active === 0} id={0}>
                      Members
                    </Tab>
                    <TabBr />
                    <Tab onClick={handleTabSelect} active={active === 1} id={1}>
                      Products
                    </Tab>
                    <TabBr />
                    <Tab onClick={handleTabSelect} active={active === 2} id={2}>
                      Orders
                    </Tab>
                    <TabBr show={state?.giftCardsStatus?.enabled} />
                    <Tab
                      onClick={handleTabSelect}
                      active={active === 3}
                      id={3}
                      show={state?.giftCardsStatus?.enabled}
                    >
                      Gift Cards
                    </Tab>
                    <TabBr />
                    <Tab onClick={handleTabSelect} active={active === 4} id={4}>
                      Theme
                    </Tab>
                  </Tabs>
                  <div>
                    <Content active={active === 0}>
                      {storeCode !== 'new' ? (
                        <CTA
                          type={'tertiary'}
                          size={'standard'}
                          icon={
                            invite ? (
                              <MdClose size="20px" />
                            ) : (
                              <IoMdPersonAdd size="20px" />
                            )
                          }
                          onClick={() => {
                            setInvite(!invite);
                            setShouldToggleOverflow(!shouldToggleOverflow);
                          }}
                        >
                          {isMobile ? '' : 'Add Members'}
                        </CTA>
                      ) : (
                        ''
                      )}
                    </Content>
                    <Content active={active === 1}>
                      <CTA
                        type={'tertiary'}
                        size={'standard'}
                        icon={<MdAdd size="20px" />}
                        onClick={() => {
                          openAddProductsModal();
                        }}
                      >
                        {isMobile ? '' : 'Add Products'}
                      </CTA>
                      <ProductSearch
                        open={addProductsModalOpen}
                        onClose={onModalClose}
                        storeCode={merchStore.storeCode}
                        storeSubdomain={merchStore.subdomain}
                        onProductsAdded={onProductsAdded}
                        disabledProductIds={disabledProductIds}
                        merchStore={merchStore}
                        isMobile={isMobile}
                        merchStoreProductsCount={merchStore.productsCount}
                      />
                    </Content>
                    <Content active={active === 3}>
                      <BuyGiftCardButton merchStore={merchStore} />
                    </Content>
                  </div>
                </div>
                <div>
                  <Content active={active === 0}>
                    <MerchStoreMemberList
                      showAddMember={invite}
                      merchStore={merchStore}
                      setMerchStoreDetails={setMerchStoreDetails}
                      uniqueId={storeCode}
                      setSortOptionChosen={setSortOptionChosen}
                      fetchMembers={fetchMembers}
                      memberAndSubmissionDetails={members}
                      onMembersAdded={onMembersAdded}
                      onContactInfoRequired={onContactInfoRequired}
                    ></MerchStoreMemberList>
                  </Content>
                  <Content active={active === 1}>
                    {merchStore && (
                      <MerchStoreProductList
                        products={merchstoreCollection}
                        setSelectedProduct={setSelectedProduct}
                        isMobile={isMobile}
                        deleteProduct={deleteProduct}
                        isLoading={productListLoading}
                        order={merchStore}
                        setOrderDetails={setMerchStoreDetails}
                      />
                    )}
                  </Content>
                  <Content active={active === 2}>
                    {merchStore && (
                      <MerchStoreOrdersTab
                        merchStore={merchStore}
                        selectedOrderId={orderId}
                        setSelectedOrderDetails={setSelectedOrderDetails}
                      />
                    )}
                  </Content>
                  <Content active={active === 3}>
                    <GiftCardsOrders
                      merchStore={merchStore}
                      setSelectedOrderDetails={setSelectedOrderDetails}
                    />
                  </Content>
                  <Content active={active === 4}>
                    <ThemeTabContent />
                  </Content>
                </div>
              </div>
              <div className="p-8 bg-gray-50 lg:min-w-order-sidebar flex flex-col items-start rounded-tr-24 rounded-br-24">
                <form
                  onSubmit={handleSubmit(handleMerchStoreSave)}
                  className="w-full"
                  id="create-edit-merch-store-form"
                >
                  <FormSelect
                    name="merch-store-status"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    label="Store Status"
                    emptyText="Status"
                    tooltip="Keep your group MerchStore set to Draft until you are ready to go live. Setting your store to Active will notify your members that your group order is available."
                    helpText="Required"
                    default={merchStore ? merchStore.status : 'draft'}
                    options={merchStoreStatusOptions}
                    onChange={handleStatusChange}
                    disabled={merchStore && merchStore.status === 'closed'}
                  />

                  <FormInput
                    disabled={merchStore}
                    inputStyle="two"
                    name="store-url"
                    defaultValue={merchStore ? merchStore.subdomain : ''}
                    placeholder="amazon-team-store"
                    type="text"
                    label="Store URL"
                    required={merchStore ? false : true}
                    errors={errors}
                    register={register}
                    tooltip="Cannot be modified once it is initially set."
                    linkSubText={
                      (tempMerchStoreUrl
                        ? tempMerchStoreUrl
                        : merchStore
                          ? merchStore.subdomain
                          : 'amazon-team-store') +
                      '.' +
                      domainName
                    }
                    helpText={
                      permissions.manageCustomDomain ? 'Custom Domain?' : ''
                    }
                    onClick={() => {
                      permissions.manageCustomDomain
                        ? openCustomSubdomainModal()
                        : false;
                    }}
                  ></FormInput>

                  <FormSelect
                    name="payment-method"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    label="Payment Method"
                    tooltip={paymentMethodTooltip}
                    default={merchStorePayerType}
                    options={merchStorePaymentOptions}
                    onChange={handlePaymentChange}
                    disabled={
                      (merchStore && merchStore.status === 'closed') ||
                      permissions.allowChangePayerType !== true
                    }
                  />

                  <FormSelect
                    name="always-open"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    label="Store Type"
                    default={merchStoreAlwaysOpen}
                    options={merchStoreTypeOptions}
                    onChange={handleStoreTypeChange}
                    disabled={
                      (merchStore && merchStore.status === 'closed') ||
                      permissions.allowChangeStoreType !== true
                    }
                    tooltip={storeTypeTooltip}
                  />

                  {merchStoreAlwaysOpen === 'true' && (
                    <SchedulerInput
                      options={generateMerchStoreScheduleOptions(merchStore)}
                      disabled={
                        merchStore && merchStore.status === 'closed'
                          ? true
                          : false
                      }
                      frequencyName="store-processing-schedule"
                      dateInputName="store-next-processing-date"
                      frequencyDefaultValue={
                        merchStore && merchStore.processingSchedule
                          ? merchStore.processingSchedule
                          : defaultScheduleOption().value
                      }
                      dateInputDefaultValue={
                        merchStore && merchStore.nextProcessingDate
                          ? convertDate(merchStore.nextProcessingDate)
                          : ''
                      }
                      label="Processing Schedule"
                      frequencyLabel="Processes"
                      dateInputLabel="Next Processing Date"
                      min={minCloseDate}
                      max={maxCloseDate}
                      required={true}
                      errors={errors}
                      register={register}
                      helpText="Required"
                      tooltip="Select how often store orders are processed. All orders placed within this date range are processed together."
                      setValue={setValue}
                      onChange={handleProcessingSchedule}
                    />
                  )}

                  {merchStoreAlwaysOpen === 'false' && (
                    <FormInput
                      disabled={
                        merchStore && merchStore.status === 'closed'
                          ? true
                          : false
                      }
                      inputStyle="two"
                      name="store-next-processing-date"
                      defaultValue={
                        merchStore && merchStore.nextProcessingDate
                          ? convertDate(merchStore.nextProcessingDate)
                          : ''
                      }
                      type="date"
                      min={minCloseDate}
                      max={maxCloseDate}
                      label="Store Close Date"
                      required={true}
                      errors={errors}
                      register={register}
                      onChange={handleProcessingDate}
                      helpText="Required"
                      tooltip="This is when the MerchStore closes and the orders are processed. Stores can be kept open up to 30 days."
                    />
                  )}

                  <div>
                    <Checkbox
                      label="Require Password"
                      className="items-center"
                      onChange={handlePasswordSelect}
                      isSelected={passwordSelected}
                      tooltip="This option requires the front-end user to input a specified password prior to entering the MerchStore."
                      disabled={disabledPasswords}
                    />
                  </div>
                  {passwordSelected && (
                    <FormInput
                      inputStyle="two"
                      name="store-password"
                      disabled={
                        !passwordSelected ||
                        (merchStore && merchStore.status === 'closed')
                      }
                      defaultValue={
                        merchStore && merchStore.password
                          ? merchStore.password
                          : ''
                      }
                      type="text"
                      placeholder="Enter Password"
                      label=""
                      required={false}
                      errors={errors}
                      register={register}
                    />
                  )}

                  {isAutoCloseOrdersAllowed(merchStore) && (
                    <div className="flex flex-row justify-between">
                      <div className="grow">
                        <Checkbox
                          label="Auto Process Orders"
                          className="items-center mt-5"
                          onChange={handleAutoProcessOrdersChange}
                          isSelected={autoProcessOrders}
                          tooltip={
                            <p>
                              Active orders will be processed automatically on
                              the next processing date without additional action
                              from the store admin. Contact information is
                              required to enable auto processing of orders.
                              Contact address can be different than shipping
                              address.
                            </p>
                          }
                        />
                      </div>
                      <div className="place-self-end text-merch-orange">
                        <a
                          className="cursor-pointer font-bold text-merch-orange"
                          onClick={() => {
                            setContactInfoModal(true);
                          }}
                        >
                          Edit Contact
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-row justify-between">
                    <div className="grow">
                      <Checkbox
                        label="Ship to Office"
                        className="items-center mt-5"
                        onChange={handleShipToOfficeChange}
                        isSelected={shipToOffice}
                        tooltip={
                          <p>
                            Lead times are dependent upon products selected, the
                            decoration method being used, and transit time to
                            the delivery address(es). Lead times begin once the
                            order has processed. Please see our{' '}
                            <a
                              href="https://www.merchology.com/pages/merchstore"
                              target="_blank"
                              rel="noreferrer"
                            >
                              FAQs
                            </a>{' '}
                            for more information.
                          </p>
                        }
                      />
                    </div>
                    <div className="place-self-end text-merch-orange">
                      <a
                        className="cursor-pointer font-bold text-merch-orange"
                        onClick={() => {
                          setShipToOfficeModal(true);
                        }}
                      >
                        Edit Address
                      </a>
                    </div>
                  </div>

                  {shipToOffice && permissions.allowBaggedLabeled && (
                    <Checkbox
                      label="Bagged and Labeled*"
                      className="items-center mt-5"
                      onChange={handleBaggedLabeledChange}
                      isSelected={baggedLabeled}
                      tooltip={
                        <p>
                          Orders will be individually bagged and labeled for
                          easy identification. *An extra handling charge of
                          $3.00 will apply to each bag.
                        </p>
                      }
                    />
                  )}

                  {permissions.allowChangeChargeShipping && (
                    <Checkbox
                      label="Charge Shipping"
                      className="items-center mt-5"
                      onChange={handleChargeShippingChange}
                      isSelected={chargeShipping}
                      tooltip="Select stores may not charge shipping (manager approval required)"
                    />
                  )}

                  <FormSelect
                    name="tracking-notification"
                    classes="mt-5"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    label="Tracking Notifications"
                    tooltip="Tracking notifications will be sent via email. Notifications may include addresses, tracking links and other relevant shipping information."
                    default={trackingNotification}
                    options={buildTrackingNotificationOptions()}
                    onChange={handleTrackingNotificationChange}
                    disabled={merchStore && merchStore.status === 'closed'}
                  />

                  {permissions.allowChangeGiftCardsStatus && (
                    <Checkbox
                      label="Enable Gift Cards"
                      className="items-center mt-5"
                      onChange={handleGiftCardStatusChange}
                      isSelected={isGiftCardsEnabled}
                      tooltip="Allow Merchology gift cards to be used on your store. Once enabled, this option cannot be disabled."
                      disabled={isGiftCardsEnabledLocked}
                    />
                  )}
                  <div className="flex w-full justify-between mt-8">
                    <div className="flex w-full">
                      <CTA
                        disabled={
                          merchStore
                            ? !merchStoreEditActive || merchStore.isDeleted
                            : false
                        }
                        stretch={true}
                        formSubmit={true}
                        size="standard"
                        type="primary"
                      >
                        {merchStore ? 'Update MerchStore' : 'Save MerchStore'}
                      </CTA>
                    </div>
                  </div>
                </form>

                <ContactInfoModal
                  modalOpen={contactInfoModal}
                  merchStore={merchStore}
                  onSave={onSaveContactInfo}
                  onCancel={() => {
                    setContactInfoModal(false);
                  }}
                />

                <MerchstoreShipToOffice
                  modalOpen={shipToOfficeModal}
                  onCancelShipToOffice={() => {
                    setShipToOffice(false);
                    setShipToOfficeModal(false);
                  }}
                  handleShipToOfficeAddressSave={handleShipToOfficeAddressSave}
                  merchStore={merchStore}
                />
              </div>
            </div>
          )}
        </Card>
        <Footer />
      </div>
    </div>
  );
};

export const sendOrderClosed = (
  recipients: Array<string>,
  storeName: string,
  storeLogo: string,
  storeCode: string
) => {
  const storeSite =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://merchologysolutions.com'
      : 'https://staging.merchologysolutions.com';
  const siteUrl = `${storeSite}/admin/merchstores/${storeCode}/orders/active`;
  const email = {
    templateId: `merchstore-order-closed`,
    subject: `Your MerchStore Order Is Now Closed… Time to Finalize Your Order!`,
    text: `Submit your order now.`,
    templateContent: [
      {
        name: 'storeCode',
        content: storeCode,
      },
      {
        name: 'storeName',
        content: storeName,
      },
      {
        name: 'storeLogo',
        content: storeLogo,
      },
      {
        name: 'siteUrl',
        content: siteUrl,
      },
    ],
  };
  sendEmail(email, recipients);
};

export const queueMerchStoreEmails = (
  storeCode: string,
  processingDate?: DateTime
): void => {
  axios
    .post('/.netlify/functions/queueMerchStoreEmails', {
      storeCode: storeCode,
      processingDate: processingDate,
    })
    .then(() => toast.success('Emails queued successfully'))
    .catch((err) => {
      console.error(err);
      toast.error('Error queueing emails.');
    });
};
