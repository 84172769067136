import { UseMutationResult, useMutation } from 'react-query';
import { useContext } from 'react';

import {
  GroupOrderAddress,
  GroupOrderAddressInput,
} from '@merchstores/admin/types/address';
import { addShippingAddress } from '@merchstores/admin/api/hubspot';
import { StoreContext } from '@merchstores/admin/context/Store';

type UseAddShippingAddressResult = UseMutationResult<
  string,
  unknown,
  GroupOrderAddressInput,
  unknown
>;

export default function useAddShippingAddress(): UseAddShippingAddressResult {
  const {
    state: { shippingAddresses },
    setIsShippingAddressesLoading,
    setShippingAddresses,
  } = useContext(StoreContext);

  return useMutation({
    mutationFn: (params: GroupOrderAddressInput) => {
      setIsShippingAddressesLoading(true);
      return addShippingAddress(params);
    },
    onSuccess: (data, variable) => {
      const updatedShippingAddresses = shippingAddresses?.map(
        (address: GroupOrderAddress) => {
          return variable.isDefault
            ? { ...address, isDefault: false }
            : address;
        }
      );
      setShippingAddresses([
        ...updatedShippingAddresses,
        { ...variable, _id: data },
      ]);
      setIsShippingAddressesLoading(false);
    },
  });
}
