import { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import {
  IMerchStoreIndividualOrder,
  IOrderDetailsView,
} from '@merchstores/admin/types/merchstoreOrder';
import { ICombinedOrder } from '@merchstores/admin/types/shopifyCombinedOrder';
import { Table } from '@merchstores/shared/elements/Table';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Loading } from '@merchstores/shared/components/Loading';
import { moneyFormat } from '@merchstores/shared/components/Money';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { TrackingLink } from './TrackingLink';
import { Badge, BadgeType } from '@merchstores/shared/elements/Badge';

export interface IMerchStoreOrderListProps {
  merchStore: IMerchStore;
  combinedOrder: ICombinedOrder;
  orders: IMerchStoreIndividualOrder[];
  isMobile?: boolean;
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
  isLoading: boolean;
}

interface IndividualOrderListCell {
  desktopOnly?: boolean;
  value: string | HTMLElement | JSX.Element;
}

interface IndividualOrderListRow {
  email?: IndividualOrderListCell;
  name?: IndividualOrderListCell;
  orderWarning?: IndividualOrderListCell;
  itemsCount?: IndividualOrderListCell;
  totalPrice?: IndividualOrderListCell;
  trackingStatus?: IndividualOrderListCell;
  editAction?: IndividualOrderListCell;
}

export const IndividualOrderList: React.FC<IMerchStoreOrderListProps> = (
  props: IMerchStoreOrderListProps
) => {
  const { orders, setSelectedOrderDetails, isMobile } = props;
  const [ordersData, setOrdersData] = useState([]);

  const combinedOrder = props.combinedOrder;

  useEffect(() => {
    if (orders) {
      const storeOrdersData = orders.map(
        (order: IMerchStoreIndividualOrder) => {
          const data: IndividualOrderListRow = {};

          data.email = {
            desktopOnly: false,
            value: (
              <div className="flex ml-20 font-bold">{order.customer.email}</div>
            ),
          };

          data.orderWarning = {
            desktopOnly: false,
            value: order.tags.includes('oos_order') ? (
              <div className="flex ml-20 font-bold">
                <Badge className="ml-20 h-25" type={BadgeType.issue}>
                  Issue
                </Badge>
              </div>
            ) : (
              ''
            ),
          };

          data.name = {
            desktopOnly: true,
            value: <div className="flex text-gray-400">{order.name}</div>,
          };

          data.itemsCount = {
            desktopOnly: true,
            value: (
              <div className="mx-10 font-bold">
                {order.itemsCount} {order.itemsCount > 1 ? 'items' : 'item'}
              </div>
            ),
          };

          data.totalPrice = {
            desktopOnly: false,
            value: (
              <div className="text-right">
                {moneyFormat(order.subtotalPrice)}
              </div>
            ),
          };

          const orderTrackingCode = order.trackingCode || '';

          (data.trackingStatus = {
            desktopOnly: false,
            value: orderTrackingCode && (
              <div className="w-full text-center mx-4">
                <TrackingLink trackingCode={orderTrackingCode} />
              </div>
            ),
          }),
            (data.editAction = {
              desktopOnly: false,
              value: (
                <div className="flex w-full justify-end pr-15">
                  <CTA
                    size="small"
                    type="mobile-icon"
                    onClick={() => {
                      setSelectedOrderDetails({
                        viewType: 'individual_order',
                        combinedOrder: combinedOrder,
                        individualOrder: order,
                      });
                    }}
                  >
                    {isMobile ? <MdEdit size="15px" /> : 'View/Edit'}
                  </CTA>
                </div>
              ),
            });

          return {
            tr: {
              class: 'order-list',
              data,
            },
          };
        }
      );
      setOrdersData(storeOrdersData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  if (props.isLoading) {
    return <Loading isLoading={props.isLoading as boolean} />;
  }

  return (
    <div className="order-list-container">
      {ordersData.length ? (
        <Table itemsName="orders" name="orders" data={ordersData} />
      ) : (
        <div className="bg-merch-background-gray px-3 py-6 lg:p-6 rounded-md flex justify-between items-center">
          <div className="text-13 italic text-merch-dark-gray opacity-50 font-medium">
            Showing 0 of 0 orders
          </div>
        </div>
      )}
    </div>
  );
};
