const MoneyV2 = {
  name: 'MoneyV2',
  kind: 'OBJECT',
  fieldBaseTypes: {
    amount: 'Decimal',
    currencyCode: 'CurrencyCode',
  },
  implementsNode: false,
};
export default MoneyV2;
