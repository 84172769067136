import { useMutation, UseMutationResult } from 'react-query';
import { disableMemberGiftCards } from '@merchstores/admin/api/merchstores/giftCards';

type IDisableMemberGiftCardsParameters = {
  id: string[];
};

type IDisableMemberGiftCardsResult = {
  giftCard: {
    id: string;
    enabled: boolean;
  };
};

type UseDisableMembersGiftCardsResult = UseMutationResult<
  IDisableMemberGiftCardsResult[],
  unknown,
  IDisableMemberGiftCardsParameters,
  unknown
>;

const useDisableMembersGiftCards = (): UseDisableMembersGiftCardsResult => {
  return useMutation((params: IDisableMemberGiftCardsParameters) => {
    return disableMemberGiftCards(params.id);
  });
};

export default useDisableMembersGiftCards;
