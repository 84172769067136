const PageEdge = {
  name: 'PageEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Page',
  },
  implementsNode: false,
};
export default PageEdge;
