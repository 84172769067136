const __Field = {
  name: '__Field',
  kind: 'OBJECT',
  fieldBaseTypes: {
    accessRestricted: 'Boolean',
    accessRestrictedReason: 'String',
    args: '__InputValue',
    deprecationReason: 'String',
    description: 'String',
    isDeprecated: 'Boolean',
    name: 'String',
    requiredAccess: 'String',
    type: '__Type',
  },
  implementsNode: false,
};
export default __Field;
