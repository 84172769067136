import { useMutation, UseMutationResult } from 'react-query';
import { assignMerchStoreMemberGiftCards } from '@merchstores/admin/api/merchstores/giftCards';
import {
  IAssignMemberGiftCardsParameters,
  IAssignMemberGiftCardsResult,
} from '@merchstores/admin/types/giftCards';

type UseAssignMembersGiftCardsResult = UseMutationResult<
  IAssignMemberGiftCardsResult,
  unknown,
  IAssignMemberGiftCardsParameters,
  unknown
>;

const useAssignMembersGiftCards = (): UseAssignMembersGiftCardsResult => {
  return useMutation((params: IAssignMemberGiftCardsParameters) =>
    assignMerchStoreMemberGiftCards(params)
  );
};

export default useAssignMembersGiftCards;
