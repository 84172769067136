const TokenizedPaymentInput = {
  name: 'TokenizedPaymentInput',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    amount: 'Money',
    idempotencyKey: 'String',
    billingAddress: 'MailingAddressInput',
    type: 'String',
    paymentData: 'String',
    test: 'Boolean',
    identifier: 'String',
  },
};
export default TokenizedPaymentInput;
