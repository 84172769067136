const MailingAddressEdge = {
  name: 'MailingAddressEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'MailingAddress',
  },
  implementsNode: false,
};
export default MailingAddressEdge;
