import { IProgressProps } from './index';
import './styles.scss';

export const Progress = (props: IProgressProps) => {
  const { bgcolor, progress } = props;

  const completed =
    progress.total > 0 ? (progress.value * 100) / progress.total : 0;

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
  };

  return (
    <div className="progress-container">
      <div className="progress-value">
        {progress.value} / {progress.total}
      </div>
      <div className="progress-container">
        <div style={fillerStyles}>
          <span className="progress-label"></span>
        </div>
      </div>
    </div>
  );
};
