import './styles.scss';
import { ISidebarMenuProps } from './index';
import Logo from '../../../assets/common/logo.svg';
import { Multipass } from 'multipass-js';
import { useState, useContext } from 'react';
import { useEffect } from 'react';
import { Context } from '../../../context';
import { MdPeople, MdMenu, MdClose, MdStore } from 'react-icons/md';
import { RiImageEditLine } from 'react-icons/ri';
import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
  ROLE_GROUPADMIN,
} from '@merchstores/admin/context/Roles';
import { hasSessionCustomInventoryAccess } from '../../Auth/CustomInventory';

export const MENU_ITEM_NONE = 'none';
export const MENU_ITEM_GOT = 'got_index';
export const MENU_ITEM_MERCHSTORES = 'merchstores_index';
export const MENU_ITEM_CUSTOM_INVENTORY_MANAGEMENT =
  'custom_inventory_management';

export const MENU_ITEM_CUSTOM_LOGOHUB_ADMIN = 'logohub_admin';

const CustomInventoryIcon = (props: { size: string; color: string }) => {
  return (
    <div style={{ width: props.size, padding: '0.2rem' }}>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.773 4.22706L11.3865 6.61354L17.7504 6.61354L17.7504 0.249581L15.3639 2.63607C11.8479 -0.880022 6.15212 -0.880022 2.63603 2.63607C1.3871 3.88499 0.591605 5.41235 0.233632 7.01129L2.55648 7.01129C2.85877 5.99306 3.42357 5.03051 4.22702 4.22706C6.86011 1.59397 11.1399 1.59397 13.773 4.22706ZM17.7663 10.9888H15.4435C15.1253 12.007 14.5764 12.9695 13.773 13.773C11.1399 16.4061 6.86011 16.4061 4.22702 13.773L6.6135 11.3865H0.249542V17.7505L2.63603 15.364C6.15212 18.8801 11.8479 18.8801 15.3639 15.364C16.6129 14.1151 17.4084 12.5877 17.7663 10.9888Z"
          fill={props.color}
        ></path>
      </svg>
    </div>
  );
};

export const SidebarMenu = (props: ISidebarMenuProps) => {
  const MULTIPASS_SECRET = process.env.REACT_APP_MULTIPASS_SECRET;
  const SHOPIFY_STORE_URL = process.env.REACT_APP_SHOPIFY_STORE_URL;
  const isStaging = process.env.REACT_APP_ENV === 'staging';
  const isProduction = process.env.REACT_APP_ENV === 'production';

  const { loginEmail, userRole, logoutUser } = useContext(Context);
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [activeItem, setActiveItem] = useState(MENU_ITEM_NONE);

  const stagingMerchStoresPermission =
    isStaging &&
    userHasRole(new Role(userRole), [
      ROLE_SUPERUSER,
      ROLE_MERCHOLOGIST,
      ROLE_GROUPADMIN,
    ]);
  const productionMerchStoresPermission =
    isProduction &&
    userHasRole(new Role(userRole), [
      ROLE_SUPERUSER,
      ROLE_MERCHOLOGIST,
      ROLE_GROUPADMIN,
    ]);
  const hasMerchStoresPermissions =
    stagingMerchStoresPermission || productionMerchStoresPermission;

  const hasCustomInventoryManagementAccess = hasSessionCustomInventoryAccess();

  const hasLogohubPermission = userHasRole(new Role(userRole), [
    ROLE_SUPERUSER,
    ROLE_MERCHOLOGIST,
  ]);

  useEffect(() => {
    if (window.location.pathname.toString().includes('/admin/group-orders')) {
      setActiveItem(MENU_ITEM_GOT);
    } else if (
      window.location.pathname.toString().includes('/admin/merchstores')
    ) {
      setActiveItem(MENU_ITEM_MERCHSTORES);
    }
  });

  const toggleSidebarMenu = () => {
    setDisplaySidebar(!displaySidebar);
    if (props.onSidebarToggle) {
      props.onSidebarToggle(!displaySidebar);
    }
  };

  const goToMerchologySite = () => {
    const customerData = {
      email: loginEmail,
    };
    const multipass = new Multipass(MULTIPASS_SECRET);
    const url = multipass
      .withCustomerData(customerData)
      .withDomain(`${SHOPIFY_STORE_URL}`)
      .withRedirect('/')
      .url();
    window.open(url, '_blank');
  };

  return (
    <div className={`sidebarMenu ${displaySidebar ? 'active' : ''}`}>
      <div className="sidebarMenu-bars" onClick={toggleSidebarMenu}>
        <MdMenu />
      </div>
      <nav
        className={`${
          displaySidebar ? 'nav-menu active' : 'nav-menu'
        } relative`}
      >
        <div
          className="flex logo justify-center align-middle"
          onClick={goToMerchologySite}
        >
          <img src={Logo} alt="Merchology Logo"></img>
        </div>
        <a
          href="/admin/group-orders"
          className={
            'flex mt-4 mb-6 ' +
            (activeItem === MENU_ITEM_GOT ? 'menu-item-active' : '')
          }
        >
          <div
            className={
              'flex menu-item-icon justify-center items-center ' +
              (activeItem === MENU_ITEM_GOT ? 'active' : '')
            }
          >
            <MdPeople size="24px" />
          </div>
        </a>
        {hasMerchStoresPermissions && (
          <a
            href="/admin/merchstores"
            className={
              'flex mt-4 mb-6 ' +
              (activeItem === MENU_ITEM_MERCHSTORES ? 'menu-item-active' : '')
            }
          >
            <div
              className={
                'flex menu-item-icon justify-center items-center ' +
                (activeItem === MENU_ITEM_MERCHSTORES ? 'active' : '')
              }
            >
              <MdStore size="24px" />
            </div>
          </a>
        )}

        {hasCustomInventoryManagementAccess && (
          <a
            href="https://inventory.merchology.com"
            className={
              'flex mt-4 mb-6 ' +
              (activeItem === MENU_ITEM_CUSTOM_INVENTORY_MANAGEMENT
                ? 'menu-item-active'
                : '')
            }
          >
            <div
              className={
                'flex menu-item-icon justify-center items-center ' +
                (activeItem === MENU_ITEM_CUSTOM_INVENTORY_MANAGEMENT
                  ? 'active'
                  : '')
              }
            >
              <CustomInventoryIcon size={'24px'} color={'#999999'} />
            </div>
          </a>
        )}

        {hasLogohubPermission && (
          <a
            href="https://logohub-admin.merchology.com/"
            className={
              'flex mt-4 mb-6 ' +
              (activeItem === MENU_ITEM_CUSTOM_LOGOHUB_ADMIN
                ? 'menu-item-active'
                : '')
            }
          >
            <div
              className={
                'flex menu-item-icon justify-center items-center ' +
                (activeItem === MENU_ITEM_CUSTOM_LOGOHUB_ADMIN
                  ? 'active'
                  : '')
              }
            >
              <RiImageEditLine size={'24px'} color={'#999999'} />
            </div>
          </a>
        )}
        <div className="closeButton" onClick={toggleSidebarMenu}>
          <MdClose />
        </div>
        <div className="w-full flex justify-center absolute faqs-link">
          <a
            className="text-white text-10"
            target="_blank"
            rel="noreferrer"
            href="https://www.merchology.com/pages/merchstore"
          >
            FAQs
          </a>
        </div>
        <div className="w-full flex justify-center absolute logout-link">
          <a
            className="text-white text-10"
            href="javascript:void()"
            onClick={logoutUser}
          >
            Logout
          </a>
        </div>
      </nav>
    </div>
  );
};