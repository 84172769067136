const Checkout = {
  name: 'Checkout',
  kind: 'OBJECT',
  fieldBaseTypes: {
    appliedGiftCards: 'AppliedGiftCard',
    availableShippingRates: 'AvailableShippingRates',
    completedAt: 'DateTime',
    createdAt: 'DateTime',
    currencyCode: 'CurrencyCode',
    customAttributes: 'Attribute',
    customer: 'Customer',
    discountApplications: 'DiscountApplicationConnection',
    email: 'String',
    id: 'ID',
    lineItems: 'CheckoutLineItemConnection',
    lineItemsSubtotalPrice: 'MoneyV2',
    note: 'String',
    order: 'Order',
    orderStatusUrl: 'URL',
    paymentDue: 'Money',
    paymentDueV2: 'MoneyV2',
    ready: 'Boolean',
    requiresShipping: 'Boolean',
    shippingAddress: 'MailingAddress',
    shippingDiscountAllocations: 'DiscountAllocation',
    shippingLine: 'ShippingRate',
    subtotalPrice: 'Money',
    subtotalPriceV2: 'MoneyV2',
    taxExempt: 'Boolean',
    taxesIncluded: 'Boolean',
    totalDuties: 'MoneyV2',
    totalPrice: 'Money',
    totalPriceV2: 'MoneyV2',
    totalTax: 'Money',
    totalTaxV2: 'MoneyV2',
    updatedAt: 'DateTime',
    webUrl: 'URL',
  },
  implementsNode: true,
};
export default Checkout;
