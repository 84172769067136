const ArticleConnection = {
  name: 'ArticleConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'ArticleEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default ArticleConnection;
