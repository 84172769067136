import { ILineItem } from '@merchstores/admin/types/shopifyCheckout';
import { moneyFormat } from '@merchstores/shared/components/Money';

interface Props {
  item: ILineItem;
}

interface ColumnProps {
  label: string;
  value: string;
}

const LineItemColumn = (props: ColumnProps): JSX.Element => {
  const { label, value } = props;
  return (
    <div className="w-full">
      <div className="text-gray-400 text-xs leading-4">{label}</div>
      <div className="text-sm font-bold leading-4">{value}</div>
    </div>
  );
};

const LineItem = (props: Props): JSX.Element => {
  const { item } = props;
  const unitPrice = item.originalUnitPriceSet?.presentmentMoney?.amount || 0;
  const total = Number(item.quantity) * Number(unitPrice);

  return (
    <div className="flex items-center grid-cols-3 w-full border-b border-t h-45">
      <LineItemColumn label="Quantity" value={item.quantity.toString()} />
      <LineItemColumn label="Value" value={moneyFormat(unitPrice)} />
      <LineItemColumn label="Total" value={moneyFormat(total)} />
    </div>
  );
};

export default LineItem;
