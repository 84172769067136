const ProductVariantConnection = {
  name: 'ProductVariantConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'ProductVariantEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default ProductVariantConnection;
