export { Badge } from './component';

export interface IBadgeProps {
  type: BadgeType;
  children?: any;
  className?: string;
}

export enum BadgeType {
  active = 'active',
  draft = 'draft',
  closed = 'closed',
  unfulfilled = 'unfulfilled',
  pending = 'pending',
  deleted = 'deleted',
  issue = 'issue',
  error = 'error',
}
