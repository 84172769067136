import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { IHubspotFormProps } from '.';
import './styles.scss';

declare global {
  const hbspt: any;
}

export const HubspotForm = ({
  formId,
  indexId,
  hubspotPortalId,
  onValidate,
  onFormReady,
  onFormSubmit,
  onFormSubmitted,
  readOnly,
}: IHubspotFormProps) => {
  const [formCreated, setFormCreated] = useState(false);
  const submitHubspotForm = (form: any) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${formId}`,
      {
        portalId: hubspotPortalId,
        formGuid: formId,
        context: {
          pageUri: window.location.href,
          pageName: document.title,
        },
        fields: form
          .serializeArray()
          .filter(
            (field: { name: string; value: string }) =>
              field.name !== 'hs_context'
          ),
      },
      config
    );
  };

  React.useEffect(() => {
    if (!formCreated) {
      setFormCreated(true);
      const jQueryScript = document.createElement('script');
      jQueryScript.onload = () => {
        onJqueryLoad();
      };
      jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.js';
      document.getElementsByTagName('head')[0].appendChild(jQueryScript);

      const onJqueryLoad = () => {
        const script = document.createElement('script');
        script.onload = () => {
          fireHubspotScript();
        };
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.getElementsByTagName('head')[0].appendChild(script);
        const fireHubspotScript = () => {
          window.jQuery =
            window.jQuery ||
            (() => ({
              // These are all methods required by HubSpot
              change: () => {},
              trigger: () => {},
            }));

          hbspt.forms.create({
            formId,
            formInstanceId: indexId,
            locale: 'en',
            onFormSubmit() {
              // Do nothing since we post the data manually on submitHubspotForm()
            },
            onFormReady($form: any) {
              const submitButton = document.querySelector(
                ".hs-button[type='submit']"
              ) as HTMLElement;
              submitButton?.addEventListener('click', function (event) {
                if (!onValidate) {
                  return;
                }

                onValidate().then((isShippingGOTFormValid: any) => {
                  if (isShippingGOTFormValid) {
                    submitHubspotForm($form);
                    if (onFormSubmit) {
                      onFormSubmit($form);
                    }
                  }
                });
                event.preventDefault();
              });
              if (onFormReady) {
                onFormReady();
              }

              // Make the form read only
              if (readOnly && submitButton) {
                submitButton.style.display = 'none';
              }
              console.log('Form ready!');
            },
            onFormSubmitted($form: any) {
              if (onFormSubmitted) {
                onFormSubmitted($form);
              }
              console.log('Form submitted end.');
            },
            portalId: hubspotPortalId,
            target: `.js-hubspot-container-${indexId}`,
          });
        };
      };
    }
  }, [
    formId,
    indexId,
    hubspotPortalId,
    onFormSubmit,
    onFormReady,
    onFormSubmitted,
    onValidate,
    readOnly,
  ]); // Only fire useEffect once to avoid errors

  return (
    <div className="hbspot-form">
      <div className="hs-form-outer-wrapper">
        <div className="hs-form-inner-wrapper">
          <div className={`js-hubspot-container-${indexId}`}></div>
        </div>
      </div>
    </div>
  );
};
