import axios from 'axios';
import { useEffect, useState } from 'react';
import { ReassignDropdown } from '@merchstores/shared/elements/ReassignDropdown';
import { Card } from '@merchstores/shared/elements/Card';
import './styles.scss';

export const ReassignMerch = (props: any) => {
  const {
    currentMerch,
    setCurrentMerch,
    currentOrderId,
    setOpen,
    isMerchstore,
    fetchMerchstores,
  } = props;

  const [chosenMerch, setChosenMerch] = useState(currentMerch);
  const [membersData, setMembersData] = useState([]);
  const [orderId] = useState(currentOrderId);
  const [, setOrderError] = useState(false);
  const [merchologists, setMerchologists] = useState(null);
  const [, setMerchologistError] = useState(false);

  useEffect(() => {
    const fetchMerchologists = async () => {
      axios
        .post('/.netlify/functions/fetchMerchologists', {})
        .then((res: any) => {
          if (res.data) {
            const getMerchologists = res.data.data.customers.edges;
            const merchologistArray: any[] = [];
            for (const merchologist of getMerchologists) {
              merchologistArray.push({
                email: merchologist.node.email,
                id: merchologist.node.id,
              });
            }
            // Sort the merchologists array alphabetically
            merchologistArray.sort((a: any, b: any) =>
              a.email > b.email ? 1 : -1
            );
            setMerchologists(merchologistArray);
          } else {
            console.log('No Merchologists Found');
            setMerchologistError(true);
          }
        })
        .catch((err: unknown) => {
          console.log('Error getting merchologists', err);
          setMerchologistError(true);
        });
    };
    fetchMerchologists();
  }, []);

  useEffect(() => {
    if (orderId) {
      axios
        .post('/.netlify/functions/fetchOrderMembers', {
          orderId,
        })
        .then((res) => {
          if (res.data) {
            setMembersData(res.data);
          } else {
            console.log(`No order found with for ID: ${orderId}`);
            setOrderError(true);
          }
        })
        .catch((err) => {
          console.error('Malformed Order ID', err);
          setOrderError(true);
        });
    } else {
      console.log('id is not defined');
    }
  }, [chosenMerch, orderId]);

  const list: any[] = [];

  for (const member of membersData) {
    if (member.invited) {
      if (
        !list.find((merchologist) => merchologist['email'] === member.email)
      ) {
        list.push({
          email: member.email,
          id: member._id,
          action: () => {
            setChosenMerch(member.email);
          },
        });
      }
    }
  }

  list.sort((a, b) => a.email.localeCompare(b.email));

  return (
    <Card style={{ maxWidth: '920px' }}>
      <div
        className="modal-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div className="w-10/12">
          <h2>Reassign Merchologist</h2>
        </div>

        <ReassignDropdown
          title={chosenMerch}
          list={merchologists}
          chosenMerchologist={chosenMerch}
          setChosenMerch={setChosenMerch}
          setCurrentMerch={setCurrentMerch}
          currentOrderId={currentOrderId}
          setOrderError={setOrderError}
          setOpen={setOpen}
          isMerchstore={isMerchstore}
          fetchMerchstores={fetchMerchstores}
        />
      </div>
    </Card>
  );
};
