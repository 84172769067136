import {
  MdAttachMoney,
  MdDateRange,
  MdGroup,
  MdShoppingCart,
} from 'react-icons/md';
import { BigNumber } from 'bignumber.js';
import { useContext } from 'react';
import { MerchStoreTileProps } from '.';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Context } from '../../../context';
import { getLocalDateText } from '../../DateTime/DateTimeFormat';
import { moneyFormat } from '@merchstores/shared/components/Money';
import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';

export const MerchStoreTiles = (
  props: MerchStoreTileProps
): JSX.Element | null => {
  const { merchStore, members } = props;
  const { userRole } = useContext(Context);
  const currentUserRole = new Role(userRole);

  const headerCardStyles =
    'w-full lg:w-create-order-header-card shadow-cards bg-white rounded-2xl p-0.5';
  const iconSize = '20px';

  const revenueDecimal =
    (merchStore.revenue && merchStore.revenue.$numberDecimal) || '0.00';
  const salesTotal = new BigNumber(revenueDecimal);
  const ordersCount = (merchStore && merchStore.individualOrdersCount) || 0;
  const membersCount = (members && members.length) || 0;

  if (!membersCount && !ordersCount) {
    return null; // hide component
  }

  return (
    <div className="flex justify-between mt-8">
      {/* Tile - Member Count */}
      <div className={`${headerCardStyles} flex`}>
        <div className="p-3 flex items-center w-full">
          <CTA
            size="standard"
            type="icon"
            icon={<MdGroup size={iconSize} />}
          ></CTA>
          <div className="ml-4 w-full">
            <p className="text-sm text-merch-dark-gray font-bold opacity-50">
              Members
            </p>
            <p className="text-xl text-merch-dark-gray font-bold">
              <span className="flex w-full justify-between">
                <span>{membersCount}</span>
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* Tile - Order Count */}
      <div className={`${headerCardStyles} overflow-hidden lg:flex ml-5`}>
        <div className="p-3 items-center flex">
          <CTA
            size="standard"
            type="icon"
            icon={<MdShoppingCart size={iconSize} />}
          ></CTA>
          <div className="ml-4">
            <p className="text-sm text-merch-dark-gray font-bold opacity-50">
              Orders
            </p>
            <p className="text-xl text-merch-dark-gray font-bold lg: break-words">
              {ordersCount}
            </p>
          </div>
        </div>
      </div>
      {/* Tile - Sales (IF Merchologist or Superuser) */}
      {userHasRole(currentUserRole, [ROLE_MERCHOLOGIST, ROLE_SUPERUSER]) && (
        <div className={`${headerCardStyles} overflow-hidden lg:flex ml-5`}>
          <div className="p-3 items-center flex">
            <CTA
              size="standard"
              type="icon"
              icon={<MdAttachMoney size={iconSize} />}
            ></CTA>
            <div className="ml-4">
              <p className="text-sm text-merch-dark-gray font-bold opacity-50">
                Sales
              </p>
              <p className="text-xl text-merch-dark-gray font-bold lg: break-words">
                {moneyFormat(salesTotal)}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Tile - Next Processing Date */}
      <div className={`${headerCardStyles} hidden lg:flex ml-5`}>
        <div className="p-3 items-center flex">
          <CTA
            size="standard"
            type="icon"
            icon={<MdDateRange size={iconSize} />}
          ></CTA>
          <div className="ml-4">
            <p className="text-sm text-merch-dark-gray font-bold opacity-50">
              Next Processing Date
            </p>
            <p className="text-xl text-merch-dark-gray font-bold">
              {merchStore
                ? getLocalDateText(merchStore.nextProcessingDate)
                : 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
