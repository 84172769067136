import axios, { AxiosResponse } from 'axios';

export async function deleteSubmission(
  submissionId: string
): Promise<AxiosResponse[]> {
  return axios.all([
    axios.post('/.netlify/functions/deleteSubmission', {
      submissionId: submissionId,
    }),
    axios.post('/.netlify/functions/updateMember', {
      submissionId: submissionId,
    }),
  ]);
}
