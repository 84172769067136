import { createStorefrontApiClient } from '@merchstores/admin/api/shopify-storefront/StorefrontApiClient';
import {
  IPriceFilter,
  makeGetProductsByStore,
  makeGetProductVariantById,
} from '@merchstores/admin/queries/storefront-products-2022-10';

import {
  IGraphProductEdge,
  IGraphImageEdge,
  IGraphVariantEdge,
  IGraphImageNode,
  IGraphMoney,
} from '@merchstores/admin/api/shopify-storefront/ShopifyProduct';

export interface IProductResult {
  id: string;
  handle: string;
  title: string;
  descriptionHtml: string;
  productType: string;
  vendor: string;
  price: {
    amount: string;
    currency: string;
  };
  compareAtPrice: {
    amount: string;
    currency: string;
  };
  variants: IProductResultVariant[];
  images: IGraphImageNode[];
  tags: string[];
  createdAt: string;
}

export interface IProductResultVariant {
  available: boolean;
  title: string;
  compareAtPrice: IGraphMoney;
  quantityAvailable: number;
  sku: string;
  id: string;
  price: IGraphMoney;
  images: IGraphImageNode[];
}

export async function fetchFilteredCollectionProducts(filters: {
  storeCode: string;
  productFilter?: string;
  brandFilter?: string;
  priceFilter?: IPriceFilter;
  sortChosen?: string;
  cursor?: string;
}): Promise<IProductResult[]> {
  const storeCode = filters.storeCode;
  const productFilter = filters.productFilter;
  const brandFilter = filters.brandFilter;
  const priceFilter = filters.priceFilter;
  const sortChosen = filters.sortChosen;
  const cursor = filters.cursor;

  let results: IProductResult[] = [];

  const storefrontClient = createStorefrontApiClient();

  const response = await storefrontClient.post(
    '',
    makeGetProductsByStore(storeCode, {
      productFilter,
      brandFilter,
      priceFilter,
      sortChosen,
      cursor: cursor,
    })
  );

  const { data: responseData } = response;
  const { products: productsData } = responseData;
  const { pageInfo, edges: productEdges } = productsData;

  if (productsData) {
    results = productEdges.map((edge: IGraphProductEdge) => {
      const dataProduct = edge.node;

      const resultProductVariants = dataProduct.variants.edges.map(
        (variantEdge: IGraphVariantEdge) => {
          return variantEdge.node;
        }
      );

      const resultProductImages = dataProduct.images.edges.map(
        (imageEdge: IGraphImageEdge) => {
          return imageEdge.node;
        }
      );

      return {
        ...dataProduct,
        images: resultProductImages,
        variants: resultProductVariants,
      };
    });
  }

  /*
    console.log(`No collection of products found for store ${merchstore.baseCollectionHandle}`);
    history.push("/404");
  */

  if (pageInfo.hasNextPage) {
    const nextCursor = productEdges[productEdges.length - 1].cursor;

    results = results.concat(
      await fetchFilteredCollectionProducts({
        storeCode,
        productFilter,
        brandFilter,
        priceFilter,
        sortChosen,
        cursor: nextCursor,
      })
    );
  }

  return results;
}

interface IVariantNodeResult {
  id: string;
  title: string;
}

export async function getProductVariantById(
  variantId: string
): Promise<IVariantNodeResult> {
  const storefrontClient = createStorefrontApiClient();

  const response = await storefrontClient.post(
    '',
    makeGetProductVariantById(variantId)
  );
  const { data: responseData } = response;
  const { node: variantData } = responseData;

  return {
    id: variantData.id,
    title: variantData.title,
  };
}
