const BlogEdge = {
  name: 'BlogEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Blog',
  },
  implementsNode: false,
};
export default BlogEdge;
