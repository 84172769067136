import axios from 'axios';
import { merchstoreStorefrontClient } from '@merchstores/admin/App';
import { getMerchstoreProductsByTag } from '@merchstores/admin/queries/storefront-graphql';
import { fetchShopifyOrders } from '../MerchStoreOrders';
import { IMerchStoreProps } from '.';

export interface IMerchStoreDeleteResult {
  success: boolean;
}

export async function deleteMerchstore(
  selectedMerchstore: IMerchStoreProps
): Promise<IMerchStoreDeleteResult> {
  const updatedMerchStore = {
    storeName: selectedMerchstore.name,
    storeCode: selectedMerchstore.storeCode,
    oldSubdomain: selectedMerchstore.subdomain,
  };

  await axios.post('/.netlify/functions/deleteMerchStore', {
    merchStoreData: updatedMerchStore,
  });

  const productTag = `merchstore_code=${selectedMerchstore.storeCode}`;

  // Get store's products and orders to update their tags
  const { data }: any = await merchstoreStorefrontClient.send(
    getMerchstoreProductsByTag(productTag)
  );

  const products = data?.products?.edges || [];

  const orders = await fetchShopifyOrders({
    storeCode: selectedMerchstore.storeCode,
  });

  const productsNewTags = products.map((p: any) => {
    return {
      id: p.node.id,
      tags: [`merchstore_subdomain=deleted-${selectedMerchstore.storeCode}`],
    };
  });
  const ordersNewTags =
    orders?.orders.map((o: any) => {
      return {
        id: o.id,
        tags: [`merchstore_subdomain=deleted-${selectedMerchstore.storeCode}`],
      };
    }) || [];

  const productsTagsToRemove =
    products.map((p: any) => {
      const subdomainTag = p.node.tags.find((t: string) =>
        t.startsWith('merchstore_subdomain=')
      );
      if (subdomainTag) {
        return {
          id: p.node.id,
          tags: [subdomainTag],
        };
      }
    }) || [];

  // Get's merchstore_url tag value from orders to remove it
  const ordersTagsToRemove =
    orders?.orders.map((o: any) => {
      const merchstoreUrlTag = o.tags.find(
        (t: string) =>
          t.startsWith('merchstore_url=') ||
          t.startsWith('merchstore_subdomain=')
      );
      if (merchstoreUrlTag) {
        return {
          id: o.id,
          tags: [merchstoreUrlTag],
        };
      }
    }) || [];

  const tagsToRemove = productsTagsToRemove.concat(ordersTagsToRemove);

  const newTags = productsNewTags.concat(ordersNewTags);

  // Add Products and Orders new tags
  if (newTags && newTags.length > 0) {
    newTags.map((t: any) => {
      axios.post('/.netlify/functions/addTags', t);
    });
  }

  // Remove order tags
  if (tagsToRemove && tagsToRemove.length > 0) {
    tagsToRemove.map((t: any) => {
      axios.post('/.netlify/functions/removeTags', t);
    });
  }

  await axios.post('/.netlify/functions/deleteNetlifySite', {
    siteId: selectedMerchstore.netlifySiteId,
  });

  return {
    success: true,
  };
}
