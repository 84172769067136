const MailingAddressInput = {
  name: 'MailingAddressInput',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    address1: 'String',
    address2: 'String',
    city: 'String',
    company: 'String',
    country: 'String',
    firstName: 'String',
    lastName: 'String',
    phone: 'String',
    province: 'String',
    zip: 'String',
  },
};
export default MailingAddressInput;
