import { ITableProps } from './index';
import './styles.scss';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useEffect } from 'react';
import { useState } from 'react';
import { generateReactKey } from '@merchstores/shared/react/ReactKey';

export const Table = (props: ITableProps) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [countPerPage, setCountPerPage] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [showHeader, setShowHeader] = useState(true);
  const [columns, setColumns] = useState(props.columns);
  const [footerText, setFooterText] = useState();

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (!props.elementsPerPage) {
      const isMobile = window.matchMedia(
        'only screen and (max-width: 760px)'
      ).matches;

      let headerHeight =
        document.getElementsByTagName('header')[0]?.offsetHeight;
      if (!headerHeight) headerHeight = 0;
      const totalHeight = document.getElementById('root')?.offsetHeight;
      if (totalHeight) {
        const tableHeight = totalHeight - headerHeight;
        if (tableHeight) {
          const theadHeight = isMobile ? 41 : 60;
          const trHeight = isMobile ? 65 : 80;
          const tfootHeight = isMobile ? 65 : 60;

          const tbodyHeight =
            totalHeight - headerHeight - theadHeight - tfootHeight;
          const countPerPage = Math.floor(tbodyHeight / trHeight);
          setCountPerPage(countPerPage > 0 ? countPerPage : 1);
        }
      }
    }
  }, [height, width]);

  useEffect(() => {
    if (props.usePagination === false) {
      setData(props.data);
      return false;
    }

    const isExternalPagination = props.externalPagination || false;

    if (isExternalPagination) {
      setData(props.data);
      setHasNextPage(props.hasNextPage);
      const count = props.elementsPerPage
        ? props.elementsPerPage
        : countPerPage;
      const startIndex = currentPage * count + 1;
      const endIndex = currentPage * count + data.length;
      setFooterText(
        `Showing ${data.length > 0 ? startIndex + ' - ' + endIndex : '0'} of ${props.totalCount || 0} ${props.itemsName}`
      );
    }

    if (!isExternalPagination) {
      /* Default behavior */
      let data = [];
      const count = props.elementsPerPage
        ? props.elementsPerPage
        : countPerPage;
      data = props.data.slice(currentPage * count, currentPage * count + count);
      if (
        props.data.slice(
          (currentPage + 1) * count,
          (currentPage + 1) * count + count
        ).length <= 0
      ) {
        setHasNextPage(false);
      } else {
        setHasNextPage(true);
      }

      // Moves the user to first page after a filter is chosen
      if (currentPage !== 0 && data.length === 0) {
        setCurrentPage(0);
      }

      setData(data);
      const startIndex = currentPage * count + 1;
      const endIndex = currentPage * count + data.length;
      setFooterText(
        `Showing ${data.length > 0 ? startIndex + ' - ' + endIndex : '0'} of ${props.data.length} ${props.itemsName}`
      );
    }

    if (currentPage === 0) {
      setHasPreviousPage(false);
    } else {
      setHasPreviousPage(true);
    }
  }, [currentPage, props.data, data.length, props.itemsName, countPerPage]);

  useEffect(() => {
    if (props.header) {
      setShowHeader(props.header);
    }
    if (props.columns && props.columns.length > 0) {
      setColumns(props.columns);
    }
  }, [props.columns, props.header]);

  useEffect(() => {
    if (props.afterRender) {
      props.afterRender();
    }
  });

  const scrollBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const previousPageAction = async () => {
    setTableLoading(true);
    const previousPageNumber = currentPage - 1;
    if (props.onPagination) {
      await props.onPagination(previousPageNumber);
    }
    setCurrentPage(previousPageNumber);
    scrollBottom();
    setTableLoading(false);
  };

  const nextPageAction = async () => {
    setTableLoading(true);
    const nextPageNumber = currentPage + 1;
    if (props.onPagination) {
      await props.onPagination(nextPageNumber);
    }
    setCurrentPage(nextPageNumber);
    scrollTop();
    setTableLoading(false);
  };

  return (
    <div className="h-full">
      <div className="table-actions">
        {props.sortable && <button>Sort</button>}
      </div>
      <table className="table-auto h-auto" id={`table-${props.name}`}>
        {showHeader !== false && columns && (
          <thead>
            <tr className="desktop">
              {columns.map((column: any, thIndex: number) => (
                <th
                  colSpan={column.colspan ? column.colspan : '1'}
                  key={`th-${thIndex}-${generateReactKey(props.name)}`}
                  className={column.class}
                >
                  {column.text}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {data.map((d: any, trIndex: number) => {
            return (
              <tr
                className={d.tr.class}
                key={`tr-${trIndex}-${generateReactKey(props.name)}`}
              >
                {Object.keys(d.tr.data).map((k: any, tdIndex: number) => {
                  const value = d.tr.data[k].value;
                  const desktopOnly = d.tr.data[k].desktopOnly ? 'desktop' : '';
                  const mobileOnly = d.tr.data[k].mobileOnly ? 'mobile' : '';
                  return (
                    <td
                      key={`td-${tdIndex}-${generateReactKey(props.name)}`}
                      className={`items-center ${k} ${desktopOnly} ${mobileOnly}`}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>

        {props.displayFooter !== false && (
          <tfoot>
            <tr>
              <td colSpan={100}>
                <div className="footer-container h-full pl-4 pr-7">
                  <div className="pagination-count italic">{footerText}</div>
                  {(hasPreviousPage || hasNextPage) && (
                    <div className="pagination-arrows buttons-container group">
                      <button
                        className={`secondary ${!hasPreviousPage || tableLoading ? 'disabled' : ''} left`}
                        disabled={!hasPreviousPage ? true : false}
                        onClick={previousPageAction}
                      >
                        <MdKeyboardArrowLeft size="18px" />
                      </button>
                      <button
                        className={`secondary ${!hasNextPage || tableLoading ? 'disabled' : ''} right`}
                        disabled={!hasNextPage ? true : false}
                        onClick={nextPageAction}
                      >
                        <MdKeyboardArrowRight size="18px" />
                      </button>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};
