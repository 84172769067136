const Transaction = {
  name: 'Transaction',
  kind: 'OBJECT',
  fieldBaseTypes: {
    amount: 'Money',
    amountV2: 'MoneyV2',
    kind: 'TransactionKind',
    status: 'TransactionStatus',
    statusV2: 'TransactionStatus',
    test: 'Boolean',
  },
  implementsNode: false,
};
export default Transaction;
