const __Directive = {
  name: '__Directive',
  kind: 'OBJECT',
  fieldBaseTypes: {
    args: '__InputValue',
    description: 'String',
    locations: '__DirectiveLocation',
    name: 'String',
    onField: 'Boolean',
    onFragment: 'Boolean',
    onOperation: 'Boolean',
  },
  implementsNode: false,
};
export default __Directive;
