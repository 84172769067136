import { useEffect } from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import Modal from 'react-responsive-modal';

import { CTA } from '@merchstores/shared/elements/Cta';
import { FormInput } from '@merchstores/shared/elements/FormInput';

import countriesInformation from 'i18n-iso-countries/langs/en.json';

import { IMerchstoreShipToOffice } from './index';
import './styles.scss';
import { FormSelect } from '@merchstores/shared/elements/FormSelect';
import _ from 'lodash';
import { testCheckoutShippingAddress } from '../../MerchStoreOrderCheckout';

export const MerchstoreShipToOffice = (
  props: IMerchstoreShipToOffice
): JSX.Element => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    setError,
    clearErrors,
    reset,
  } = useForm();

  const [modalOpen, setModalOpen] = useState(props.modalOpen);
  const [countries, setCountries] = useState([]);
  const [merchStore, setMerchStore] = useState(props.merchStore);
  const [defaultCountry, setDefaultCountry] = useState('');
  const [addressError, setAddressError] = useState(null);

  const phoneNumber = useWatch({
    control,
    name: 'phone',
    defaultValue: '',
  });

  useEffect(() => {
    if (countriesInformation && countriesInformation.countries) {
      const countriesNames = Object.values(countriesInformation.countries);
      const countries = countriesNames.map((c) => {
        let countryName = '';
        if (Array.isArray(c)) {
          countryName = c[0];
        } else {
          countryName = c;
        }
        if (countryName.toLowerCase() === 'united states of america') {
          countryName = 'United States';
        }
        return { displayText: countryName, value: countryName };
      });
      setCountries(countries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesInformation]);

  useEffect(() => {
    if (props.modalOpen !== modalOpen) {
      setModalOpen(props.modalOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalOpen]);

  useEffect(() => {
    if (props.merchStore && props.merchStore !== merchStore) {
      setMerchStore(props.merchStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.merchStore]);

  useEffect(() => {
    if (countries && merchStore) {
      if (merchStore.officeAddress) {
        setDefaultCountry(merchStore.officeAddress.country);
        setValue('phone', merchStore.officeAddress.phone);
      } else {
        setDefaultCountry('United States');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, merchStore]);

  useEffect(() => {
    const regex =
      /(\+*[0-9]{1,3})*\s*[(]{0,1}[0-9]{1,4}[)]{0,1}\s*[-\s./0-9]{0,20}$/g;
    if (phoneNumber && !phoneNumber.match(regex)) {
      setError('phone', {
        type: 'invalid',
        message: 'Please input a valid phone number.',
      });
    } else {
      clearErrors('phone');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const handleShipToOfficeAddressSave = async (data: any) => {
    try {
      const checkoutResponse = await testCheckoutShippingAddress([], {
        ...merchStore,
        // always force ship to office to trigger the validation
        shipToOffice: true,
        officeAddress: data,
      });

      if (checkoutResponse.errors && checkoutResponse.errors.length >= 1) {
        const errors = checkoutResponse.errors.map((err) => err.message);
        setAddressError(_.join(errors, '\n'));
      } else {
        setAddressError(null);
        if (props.handleShipToOfficeAddressSave) {
          props.handleShipToOfficeAddressSave(data);
        }
      }
    } catch (err) {
      setAddressError(
        "The shipping address doesn't have a valid format. Please update it before saving your Merchstore."
      );
      throw err;
    }
  };

  const onCloseModal = () => {
    setModalOpen(false);
    reset();
    if (props.onCancelShipToOffice) {
      props.onCancelShipToOffice();
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        classNames={{ modal: 'ship-to-office-modal' }}
        onClose={onCloseModal}
        center
      >
        <h2>Ship to Office</h2>
        <h3 className="font-bold">Office Address</h3>
        <form
          onSubmit={handleSubmit(handleShipToOfficeAddressSave)}
          className="w-full"
          id="create-edit-shipping-form"
        >
          <FormInput
            label="Company"
            name="company"
            errors={errors}
            register={register}
            required={true}
            type="text"
            defaultValue={
              merchStore && merchStore.officeAddress
                ? merchStore.officeAddress.company
                : ''
            }
          />
          <div className="flex two-inputs-50-50">
            <FormInput
              label="First name"
              name="firstName"
              errors={errors}
              register={register}
              required={false}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.firstName
                  : ''
              }
            />
            <FormInput
              label="Last name"
              name="lastName"
              errors={errors}
              register={register}
              required={true}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.lastName
                  : ''
              }
            />
          </div>

          <div className="flex two-inputs-60-40">
            <FormInput
              label="Street Address"
              name="address1"
              errors={errors}
              register={register}
              required={true}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.address1
                  : ''
              }
            />
            <FormInput
              label="Apt, Suite, etc."
              name="address2"
              errors={errors}
              register={register}
              required={false}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.address2
                  : ''
              }
            />
          </div>

          <div className="flex two-inputs-50-50">
            <FormInput
              label="City"
              name="city"
              errors={errors}
              register={register}
              required={true}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.city
                  : ''
              }
            />
            <FormInput
              label="State"
              name="province"
              errors={errors}
              register={register}
              required={true}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.province
                  : ''
              }
            />
          </div>

          <div className="flex two-inputs-60-40">
            <FormSelect
              label="Country"
              name="country"
              errors={errors}
              register={register}
              setValue={setValue}
              options={countries}
              default={defaultCountry}
              selectStyle="two"
            />
            <FormInput
              label="Postal code"
              name="zip"
              errors={errors}
              register={register}
              required={true}
              type="text"
              defaultValue={
                merchStore && merchStore.officeAddress
                  ? merchStore.officeAddress.zip
                  : ''
              }
            />
          </div>
          <FormInput
            label="Phone"
            name="phone"
            errors={errors}
            register={register}
            required={true}
            type="text"
            defaultValue={
              merchStore && merchStore.officeAddress
                ? merchStore.officeAddress.phone
                : ''
            }
          />
          {addressError && <p className="error pl-2 pb-2">{addressError}</p>}

          <div className="flex justify-between">
            <CTA
              size="large"
              type="secondary"
              onClick={onCloseModal}
              classes="mr-16"
            >
              Cancel
            </CTA>
            <CTA
              size="large"
              type="primary"
              formSubmit={true}
              disabled={!_.isEmpty(errors)}
            >
              Save
            </CTA>
          </div>
        </form>
      </Modal>
    </>
  );
};
