const Node = {
  name: 'Node',
  kind: 'INTERFACE',
  fieldBaseTypes: {
    id: 'ID',
  },
  possibleTypes: [
    'AppliedGiftCard',
    'Article',
    'Blog',
    'Checkout',
    'CheckoutLineItem',
    'Collection',
    'Comment',
    'ExternalVideo',
    'MailingAddress',
    'MediaImage',
    'Metafield',
    'Model3d',
    'Order',
    'Page',
    'Payment',
    'Product',
    'ProductOption',
    'ProductVariant',
    'ShopPolicy',
    'Video',
  ],
};
export default Node;
