import { IMerchStore } from '@merchstores/admin/types/merchstore';

import { FormInput } from '@merchstores/shared/elements/FormInput';
import { CTA } from '@merchstores/shared/elements/Cta';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { useEffect } from 'react';

import { toast } from 'react-toastify';

interface Props {
  allowEdit?: boolean;
  merchStore: IMerchStore;
  getOrderNotes?: () => Promise<string>;
  updateOrderNotes?: (storeCode: string, orderNotes: string) => void;
}

interface InternalOrderNotesFormData {
  'internal-order-notes': string;
}

export const InternalOrderNotesForm = (props: Props): JSX.Element => {
  const { allowEdit, merchStore, getOrderNotes, updateOrderNotes } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [
    previousInternalOrderNotesLoaded,
    setExistingInternalOrderNotesLoaded,
  ] = useState(false);

  useEffect(() => {
    if (!getOrderNotes) {
      return;
    }

    getOrderNotes().then((existingNotes) => {
      setValue('internal-order-notes', existingNotes);
      setExistingInternalOrderNotesLoaded(true);
    });
  }, [merchStore]);

  if (!previousInternalOrderNotesLoaded) {
    return <></>;
  }

  function onInternalOrderNotesChange() {
    if (allowEdit) {
      setShowSaveButton(true);
    }
  }

  async function onInternalOrderNotesSubmit(
    submitData: InternalOrderNotesFormData
  ): Promise<void> {
    if (!allowEdit) {
      return;
    }

    if (!updateOrderNotes) {
      return;
    }

    const internalOrderNotes = submitData['internal-order-notes'] || '';

    await updateOrderNotes(merchStore.storeCode, internalOrderNotes);

    setShowSaveButton(false);

    toast.success('Internal Order Notes updated successfully.');
  }

  return (
    <form onSubmit={handleSubmit(onInternalOrderNotesSubmit)} className="mb-30">
      <FormInput
        onChange={onInternalOrderNotesChange}
        type="textarea"
        textArea={true}
        inputStyle="two"
        name="internal-order-notes"
        textAreaLines={2}
        defaultValue={''}
        placeholder="No internal notes for this store."
        label="Internal Order Notes"
        required={true}
        register={register}
        errors={errors}
        tooltip={`These notes will be copied as Shopify Order Notes of the current combined order submission.`}
        textAreaClassName="opacity-100"
        disabled={!props.allowEdit}
      />

      {allowEdit && showSaveButton && (
        <div className="flex gap-4 mb-4 justify-end">
          <CTA type={'primary'} size={'standard'} formSubmit={true}>
            Save
          </CTA>
        </div>
      )}
    </form>
  );
};

export default InternalOrderNotesForm;
