import { useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { IOrderDetailsView } from '@merchstores/admin/types/merchstoreOrder';
import { CTA } from '@merchstores/shared/elements/Cta';

export interface Props {
  storeCode: string;
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
}

const BackButton = (props: Props): JSX.Element => {
  const { storeCode, setSelectedOrderDetails } = props;
  const history = useHistory();

  const handlebackClick = (): void => {
    const selectedOrder: IOrderDetailsView = {
      viewType: 'none',
      individualOrder: null,
      combinedOrder: null,
    };
    setSelectedOrderDetails(selectedOrder);
    history.push(`/admin/merchstores/${storeCode}`);
  };

  return (
    <CTA size="small" type="mobile-icon" onClick={handlebackClick}>
      <MdArrowBack />
    </CTA>
  );
};

export default BackButton;
