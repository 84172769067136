import { GiftCardPlaceholder } from '@merchstores/admin/assets/orders';

interface Props {
  image: string | null | undefined;
}

const OrderImage = (props: Props): JSX.Element => {
  const { image } = props;

  return (
    <div>
      {image ? <img width={119} src={image} /> : <GiftCardPlaceholder />}
    </div>
  );
};

export default OrderImage;
