const ScriptDiscountApplication = {
  name: 'ScriptDiscountApplication',
  kind: 'OBJECT',
  fieldBaseTypes: {
    allocationMethod: 'DiscountApplicationAllocationMethod',
    description: 'String',
    targetSelection: 'DiscountApplicationTargetSelection',
    targetType: 'DiscountApplicationTargetType',
    title: 'String',
    value: 'PricingValue',
  },
  implementsNode: false,
};
export default ScriptDiscountApplication;
