const FulfillmentTrackingInfo = {
  name: 'FulfillmentTrackingInfo',
  kind: 'OBJECT',
  fieldBaseTypes: {
    number: 'String',
    url: 'URL',
  },
  implementsNode: false,
};
export default FulfillmentTrackingInfo;
