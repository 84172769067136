const CustomerAddressDeletePayload = {
  name: 'CustomerAddressDeletePayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customerUserErrors: 'CustomerUserError',
    deletedCustomerAddressId: 'String',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CustomerAddressDeletePayload;
