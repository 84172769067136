const CustomerAccessTokenCreateWithMultipassPayload = {
  name: 'CustomerAccessTokenCreateWithMultipassPayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customerAccessToken: 'CustomerAccessToken',
    customerUserErrors: 'CustomerUserError',
  },
  implementsNode: false,
};
export default CustomerAccessTokenCreateWithMultipassPayload;
