const VideoSource = {
  name: 'VideoSource',
  kind: 'OBJECT',
  fieldBaseTypes: {
    format: 'String',
    height: 'Int',
    mimeType: 'String',
    url: 'String',
    width: 'Int',
  },
  implementsNode: false,
};
export default VideoSource;
