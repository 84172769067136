const MailingAddress = {
  name: 'MailingAddress',
  kind: 'OBJECT',
  fieldBaseTypes: {
    address1: 'String',
    address2: 'String',
    city: 'String',
    company: 'String',
    country: 'String',
    countryCode: 'String',
    countryCodeV2: 'CountryCode',
    firstName: 'String',
    formatted: 'String',
    formattedArea: 'String',
    id: 'ID',
    lastName: 'String',
    latitude: 'Float',
    longitude: 'Float',
    name: 'String',
    phone: 'String',
    province: 'String',
    provinceCode: 'String',
    zip: 'String',
  },
  implementsNode: true,
};
export default MailingAddress;
