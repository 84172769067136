import {
  Role,
  ROLE_MERCHOLOGIST,
  ROLE_SUPERUSER,
  userHasRole,
} from '@merchstores/admin/context/Roles';
import { getLocalDateFullMonthText } from '@merchstores/admin/components/DateTime';
import { isAutoCloseOrdersEnabled } from '../CreateEditMerchStore/AutoCloseOrders';
import { ACTIVE_ORDER_ID } from '@merchstores/admin/components/MerchStoreOrders';
import { ICombinedOrder } from '@merchstores/admin/types/shopifyCombinedOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { PayerType } from '@merchstores/shared/components/MerchStore';
import { moneyFormat } from '@merchstores/shared/components/Money';
import { CTA } from '@merchstores/shared/elements/Cta';
import { OrderCheckoutCTA } from './OrderCheckoutCTA';
import OrderCalculator from '@merchstores/admin/components/MerchStoreOrders/OrderCalculator';
import './OrderDetailsSummary.scss';

export interface IOrderDetailsSummaryProps {
  merchStore: IMerchStore;
  combinedOrder: ICombinedOrder;
  userRole: Role;
  closeDate: string;
}

export const OrderDetailsSummary: React.FC<IOrderDetailsSummaryProps> = (
  props: IOrderDetailsSummaryProps
) => {
  const merchStore = props.merchStore;
  const closeDate = props.closeDate;
  const order = props.combinedOrder;
  const orderTotal = OrderCalculator.calculateSummaryTotal(order);

  const isActiveOrder = order.id === ACTIVE_ORDER_ID;

  const isAlwaysOpen =
    merchStore.alwaysOpen && [true, 'true'].includes(merchStore.alwaysOpen);
  const isStaticClose = !isAlwaysOpen;
  const isAdminPayer = merchStore.payerType === PayerType.ADMIN;
  const isIndividualPayer = merchStore.payerType === PayerType.INDIVIDUAL;
  const isPendingProcessing = order.isProcessingPending;
  const isProcessingDueDate = order.isProcessingDueDate;
  const isStoreOpen = merchStore.status !== 'closed';
  const isStoreInDraft = merchStore.status === 'draft';

  const hasItems = !!order.itemsCount;
  const isMerchologistOrSuperuser = userHasRole(props.userRole, [
    ROLE_MERCHOLOGIST,
    ROLE_SUPERUSER,
  ]);

  return (
    <div
      // eslint-disable-next-line max-len
      className="order-details-sidebar-menu p-8 bg-gray-50 lg:min-w-order-sidebar flex flex-col items-start rounded-tr-24 rounded-br-24"
    >
      <div className="w-full">
        <div className="w-full text-sm text-merch-dark-gray font-bold mb-5">
          <div className="title w-full pb-20">Payment Details</div>
        </div>
        <div className="flex grid grid-cols-3 gap-4 pb-30 mb-30 border-b item-text">
          <div className="font-bold">Subtotal:</div>
          <div>{`${order.itemsCount ? order.itemsCount : 0} Items`}</div>
          <div className="text-right item-value">${order.subtotalPrice}</div>

          {!isActiveOrder && (
            <>
              <div className="font-bold">Shipping:</div>
              <div>{order.shippingTitle}</div>
              <div className="text-right item-value">
                ${order.totalShippingPrice}
              </div>

              <div className="font-bold">Sales Tax:</div>
              <div className="empty-cell"></div>
              <div className="text-right item-value">${order.totalTax}</div>
            </>
          )}
        </div>

        {!isActiveOrder && (
          <div className="flex justify-between mb-25 item-text item-value font-bold">
            <div>Total:</div>
            <div className="text-lg">{moneyFormat(orderTotal)}</div>
          </div>
        )}

        <div className="flex w-full">
          <OrderCheckoutCTA
            merchStore={merchStore}
            combinedOrder={order}
            display={
              isActiveOrder &&
              (isMerchologistOrSuperuser ||
                isAdminPayer ||
                (isIndividualPayer && !isAutoCloseOrdersEnabled(merchStore)))
            }
            disabled={!order.itemsCount || isStoreInDraft}
          />

          {!isActiveOrder && (
            <CTA
              type="clear-background"
              size="standard"
              classes="text-sm w-full bg-gray-400 hover:bg-gray-400 text-gray-100	"
            >
              Order Placed: {getLocalDateFullMonthText(closeDate)}
            </CTA>
          )}
        </div>

        <div className="flex justify-center flex-col text-center mt-20">
          {isPendingProcessing &&
            isStaticClose &&
            isStoreOpen &&
            !isProcessingDueDate &&
            merchStore.nextProcessingDate && (
              <div className="item-text mb-10">
                Orders can be placed until
                <div className="text-lg font-bold item-value mt-10">
                  {getLocalDateFullMonthText(merchStore.nextProcessingDate)}
                </div>
                .
              </div>
            )}

          {isStoreInDraft && (
            <div className="item-text mb-10">This store is not yet active.</div>
          )}

          {!isStoreInDraft &&
            isPendingProcessing &&
            isAlwaysOpen &&
            hasItems &&
            isAdminPayer && (
              <div className="item-text mb-10">
                Your current order is ready to process. Please complete your
                pending order.
              </div>
            )}

          {!isStoreInDraft &&
            isPendingProcessing &&
            hasItems &&
            isIndividualPayer &&
            merchStore.nextProcessingDate && (
              <div className="item-text mb-10">
                This group order will process automatically on:{' '}
                {getLocalDateFullMonthText(merchStore.nextProcessingDate)}
              </div>
            )}

          {!isStoreInDraft &&
            isPendingProcessing &&
            isStaticClose &&
            isIndividualPayer &&
            isProcessingDueDate &&
            hasItems && (
              <div className="item-text mb-10">
                Your store has closed. Individuals already paid. Please complete
                your pending order.
              </div>
            )}

          {!isStoreInDraft &&
            isPendingProcessing &&
            isStaticClose &&
            isAdminPayer &&
            isProcessingDueDate &&
            hasItems && (
              <div className="item-text mb-10">
                Your store has closed. Please complete your pending order.
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
