const Mutation = {
  name: 'Mutation',
  kind: 'OBJECT',
  fieldBaseTypes: {
    checkoutAttributesUpdate: 'CheckoutAttributesUpdatePayload',
    checkoutAttributesUpdateV2: 'CheckoutAttributesUpdateV2Payload',
    checkoutCompleteFree: 'CheckoutCompleteFreePayload',
    checkoutCompleteWithCreditCard: 'CheckoutCompleteWithCreditCardPayload',
    checkoutCompleteWithCreditCardV2: 'CheckoutCompleteWithCreditCardV2Payload',
    checkoutCompleteWithTokenizedPayment:
      'CheckoutCompleteWithTokenizedPaymentPayload',
    checkoutCompleteWithTokenizedPaymentV2:
      'CheckoutCompleteWithTokenizedPaymentV2Payload',
    checkoutCompleteWithTokenizedPaymentV3:
      'CheckoutCompleteWithTokenizedPaymentV3Payload',
    checkoutCreate: 'CheckoutCreatePayload',
    checkoutCustomerAssociate: 'CheckoutCustomerAssociatePayload',
    checkoutCustomerAssociateV2: 'CheckoutCustomerAssociateV2Payload',
    checkoutCustomerDisassociate: 'CheckoutCustomerDisassociatePayload',
    checkoutCustomerDisassociateV2: 'CheckoutCustomerDisassociateV2Payload',
    checkoutDiscountCodeApply: 'CheckoutDiscountCodeApplyPayload',
    checkoutDiscountCodeApplyV2: 'CheckoutDiscountCodeApplyV2Payload',
    checkoutDiscountCodeRemove: 'CheckoutDiscountCodeRemovePayload',
    checkoutEmailUpdate: 'CheckoutEmailUpdatePayload',
    checkoutEmailUpdateV2: 'CheckoutEmailUpdateV2Payload',
    checkoutGiftCardApply: 'CheckoutGiftCardApplyPayload',
    checkoutGiftCardRemove: 'CheckoutGiftCardRemovePayload',
    checkoutGiftCardRemoveV2: 'CheckoutGiftCardRemoveV2Payload',
    checkoutGiftCardsAppend: 'CheckoutGiftCardsAppendPayload',
    checkoutLineItemsAdd: 'CheckoutLineItemsAddPayload',
    checkoutLineItemsRemove: 'CheckoutLineItemsRemovePayload',
    checkoutLineItemsReplace: 'CheckoutLineItemsReplacePayload',
    checkoutLineItemsUpdate: 'CheckoutLineItemsUpdatePayload',
    checkoutShippingAddressUpdate: 'CheckoutShippingAddressUpdatePayload',
    checkoutShippingAddressUpdateV2: 'CheckoutShippingAddressUpdateV2Payload',
    checkoutShippingLineUpdate: 'CheckoutShippingLineUpdatePayload',
    customerAccessTokenCreate: 'CustomerAccessTokenCreatePayload',
    customerAccessTokenCreateWithMultipass:
      'CustomerAccessTokenCreateWithMultipassPayload',
    customerAccessTokenDelete: 'CustomerAccessTokenDeletePayload',
    customerAccessTokenRenew: 'CustomerAccessTokenRenewPayload',
    customerActivate: 'CustomerActivatePayload',
    customerActivateByUrl: 'CustomerActivateByUrlPayload',
    customerAddressCreate: 'CustomerAddressCreatePayload',
    customerAddressDelete: 'CustomerAddressDeletePayload',
    customerAddressUpdate: 'CustomerAddressUpdatePayload',
    customerCreate: 'CustomerCreatePayload',
    customerDefaultAddressUpdate: 'CustomerDefaultAddressUpdatePayload',
    customerRecover: 'CustomerRecoverPayload',
    customerReset: 'CustomerResetPayload',
    customerResetByUrl: 'CustomerResetByUrlPayload',
    customerUpdate: 'CustomerUpdatePayload',
  },
  implementsNode: false,
  relayInputObjectBaseTypes: {
    checkoutAttributesUpdate: 'CheckoutAttributesUpdateInput',
    checkoutAttributesUpdateV2: 'CheckoutAttributesUpdateV2Input',
    checkoutCreate: 'CheckoutCreateInput',
    customerAccessTokenCreate: 'CustomerAccessTokenCreateInput',
    customerActivate: 'CustomerActivateInput',
    customerCreate: 'CustomerCreateInput',
    customerReset: 'CustomerResetInput',
  },
};
export default Mutation;
