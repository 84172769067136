export const adminPayerOption = {
  value: 'admin_payer',
  displayText: 'Administrator Pays',
};

export const individualPayerOption = {
  value: 'individual_payer',
  displayText: 'Individual Pays',
};

export function newMerchStorePaymentOptions(): {
  value: string;
  displayText: string;
}[] {
  return [adminPayerOption, individualPayerOption];
}
