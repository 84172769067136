const ProductVariantEdge = {
  name: 'ProductVariantEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'ProductVariant',
  },
  implementsNode: false,
};
export default ProductVariantEdge;
