const CollectionEdge = {
  name: 'CollectionEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Collection',
  },
  implementsNode: false,
};
export default CollectionEdge;
