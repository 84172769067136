import { DateTime } from 'luxon';

export function currentDate(): Date {
  return new Date(DateTime.utc().toISO());
}

export function formatLocalShortDate(dateTime: DateTime): string {
  return dateTime.toLocaleString();
}

export function parseIsoDate(isoDateText: string): DateTime {
  const dt = DateTime.fromISO(isoDateText, { zone: 'utc' });

  if (!dt.isValid) {
    throw new Error(String(dt.invalidExplanation));
  }

  return dt.setZone('utc');
}

/*
  WARNING: this function is meant to be used for compatibility purposes
  with existing datasets using the american short address (M/D/YYYY)
  new code MUST use ISO and parseIsoDate()
*/
export function parseAmericanDate(rawDateText: string): Date {
  return parseMYDDate(rawDateText);
}

/*
  WARNING: this function is meant to be used for compatibility purposes
  with existing datasets using the american short address (M/D/YYYY)
  new code MUST use ISO and parseIsoDate()
*/
export function parseMYDDate(rawDateText: string): Date {
  const dt = DateTime.fromFormat(rawDateText, 'MM/dd/yyyy', { zone: 'utc' });

  if (!dt.isValid) {
    throw new Error(String(dt.invalidExplanation));
  }

  return new Date(dt.toISO());
}

/*
  WARNING: this function is meant to be used for compatibility purposes
  with existing datasets using the american short address (M/D/YYYY)
  new code MUST use ISO and parseIsoDate()
*/
export function parseDate(rawDateText: string): DateTime {
  let dt;

  if (rawDateText.indexOf('/') > -1) {
    dt = DateTime.fromFormat(rawDateText, 'MM/dd/yyyy', { zone: 'utc' });
  } else {
    dt = DateTime.fromISO(rawDateText, { zone: 'utc' });
  }

  return dt.setZone('utc');
}

export function isPastDate(dateString: string): boolean {
  return DateTime.utc() > parseDate(dateString);
}
