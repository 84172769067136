const ApiVersion = {
  name: 'ApiVersion',
  kind: 'OBJECT',
  fieldBaseTypes: {
    displayName: 'String',
    handle: 'String',
    supported: 'Boolean',
  },
  implementsNode: false,
};
export default ApiVersion;
