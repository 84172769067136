import { TableCellAlign } from '@merchstores/admin/types/tableStyles';

interface Props {
  text: string;
  align?: TableCellAlign;
}

const getCellAlign = (align?: TableCellAlign): string => {
  const alignMap: Record<string, string> = {
    left: 'justify-start',
    right: 'justify-end',
    center: 'justify-center',
  };
  return align ? alignMap[align] : alignMap[TableCellAlign.LEFT];
};

const TextTableCell = (props: Props): JSX.Element => {
  const { text, align } = props;
  const aligment = getCellAlign(align);

  return <div className={`font-bold flex mx-4 ${aligment}`}>{text}</div>;
};

export default TextTableCell;
