export const alwaysOpenOption = {
  value: 'true',
  displayText: 'Always Open',
};

export const limitedTimeOption = {
  value: 'false',
  displayText: 'Limited Time',
};

export function newMerchStoreTypeOptions(): {
  value: string;
  displayText: string;
}[] {
  return [alwaysOpenOption, limitedTimeOption];
}
