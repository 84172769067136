import { extractAttribute } from '@merchstores/admin/lib/shopifyOrders';
import { IShopifyOrder } from '@merchstores/admin/types/shopifyOrder';
import { ILineItem } from '@merchstores/admin/types/shopifyCheckout';
import OrderImage from './OrderImage';
import LineItem from './LineItem';

interface Props {
  order: IShopifyOrder;
}

const extractImage = (lineItems: ILineItem[] | undefined) => {
  return lineItems && lineItems?.length > 0
    ? lineItems[0].image?.transformedSrc
    : null;
};

const OrderSummary = (props: Props): JSX.Element => {
  const { order } = props;
  const quantity =
    extractAttribute(order.customAttributes, 'gift_card_quantity') || '0';
  const itemsQuantity = `${quantity} items in cart`;
  const image = extractImage(order.lineItems);

  return (
    <div className="flex flex-col p-8 border rounded-xl border-gray-200 shadow mb-20">
      <div className="text-sm pb-8 text-gray-400">{itemsQuantity}</div>
      <div className="flex grid-cols-2 gap-8">
        <OrderImage image={image} />
        <div className="w-full">
          <div className="text-xl font-bold mb-3 leading-6">
            MerchStore Gift Card
          </div>
          {order.lineItems?.map((item: ILineItem, index: number) => (
            <LineItem key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
