const CustomerAccessToken = {
  name: 'CustomerAccessToken',
  kind: 'OBJECT',
  fieldBaseTypes: {
    accessToken: 'String',
    expiresAt: 'DateTime',
  },
  implementsNode: false,
};
export default CustomerAccessToken;
