import axios, { AxiosResponse, AxiosError } from 'axios';

export interface ISendEmailResult {
  data?: any;
  error?: Error;
}

interface IEmailTemplateVariable {
  name: string;
  content?: string;
}

export interface ITemplatedEmail {
  templateId: string;
  subject: string;
  text: string;
  templateContent: Array<IEmailTemplateVariable>;
}

export const sendEmail = (
  email: ITemplatedEmail,
  recipients: Array<string>
): Promise<ISendEmailResult> => {
  const senderUrl = '/.netlify/functions/sendEmail-background';

  return axios
    .post(senderUrl, {
      email,
      recipients: recipients.map((email: string) => {
        return { email };
      }),
    })
    .then((response: AxiosResponse) => {
      console.log('Email sent', response.data);
      return {
        data: response.data,
      };
    })
    .catch((err: AxiosError) => {
      console.error('An error occured sending the email.', err);
      return {
        error: err,
      };
    });
};
