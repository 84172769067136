import { useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import { useState, useEffect } from 'react';
import {
  ACTIVE_ORDER_ID,
  fetchIndividualOrders,
  fetchShopifyOrder,
} from '@merchstores/admin/components/MerchStoreOrders';
import {
  IOrderDetailsView,
  OrderStatus,
} from '@merchstores/admin/types/merchstoreOrder';
import { ICombinedOrder } from '@merchstores/admin/types/shopifyCombinedOrder';
import { IShopifyOrdersData } from '@merchstores/admin/types/shopifyOrder';
import { getLocalDateText } from '@merchstores/admin/components/DateTime';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { Badge, BadgeType } from '@merchstores/shared/elements/Badge';
import { IndividualOrderList } from './IndividualOrderList';
import { OrderDetailsSummary } from './OrderDetailsSummary';
import { CTA } from '@merchstores/shared/elements/Cta';
import './OrderDetailsPage.scss';

import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';

import {
  InternalOrderNotesForm,
  fetchMerchStoreInternalOrderNotes,
  updateMerchStoreInternalOrderNotes,
} from '@merchstores/admin/components/MerchStoreInternalOrderNotes';

export interface IOrderDetailsPageProps {
  merchStore: IMerchStore;
  order: ICombinedOrder;
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
  userRole: Role;
}

async function getInternalOrderNotes(storeCode: string, orderId: string) {
  if (orderId === ACTIVE_ORDER_ID) {
    return fetchMerchStoreInternalOrderNotes(storeCode).then((notesData) => {
      return notesData.internalOrderNotes;
    });
  }

  if (orderId !== ACTIVE_ORDER_ID) {
    return fetchShopifyOrder({
      storeCode: storeCode,
      orderId: orderId,
    }).then((shopifyOrder) => {
      return shopifyOrder.order.note;
    });
  }

  return '';
}

export const OrderDetailsPage: React.FC<IOrderDetailsPageProps> = (
  props: IOrderDetailsPageProps
) => {
  const history = useHistory();
  const merchStore = props.merchStore;
  const order = props.order;
  const setSelectedOrderDetails = props.setSelectedOrderDetails;
  const userRole = props.userRole;
  const shipToOffice = merchStore.shipToOffice;
  const officeAddress = merchStore.officeAddress;
  const closeDate = order.closedAt
    ? order.closedAt
    : merchStore.nextProcessingDate
      ? merchStore.nextProcessingDate
      : '';

  const [isLoading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [individualOrders, setIndividualOrders] = useState([]);

  const showInternalOrderNotes = userHasRole(userRole, [
    ROLE_SUPERUSER,
    ROLE_MERCHOLOGIST,
  ]);

  useEffect(() => {
    const combinedOrderId = order.id;
    fetchIndividualOrders(merchStore.storeCode, combinedOrderId)
      .then((ordersData: IShopifyOrdersData) => {
        setIndividualOrders(ordersData.orders);
      })
      .catch((err) => {
        console.error('Error fetching individual orders', err);
      })
      .finally(() => {
        setLoading(false);
      });
    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;
    setIsMobile(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <div className="md:flex">
      <div className="order-details w-full md:p-30">
        <div className="p-20 md:p-0">
          <div className="w-full flex mb-3 lg:mb-3">
            <div className="flex">
              <CTA
                size="small"
                type="mobile-icon"
                onClick={() => {
                  setSelectedOrderDetails({
                    viewType: 'none',
                    individualOrder: null,
                    combinedOrder: null,
                  });
                  history.push(`/admin/merchstores/${merchStore.storeCode}`);
                }}
              >
                <MdArrowBack />
              </CTA>
              <div className="ml-6 flex font-bold text-xl">{order.name}</div>
            </div>
            <Badge
              className="ml-20 h-25"
              type={order.status.toLowerCase() as BadgeType}
            >
              {order.status}
            </Badge>
          </div>
          {order.status === OrderStatus.active && (
            <div className="md:flex w-full mt-10 md:ml-60 mb-25">
              <div className="close-date md:mr-100 mb-10 md:mb-0">
                Closes Monthly
              </div>
              <div className="close-date">
                Next Order closing date: {getLocalDateText(closeDate)}
              </div>
            </div>
          )}

          {order.id !== ACTIVE_ORDER_ID && (
            <div className="md:flex w-full md:ml-60">
              <div className="md:flex-grow mb-15">
                <div className="section-title mb-10">Customer Contact</div>
                <div className="section-info">
                  <div className="font-bold">
                    {order.customer ? order.customer.displayName : ''}
                  </div>
                  {order.customer ? order.customer.email : ''}
                </div>
              </div>
              <div className="md:flex-grow mb-15">
                <div className="section-title mb-10">Shipping Address</div>
                <div className="section-info">
                  <div className="section-subtitle">
                    {shipToOffice ? 'Ship to Office' : ''}
                  </div>
                  {shipToOffice && officeAddress ? (
                    <>
                      <div>
                        {officeAddress.address1} {officeAddress.address2}
                      </div>
                      <div>
                        {officeAddress.city} {officeAddress.province}{' '}
                        {officeAddress.zip}
                      </div>
                      <div>{officeAddress.country}</div>
                    </>
                  ) : (
                    order.shippingAddress && (
                      <>
                        <div>
                          {order.shippingAddress.address1}{' '}
                          {order.shippingAddress.address2}
                        </div>
                        <div>
                          {order.shippingAddress.city}{' '}
                          {order.shippingAddress.province}{' '}
                          {order.shippingAddress.zip}
                        </div>
                        <div>{order.shippingAddress.country}</div>
                      </>
                    )
                  )}
                </div>
              </div>
              {order.status === OrderStatus.closed && (
                <div className="md:flex-grow">
                  <div className="section-title mb-10">Billing Address</div>
                  <div className="section-info">
                    <div className="section-subtitle">{'Group Payment'}</div>
                    {order.billingAddress && (
                      <>
                        <div>
                          {order.billingAddress.address1}{' '}
                          {order.billingAddress.address2}
                        </div>
                        <div>
                          {order.billingAddress.city}{' '}
                          {order.billingAddress.province}{' '}
                          {order.billingAddress.zip}
                        </div>
                        <div>{order.billingAddress.country}</div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {order && showInternalOrderNotes && (
          <InternalOrderNotesForm
            merchStore={merchStore}
            getOrderNotes={async () =>
              getInternalOrderNotes(merchStore.storeCode, order.id)
            }
            updateOrderNotes={updateMerchStoreInternalOrderNotes}
            allowEdit={order.id === ACTIVE_ORDER_ID}
          />
        )}

        <div className="w-full mt-10">
          <IndividualOrderList
            merchStore={merchStore}
            combinedOrder={order}
            orders={individualOrders}
            setSelectedOrderDetails={setSelectedOrderDetails}
            isMobile={isMobile}
            isLoading={isLoading}
          />
        </div>
      </div>
      <OrderDetailsSummary
        merchStore={merchStore}
        combinedOrder={order}
        userRole={userRole}
        closeDate={closeDate}
      />
    </div>
  );
};
