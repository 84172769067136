const ShippingRate = {
  name: 'ShippingRate',
  kind: 'OBJECT',
  fieldBaseTypes: {
    handle: 'String',
    price: 'Money',
    priceV2: 'MoneyV2',
    title: 'String',
  },
  implementsNode: false,
};
export default ShippingRate;
