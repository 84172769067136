const StringEdge = {
  name: 'StringEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'String',
  },
  implementsNode: false,
};
export default StringEdge;
