const ProductOption = {
  name: 'ProductOption',
  kind: 'OBJECT',
  fieldBaseTypes: {
    id: 'ID',
    name: 'String',
    values: 'String',
  },
  implementsNode: true,
};
export default ProductOption;
