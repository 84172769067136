interface ITrackingLinkProps {
  trackingCode: string;
  children?: string | HTMLElement | JSX.Element;
}

export const TrackingLink = (props: ITrackingLinkProps): JSX.Element => {
  const trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${props.trackingCode}`;

  return (
    <a
      className="text-merch-orange"
      href={trackingUrl}
      target="_blank"
      rel="noreferrer"
    >
      {props.children ? props.children : 'Track'}
    </a>
  );
};
