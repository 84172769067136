const CustomerRecoverPayload = {
  name: 'CustomerRecoverPayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customerUserErrors: 'CustomerUserError',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CustomerRecoverPayload;
