const PageConnection = {
  name: 'PageConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'PageEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default PageConnection;
