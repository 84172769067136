const CheckoutAttributesUpdateV2Input = {
  name: 'CheckoutAttributesUpdateV2Input',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    note: 'String',
    customAttributes: 'AttributeInput',
    allowPartialAddresses: 'Boolean',
  },
};
export default CheckoutAttributesUpdateV2Input;
