const __Type = {
  name: '__Type',
  kind: 'OBJECT',
  fieldBaseTypes: {
    accessRestricted: 'Boolean',
    accessRestrictedReason: 'String',
    componentName: 'String',
    description: 'String',
    enumValues: '__EnumValue',
    fields: '__Field',
    inputFields: '__InputValue',
    interfaces: '__Type',
    kind: '__TypeKind',
    name: 'String',
    ofType: '__Type',
    possibleTypes: '__Type',
    requiredAccess: 'String',
  },
  implementsNode: false,
};
export default __Type;
