const SHOPIFY_DOMAIN_SOURCE_URL =
  process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_ADMIN_URL_PRODUCTS ||
  process.env.REACT_APP_SHOPIFY_MERCH_SERVICES_STOREFRONT_URL ||
  '';

export function resolveShopifySubdomain(): string {
  const shopifySubdomainRegex = /([^\\./]+).myshopify.com/gi;
  const shopifySubdomainMatch = shopifySubdomainRegex.exec(
    SHOPIFY_DOMAIN_SOURCE_URL
  );

  const shopifyFullSubdomain = shopifySubdomainMatch
    ? shopifySubdomainMatch[0]
    : '';

  if (shopifyFullSubdomain) {
    return shopifyFullSubdomain;
  }

  return '';
}

export function resolveShopifyDomainBaseUrl(): string {
  const shopifyFullSubdomain = resolveShopifySubdomain();

  if (shopifyFullSubdomain) {
    return `https://${shopifyFullSubdomain}/`;
  }

  return '';
}

export function generateShopifyDomainUrl(relativePath?: string): string {
  relativePath = relativePath ? relativePath.replace(/^\/+/, '') : '';
  return `${SHOPIFY_DOMAIN_BASE_URL.replace(/\/+$/g, '')}/${relativePath}`;
}

export const SHOPIFY_DOMAIN_BASE_URL = resolveShopifyDomainBaseUrl();
