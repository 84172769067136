/* eslint-disable max-len */
import axios from 'axios';
import Bluebird from 'bluebird';
import { DateTime } from 'luxon';
import slug from 'slug';
import { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { IconContext } from 'react-icons';
import {
  MdClose,
  MdDone,
  MdCardGiftcard,
  MdEdit,
  MdDateRange,
  MdPerson,
  MdGroup,
  MdMailOutline,
  MdInfo,
  MdAdd,
} from 'react-icons/md';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { HiOutlineSave } from 'react-icons/hi';
import { IoMdPersonAdd } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-responsive-modal';
import { Context } from '../../context';
import { Card } from '@merchstores/shared/elements/Card';
import { Tab, Tabs, TabBr, Content } from '@merchstores/shared/elements/Tabs';
import { CTA } from '@merchstores/shared/elements/Cta';
import { toast } from 'react-toastify';

import {
  FormSelect,
  IFormOptionProps,
} from '@merchstores/shared/elements/FormSelect';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { CloudinaryFileInput } from '@merchstores/shared/elements/CloudinaryFileInput';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';
import { GroupOrderMemberList } from '../Order/GroupOrderMemberList';
import { ShippingForm } from '@merchstores/admin/components/ShippingForm';
import { ISubmissionProps as IShippingFormSubmissionProps } from '../ShippingForm';
import { PackageList } from '../Order/PackageList';
import { CreateEditPackage } from '../CreateEditPackage';
import { ConfirmationModal } from '@merchstores/shared/elements/ConfirmationModal';
import { Loading } from '@merchstores/shared/components/Loading';

import { IOrderIdProps, ISubmissionProps } from './index';

import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import { sendEmail } from '@merchstores/shared/components/Mailer';
import { getLocalDateText } from '../DateTime/DateTimeFormat';
import { exportGOTRecipientsToNetsuite } from '../Netsuite/GroupOrderToolRecipients';
import {
  newOrderStatusOptions,
  buildOrderStatusOptions,
} from './OrderStatusOptions';

import {
  buildTrackingNotificationOptions,
  resolveTrackingNotification,
} from './TrackingNotificationOptions';
import { ShippingAddressModal } from './ShippingAddressModal';
import ShippingAddressTabContent from './ShippingAddressTabContent';
import { GroupOrderAddress } from '@merchstores/admin/types/address';
import { StoreContext } from '@merchstores/admin/context/Store';
import { MemberSubmissionManagedAddressForm } from '../GroupOrderMemberSubmission/MemberSubmissionManagedAddressForm';
import { Footer } from '@merchstores/shared/elements/Footer';

type MemberParams = {
  email: string;
  groupOrderId: string;
  invited: boolean;
  memberId: string;
  submission?: ISubmissionProps;
  submissionId?: string;
  _id: string;
};

export const CreateEditGroupOrder = () => {
  const [order, setOrderDetails] = useState(undefined);
  const [orderError, setOrderError] = useState(false);
  const [memberAndSubmissionDetails, setMemberAndSubmissionDetails] = useState(
    []
  );
  const [orderEditActive, setOrderEditActive] = useState(false);
  const [members, setMembers] = useState([]);
  const [orderName, setOrderName] = useState('');
  const [newOrderName, setNewOrderName] = useState('');
  const [nameEditActive, setNameEditActive] = useState(false);
  const [submittedCount, setSubmittedCount] = useState(undefined);
  const [mostRecentSubmission, setMostRecentSubmission] = useState(undefined);
  const [storefrontLogo, setStorefrontLogo] = useState(null);
  const [storefrontLogoError, setStorefrontLogoError] = useState(false);
  const [showPercentage, setShowPercentage] = useState(undefined);
  const [passwordSelected, setPasswordSelected] = useState(false);
  const [isShipToOfficeSelected, setIsShipToOfficeSelected] = useState(false);
  const [verificationSelected, setVerificationSelected] = useState(false);
  const [, setSelectedStatus] = useState(undefined);
  const [orderStatusOptions, setOrderStatusOptions] = useState(
    newOrderStatusOptions()
  );
  const [changeToActive, setChangeToActive] = useState(false);
  const [changeToClosed, setChangeToClosed] = useState(false);
  const [active, setActive] = useState(0);
  const [invite, setInvite] = useState(false);
  const [tempStorefrontUrl, setTempStorefrontUrl] = useState(undefined);
  const [submissionEditOpen, setSubmissionEditOpen] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(undefined);
  const [readOnlySubmission, setReadOnlySubmission] = useState(undefined);
  const [addPackageModalOpen, setAddPackageModalOpen] = useState(false);
  const [disabledPasswords, setDisabledPassword] = useState(false);
  const [formFound, setFormFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changedVerificationCode, setChangedVerificationCode] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isShippingAddressModalOpen, setIsShippingAddressModalOpen] =
    useState(false);
  const [formData, setFormData] = useState(false);
  const [sortOptionChosen, setSortOptionChosen] = useState('nameDesc');
  const [isMobile, setIsMobile] = useState(false);
  // const [currentShippingAddress, setCurrentShippingAddress] = useState();
  const { setSelectedShippingAddress } = useContext(StoreContext);

  const iconSize = isMobile ? '16px' : '20px';

  const [trackingNotification, setTrackingNotification] = useState(
    resolveTrackingNotification()
  );

  const { id } = useParams<IOrderIdProps>();
  const history = useHistory();
  const { userRole, userEmail } = useContext(Context);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const headerCardStyles =
    'w-full lg:w-create-order-header-card shadow-cards bg-white rounded-xl p-0.5';
  const domainName =
    process.env.REACT_APP_ENV === 'production'
      ? 'merchologysolutions.com'
      : 'staging.merchologysolutions.com';

  const handleTabSelect = (e: Event) => {
    const target = e.target as HTMLButtonElement;
    const index = target.id ? parseInt(target.id, 0) : null;
    if (index !== active) {
      setActive(index);
    }
  };
  const handleOrderNameChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setOrderName(target.value);
    setOrderEditActive(true);
  };
  const handleOrderNameSubmit = () => {
    if (orderName) {
      setNewOrderName(orderName);
      setNameEditActive(false);
      setOrderName('');
    } else {
      setNameEditActive(false);
    }
  };
  const handleLogoChange = (logoData: any) => {
    if (logoData.length) {
      setStorefrontLogo(logoData);
      if (storefrontLogoError) {
        setStorefrontLogoError(false);
      }
    } else {
      setStorefrontLogo(null);
    }
  };

  const handleTrackingNotificationChange = (
    trackingNotificationOption: IFormOptionProps
  ) => {
    if (!order) {
      return;
    }

    const currentTracking = resolveTrackingNotification(
      order?.trackingNotification ?? null
    );

    if (trackingNotificationOption.value !== currentTracking) {
      setTrackingNotification(trackingNotificationOption.value);
      setOrderEditActive(true);
    }
  };

  const fetchOrder = useCallback(
    (id: string) => {
      return new Promise((resolve, reject) => {
        axios
          .post('/.netlify/functions/fetchOrderById', {
            orderId: id,
            sortOptionChosen,
          })
          .then((res) => {
            if (res.data) {
              if (res.data.password) {
                setPasswordSelected(true);
              } else if (res.data.verificationCode) {
                setVerificationSelected(true);
              }
              if (res.data?.managedShippingAddresses) {
                setIsShipToOfficeSelected(true);
              }
              setOrderStatusOptions(buildOrderStatusOptions(res.data.status));
              setOrderDetails(res.data);
              setStorefrontLogo([res.data.storeLogo]);

              setTrackingNotification(
                resolveTrackingNotification(res.data.trackingNotification)
              );

              reset();
              resolve(res.data);
            } else {
              toast.error(`No order found with for ID: ${id}`);
              history.push('/');
              setOrderError(true);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [reset]
  );

  /**
   * since order ID generation happens after the order is created, we need to run a fetch by url to get the order ID
   * in the event that the order is created, but the ID hasn't been generated yet, i.e.
   * there's a long queue of order being generated
   * notify the user their order was created and fall back to sending the user to the group order list
   */
  const fetchOrderAndRedirect = (url: string, retry?: number) => {
    let currentRetry = retry || 1;
    const RETRY_LIMIT =
      parseInt(String(process.env.GROUP_ORDER_FETCH_RETRY)) || 1;
    axios
      .post('/.netlify/functions/fetchOrderByStoreUrl', {
        storeUrl: url,
      })
      .then((res) => {
        toast.success('Order created successfully!');
        if (res.data.groupOrderId) {
          setOrderDetails(res.data);
          history.push(`/admin/group-orders/${res.data.groupOrderId}`);
        } else {
          if (currentRetry < RETRY_LIMIT) {
            setTimeout(() => {
              currentRetry++;
              fetchOrderAndRedirect(url, currentRetry);
            }, 1000);
          } else {
            toast.warning(
              'Your order was created successfully, but the service is taking longer than ' +
                'expected to process your Order ID due to high volume. Returning to the order list.'
            );
            history.push('/admin/group-orders');
          }
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          'There was an error creating your order. Please try again or contact support'
        );
      });
  };

  const fetchMembersWithSubmissions = (groupOrderId: string) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/.netlify/functions/getSubmissionsForMembers', {
          groupOrderId: groupOrderId,
          sortBy: sortOptionChosen,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.length) {
              setMembers(res.data);
            }
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const processMemberData = (memberData: Array<MemberParams>) => {
    setMemberAndSubmissionDetails(memberData);
    let submittedCounter = 0;
    let mostRecentDate: string | null = null;
    let mostRecentEmail = null;
    let allInvited = true;
    if (memberData.length) {
      memberData.forEach((member) => {
        if (member.submission) {
          submittedCounter++;
          if (
            !mostRecentDate ||
            new Date(member.submission.timestamp) > new Date(mostRecentDate)
          ) {
            mostRecentDate = member.submission.timestamp;
            mostRecentEmail = member.email;
          }
        }
        if (!member.invited) {
          allInvited = false;
        }
      });
    }
    setMostRecentSubmission(mostRecentEmail);
    setShowPercentage(allInvited);
    setSubmittedCount(submittedCounter);
  };
  const validateUrl = (url: string) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/.netlify/functions/validateUrl', {
          storefrontUrl: url,
        })
        .then((urlResp) => {
          if (urlResp.data) {
            resolve(true);
          } else {
            setError('storefront-url', {
              types: {
                custom: 'URL is taken, please try again',
              },
            });
            reject(false);
          }
        })
        .catch((err) => console.log(err));
    });
  };
  const queueEmails = (orderId: string, closeDate: DateTime) => {
    axios
      .post('/.netlify/functions/queueEmails', {
        entryId: orderId,
        closeDate: closeDate,
      })
      .then(() => toast.success('Emails queued successfully'))
      .catch((err) => {
        console.error(err);
        toast.error('Error queueing emails.');
      });
  };
  const updateEmails = (
    orderId: string,
    openDate: DateTime,
    closeDate: DateTime
  ) => {
    axios
      .post('/.netlify/functions/updateEmails', {
        entryId: orderId,
        openDate: openDate,
        closeDate: closeDate,
      })
      .then(() => toast.success('Email queue updated successfully'))
      .catch((err) => {
        console.error(err);
        toast.error('Error updating email queue.');
      });
  };
  const createNewOrder = async (data: any) => {
    return new Promise((resolve, reject) => {
      // TODO: Add package logic to newOrder
      // if no name has been entered (i.e. the group admin is going to choose the name) use the default
      const newOrder = {
        groupOrderId: id,
        name: newOrderName.length
          ? newOrderName
          : order
            ? order.name
            : 'Untitled',
        status: data['order-status'],
        storeUrl: slug(String(data['storefront-url'] || '').trim()),
        storeLogo: storefrontLogo[0],
        storeMessage:
          data['storefront-message'] ||
          `Welcome to your ${newOrderName || 'Merchology'} Group Order!`,
        storeSubMessage:
          data['storefront-sub-message'] ||
          'Please fill out the form below to place your order.',
        packages: [],
        openDate:
          data['order-status'] === 'active' ? DateTime.now().toISODate() : '',
        closeDate: convertDate(data['storefront-closedate']),
        isInternational: false,
        merchologist:
          userRole === 'MERCHOLOGIST' || userRole === 'SUPERUSER'
            ? userEmail
            : '',
        groupAdmin: data['storefront-admin'],
        verificationCode: verificationSelected ? true : false,
        password: passwordSelected ? data['storefront-password'] : '',
        managedShippingAddresses: isShipToOfficeSelected || false,
      };
      axios
        .post('/.netlify/functions/createNewOrder', {
          orderData: newOrder,
        })
        .then((orderResp) => {
          resolve(orderResp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const updateOrder = async (data: any) => {
    let openDate = order.openDate;
    if (!order.openDate && data['order-status'] === 'active') {
      openDate = DateTime.now().toISODate();
    }
    return new Promise((resolve, reject) => {
      const updatedOrder = {
        groupOrderId: order.groupOrderId,
        name: newOrderName.length ? newOrderName : order.name,
        status: data['order-status'].length
          ? data['order-status']
          : order.status,
        storeUrl: order.storeUrl,
        storeLogo: storefrontLogo[0],
        storeMessage: data['storefront-message'],
        storeSubMessage: data['storefront-sub-message'],
        packages: order.packages,
        openDate: openDate,
        closeDate:
          userRole === 'ADMIN'
            ? order.closeDate
            : convertDate(data['storefront-closedate']),
        isInternational: order.isInternational,
        merchologist: order.merchologist ? order.merchologist : '',
        // if the user isn't a superuser or merchologist, the groupAdmin wont be updated, so use the order value
        groupAdmin:
          userRole === 'ADMIN' ? order.groupAdmin : data['storefront-admin'],
        verificationCode: verificationSelected ? true : false,
        password: passwordSelected ? data['storefront-password'] : '',
        trackingNotification: trackingNotification,
        managedShippingAddresses: isShipToOfficeSelected || false,
      };
      axios
        .post('/.netlify/functions/updateOrder', {
          orderData: updatedOrder,
        })
        .then((orderResp) => {
          setOrderStatusOptions(buildOrderStatusOptions(updatedOrder.status));

          if (updatedOrder.status === 'closed') {
            exportGOTRecipientsToNetsuite(order.groupOrderId, '');
          }
          if (updatedOrder.status === 'active' && order.status !== 'active') {
            queueEmails(order.groupOrderId, updatedOrder.closeDate);
            setOrderDetails(updatedOrder);
          }
          if (
            order.status === 'active' &&
            order.closeDate !== updatedOrder.closeDate
          ) {
            updateEmails(
              order.groupOrderId,
              order.openDate,
              updatedOrder.closeDate
            );
            setOrderDetails(updatedOrder);
          }
          axios
            .post('/.netlify/functions/setMembersUniquePassword', {
              groupOrderId: order.groupOrderId,
              verificationCode: verificationSelected,
            })
            .then(() => {
              resolve(orderResp);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  // check custom fields that don't get submitted through react-hooks-form, return true if any errors, false if none
  const checkCustomParams = () => {
    if (!storefrontLogo) {
      setStorefrontLogoError(true);
    }
    return false;
  };

  const inviteAdmin = async () => {
    const groupOrderId = order.groupOrderId;
    const storeName = newOrderName.length
      ? newOrderName
      : order
        ? order.name
        : 'Untitled';
    const storeLogo =
      storefrontLogo && storefrontLogo[0] ? storefrontLogo[0] : '';
    const storefrontAdmin = (
      document.getElementsByName('storefront-admin')[0] as HTMLInputElement
    ).value;
    const adminEmail =
      order && order.groupAdmin ? order.groupAdmin : storefrontAdmin;
    const siteUrl =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://merchologysolutions.com'
        : 'https://staging.merchologysolutions.com';
    const email = {
      templateId: 'got-admin-invite',
      subject: `Welcome to ${storeName}’s Merchology Group Order!`,
      text: 'You’ve been assigned the Admin for this order.',
      templateContent: [
        {
          name: 'groupOrderId',
          content: groupOrderId,
        },
        {
          name: 'storeName',
          content: storeName,
        },
        {
          name: 'storeLogo',
          content: storeLogo,
        },
        {
          name: 'siteUrl',
          content: siteUrl,
        },
      ],
    };
    await handleSubmit(handleOrderSave)(true);
    if (!errors || Object.keys(errors).length === 0) {
      sendEmail(email, [adminEmail]);
      toast.success('Admin invite email sent successfully');
    }
  };
  /**
   * because this logic needs to be gated behind a confirmation modal oif the order
   * is set to active, we need to make it it's own function
   */
  const processOrder = async (data: any, hideToast: boolean) => {
    if (order) {
      updateOrder(data)
        .then(async () => {
          setOrderEditActive(false);

          if (!(hideToast === true)) {
            toast.success('Your order was successfully updated');
          }
          // if the order status change from "Draft" to "Active" -> send Members' invites
          if (changeToActive) {
            const updatedMembers = changedVerificationCode
              ? ((await fetchMembersWithSubmissions(
                  order.groupOrderId
                )) as Array<any>)
              : members;
            const recipients = updatedMembers.map((member: any) => {
              return {
                email: member.email,
                verificationCode: member.uniquePassword,
              };
            });
            let invitationType = 'regular';
            if (verificationSelected) {
              invitationType = 'code';
            } else if (passwordSelected) {
              invitationType = 'password';
            }
            sendMembersInvites(
              order.groupOrderId,
              invitationType,
              recipients,
              newOrderName.length ? newOrderName : order.name,
              storefrontLogo[0],
              convertDate(data['storefront-closedate']),
              data['storefront-password'],
              order.storeUrl
            );
          } else if (changeToClosed) {
            const recipients = [
              { email: order.merchologist },
              { email: order.groupAdmin },
            ];
            sendOrderClosed(
              order.groupOrderId,
              recipients,
              newOrderName.length ? newOrderName : order.name,
              storefrontLogo[0]
            );
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(
            'There was an error updating your order. Please try again or contact support.'
          );
        });
    } else {
      // Logic to create a new group order
      if (data['storefront-url'].indexOf('/') === 0) {
        // Remove the leading forward slash
        data['storefront-url'] = data['storefront-url'].substring(1);
      }
      // for new orders, always validate
      validateUrl(data['storefront-url'])
        .then(() => {
          return createNewOrder(data);
        })
        .then((newOrderData: any) => {
          if (newOrderData.data.error) {
            console.error('Order creation reached maximum number of retries');
            toast.error(
              'Group order creation failed. Please try again or contact support.'
            );
          } else {
            fetchOrderAndRedirect(newOrderData.data.storeUrl);
          }
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status === 409 &&
            err.response.data.error === 'Duplicate storeUrl'
          ) {
            toast.error('This URL is already in use. Please try again.');
          } else {
            toast.error(
              'Group order creation failed. Please try again or contact support.'
            );
          }
        });
    }
  };
  // since the top button lives outside the form
  const handleOrderSave = async (data: any, hideToast: boolean) => {
    // trim and cleanup storefront-url
    data['storefront-url'] = slug(String(data['storefront-url'] || '').trim());

    // check for errors outside the form data
    const outsideErrors = checkCustomParams();
    if (!outsideErrors) {
      if (data['order-status'] === 'active') {
        if (!order || order.status !== 'active') {
          setFormData(data);
          setConfirmationModalOpen(true);
        } else {
          processOrder(data, hideToast);
        }
      } else {
        processOrder(data, hideToast);
      }
    }
  };

  const sendMembersInvites = (
    groupOrderId: string,
    invitationType: string,
    recipients: Array<{
      email: string;
      verificationCode: string;
    }>,
    storeName: string,
    storeLogo: string,
    closeDate: any,
    password: string,
    storeUrl: string
  ) => {
    const storeSite =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://merchologysolutions.com'
        : 'https://staging.merchologysolutions.com';
    const email = {
      templateId: `got-group-member-invite${
        invitationType !== 'regular' ? `-${invitationType}` : ''
      }`,
      subject: `You’ve Been Invited to ${storeName}’s Merchology Group Order!`,
      text: 'Get excited, it’s time to select your new custom merch.',
      templateContent: [
        {
          name: 'groupOrderId',
          content: groupOrderId,
        },
        {
          name: 'storeName',
          content: storeName,
        },
        {
          name: 'storeLogo',
          content: storeLogo,
        },
        {
          name: 'orderCloseDate',
          content: getLocalDateText(closeDate),
        },
        {
          name: 'password',
          content: password,
        },
        {
          name: 'storeUrl',
          content: `${storeSite}/${storeUrl}`,
        },
      ],
      recipientTemplateContent: [{}],
    };
    if (invitationType === 'code') {
      recipients.forEach((recipient) => {
        const verificationCode = recipient.verificationCode;
        email.recipientTemplateContent.push({
          rcpt: recipient.email,
          vars: [
            {
              name: 'verificationCode',
              content: verificationCode,
            },
          ],
        });
      });
    }
    sendEmail(
      email,
      recipients.map((recipient) => recipient.email)
    );
  };

  const sendOrderClosed = (
    groupOrderId: string,
    recipients: Array<{ email: string }>,
    storeName: string,
    storeLogo: string
  ) => {
    const email = {
      templateId: `got-order-closed`,
      subject: `That’s a Wrap! The ${storeName} Group Order is Now Closed`,
      text: `All good things must come to an end.`,
      templateContent: [
        {
          name: 'groupOrderId',
          content: groupOrderId,
        },
        {
          name: 'storeName',
          content: storeName,
        },
        {
          name: 'storeLogo',
          content: storeLogo,
        },
      ],
    };
    sendEmail(
      email,
      recipients.map((recipient) => recipient.email)
    );
  };

  const onMembersAdded = (
    membersAdded: Array<{ email: string; verificationCode: string }>
  ) => {
    if (order.status === 'active') {
      let invitationType = 'regular';
      if (verificationSelected) {
        invitationType = 'code';
      } else if (passwordSelected) {
        invitationType = 'password';
      }
      sendMembersInvites(
        order.groupOrderId,
        invitationType,
        membersAdded,
        order.name,
        order.storeLogo,
        order.closeDate,
        order.password,
        order.storeUrl
      );
    }
    getOrderAndMemberInfo();
  };

  const handleOrderReset = () => {
    reset();
    setOrderName('');
    setNewOrderName('');
    setStorefrontLogo(null);
    setPasswordSelected(false);
    setVerificationSelected(false);
  };

  const convertDate = (date: string) => {
    let dt;

    if (date.indexOf('/') > -1) {
      dt = DateTime.fromFormat(date, 'MM/dd/yyyy', { zone: 'utc' });
    } else {
      dt = DateTime.fromISO(date, { zone: 'utc' });
    }

    return dt.setZone('utc').toISODate();
  };

  const handlePasswordSelect = () => {
    setPasswordSelected(!passwordSelected);
    if (verificationSelected) {
      setVerificationSelected(false);
    }
  };

  const handleShipToOfficeSelect = () => {
    setIsShipToOfficeSelected(!isShipToOfficeSelected);
    // if (verificationSelected) {
    //   setVerificationSelected(false);
    // }
  };

  const handleVerificationSelect = (value: boolean) => {
    setVerificationSelected(!verificationSelected);
    if (passwordSelected) {
      setPasswordSelected(false);
    }
    const currentVerificationStatus = order ? order.verificationCode : false;
    if (currentVerificationStatus !== value) {
      setChangedVerificationCode(true);
    } else {
      setChangedVerificationCode(false);
    }
  };
  const handleStatusChange = (status: IFormOptionProps) => {
    const currentStatus = order ? order.status : 'default';
    if (status.value !== currentStatus) {
      if (currentStatus === 'draft' && status.value === 'active') {
        setChangeToActive(true);
      } else if (
        (currentStatus === 'draft' || currentStatus === 'active') &&
        status.value === 'closed'
      ) {
        setChangeToClosed(true);
      } else {
        setChangeToActive(false);
      }
      setOrderEditActive(true);
      setSelectedStatus(status.value);
    }
  };
  // this sets the selected submission, which then triggers the useEffect hook below
  const handleSubmissionEditOpen = (
    submissionId: string,
    email: string,
    submission: any,
    readOnly: boolean
  ) => {
    setReadOnlySubmission(readOnly);
    if (submission && email && submissionId) {
      setSelectedSubmission({
        id: submissionId,
        email: email,
        submission: submission,
      });
    } else if (email) {
      setSelectedSubmission({
        email: email,
        submission: {},
      });
    }
  };
  /**
   * to prevent a race condition that sends an empty selectedSubmission to the modal,
   *  wait to open the edit modal until the submission is set
   */
  useEffect(() => {
    if (selectedSubmission) {
      setSubmissionEditOpen(true);
    }
  }, [selectedSubmission]);
  const handleSubmissionEditClose = () => {
    setSubmissionEditOpen(false);
    setSelectedSubmission(undefined);
  };
  // updates the submission after the admin makes an update
  const handleSubmissionDetailsUpdate = (
    email: string,
    submission: IShippingFormSubmissionProps,
    deletion: boolean
  ) => {
    const updatedMemberAndSubmissionDetails = memberAndSubmissionDetails;
    updatedMemberAndSubmissionDetails.every((member: any) => {
      if (member.email === email) {
        member.submission = submission;
        member.submissionId = submission._id;
        if (deletion) {
          delete member.submissionId;
        }
        return false;
      } else {
        return true;
      }
    });
    setMemberAndSubmissionDetails(updatedMemberAndSubmissionDetails);
  };
  const getSelectedPackage = () => {
    if (order && selectedSubmission) {
      if (selectedSubmission.submission.selectedPackage) {
        const packageToUse = order.packages.filter(
          (packageData: any) =>
            packageData.title === selectedSubmission.submission.selectedPackage
        );
        return packageToUse[0];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };
  const handleFormSuccess = () => {
    toast.success('Your submission was updated successfully.');
  };
  const getOrderAndMemberInfo = async () => {
    if (id && id !== 'new') {
      await Bluebird.props({
        order: fetchOrder(id),
        memberSubmissions: fetchMembersWithSubmissions(id),
      })
        .then(async (results) => {
          const { memberSubmissions } = results;
          await processMemberData(memberSubmissions as Array<MemberParams>);
        })
        .catch((err: unknown) => {
          console.error('Malformed Order ID', err);
          setOrderError(true);
        });
    }

    await Bluebird.delay(200);
    setLoading(false);
  };

  useEffect(() => {
    getOrderAndMemberInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchOrder, sortOptionChosen]);

  // listen for page changes until the form is rendered before adding the change event listener
  useEffect(() => {
    const form = document.getElementById('create-edit-order-form');
    if (form && !formFound) {
      form.addEventListener('input', function (e) {
        if ((e.target as HTMLInputElement).name === 'storefront-url') {
          setTempStorefrontUrl(slug((e.target as HTMLInputElement).value));
        }
        if (
          (e.target as HTMLInputElement).value !==
          (e.target as HTMLInputElement).defaultValue
        ) {
          setOrderEditActive(true);
        }
      });
      setFormFound(true);
    }
  });

  // listen for custom form element changes
  useEffect(() => {
    // If user navigates to a specific order
    if (order) {
      if (userRole === 'ADMIN' && userEmail && id && id !== 'new') {
        if (order.groupAdmin !== userEmail) {
          history.push('/');
        }
      }

      if (passwordSelected && !order.password) {
        setOrderEditActive(true);
      }
      if (verificationSelected && !order.verificationCode) {
        setOrderEditActive(true);
      }
      if (storefrontLogo && storefrontLogo[0] !== order.storeLogo) {
        setOrderEditActive(true);
      }
      if (order.status !== 'draft') {
        setDisabledPassword(true);
      }
      if (order.status === 'closed') {
        setOrderEditActive(false);
      }
      if (isShipToOfficeSelected != (order.managedShippingAddresses || false)) {
        setOrderEditActive(true);
      }
      setSelectedStatus(order.status);
    }

    const isMobile = window.matchMedia(
      'only screen and (max-width: 760px)'
    ).matches;
    setIsMobile(isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    storefrontLogo,
    passwordSelected,
    verificationSelected,
    userRole,
    userEmail,
    isMobile,
    isShipToOfficeSelected,
  ]);

  const onOpenAddPackageModal = () => setAddPackageModalOpen(true);
  const onCloseAddPackageModal = () => setAddPackageModalOpen(false);
  const onPackageCreatedOrEdited = () => {
    retrieveOrderData(id);
  };
  // when a package is added or updated, we need a single order fetch without resetting submission data. logo, etc
  const retrieveOrderData = (orderId: string) => {
    axios
      .post('/.netlify/functions/fetchOrderById', {
        orderId,
      })
      .then((res) => {
        if (res.data) {
          setOrderDetails(res.data);
        } else {
          console.log(`No order found with for ID: ${orderId}`);
          setOrderError(true);
        }
      })
      .catch((err) => {
        console.error('Malformed Order ID', err);
        setOrderError(true);
      });
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmationModalConfirm = () => {
    setConfirmationModalOpen(false);
    processOrder(formData, false);
  };

  const handleOpenShippingAddress = (shippingAddress?: GroupOrderAddress) => {
    setSelectedShippingAddress(shippingAddress);
    setIsShippingAddressModalOpen(true);
  };

  const handleShippingAddressModalState = (newState: boolean) => {
    if (!newState) {
      setSelectedShippingAddress(null);
    }
    setIsShippingAddressModalOpen(newState);
  };

  if (loading) {
    return <Loading isLoading={true} />;
  }

  return (
    <div className="relative flex justify-center">
      <Modal
        center
        open={submissionEditOpen}
        onClose={handleSubmissionEditClose}
        classNames={{ modal: 'shipping-form-modal' }}
      >
        {/* HERE:SOL-158 */}
        {order && order.managedShippingAddresses ? (
          <MemberSubmissionManagedAddressForm
            order={order}
            selectedSubmission={selectedSubmission}
            selectedPackage={getSelectedPackage()}
            submissionUpdate={handleSubmissionDetailsUpdate}
            onCloseModal={handleSubmissionEditClose}
            onFormSubmitted={handleFormSuccess}
            readOnly={readOnlySubmission}
          />
        ) : (
          <ShippingForm
            order={order}
            selectedSubmission={selectedSubmission}
            selectedPackage={getSelectedPackage()}
            submissionUpdate={handleSubmissionDetailsUpdate}
            onCloseModal={handleSubmissionEditClose}
            onFormSubmitted={handleFormSuccess}
            readOnly={readOnlySubmission}
          />
        )}
      </Modal>
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleConfirmationModalClose}
        image={storefrontLogo}
        headlineText="Are you sure you want to set your Group Order to active?"
        bodyText="Setting your order to Active will notify your members that your Group Order is open for business."
        onConfirmation={handleConfirmationModalConfirm}
        onCancellation={handleConfirmationModalClose}
      />
      <CreateEditPackage
        groupOrderId={id}
        modalOpened={addPackageModalOpen}
        onOpenModal={onOpenAddPackageModal}
        onCloseModal={onCloseAddPackageModal}
        onPackageCreatedOrEdited={onPackageCreatedOrEdited}
      />

      {order && order.groupOrderId && (
        <ShippingAddressModal
          // address={currentShippingAddress}
          groupOrderId={order.groupOrderId}
          isOpen={isShippingAddressModalOpen}
          setIsOpen={handleShippingAddressModalState}
        />
      )}

      <div className="flex flex-col p-0 lg:px-20 lg:py-8 w-full items-center">
        <div className="mb-7 pt-3 px-5 lg:px-7 lg: pt-0 w-full flex justify-center flex-0">
          <div className="w-full max-w-7xl">
            {order && (
              <p className="px-7 ml-4 md:ml-0 lg:px-0 text-xs lg:text-sm text-merch-dark-gray font-bold opacity-50">
                {order.groupOrderId}
              </p>
            )}
            <div className={'flex items-center ml-4 md:ml-0 justify-between'}>
              {nameEditActive ? (
                <div className="px-7 lg:px-0 flex items-center">
                  <input
                    className="bg-transparent text-lg lg:text-3xl text-merch-dark-gray font-bold pl-0.5"
                    type="text"
                    autoFocus
                    placeholder={
                      newOrderName
                        ? newOrderName
                        : order
                          ? order.name
                          : 'Untitled'
                    }
                    value={orderName}
                    onChange={(e) => {
                      handleOrderNameChange(e);
                    }}
                    onBlur={() => {
                      handleOrderNameSubmit();
                    }}
                  />
                  <button
                    disabled={orderName ? false : true}
                    className={`opacity-50 ml-1.5 ${
                      orderName ? '' : 'cursor-default'
                    }`}
                    onClick={() => {
                      handleOrderNameSubmit();
                    }}
                  >
                    <IconContext.Provider
                      value={
                        orderName ? { color: 'limeGreen' } : { color: 'grey' }
                      }
                    >
                      <div>
                        <MdDone />
                      </div>
                    </IconContext.Provider>
                  </button>
                </div>
              ) : (
                <div className="px-7 lg:px-0 flex items-center">
                  <div
                    onClick={() => {
                      setNameEditActive(true);
                    }}
                    className="cursor-pointer text-lg lg:text-3xl text-merch-dark-gray font-bold"
                  >
                    {newOrderName
                      ? newOrderName
                      : order
                        ? order.name
                        : 'Untitled'}
                  </div>
                  <div
                    onClick={() => {
                      setNameEditActive(true);
                    }}
                    className="cursor-pointer flex ml-1.5 h-full"
                  >
                    <MdEdit />
                  </div>
                </div>
              )}
              <div>
                <div className="md:hidden">
                  {/* If user is group admin, do not display the Invite Admin Button */}
                  {order &&
                    order.status !== 'closed' &&
                    userRole === 'ADMIN' && (
                      <ActionDropdown
                        title="Actions"
                        list={[
                          {
                            title: `${
                              order ? 'Update Group Order' : 'Save Group Order'
                            }`,
                            id: `save-group-order`,
                            action: handleSubmit(handleOrderSave),
                          },
                        ]}
                      />
                    )}
                  {order &&
                    order.status !== 'closed' &&
                    userRole !== 'ADMIN' && (
                      <ActionDropdown
                        title="Actions"
                        list={[
                          {
                            title: 'Invite Admin',
                            id: `invite-admin`,
                            action: inviteAdmin,
                          },
                          {
                            title: `${
                              order ? 'Update Group Order' : 'Save Group Order'
                            }`,
                            id: `save-group-order`,
                            action: handleSubmit(handleOrderSave),
                          },
                        ]}
                      />
                    )}
                </div>
                <div className="hidden md:flex">
                  {order &&
                    order.status !== 'closed' &&
                    userRole !== 'ADMIN' && (
                      <CTA
                        size="standard"
                        type="secondary"
                        classes="h-45 mr-15 text-14"
                        icon={<MdMailOutline size="20px" />}
                        onClick={inviteAdmin}
                      >
                        Invite Admin
                      </CTA>
                    )}
                  <CTA
                    disabled={order ? !orderEditActive : false}
                    size="standard"
                    type="primary"
                    classes="h-45"
                    icon={<HiOutlineSave size={iconSize} />}
                    onClick={handleSubmit(handleOrderSave)}
                  >
                    {order ? 'Update Group Order' : 'Save Group Order'}
                  </CTA>
                </div>
              </div>
            </div>
            {order && submittedCount > 0 && (
              <div className="flex justify-between mt-8">
                <div className={`${headerCardStyles} flex`}>
                  <div className="p-3 flex items-center w-full">
                    <CTA
                      size="standard"
                      type="icon"
                      icon={<MdGroup size={iconSize} />}
                    ></CTA>
                    <div className="ml-4 w-full">
                      <p className="text-sm text-merch-dark-gray font-bold opacity-50">
                        Submissions
                      </p>
                      <p className="text-xl text-merch-dark-gray font-bold">
                        {submittedCount ? (
                          showPercentage ? (
                            <span className="flex w-full justify-between">
                              <span>
                                {submittedCount}/{members.length}
                              </span>
                              <span className="text-merch-orange">
                                {Math.trunc(
                                  (submittedCount / members.length) * 100
                                )}
                                &#37;
                              </span>
                            </span>
                          ) : (
                            submittedCount
                          )
                        ) : (
                          'N/A'
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${headerCardStyles} overflow-hidden hidden lg:flex `}
                >
                  <div className="p-3 items-center flex">
                    <CTA
                      size="standard"
                      type="icon"
                      icon={<MdPerson size={iconSize} />}
                    ></CTA>
                    <div className="ml-4">
                      <p className="text-sm text-merch-dark-gray font-bold opacity-50">
                        Most Recent Submission
                      </p>
                      <p className="text-xl text-merch-dark-gray font-bold lg: break-words">
                        {submittedCount ? mostRecentSubmission : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${headerCardStyles} hidden lg:flex`}>
                  <div className="p-3 items-center flex">
                    <CTA
                      size="standard"
                      type="icon"
                      icon={<MdDateRange size={iconSize} />}
                    ></CTA>
                    <div className="ml-4">
                      <p className="text-sm text-merch-dark-gray font-bold opacity-50">
                        Close Date
                      </p>
                      <p className="text-xl text-merch-dark-gray font-bold">
                        {order ? getLocalDateText(order.closeDate) : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Card>
          {orderError ? (
            <h3>TODO: BUILD AN ERROR VIEW</h3>
          ) : (
            <div className="flex flex-col lg:flex-row w-full">
              <div className="md:p-8 p-0 w-full">
                <div className="md:p-0 p-6 flex items-start justify-between mb-4 md:mb-12">
                  <Tabs>
                    <Tab onClick={handleTabSelect} active={active === 0} id={0}>
                      Members
                    </Tab>
                    <TabBr />
                    <Tab onClick={handleTabSelect} active={active === 1} id={1}>
                      Packages
                    </Tab>
                    <TabBr show={order?.managedShippingAddresses} />
                    <Tab
                      onClick={handleTabSelect}
                      active={active === 2}
                      id={2}
                      show={order?.managedShippingAddresses}
                    >
                      Shipping Addresses
                    </Tab>
                  </Tabs>
                  <div>
                    <Content active={active === 0}>
                      {id !== 'new' ? (
                        <CTA
                          type={'tertiary'}
                          size={'standard'}
                          icon={
                            invite ? (
                              <MdClose size={iconSize} />
                            ) : (
                              <IoMdPersonAdd size={iconSize} />
                            )
                          }
                          onClick={() => {
                            setInvite(!invite);
                          }}
                        >
                          Add Members
                        </CTA>
                      ) : (
                        ''
                      )}
                    </Content>
                    {userRole !== 'ADMIN' && (
                      <Content active={active === 1}>
                        <CTA
                          size="standard"
                          type="tertiary"
                          icon={<MdCardGiftcard size={iconSize} />}
                          onClick={onOpenAddPackageModal}
                        >
                          Add Packages
                        </CTA>
                      </Content>
                    )}

                    {
                      <Content active={active === 2}>
                        <CTA
                          type={'tertiary'}
                          size={'standard'}
                          icon={<MdAdd size="20px" />}
                          onClick={() => handleOpenShippingAddress()}
                        >
                          {isMobile ? '' : 'Add Address'}
                        </CTA>
                      </Content>
                    }
                  </div>
                </div>
                <div>
                  <Content active={active === 0}>
                    {id && id !== 'new' ? (
                      <GroupOrderMemberList
                        fetchMembers={getOrderAndMemberInfo}
                        showAddMember={invite}
                        uniqueId={id}
                        order={order}
                        setSortOptionChosen={setSortOptionChosen}
                        handleSubmissionEditOpen={handleSubmissionEditOpen}
                        memberAndSubmissionDetails={memberAndSubmissionDetails}
                        onMembersAdded={onMembersAdded}
                      ></GroupOrderMemberList>
                    ) : (
                      <div className="flex items-center border border-gray-300 rounded-md h-80 bg-gray-100">
                        <div className="flex justify-center items-center h-40 w-40 bg-gray-50 border border-gray-300 rounded-md text-gray-400 text-2xl mx-20">
                          <MdInfo />
                        </div>
                        <div className="font-bold">
                          Please edit and save the info in the admin panel first
                          to start adding members.
                        </div>
                      </div>
                    )}
                  </Content>
                  <Content active={active === 1}>
                    {order ? (
                      <PackageList
                        orderDetails={order}
                        onPackageCreatedOrEdited={onPackageCreatedOrEdited}
                      />
                    ) : (
                      <div className="flex items-center border border-gray-300 rounded-md h-80 bg-gray-100">
                        <div className="flex justify-center items-center h-40 w-40 bg-gray-50 border border-gray-300 rounded-md text-gray-400 text-2xl mx-20">
                          <MdInfo />
                        </div>
                        <div className="font-bold">
                          Please edit and save the info in the admin panel first
                          to start adding packages.
                        </div>
                      </div>
                    )}
                  </Content>
                  {order &&
                    order.groupOrderId &&
                    order?.managedShippingAddresses && (
                      <Content active={active === 2}>
                        <ShippingAddressTabContent
                          groupOrderId={order.groupOrderId}
                          onEdit={handleOpenShippingAddress}
                        />
                      </Content>
                    )}
                </div>
              </div>
              <div className="p-8 bg-gray-50 lg:min-w-order-sidebar flex flex-col items-start rounded-tr-24 rounded-br-24">
                <form
                  onSubmit={handleSubmit(handleOrderSave)}
                  className="w-full"
                  id="create-edit-order-form"
                >
                  <FormSelect
                    name="order-status"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    label="Order Status"
                    emptyText="Status"
                    tooltip={
                      'Keep your group order set to Draft until you are ready to go live. ' +
                      'Setting your store to Active will notify your members that your group order is available.'
                    }
                    helpText="Required"
                    default={order ? order.status : 'draft'}
                    options={orderStatusOptions}
                    onChange={handleStatusChange}
                    disabled={order && order.status === 'closed'}
                  />
                  <FormInput
                    disabled={order}
                    inputStyle="two"
                    name="storefront-url"
                    defaultValue={order ? order.storeUrl : ''}
                    placeholder="your-group-order"
                    type="text"
                    label="Storefront URL"
                    required={order ? false : true}
                    errors={errors}
                    register={register}
                    tooltip="Cannot be modified once it is initially set."
                    linkSubText={
                      domainName +
                      '/' +
                      (tempStorefrontUrl
                        ? tempStorefrontUrl
                        : order
                          ? slug(order.storeUrl.trim())
                          : 'your-group-order')
                    }
                  />
                  <CloudinaryFileInput
                    error={storefrontLogoError}
                    name="storefront-logo"
                    type="one"
                    updateContent={handleLogoChange}
                    content={storefrontLogo ? storefrontLogo : []}
                    maxFiles={1}
                    label="Storefront Logo"
                    placeholder="JPEGs, PNGs or SVGs"
                    tooltip="Logo should have a pixel height dimension of 90px or more."
                    helpText="Min. height of 88px"
                    disabled={order && order.status === 'closed'}
                  />

                  <FormInput
                    textArea={true}
                    inputStyle="two"
                    name="storefront-message"
                    defaultValue={order ? order.storeMessage : ''}
                    placeholder="Message"
                    type="text"
                    label="Storefront Header"
                    required={false}
                    errors={errors}
                    register={register}
                    tooltip={
                      'This messaging displays on your group order storefront and ' +
                      'provides some context as to what the user can do and see.'
                    }
                    disabled={order && order.status === 'closed'}
                  />

                  <FormInput
                    textArea={true}
                    inputStyle="two"
                    name="storefront-sub-message"
                    defaultValue={order ? order.storeSubMessage : ''}
                    placeholder="Message"
                    type="text"
                    label="Storefront Message"
                    required={false}
                    errors={errors}
                    register={register}
                    tooltip={
                      'This messaging displays on your group order storefront and ' +
                      'provides some context as to what the user can do and see.'
                    }
                    disabled={order && order.status === 'closed'}
                  />

                  <FormInput
                    disabled={
                      userRole === 'ADMIN'
                        ? true
                        : order && order.status === 'closed'
                          ? true
                          : false
                    }
                    inputStyle="two"
                    name="storefront-admin"
                    defaultValue={order ? order.groupAdmin : ''}
                    placeholder="Email"
                    type="email"
                    label="Admin"
                    required={true}
                    errors={errors}
                    register={register}
                    tooltip="This is the group order admin’s email address."
                  />
                  <FormInput
                    disabled={
                      userRole === 'ADMIN'
                        ? true
                        : order && order.status === 'closed'
                          ? true
                          : false
                    }
                    inputStyle="two"
                    name="storefront-closedate"
                    defaultValue={order ? convertDate(order.closeDate) : ''}
                    type="date"
                    label="Close Date"
                    required={true}
                    errors={errors}
                    register={register}
                    helpText="Required"
                    tooltip="This is when the group order closes and the orders are processed."
                  />
                  <div
                    className={
                      !verificationSelected && passwordSelected
                        ? 'opacity-50'
                        : ''
                    }
                  >
                    <Checkbox
                      label="Require Verification Code"
                      className="items-center mb-4"
                      onChange={handleVerificationSelect}
                      isSelected={verificationSelected}
                      tooltip={
                        'This option requires the front-end users to verify ' +
                        'prior to entering the group order storefront.'
                      }
                      disabled={disabledPasswords}
                    />
                  </div>
                  <div
                    className={`${!passwordSelected ? 'mb-2' : ''} ${
                      !passwordSelected && verificationSelected
                        ? 'opacity-50'
                        : ''
                    }`}
                  >
                    <Checkbox
                      label="Require Storefront Password"
                      className="items-center"
                      onChange={handlePasswordSelect}
                      isSelected={passwordSelected}
                      tooltip={
                        'This option requires the front-end user to input a ' +
                        'specified password prior to entering the group order storefront.'
                      }
                      disabled={disabledPasswords}
                    />
                  </div>
                  {
                    <Checkbox
                      label="Ship to Office"
                      className="items-center mt-4"
                      onChange={handleShipToOfficeSelect}
                      isSelected={isShipToOfficeSelected}
                      tooltip={
                        'Check this option to restrict your members addresses to a ' +
                        'list of pre-defined locations in the Shipping Addresses tab.'
                      }
                    />
                  }
                  {passwordSelected && (
                    <FormInput
                      inputStyle="two"
                      name="storefront-password"
                      disabled={
                        !passwordSelected ||
                        (order && order.status === 'closed')
                      }
                      defaultValue={
                        order && order.password ? order.password : ''
                      }
                      type="text"
                      placeholder="Enter Password"
                      label=""
                      required={false}
                      errors={errors}
                      register={register}
                    ></FormInput>
                  )}
                  <FormSelect
                    name="tracking-notification"
                    classes="mt-5"
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    label="Tracking Notifications"
                    tooltip="Tracking notifications will be sent via email. Notifications may include addresses, tracking links and other relevant shipping information."
                    default={trackingNotification}
                    options={buildTrackingNotificationOptions()}
                    onChange={handleTrackingNotificationChange}
                    disabled={order && order.status === 'closed'}
                  />
                  <div className="flex w-full justify-between">
                    <div className="flex w-full">
                      <CTA
                        disabled={order ? !orderEditActive : false}
                        stretch={true}
                        formSubmit={true}
                        size="standard"
                        type="primary"
                      >
                        {order ? 'Update Group Order' : 'Save Group Order'}
                      </CTA>
                    </div>
                    {!order && (
                      <div className="ml-4">
                        <CTA
                          disabled={!orderEditActive}
                          formSubmit={false}
                          size="standard"
                          type="secondary"
                          icon={<RiDeleteBin7Line size="16px" />}
                          onClick={() => {
                            handleOrderReset();
                          }}
                        ></CTA>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
        </Card>
        <Footer />
      </div>
    </div>
  );
};
