const ProductConnection = {
  name: 'ProductConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'ProductEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default ProductConnection;
