const DiscountCodeApplication = {
  name: 'DiscountCodeApplication',
  kind: 'OBJECT',
  fieldBaseTypes: {
    allocationMethod: 'DiscountApplicationAllocationMethod',
    applicable: 'Boolean',
    code: 'String',
    targetSelection: 'DiscountApplicationTargetSelection',
    targetType: 'DiscountApplicationTargetType',
    value: 'PricingValue',
  },
  implementsNode: false,
};
export default DiscountCodeApplication;
