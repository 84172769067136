const CustomerCreateInput = {
  name: 'CustomerCreateInput',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    firstName: 'String',
    lastName: 'String',
    email: 'String',
    phone: 'String',
    password: 'String',
    acceptsMarketing: 'Boolean',
  },
};
export default CustomerCreateInput;
