const Fulfillment = {
  name: 'Fulfillment',
  kind: 'OBJECT',
  fieldBaseTypes: {
    fulfillmentLineItems: 'FulfillmentLineItemConnection',
    trackingCompany: 'String',
    trackingInfo: 'FulfillmentTrackingInfo',
  },
  implementsNode: false,
};
export default Fulfillment;
