import { Tooltip } from '@merchstores/shared/elements/Tooltip';
import { MdHelpOutline } from 'react-icons/md';

interface SummaryCardProps {
  heading: string;
  description: string;
  tooltipContent?: string | HTMLElement | JSX.Element;
}

const SummaryCard = (props: SummaryCardProps): JSX.Element => {
  const { heading, description, tooltipContent } = props;
  return (
    <div className="text-base flex flex-col w-full rounded-lg border border-gray-200 px-3 pt-2 pb-3 shadow my-4 sm:my-0">
      <p className="text-2xl leading-10 font-semibold tracking-wide">
        {heading}
      </p>
      <div className="flex flex-row">
        <p className="flex-auto text-sm leading-3 text-merch-dark-gray font-medium opacity-50">
          {description}
        </p>
        {tooltipContent && (
          <Tooltip content={tooltipContent}>
            <div className="opacity-50 ml-2">
              <MdHelpOutline />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SummaryCard;
