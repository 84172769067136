const DiscountApplicationEdge = {
  name: 'DiscountApplicationEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'DiscountApplication',
  },
  implementsNode: false,
};
export default DiscountApplicationEdge;
