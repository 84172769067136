import { IBadgeProps } from './index';
import './styles.scss';

export const Badge = (props: IBadgeProps) => {
  return (
    <div className={`badge ${props.type} ${props.className}`}>
      <span>{props.children}</span>
    </div>
  );
};
