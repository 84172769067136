const Shop = {
  name: 'Shop',
  kind: 'OBJECT',
  fieldBaseTypes: {
    articles: 'ArticleConnection',
    blogs: 'BlogConnection',
    collectionByHandle: 'Collection',
    collections: 'CollectionConnection',
    currencyCode: 'CurrencyCode',
    description: 'String',
    moneyFormat: 'String',
    name: 'String',
    paymentSettings: 'PaymentSettings',
    primaryDomain: 'Domain',
    privacyPolicy: 'ShopPolicy',
    productByHandle: 'Product',
    productTags: 'StringConnection',
    productTypes: 'StringConnection',
    products: 'ProductConnection',
    refundPolicy: 'ShopPolicy',
    shippingPolicy: 'ShopPolicy',
    shipsToCountries: 'CountryCode',
    shopifyPaymentsAccountId: 'String',
    termsOfService: 'ShopPolicy',
  },
  implementsNode: false,
};
export default Shop;
