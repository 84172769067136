import { useHistory } from 'react-router-dom';
import { CTA } from '@merchstores/shared/elements/Cta';
import { IOrderDetailsView } from '@merchstores/admin/types/merchstoreOrder';
import { IShopifyOrder } from '@merchstores/admin/types/shopifyOrder';

interface Props {
  order: IShopifyOrder;
  storeCode: string;
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
}

const ActionTabCell = (props: Props): JSX.Element => {
  const { storeCode, order, setSelectedOrderDetails } = props;
  const orderId = order.name.replace('#', '');
  const history = useHistory();

  const handleOnEditClick = () => {
    const selectedOrder: IOrderDetailsView = {
      combinedOrder: null,
      individualOrder: order,
      viewType: 'gift_card_order',
    };
    setSelectedOrderDetails(selectedOrder);
    history.push(`/admin/merchstores/${storeCode}/orders/${orderId}`);
  };

  return (
    <div className="flex justify-center mr-2 sm:mr-0">
      <CTA size="small" type="secondary" onClick={handleOnEditClick}>
        View
      </CTA>
    </div>
  );
};

export default ActionTabCell;
