const CustomerUserError = {
  name: 'CustomerUserError',
  kind: 'OBJECT',
  fieldBaseTypes: {
    code: 'CustomerErrorCode',
    field: 'String',
    message: 'String',
  },
  implementsNode: false,
};
export default CustomerUserError;
