const CustomerUpdatePayload = {
  name: 'CustomerUpdatePayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customer: 'Customer',
    customerAccessToken: 'CustomerAccessToken',
    customerUserErrors: 'CustomerUserError',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CustomerUpdatePayload;
