import React, { useState } from 'react';
import { useEffect } from 'react';
import { clearSessionCustomInventoryData } from '../components/Auth/CustomInventory';

const Context = React.createContext({
  loggedIn: false,
  userRole: null,
  loginEmail: null,
  userEmail: null,
  groupMemberLoggedIn: false,
  loginUser: (
    accessToken: string,
    expiresAt: string,
    email: string,
    rememberMe: boolean,
    role: string
  ) => {},
  logoutUser: () => {},
  loginGroupMember: (groupOrderId: string, email?: string) => {},
});

const ContextProvider = (props: any) => {
  let localStorageLoggedIn = localStorage.getItem('loggedIn');
  let localstorageUserRole = null;
  if (localStorageLoggedIn) {
    localstorageUserRole = JSON.parse(localStorageLoggedIn).role;
  }
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem('loggedIn') ? true : false
  );
  const [loginEmail, setLoginEmail] = useState(
    localStorage.getItem('loginEmail') !== ''
      ? localStorage.getItem('loginEmail')
      : null
  );
  const [groupMemberLoggedIn, setGroupMemberLoggedIn] = useState({
    loggedIn: false,
    groupOrder: null,
    email: null,
  });
  const [userRole, setUserRole] = useState(localstorageUserRole);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn');
    const groupMemberLoggedIn = localStorage.getItem('groupMemberLoggedIn');
    if (loggedIn) {
      const userRole = JSON.parse(loggedIn).role;
      setUserRole(userRole);
      const userEmail = JSON.parse(loggedIn).email.toLowerCase().trim();
      setUserEmail(userEmail);
    }

    if (groupMemberLoggedIn) {
      const values = JSON.parse(groupMemberLoggedIn);
      setGroupMemberLoggedIn({
        loggedIn: values.loggedIn,
        groupOrderId: values.groupOrderId,
        email: values.groupOrderId,
      });
    }
  }, []);

  const loginUser = (
    accessToken: string,
    expiresAt: string,
    email: string,
    rememberMe: boolean,
    role: string
  ) => {
    const now = new Date();
    const item = {
      value: accessToken,
      role,
      email,
      expiry: now.getTime() + new Date(expiresAt).getTime(),
    };
    localStorage.setItem('loggedIn', JSON.stringify(item));
    setLoginEmail(email);
    if (rememberMe) {
      localStorage.setItem('loginEmail', email);
    } else {
      localStorage.removeItem('loginEmail');
    }
    setUserRole(role);
    setUserEmail(email);
    setLoggedIn(true);
  };

  const logoutUser = () => {
    setLoggedIn(false);
    clearSessionCustomInventoryData();
    localStorage.removeItem('loggedIn');
  };

  const loginGroupMember = (groupOrderId: string, email?: string) => {
    localStorage.setItem(
      'groupMemberLoggedIn',
      JSON.stringify({ loggedIn: true, groupOrderId, email })
    );
    setGroupMemberLoggedIn({ loggedIn: true, groupOrderId, email });
  };

  return (
    <Context.Provider
      value={{
        loggedIn: loggedIn,
        loginEmail: loginEmail,
        groupMemberLoggedIn: groupMemberLoggedIn,
        loginUser: loginUser,
        logoutUser: logoutUser,
        loginGroupMember: loginGroupMember,
        userRole: userRole,
        userEmail: userEmail,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
