const CheckoutAttributesUpdateV2Payload = {
  name: 'CheckoutAttributesUpdateV2Payload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    checkout: 'Checkout',
    checkoutUserErrors: 'CheckoutUserError',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CheckoutAttributesUpdateV2Payload;
