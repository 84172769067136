const GET_SHOPIFY_ORDER_BY_ID = '/.netlify/functions/fetchMerchStoreOrderById';
const GET_GIFT_CARDS_STATUS = '/.netlify/functions/fetchMerchStoreById';
const GET_SHOPIFY_ORDERS = '/.netlify/functions/fetchMerchStoreOrders';
const MERCHSTORES_GET_MEMBER_GIFT_CARDS =
  '/.netlify/functions/fetchMerchStoreMemberGiftCardsStatus';
const MERCHSTORES_ASSIGN_MEMBER_GIFT_CARDS =
  '/.netlify/functions/assignMerchStoreMemberGiftCards';
const MERCHSTORES_REASSIGN_MEMBER_GIFT_CARDS =
  '/.netlify/functions/reassignMerchStoreMemberGiftCard';
const MERCHSTORES_UNASSIGN_MEMBER_GIFT_CARDS =
  '/.netlify/functions/unassignMerchStoreMemberGiftCard';
const MERCHSTORES_DISABLE_MEMBER_GIFT_CARDS =
  '/.netlify/functions/disableGiftCards';
const MERCHSTORES_MERCHSTORE_UPDATE = '/.netlify/functions/updateMerchStore';
const MERCHSTORES_MERCHSTORE_GET_BY_ID =
  '/.netlify/functions/fetchMerchStoreById';
const MERCHSTORES_ADMIN_UPDATE = '/.netlify/functions/updateMerchStoreAdmin';

export {
  GET_SHOPIFY_ORDERS,
  GET_GIFT_CARDS_STATUS,
  GET_SHOPIFY_ORDER_BY_ID,
  MERCHSTORES_GET_MEMBER_GIFT_CARDS,
  MERCHSTORES_ASSIGN_MEMBER_GIFT_CARDS,
  MERCHSTORES_REASSIGN_MEMBER_GIFT_CARDS,
  MERCHSTORES_UNASSIGN_MEMBER_GIFT_CARDS,
  MERCHSTORES_MERCHSTORE_GET_BY_ID,
  MERCHSTORES_MERCHSTORE_UPDATE,
  MERCHSTORES_ADMIN_UPDATE,
  MERCHSTORES_DISABLE_MEMBER_GIFT_CARDS,
};
