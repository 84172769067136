const ImageConnection = {
  name: 'ImageConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'ImageEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default ImageConnection;
