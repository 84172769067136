const SEO = {
  name: 'SEO',
  kind: 'OBJECT',
  fieldBaseTypes: {
    description: 'String',
    title: 'String',
  },
  implementsNode: false,
};
export default SEO;
