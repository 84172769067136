import { IMerchStore } from '../MerchStore';
import { PayerType } from '@merchstores/shared/components/MerchStore';

export function isAutoCloseOrdersAllowed(merchStore: IMerchStore): boolean {
  return merchStore.payerType === PayerType.INDIVIDUAL;
}

export function isAutoCloseOrdersEnabled(merchStore: IMerchStore): boolean {
  const isAllowed = isAutoCloseOrdersAllowed(merchStore);
  const hasAutoProcessOrdersFlag = Boolean(merchStore.autoProcessOrders);
  const hasContactInfo = Boolean(merchStore.contactInfo);
  return isAllowed && hasAutoProcessOrdersFlag && hasContactInfo;
}
