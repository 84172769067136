const DiscountAllocation = {
  name: 'DiscountAllocation',
  kind: 'OBJECT',
  fieldBaseTypes: {
    allocatedAmount: 'MoneyV2',
    discountApplication: 'DiscountApplication',
  },
  implementsNode: false,
};
export default DiscountAllocation;
