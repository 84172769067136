import './styles.scss';
import { useState, useEffect } from 'react';
import { ICheckboxProps } from './index';
import { Tooltip } from '../Tooltip';
import { MdHelpOutline } from 'react-icons/md';

export const Checkbox = (props: ICheckboxProps) => {
  const [checked, setChecked] = useState(props?.isSelected);
  const [isDisabled, setIsDisabeld] = useState(false);

  useEffect(() => {
    if (props.isSelected !== undefined) {
      setChecked(props.isSelected);
    }
  }, [props.isSelected]);

  useEffect(() => {
    if (isDisabled !== props.disabled) {
      setIsDisabeld(props.disabled);
    }
  }, [props.disabled]);

  const onCheckboxClicked = (ev: any) => {
    if (!isDisabled) {
      setChecked(!checked);
      if (props.onChange) {
        props.onChange(
          !checked,
          props.id ? props.id : '',
          props.value ? props.value : '',
          ev
        );
      }
    }
  };

  // If props.isSelected is passed in, use that instead of the checked variable to toggle check
  let checkVar = checked;
  if (props.isSelected !== undefined) {
    checkVar = props.isSelected;
  }

  return (
    <div
      className={`checkbox-container ${props.className} ${isDisabled ? 'disabled opacity-50' : ''}`}
    >
      <input
        type="checkbox"
        className="checkbox-hidden"
        checked={checkVar}
        readOnly={true}
      />
      <input
        className={props.className}
        type="hidden"
        value={`${props.id}-${checkVar}`}
      />
      <div
        className="checkbox-styled"
        id={`product-cb-${props.id}`}
        onClick={onCheckboxClicked}
      >
        <svg
          className={`checkbox-check-icon ${checkVar ? 'visible' : 'invisible'}`}
          viewBox="0 0 24 24"
        >
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
      {props.label ? <div className="checkbox-label">{props.label}</div> : ''}
      {props.tooltip && (
        <Tooltip content={props.tooltip}>
          <div className="opacity-50 ml-1">
            <MdHelpOutline />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
