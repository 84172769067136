const OrderConnection = {
  name: 'OrderConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'OrderEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default OrderConnection;
