const Payment = {
  name: 'Payment',
  kind: 'OBJECT',
  fieldBaseTypes: {
    amount: 'Money',
    amountV2: 'MoneyV2',
    billingAddress: 'MailingAddress',
    checkout: 'Checkout',
    creditCard: 'CreditCard',
    errorMessage: 'String',
    id: 'ID',
    idempotencyKey: 'String',
    nextActionUrl: 'URL',
    ready: 'Boolean',
    test: 'Boolean',
    transaction: 'Transaction',
  },
  implementsNode: true,
};
export default Payment;
