import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RiImageAddLine } from 'react-icons/ri';
import { cloudinaryApplyWidthHeight } from '@merchstores/shared/components/Cloudinary';

import './styles.scss';
import { IMerchStoreArtworkOptions } from '.';

export const MerchStoreArtworkOptions: React.FC<IMerchStoreArtworkOptions> = (
  props: IMerchStoreArtworkOptions
) => {
  const [artworkOptions, setArtworkOptions] = useState([]);
  const [merchStore] = useState(props.merchStore);
  const [fileInput, setFileInput] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    setArtworkOptions(
      props.merchStore.artworkOptions ? props.merchStore.artworkOptions : []
    );
  }, [props]);

  useEffect(() => {
    setFileInput(document.getElementById(`artwork-hidden-input`));
  }, [fileInput]);

  const updateArtwork = (url: string, index: number) => {
    //If artworkOptions is null, newArray will equal an empty array
    const newArray = artworkOptions ? [...artworkOptions] : [];
    newArray[index] = url;
    setArtworkOptions(newArray);
    updateMerchStore(newArray);
    toast.success('Artwork options updated');
  };

  const updateMerchStore = async (artworkArray: string[]) => {
    return new Promise((resolve, reject) => {
      const updatedMerchStore = {
        ...merchStore,
        artworkOptions: artworkArray,
      };
      axios
        .post('/.netlify/functions/updateMerchStore', {
          mutationType: 'updateMerchStore',
          merchStoreData: updatedMerchStore,
        })
        .then((merchStoreResp) => {
          resolve(merchStoreResp);
          props.setMerchStoreDetails(updatedMerchStore);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleFileInput = async (e: any) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files.length) {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('upload_preset', 'merchstores');
        axios
          .post(
            'https://api.cloudinary.com/v1_1/merchology/image/upload',
            formData
          )
          .then((resp) => {
            updateArtwork(resp.data.secure_url, selectedIndex);
            if (fileInput) {
              fileInput.removeEventListener('change', handleFileInput);
              fileInput.value = null;
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(
              'An error occured uploading your image, please try again.'
            );
            if (fileInput) {
              fileInput.removeEventListener('change', handleFileInput);
              fileInput.value = null;
            }
          });
      }
    }
  };

  useEffect(() => {
    if (selectedIndex !== null) {
      fileInput.addEventListener('change', handleFileInput);
      fileInput.click();
      setSelectedIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  /**
   * in order for the correct selected index to be read by the handleFileInput function,
   * the actual click logic is handled in the above useEffect block
   */
  const triggerInputClick = (index: number) => {
    setSelectedIndex(index);
  };

  const removeArtwork = (index: number) => {
    if (
      !confirm(
        'This will remove this artwork from active products. New artwork will need to be selected for each product using this graphic. Continue?'
      )
    ) {
      return;
    }
    const newArray = [...artworkOptions];
    newArray.splice(index, 1);
    setArtworkOptions(newArray);
    updateMerchStore(newArray);
    toast.success('Artwork options updated');
  };

  const headerCardStyles = `h-60 px-4 flex items-center text-sm font-medium w-full ${
    artworkOptions.length === 1
      ? 'lg:w-admin-header-card-2/3'
      : 'lg:w-create-order-header-card'
  } shadow-buttons border border-gray-300 rounded-xl p-0.5 mb-3 lg:mb-0`;

  return (
    <>
      <div>
        <div className="flex flex-col px-5 md:px-0 lg:flex-row lg:mb-2.5 justify-between">
          <input
            type="file"
            accept="image/png, image/jpeg, image/svg"
            id="artwork-hidden-input"
            className="hidden bg-right-top group-order-form-select p-3 border border-gray-300 rounded-md"
          />
          {artworkOptions.map((artwork: string, index: number) => {
            const artworkUrl = artwork.toString();
            const resizedArtworkUrl = cloudinaryApplyWidthHeight(
              artworkUrl,
              36,
              30
            );
            return (
              <div
                className={`artwork-container ${headerCardStyles}`}
                key={`artwork_${index}`}
              >
                <div className="artwork-thumbnail flex items-center justify-center bg-gray-50">
                  <img src={resizedArtworkUrl}></img>
                </div>
                <div className="artwork-title flex-grow items-center overflow-hidden ml-10">
                  {artworkUrl.substring(artworkUrl.lastIndexOf('/') + 1)}
                </div>
                <div
                  className="artwork-edit flex justify-end items-center ml-10 cursor-pointer h-full"
                  onClick={() => removeArtwork(index)}
                >
                  Remove
                </div>
                <div
                  className="artwork-edit flex justify-end items-center ml-10 cursor-pointer h-full"
                  onClick={() => triggerInputClick(index)}
                >
                  Edit
                </div>
              </div>
            );
          })}
          {artworkOptions.length < 3 && (
            <div
              className={`artwork-container cursor-pointer ${headerCardStyles}`}
              key={'addArtwork'}
              onClick={() => {
                triggerInputClick(artworkOptions.length);
              }}
            >
              <div className="artwork-thumbnail flex items-center justify-center">
                <RiImageAddLine color={'#999999'} />
              </div>
              <div className="add-artwork-title flex items-center overflow-hidden ml-10 text-gray-400">
                Add Artwork
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
