const Comment = {
  name: 'Comment',
  kind: 'OBJECT',
  fieldBaseTypes: {
    author: 'CommentAuthor',
    content: 'String',
    contentHtml: 'HTML',
    id: 'ID',
  },
  implementsNode: true,
};
export default Comment;
