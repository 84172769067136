const ArticleAuthor = {
  name: 'ArticleAuthor',
  kind: 'OBJECT',
  fieldBaseTypes: {
    bio: 'String',
    email: 'String',
    firstName: 'String',
    lastName: 'String',
    name: 'String',
  },
  implementsNode: false,
};
export default ArticleAuthor;
