import { useContext } from 'react';

import { MdCardGiftcard } from 'react-icons/md';
import { Context } from '@merchstores/admin/context';
import { IMemberGiftCard } from '@merchstores/admin/types/giftCards';
import { moneyFormat } from '@merchstores/shared/components/Money';
import { Tooltip } from '@merchstores/shared/elements/Tooltip';
import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';

interface Props {
  memberCode: string;
  memberGiftCards: IMemberGiftCard[] | undefined;
  giftCardsModalState: {
    setIsConfirmationGiftCardsModalOpen: (value: boolean) => void;
    setModalOpenedInline: (value: boolean) => void;
    handleInlineAction: (data: { memberCode: string; action: string }) => void;
  };
}

const MemberIdTableColumn = (props: Props): JSX.Element => {
  const { memberCode, memberGiftCards, giftCardsModalState } = props;

  const { userRole } = useContext(Context);
  const isSuperUserView = userHasRole(new Role(userRole), [ROLE_SUPERUSER]);
  const isMerchologistView = userHasRole(new Role(userRole), [
    ROLE_SUPERUSER,
    ROLE_MERCHOLOGIST,
  ]);

  const handleDropdownAction = (memberCode: string, action: string) => {
    const isConfirmationAndInline = [
      'assign_gift_cards',
      'reassign_gift_cards',
      'unassign_gift_cards',
      'disable_gift_cards',
    ].includes(action);

    if (isConfirmationAndInline) {
      giftCardsModalState.setIsConfirmationGiftCardsModalOpen(true);
      giftCardsModalState.setModalOpenedInline(true);
    }

    giftCardsModalState.handleInlineAction({ memberCode, action });
  };

  const calculateTotalBalance = (cards: IMemberGiftCard[]): number => {
    return cards.reduce(
      (total: number, card: IMemberGiftCard) => total + Number(card.balance),
      0
    );
  };

  const tooltipContent = memberGiftCards && (
    <div className="px-1 py-0">
      <div className="flex flex-col text-left">
        <div className="flex justify-between">
          <div className="text-13 text-gray-500 mr-2">
            {memberGiftCards.length > 1 ? 'Balances:' : 'Balance:'}
          </div>
          <div className="flex flex-col text-13 text text-black ml-1">
            {memberGiftCards
              .sort((a, b) => parseFloat(a.balance) - parseFloat(b.balance))
              .map((card, index) => (
                <div key={index}>{moneyFormat(card.balance)}</div>
              ))}
          </div>
        </div>
        {memberGiftCards.length > 1 && (
          <div
            className="mt-1 mb-1"
            style={{ height: '1px', backgroundColor: '#DDDDDD' }}
          ></div>
        )}
        {memberGiftCards.length > 1 && (
          <div className="flex justify-between">
            <div className="text-13 text-gray-500">Total:</div>
            <div className="text-13 text text-black ml-1">
              {moneyFormat(calculateTotalBalance(memberGiftCards))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const hasGiftCard = memberGiftCards && memberGiftCards.length > 0;
  const hasMemberGiftCards = memberGiftCards?.length;

  const MEMBER_DROPDOWN_OPTIONS = [
    {
      id: 'assign_gift_cards',
      title: 'Assign Gift Card',
      action: () => handleDropdownAction(memberCode, 'assign_gift_cards'),
    },
    {
      id: 'reassign_gift_cards',
      title: 'Reassign Gift Card',
      action: () => handleDropdownAction(memberCode, 'reassign_gift_cards'),
      hide: !hasMemberGiftCards || !isMerchologistView,
    },
    {
      id: 'unassign_gift_cards',
      title: 'Unassign Gift Card',
      action: () => handleDropdownAction(memberCode, 'unassign_gift_cards'),
      hide: !hasMemberGiftCards || !isMerchologistView,
    },
    {
      id: 'disable_gift_cards',
      title: 'Disable Gift Card',
      action: () => handleDropdownAction(memberCode, 'disable_gift_cards'),
      hide: !isSuperUserView || !hasGiftCard,
    },
    {
      id: 'delete',
      title: 'Delete Member',
      action: () => handleDropdownAction(memberCode, 'delete'),
      disabled: hasGiftCard,
      disabledTip: 'Members with gift cards cannot be deleted.',
    },
  ];

  return (
    <div className="flex items-center justify-end">
      <div className="mr-4">{memberCode}</div>
      {memberGiftCards && memberGiftCards.length > 0 && (
        <Tooltip content={tooltipContent}>
          <div className="text-gray-600 hover:text-merch-orange mr-3">
            <MdCardGiftcard size="18px" />
          </div>
        </Tooltip>
      )}
      <ActionDropdown
        title="Actions"
        className="h-9 px-4 w-full text-sm ml-1 mr-4"
        list={MEMBER_DROPDOWN_OPTIONS}
      />
    </div>
  );
};

export default MemberIdTableColumn;
