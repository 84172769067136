import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import { moneyFormat } from '@merchstores/shared/components/Money';
import { CTA } from '@merchstores/shared/elements/Cta';
import {
  FormSelect,
  IFormOptionProps,
} from '@merchstores/shared/elements/FormSelect';
import { IMemberGiftCard } from '@merchstores/admin/types/giftCards';
import { IMerchStoreMember } from '@merchstores/admin/types/merchstoreMember';
import { IRequestGiftCardsSummaryResult } from '@merchstores/admin/components/MerchStoreGiftCards/GiftCardsSummary';

import { useForm } from 'react-hook-form';
import { MdClear } from 'react-icons/md';
import { useState } from 'react';
import '@merchstores/admin/components/AdminModal/AdminModal.scss';
import './styles.scss';

interface Props {
  isLoading: boolean;
  membersList: IMerchStoreMember[];
  selectedMembers: string[];
  isOpen: boolean;
  selectedAction: string;
  isStoreActive: boolean;
  memberGiftCards?: IMemberGiftCard[];
  handleCancel: () => void;
  giftCardsSummary: IRequestGiftCardsSummaryResult;
  assignGiftCards: (data: {
    storeCode: string;
    memberEmails: string[];
    cardValue: string;
  }) => void;
  reassignGiftCard: (
    storeCode: string,
    storeGiftCardCode: string,
    fromMemberCode: string,
    toMemberCode: string
  ) => void;
  unassignGiftCards: (storeCode: string, fromMemberCodes: string[]) => void;
  disableGiftCard: (id: string[]) => void;
  storeCode: string;
}

const getModalDescriptionText = (isStoreActive: boolean): string => {
  const textMap = {
    active:
      'Members will receive an email notifying them that they’ve received a gift card with the following value:',
    nonActive:
      'Once the store is activated, each member will receive ' +
      'an email notifying them that they’ve received a gift card with the following value:',
  };
  const textDescription = isStoreActive
    ? textMap['active']
    : textMap['nonActive'];
  return `${textDescription}`;
};

const GiftCardsModificationModal = (props: Props): JSX.Element => {
  const {
    isLoading,
    membersList,
    selectedMembers,
    isOpen,
    selectedAction,
    isStoreActive,
    memberGiftCards = [],
    handleCancel,
    giftCardsSummary,
    assignGiftCards,
    reassignGiftCard,
    unassignGiftCards,
    disableGiftCard,
    storeCode,
  } = props;

  const description = getModalDescriptionText(isStoreActive);
  const [selectedAmount, setSelectedAmount] = useState('');
  const [memberToReassign, setMemberToReassign] = useState('');

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const handleAssignGiftCards = () => {
    const selectedAmountInfo = giftCardsSummary.availableGiftCards.find(
      (card) => card.cardValue === selectedAmount
    );
    const availableCards = selectedAmountInfo
      ? selectedAmountInfo.cardCount
      : 0;
    if (availableCards >= selectedMembers?.length) {
      const memberEmails = membersList
        ?.filter((member) => selectedMembers?.includes(member.memberCode))
        .map((member) => member.email);
      assignGiftCards({
        storeCode: storeCode,
        memberEmails,
        cardValue: selectedAmount,
      });
    } else {
      toast.error('You do not have enough gift cards. \n Buy more gift cards');
    }
    onCloseModal();
  };

  const handleDisableGiftCards = async () => {
    const storeGiftCardCodes = giftCardMatchingEmail.map(
      (card) => card.storeGiftCardCode
    );

    if (storeGiftCardCodes.length === 0) {
      toast.error('No gift cards found to disable.');
      onCloseModal();
      return;
    }

    await disableGiftCard(storeGiftCardCodes);
    toast.success(
      `Gift ${
        storeGiftCardCodes.length > 1 ? 'cards' : 'card'
      } disabled successfully.`
    );
    onCloseModal();
  };

  const handleReassignGiftCards = () => {
    const fromMemberCode = selectedMembers[0];
    const toMemberCode = memberToReassign;
    const filteredGiftCards = giftCardMatchingEmail.filter(
      (giftCard): giftCard is IMemberGiftCard =>
        giftCard.balance === selectedAmount
    );
    if (filteredGiftCards.length) {
      const storeGiftCardCode = filteredGiftCards[0].storeGiftCardCode;
      reassignGiftCard(
        storeCode,
        storeGiftCardCode,
        fromMemberCode,
        toMemberCode
      );
    } else {
      toast.error('No gift card matching the selected total found on user.');
    }
    onCloseModal();
  };

  const handleUnassignGiftCards = () => {
    const fromMemberCodes = selectedMembers;
    unassignGiftCards(storeCode, fromMemberCodes);
    onCloseModal();
  };

  const mapMemberCodesToEmails = (
    selectedMembers: string[],
    membersList: IMerchStoreMember[]
  ): string[] => {
    if (!membersList) {
      return [];
    }
    return membersList
      .filter((member) => selectedMembers.includes(member.memberCode))
      .map((member) => member.email);
  };

  const selectedMembersEmails: string[] = mapMemberCodesToEmails(
    selectedMembers,
    membersList
  );
  const giftCardMatchingEmail = memberGiftCards.filter(
    (giftCard) => giftCard.memberEmail === selectedMembersEmails[0]
  );
  const modalContentMap = [
    {
      key: 'assign_gift_cards',
      title: `Assign gift cards to ${selectedMembers.length} members?`,
      description: description,
      button: 'Confirm',
      action: handleAssignGiftCards,
    },
    {
      key: 'unassign_gift_cards',
      title: 'Unassign Gift Cards',
      description: `Are you sure you want to unassign all  unused gift card balances for ${selectedMembers.length} members?`,
      subDescription:
        'These gift cards will be returned to your current available gift cards and can be assigned to new and existing store members.',
      button: 'Confirm & Unassign',
      action: handleUnassignGiftCards,
    },
    {
      key: 'disable_gift_cards',
      title: 'Disable Gift Cards',
      description: `Are you sure you want to disable all gift card balances for ${selectedMembers.length} members?`,
      subDescription: `The value of these gift cards will not be available for member use and cannot 
      be reassigned. The balance of disabled gift cards is viewable in the Gift Card tab.`,
      button: 'Confirm & Disable',
      action: handleDisableGiftCards,
    },
    {
      key: 'reassign_gift_cards',
      title: 'Reassign Gift Cards',
      description: 'Select the member’s balance you would like to reassign.',
      subDescription:
        'Store member will receive an email notification of their new gift card balance.',
      button: 'Confirm and Reassign',
      action: handleReassignGiftCards,
    },
  ];

  const selectedModalContent = modalContentMap.find(
    (content) => content.key === selectedAction
  ) || {
    title: 'Unknown Action',
    description: 'Unknown action description',
    subDescription: '',
    button: 'Confirm',
    action: handleCancel,
  };

  const formSelectOptions = (
    giftCardsSummary: IRequestGiftCardsSummaryResult
  ) => {
    if (giftCardsSummary && giftCardsSummary.availableGiftCards) {
      const sortedGiftCards = giftCardsSummary.availableGiftCards.sort(
        (a, b) => parseFloat(a.cardValue) - parseFloat(b.cardValue)
      );
      return sortedGiftCards.map((giftCard) => ({
        value: giftCard.cardValue,
        displayText: `${moneyFormat(parseFloat(giftCard.cardValue))}`,
      }));
    }
    return [];
  };

  const onCloseModal = () => {
    setMemberToReassign('');
    setSelectedAmount('');
    handleCancel();
  };

  return (
    <Modal
      center
      blockScroll
      open={isOpen}
      onClose={onCloseModal}
      classNames={{ modal: 'giftcards-modal' }}
      closeIcon={<MdClear />}
      onOverlayClick={handleCancel}
    >
      <p className="text-xl text-merch-dark-gray font-bold mb-2">
        {selectedModalContent.title}
      </p>
      <p className="description-text-lg">{selectedModalContent.description}</p>
      {selectedModalContent.subDescription && (
        <p className="description-text-sm">
          {selectedModalContent.subDescription}
        </p>
      )}
      {selectedAction === 'assign_gift_cards' && (
        <FormSelect
          name="assignGiftCardSelect"
          register={register}
          errors={errors}
          emptyText="Select an amount"
          setValue={setValue}
          label=""
          options={formSelectOptions(giftCardsSummary)}
          onChange={(selectedOption: IFormOptionProps) => {
            setSelectedAmount(selectedOption.value);
          }}
        />
      )}
      {selectedAction === 'reassign_gift_cards' && (
        <>
          <FormSelect
            name="reassignGiftCardSelectAmount"
            classes="mt-4"
            register={register}
            errors={errors}
            emptyText="Select an amount"
            default={selectedAmount}
            setValue={setValue}
            label=""
            options={giftCardMatchingEmail.map((giftCard) => ({
              value: giftCard.balance,
              displayText: `${moneyFormat(giftCard.balance)}`,
            }))}
            onChange={(selectedOption: IFormOptionProps) => {
              setSelectedAmount(selectedOption.value);
            }}
          />
          <p className="description-text-lg">to:</p>
          <FormSelect
            name="reassignGiftCardSelectMember"
            register={register}
            errors={errors}
            emptyText="Select a member"
            default={memberToReassign}
            setValue={setValue}
            classes="break-all"
            label=""
            options={membersList
              .filter((member) => !selectedMembersEmails.includes(member.email))
              .map((member) => ({
                value: member.memberCode,
                displayText: member.email,
              }))}
            onChange={(selectedOption: IFormOptionProps) => {
              setMemberToReassign(selectedOption.value);
            }}
          />
        </>
      )}
      <div className="flex flex-col">
        <CTA
          size={'large'}
          type={'primary'}
          formSubmit={false}
          disabled={
            isLoading ||
            (selectedAction === 'assign_gift_cards' && !selectedAmount) ||
            (selectedAction === 'reassign_gift_cards' &&
              (!selectedAmount || !memberToReassign))
          }
          classes="gift-card-add-checkout-button w-full"
          onClick={selectedModalContent.action}
        >
          {isLoading ? 'Saving...' : selectedModalContent.button}
        </CTA>
        <CTA
          formSubmit={false}
          size={'large'}
          type={'secondary'}
          classes="gift-card-add-cancel-button w-full	mt-2"
          onClick={onCloseModal}
        >
          Cancel
        </CTA>
      </div>
    </Modal>
  );
};

export default GiftCardsModificationModal;
