const __Schema = {
  name: '__Schema',
  kind: 'OBJECT',
  fieldBaseTypes: {
    directives: '__Directive',
    mutationType: '__Type',
    queryType: '__Type',
    subscriptionType: '__Type',
    types: '__Type',
  },
  implementsNode: false,
};
export default __Schema;
