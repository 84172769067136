const TokenizedPaymentInputV2 = {
  name: 'TokenizedPaymentInputV2',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    paymentAmount: 'MoneyInput',
    idempotencyKey: 'String',
    billingAddress: 'MailingAddressInput',
    paymentData: 'String',
    test: 'Boolean',
    identifier: 'String',
    type: 'String',
  },
};
export default TokenizedPaymentInputV2;
