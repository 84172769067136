const Attribute = {
  name: 'Attribute',
  kind: 'OBJECT',
  fieldBaseTypes: {
    key: 'String',
    value: 'String',
  },
  implementsNode: false,
};
export default Attribute;
