import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import './styles.scss';
import { MdArrowDropDown, MdDone, MdArrowDropUp } from 'react-icons/md';
import { generateReactKey } from '@merchstores/shared/react/ReactKey';
import { useRef } from 'react';
import { CTA } from '@merchstores/shared/elements/Cta';
import { toast } from 'react-toastify';

export const ReassignDropdown = (props: any) => {
  const {
    title,
    list,
    chosenMerchologist,
    setChosenMerch,
    setCurrentMerch,
    currentOrderId,
    setOrderError,
    setOpen,
    isMerchstore,
    fetchMerchstores,
  } = props;
  const [listOpen, setListOpen] = useState(false);
  const [, setHeaderTitle] = useState(title);
  const [, setSelectedItem] = useState(null);

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousemove', handleClickOutside);
    };
  }, []);

  const toggleList = () => {
    setListOpen(!listOpen);
  };

  const closeList = () => {
    setListOpen(false);
  };

  const selectItem = (item: any) => {
    if (item) {
      const { title } = item;
      setListOpen(false);
      if (item.action) {
        item.action();
      } else {
        setSelectedItem(item);
        setHeaderTitle(title);
        setChosenMerch(item.email);
      }
    }
  };

  const updateMerchologist = (
    groupOrderId: string,
    member: string,
    isMerchstore: boolean
  ) => {
    axios
      .post('/.netlify/functions/updateMerchologist', {
        groupOrderId,
        newMerchologist: member,
        isMerchstore,
      })
      .then((res) => {
        if (res.data) {
          if (isMerchstore) {
            fetchMerchstores();
          }
          setCurrentMerch(member);
        } else {
          console.log(`No order found with for ID: ${groupOrderId}`);
          setOrderError(true);
        }
      })
      .catch((err) => {
        console.error('Malformed Order ID', err);
        setOrderError(true);
      });

    setOpen(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      closeList();
    }
  };

  return (
    <>
      <div
        className={`reassign-container ${listOpen ? 'open' : ''}`}
        id={''}
        ref={wrapperRef}
      >
        <button
          type="button"
          className="reassign-header font-medium text-merch-dark-gray"
          onClick={toggleList}
        >
          <div className="reassign-header-title">{title}</div>
          {!listOpen ? (
            <div>
              <MdArrowDropDown />
            </div>
          ) : (
            <div>
              <MdArrowDropUp />
            </div>
          )}
        </button>
        {listOpen && list && list.length > 0 ? (
          <div
            role="list"
            className={`${list.length > 5 && 'scroll'} reassign-list absolute`}
          >
            {list.map((item: any) => (
              <div
                role="listitem"
                onClick={() => selectItem(item)}
                className="reassign-item-container"
                key={generateReactKey(item.id)}
              >
                <div
                  className={`reassign-list-item font-medium text-merch-dark-gray`}
                >
                  {item.email}{' '}
                </div>
                <div>
                  {chosenMerchologist === item.email && (
                    <MdDone color="#FF671D" />
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          listOpen && toast.error('No Merchologists found. Try again later')
        )}
      </div>
      {!listOpen ? (
        <div className="button-container">
          <CTA
            size="large"
            type="primary"
            onClick={() =>
              updateMerchologist(
                currentOrderId,
                chosenMerchologist,
                isMerchstore
              )
            }
          >
            Reassign
          </CTA>
          <span
            style={{ color: 'rgba(75, 75, 85, 0.5)', cursor: 'pointer' }}
            className="w-10/12 px-3.5 py-1 md:py-2.5 mt-2 text-14 font-bold text-center"
            onClick={() => setOpen(false)}
          >
            Cancel
          </span>
        </div>
      ) : (
        <div className="button-container">
          {/* Mobile Needs this for the dropdown to be able to scroll */}
          <span className="w-10/12 px-3.5 py-1 md:py-2.5 mt-6 text-14 font-bold text-center">
            {' '}
            _{' '}
          </span>
        </div>
      )}
    </>
  );
};
