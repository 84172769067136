const ImageEdge = {
  name: 'ImageEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Image',
  },
  implementsNode: false,
};
export default ImageEdge;
