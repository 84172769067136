import { UseQueryResult, useQuery } from 'react-query';

import { CountriesAndStates } from '@merchstores/admin/types/address';
import { QueryKey } from '@merchstores/admin/types/reactQuery';
import { fetchShippingAddressForm } from '../../api/hubspot';

function transformFormData(data: any) {
  const result = {
    countries: [],
    states: [],
  };
  for (const fieldGroup of data?.formFieldGroups) {
    if (result.countries.length > 0 && result.states.length > 0) {
      break;
    }
    if (fieldGroup?.fields?.[0]?.name === 'group_order_tool_shipping_state') {
      result.states = fieldGroup?.fields?.[0]?.options;
    }
    if (fieldGroup?.fields?.[0]?.name === 'group_order_tool_shipping_country') {
      result.countries = fieldGroup?.fields?.[0]?.options;
    }
  }
  return result;
}

export default function useAddressCountriesAndStates(): UseQueryResult<CountriesAndStates> {
  return useQuery({
    queryKey: [QueryKey.SHIPPING_ADDRESS_FORM_DATA],
    queryFn: () => fetchShippingAddressForm(),
    select: transformFormData,
  });
}
