import './styles.scss';
import { MdErrorOutline, MdHelpOutline } from 'react-icons/md';
import { Tooltip } from '../Tooltip';
import { FormSelect } from '../FormSelect';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

export interface ISchedulerOption {
  displayText: string;
  value: string;
}

export interface ISchedulerInputProps {
  frequencyDefaultValue?: string;
  dateInputDefaultValue?: string;
  label?: string;
  frequencyLabel?: string;
  frequencyName: string;
  dateInputLabel?: string;
  dateInputName: string;
  required: boolean;
  errors: any;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  onChange?: (ev: any) => any;
  inputStyle?: string;
  tooltip?: string | HTMLElement | JSX.Element;
  helpText?: string;
  placeholder?: string;
  id?: string;
  disabled?: boolean;
  textArea?: boolean;
  halfWidth?: boolean;
  min?: string;
  max?: string;
  options: ISchedulerOption[];
}

export const SchedulerInput = (props: ISchedulerInputProps) => {
  return (
    <div className="mb-5">
      <div className={'form-input-container flex flex-col w-full form-input'}>
        <div className="flex justify-between mb-2">
          <div className="flex items-center">
            {props.label && (
              <label
                htmlFor={props.frequencyName}
                className="text-sm text-merch-dark-gray font-bold"
              >
                {props.label}
              </label>
            )}
            {props.tooltip && (
              <Tooltip content={props.tooltip}>
                <div className="opacity-50 ml-2">
                  <MdHelpOutline />
                </div>
              </Tooltip>
            )}
          </div>
          {props.helpText && (
            <div className="text-xs italic text-merch-dark-gray opacity-50 mr-3">
              {props.helpText}
            </div>
          )}
        </div>
      </div>

      <div className="flex">
        <div className="mr-10 w-1/2">
          <div>
            {props.dateInputLabel && (
              <label htmlFor={props.dateInputName} className={`textbox-label`}>
                {props.dateInputLabel}
              </label>
            )}
          </div>
          <input
            id={props.id}
            type="date"
            min={props.min}
            max={props.max}
            disabled={props.disabled}
            placeholder={props.placeholder ? props.placeholder : ''}
            {...props.register(props.dateInputName, {
              required: props.required,
            })}
            defaultValue={
              props.dateInputDefaultValue ? props.dateInputDefaultValue : ''
            }
            className={
              `form-input input-type-two bg-white text-sm font-medium group-order-form-select ` +
              `h-full p-3 pr-1 pt-20 border border-gray-300 rounded-md relative next-processing-date
                ${props.disabled ? 'opacity-50' : ''}
                `
            }
          />
        </div>

        <FormSelect
          name={props.frequencyName}
          register={props.register}
          errors={props.errors}
          setValue={props.setValue}
          label="Processes"
          emptyText=""
          tooltip="."
          helpText="Required"
          default={
            props.frequencyDefaultValue
              ? props.frequencyDefaultValue
              : 'Monthly'
          }
          options={props.options ? props.options : []}
          disabled={props.disabled}
          selectStyle="two"
          halfWidth={true}
          onChange={props.onChange}
        />
      </div>
      {props.errors[props.frequencyName] &&
        Object.keys(props.errors[props.frequencyName]).length > 0 && (
          <div className="flex">
            {props.errors[props.frequencyName]?.type === 'required' ? (
              <span className="error">{props.frequencyLabel} is required</span>
            ) : props.errors[props.frequencyName]?.types?.custom ? (
              <span className="error">
                {props.errors[props.frequencyName]?.types.custom}
              </span>
            ) : (
              <span className="error">
                {props.errors[props.frequencyName]?.message}
              </span>
            )}
            <MdErrorOutline />
          </div>
        )}
      {props.errors[props.dateInputName] &&
        Object.keys(props.errors[props.dateInputName]).length > 0 && (
          <div className="flex">
            {props.errors[props.dateInputName]?.type === 'required' ? (
              <span className="error">{props.dateInputLabel} is required</span>
            ) : props.errors[props.dateInputName]?.types?.custom ? (
              <span className="error">
                {props.errors[props.dateInputName]?.types.custom}
              </span>
            ) : (
              <span className="error">
                {props.errors[props.dateInputName]?.message}
              </span>
            )}
            <MdErrorOutline />
          </div>
        )}
    </div>
  );
};
