const Collection = {
  name: 'Collection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    description: 'String',
    descriptionHtml: 'HTML',
    handle: 'String',
    id: 'ID',
    image: 'Image',
    products: 'ProductConnection',
    title: 'String',
    updatedAt: 'DateTime',
  },
  implementsNode: true,
};
export default Collection;
