const OrderEdge = {
  name: 'OrderEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Order',
  },
  implementsNode: false,
};
export default OrderEdge;
