import { useState, useRef, useEffect } from 'react';
import ColorPicker from 'react-pick-color';
import { MdErrorOutline, MdHelpOutline } from 'react-icons/md';
import { IColorPickerProps } from '.';
import { Tooltip } from '../Tooltip';
import './styles.scss';

export const ColorPickerInput = (props: IColorPickerProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(
    props.defaultValue ? props.defaultValue : '#000000'
  );
  const [isInvalidColor, setIsInvalidColor] = useState(false);

  useEffect(() => {
    setColor(props.defaultValue ? props.defaultValue : '#000000');
  }, [props.defaultValue]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setDisplayColorPicker(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const onColorChange = (newColor: string) => {
    setColor(newColor);
    const inputElement = document.getElementById(props.id) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = newColor;
      inputElement.dispatchEvent(new Event('focus', { bubbles: true }));
      inputElement.dispatchEvent(new Event('change', { bubbles: true }));
      setIsInvalidColor(false);
    }
    if (props.onChange) {
      props.onChange(newColor);
    }
  };
  //onColorChange(color);

  const onFocus = () => {
    setDisplayColorPicker(false);
  };

  const onBlur = (value: string) => {
    // Validate if the value is a valid Hex Color on #xxxxxx
    if (/^#[0-9A-F]{6}$/i.test(value)) {
      setIsInvalidColor(false);
      onColorChange(value);
    } else {
      setIsInvalidColor(true);
    }
  };

  const handleClick = () => {
    if (!props.disabled) {
      setDisplayColorPicker(!displayColorPicker);
    }
  };

  return (
    <div className="mb-20 w-full">
      <div className="flex justify-between mb-2">
        <div className="flex items-center">
          {props.label && (
            <label
              htmlFor={props.name}
              className="text-sm text-merch-dark-gray font-bold"
            >
              {props.label}
            </label>
          )}
          {props.tooltip && (
            <Tooltip content={props.tooltip}>
              <div className="opacity-50 ml-2">
                <MdHelpOutline />
              </div>
            </Tooltip>
          )}
        </div>
        {props.helpText && (
          <div className="text-xs italic text-merch-dark-gray opacity-50 mr-3">
            {props.helpText}
          </div>
        )}
      </div>

      <div className="relative inline-block w-full">
        <input
          id={props.id}
          type="text"
          disabled={props.disabled}
          placeholder={props.placeholder ? props.placeholder : ''}
          {...props.register(props.name, { required: props.required })}
          defaultValue={props.defaultValue ? props.defaultValue : ''}
          className={`
                        form-input
                        ${props.disabled ? 'opacity-50' : ''}
                        ${
                          props.errors[props.name] &&
                          Object.keys(props.errors[props.name]).length > 0
                            ? 'error'
                            : ''
                        }
                                ${props.label ? '' : 'no-label'} 
                        input-type-two bg-white text-sm font-medium group-order-form-select p-3 pl-45 border border-gray-300 rounded-md relative w-full
                        `}
          onFocus={onFocus}
          onBlur={(ev: any) => onBlur(ev.target.value)}
        />
        <div onClick={handleClick} className="w-50 h-50 absolute top-0 left-0">
          <div
            style={{ backgroundColor: color }}
            className="w-20 h-20 border border-gray-300 rounded-md absolute top-15 left-15"
          ></div>
        </div>
      </div>
      {displayColorPicker ? (
        <div className="popover" ref={wrapperRef}>
          <ColorPicker
            color={color}
            onChange={(color) => onColorChange(color.hex)}
            hideAlpha={true}
            hideInputs={true}
          />
        </div>
      ) : null}

      {props.errors[props.name] &&
        Object.keys(props.errors[props.name]).length > 0 && (
          <>
            {props.errors[props.name]?.type === 'required' ? (
              <span className="error">{props.label} is required</span>
            ) : props.errors[props.name]?.types?.custom ? (
              <span className="error">
                {props.errors[props.name]?.types.custom}
              </span>
            ) : (
              <span className="error">{props.errors[props.name]?.message}</span>
            )}
            <MdErrorOutline />
          </>
        )}

      {isInvalidColor && (
        <div className="flex center">
          <span className="error">
            Please enter a valid color in #xxxxxx format.{' '}
          </span>
          <div className="text-red-800">
            <MdErrorOutline />
          </div>
        </div>
      )}
    </div>
  );
};
