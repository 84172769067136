const MetafieldEdge = {
  name: 'MetafieldEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Metafield',
  },
  implementsNode: false,
};
export default MetafieldEdge;
