import { History } from 'history';
import { toast } from 'react-toastify';
import { exportGOTRecipientsToNetsuite } from '../Netsuite/GroupOrderToolRecipients';

const EXPORT_TO_NETSUITE_ALLOWED_ROLES = ['SUPERUSER', 'MERCHOLOGIST'];
const DOWNLOAD_CSV_ALLOWED_ROLES = ['ADMIN', 'SUPERUSER', 'MERCHOLOGIST'];

export function resolveDropdownOptions(
  groupOrder: { groupOrderId: string; status: string },
  userRole: string,
  userEmail: string,
  history: History,
  dropDownOptions: any[]
) {
  const groupOrderId = groupOrder.groupOrderId;

  if (groupOrder.status !== 'closed') {
    return dropDownOptions;
  }

  const resolvedDropdownOptions = [
    {
      title: 'View/Edit',
      id: `${groupOrderId}-view`,
      action: () => {
        history.push(`/admin/group-orders/${groupOrderId}`);
      },
    },
  ];

  if (DOWNLOAD_CSV_ALLOWED_ROLES.includes(userRole)) {
    resolvedDropdownOptions.push({
      title: 'Download CSV',
      id: `${groupOrderId}-download-csv`,
      action: () => {
        toast.success(`Downloading recipients csv...`);
        window.open(
          `/.netlify/functions/downloadRecipientsCsv?orderId=${groupOrderId}`
        );
      },
    });
  }

  if (EXPORT_TO_NETSUITE_ALLOWED_ROLES.includes(userRole)) {
    resolvedDropdownOptions.push({
      title: 'Export to Netsuite',
      id: `${groupOrderId}-export-netsuite`,
      action: () => {
        exportGOTRecipientsToNetsuite(groupOrderId, userEmail);
      },
    });
  }

  return resolvedDropdownOptions;
}

export default {
  resolveDropdownOptions,
};
