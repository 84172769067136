const QueryRoot = {
  name: 'QueryRoot',
  kind: 'OBJECT',
  fieldBaseTypes: {
    articles: 'ArticleConnection',
    blogByHandle: 'Blog',
    blogs: 'BlogConnection',
    collectionByHandle: 'Collection',
    collections: 'CollectionConnection',
    customer: 'Customer',
    node: 'Node',
    nodes: 'Node',
    pageByHandle: 'Page',
    pages: 'PageConnection',
    productByHandle: 'Product',
    productRecommendations: 'Product',
    productTags: 'StringConnection',
    productTypes: 'StringConnection',
    products: 'ProductConnection',
    publicApiVersions: 'ApiVersion',
    shop: 'Shop',
  },
  implementsNode: false,
};
export default QueryRoot;
