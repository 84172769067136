const FulfillmentLineItemEdge = {
  name: 'FulfillmentLineItemEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'FulfillmentLineItem',
  },
  implementsNode: false,
};
export default FulfillmentLineItemEdge;
