const ProductVariant = {
  name: 'ProductVariant',
  kind: 'OBJECT',
  fieldBaseTypes: {
    available: 'Boolean',
    availableForSale: 'Boolean',
    compareAtPrice: 'Money',
    compareAtPriceV2: 'MoneyV2',
    currentlyNotInStock: 'Boolean',
    id: 'ID',
    image: 'Image',
    metafield: 'Metafield',
    metafields: 'MetafieldConnection',
    presentmentPrices: 'ProductVariantPricePairConnection',
    presentmentUnitPrices: 'MoneyV2Connection',
    price: 'Money',
    priceV2: 'MoneyV2',
    product: 'Product',
    quantityAvailable: 'Int',
    requiresShipping: 'Boolean',
    selectedOptions: 'SelectedOption',
    sku: 'String',
    title: 'String',
    unitPrice: 'MoneyV2',
    unitPriceMeasurement: 'UnitPriceMeasurement',
    weight: 'Float',
    weightUnit: 'WeightUnit',
  },
  implementsNode: true,
};
export default ProductVariant;
