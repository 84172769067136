const CreditCard = {
  name: 'CreditCard',
  kind: 'OBJECT',
  fieldBaseTypes: {
    brand: 'String',
    expiryMonth: 'Int',
    expiryYear: 'Int',
    firstDigits: 'String',
    firstName: 'String',
    lastDigits: 'String',
    lastName: 'String',
    maskedNumber: 'String',
  },
  implementsNode: false,
};
export default CreditCard;
