import axios from 'axios';

const MERCHSTORES_FETCH_GIFT_CARDS_STATUS_ROUTE =
  '/.netlify/functions/fetchMerchStoreGiftCardsSummary';

const giftCardsSummaryCache = new Map<string, IRequestGiftCardsSummaryResult>();

interface IRequestGiftCardsSummaryParameters {
  storeCode: string;
}

export interface IRequestGiftCardsSummaryResult {
  success: boolean;
  balanceSummary: {
    totalAssignableBalance: string;
    totalDisabledBalance: string;
    totalRedeemedBalance: string;
    totalAssignedBalance: string;
    totalRemainingBalance: string;
    totalPurchasedBalance: string;
    totalUnassignedBalance: string;
  };
  availableGiftCards: {
    cardValue: string;
    cardCount: number;
  }[];
}

export async function requestGiftCardsSummary(
  parameters: IRequestGiftCardsSummaryParameters
): Promise<IRequestGiftCardsSummaryResult> {
  const response = await axios.post(
    MERCHSTORES_FETCH_GIFT_CARDS_STATUS_ROUTE,
    parameters
  );

  return response.data as IRequestGiftCardsSummaryResult;
}

export async function getGiftCardsSummary(
  parameters: IRequestGiftCardsSummaryParameters
): Promise<IRequestGiftCardsSummaryResult> {
  const cachedResult = giftCardsSummaryCache.get(parameters.storeCode);

  if (cachedResult) {
    return cachedResult;
  }

  return requestGiftCardsSummary(parameters).then((result) => {
    giftCardsSummaryCache.set(parameters.storeCode, result);
    return result;
  });
}
