import { UseMutationResult, useMutation } from 'react-query';
import { useContext } from 'react';

import { updateShippingAddress } from '@merchstores/admin/api/hubspot';
import { GroupOrderAddress } from '@merchstores/admin/types/address';
import { StoreContext } from '@merchstores/admin/context/Store';

type UseUpdateShippingAddressResult = UseMutationResult<
  string,
  unknown,
  Partial<GroupOrderAddress>,
  unknown
>;

export default function useUpdateShippingAddress(): UseUpdateShippingAddressResult {
  const {
    state: { shippingAddresses },
    setIsShippingAddressesLoading,
    setShippingAddresses,
  } = useContext(StoreContext);

  return useMutation({
    mutationFn: ({ _id, ...input }: Partial<GroupOrderAddress>) => {
      setIsShippingAddressesLoading(true);
      return updateShippingAddress(_id || '', input);
    },
    onSuccess: (_data, variable) => {
      const updatedShippingAddresses = shippingAddresses
        ?.map((address: GroupOrderAddress) => {
          if (address._id === variable._id) {
            return variable.isDeleted ? undefined : { ...address, ...variable };
          }
          return variable.isDefault
            ? { ...address, isDefault: false }
            : address;
        })
        ?.filter((address: GroupOrderAddress) => address);
      setShippingAddresses(updatedShippingAddresses);
      return setIsShippingAddressesLoading(false);
    },
  });
}
