//export const dropdownOptions = [ (removed temorarily: Custom Gift Card Values)
//  {
//    displayText: '$250',
//    value: '$250',
//  },
//  {
//    displayText: '$200',
//    value: '$200',
//  },
//  {
//    displayText: '$150',
//    value: '$150',
//  },
//  {
//    displayText: '$100',
//    value: '$100',
//  },
//  {
//    displayText: '$75',
//    value: '$75',
//  },
//  {
//    displayText: '$50',
//    value: '$50',
//  },
//  {
//    displayText: '$25',
//    value: '$25',
//  },
//];

export const dropdownOptions = [
  { displayText: '$5', value: '$5' },
  { displayText: '$10', value: '$10' },
  { displayText: '$15', value: '$15' },
  { displayText: '$20', value: '$20' },
  { displayText: '$25', value: '$25' },
  { displayText: '$30', value: '$30' },
  { displayText: '$35', value: '$35' },
  { displayText: '$40', value: '$40' },
  { displayText: '$45', value: '$45' },
  { displayText: '$50', value: '$50' },
  { displayText: '$55', value: '$55' },
  { displayText: '$60', value: '$60' },
  { displayText: '$65', value: '$65' },
  { displayText: '$70', value: '$70' },
  { displayText: '$75', value: '$75' },
  { displayText: '$80', value: '$80' },
  { displayText: '$85', value: '$85' },
  { displayText: '$90', value: '$90' },
  { displayText: '$95', value: '$95' },
  { displayText: '$100', value: '$100' },
  { displayText: '$125', value: '$125' },
  { displayText: '$150', value: '$150' },
  { displayText: '$175', value: '$175' },
  { displayText: '$200', value: '$200' },
  { displayText: '$250', value: '$250' },
  { displayText: '$300', value: '$300' },
  { displayText: '$350', value: '$350' },
  { displayText: '$400', value: '$400' },
  { displayText: '$450', value: '$450' },
  { displayText: '$500', value: '$500' },
  { displayText: '$750', value: '$750' },
  { displayText: '$1000', value: '$1000' },
  { displayText: '$1250', value: '$1250' },
  { displayText: '$1500', value: '$1500' },
  { displayText: '$1750', value: '$1750' },
  { displayText: '$2000', value: '$2000' },
];
