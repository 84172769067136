const CheckoutAttributesUpdateInput = {
  name: 'CheckoutAttributesUpdateInput',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    note: 'String',
    customAttributes: 'AttributeInput',
    allowPartialAddresses: 'Boolean',
  },
};
export default CheckoutAttributesUpdateInput;
