type TabsProps = {
  children?: any;
};

export const Tabs = (props: TabsProps) => {
  return (
    <div className="flex border border-gray-300 rounded-lg overflow-hidden">
      {props.children}
    </div>
  );
};

type TabProps = {
  id: number;
  active?: boolean;
  children?: any;
  onClick: any;
  show?: boolean;
};

export const Tab = (props: TabProps) => {
  if (props.show === false) {
    return null;
  }
  return (
    <button
      id={`${props.id}`}
      onClick={props.onClick}
      className={`
        py-2.5
        text-13
        text-center
        font-bold
        cursor-pointer
        lg:text-base
        lg:py-2.5 px-3
        text-gray-700
        lg:py-2.5 px-3
        ${!props.active ? 'bg-gray-100 opacity-50' : ''}
        
      `}
    >
      {props.children}
    </button>
  );
};

type TabBrProps = {
  show?: boolean;
};

export const TabBr = (props: TabBrProps) => {
  if (props.show === false) {
    return null;
  }
  return <div className="flex border-0 border-r border-gray-300"></div>;
};

type ContentProps = {
  active?: boolean;
  children?: any;
};

export const Content = (props: ContentProps) => {
  return (
    <div className={`${props.active ? '' : 'hidden'}`}>{props.children}</div>
  );
};
