const CUSTOM_INVENTORY_ROLE_TAGS = [
  'custom_inventory_merchandising',
  'custom_inventory_merchandiser',
  'role=merchandising',
];

export function hasSessionCustomInventoryAccess(): boolean {
  return localStorage.getItem('customInventoryManagementAccess') === 'true';
}

export function setSessionCustomInventoryAccess(
  accessEnabled: boolean
): boolean {
  if (accessEnabled) {
    localStorage.setItem(
      'customInventoryManagementAccess',
      String(accessEnabled)
    );
    return true;
  }

  localStorage.removeItem('customInventoryManagementAccess');
  return false;
}

export function clearSessionCustomInventoryData(): void {
  localStorage.removeItem('customInventoryManagementAccess');
}

export function hasCustomInventoryManagementTag(
  customerTags: string[]
): boolean {
  return Boolean(
    customerTags.find((tag: string) => {
      return CUSTOM_INVENTORY_ROLE_TAGS.includes(tag);
    })
  );
}
