const MediaEdge = {
  name: 'MediaEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'Media',
  },
  implementsNode: false,
};
export default MediaEdge;
