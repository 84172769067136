const CheckoutLineItemsReplacePayload = {
  name: 'CheckoutLineItemsReplacePayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    checkout: 'Checkout',
    userErrors: 'CheckoutUserError',
  },
  implementsNode: false,
};
export default CheckoutLineItemsReplacePayload;
