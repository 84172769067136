import { IOrderDetailsView } from '@merchstores/admin/types/merchstoreOrder';
import { ITableDataProps, Table } from '@merchstores/shared/elements/Table';
import { getLocalDateText } from '@merchstores/admin/components/DateTime';
import { extractAttribute } from '@merchstores/admin/lib/shopifyOrders';
import { IShopifyOrder } from '@merchstores/admin/types/shopifyOrder';
import { TableCellAlign } from '@merchstores/admin/types/tableStyles';
import { MerchStoreTab } from '@merchstores/admin/types/merchstore';
import { moneyFormat } from '@merchstores/shared/components/Money';
import TextTableCell from './TextTableCell';
import ActionTabCell from './ActionTabCell';

interface Props {
  storeCode: string;
  tab: MerchStoreTab;
  orders: IShopifyOrder[];
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
}

const buildTableTitle = (tab?: MerchStoreTab): string => {
  return tab === MerchStoreTab.ORDERS
    ? 'Gift Card Order History'
    : 'Gift Card Order History';
};

const buildTableData = (
  orders: IShopifyOrder[],
  storeCode: string,
  tab: MerchStoreTab,
  setSelectedOrderDetails: (orderDetailsView: IOrderDetailsView) => void
): ITableDataProps[] => {
  return orders.map((order: IShopifyOrder) => {
    const unitAmountValue =
      extractAttribute(order.customAttributes, 'gift_card_value') || '0';
    const quantity =
      extractAttribute(order.customAttributes, 'gift_card_quantity') || '0';
    const createdDate = getLocalDateText(order.createdAt) || 'N/A';
    const isGiftCardsTab = tab === MerchStoreTab.GIFT_CARS;
    const data: Record<string, unknown> = {
      orderName: {
        desktopOnly: false,
        value: <TextTableCell text={order.name} />,
      },
      createdDate: {
        desktopOnly: false,
        value: (
          <TextTableCell text={createdDate} align={TableCellAlign.CENTER} />
        ),
      },
      quantity: {
        desktopOnly: true,
        value: <TextTableCell text={quantity} align={TableCellAlign.CENTER} />,
      },
      unitAmount: {
        desktopOnly: true,
        value: (
          <TextTableCell
            text={moneyFormat(unitAmountValue)}
            align={TableCellAlign.CENTER}
          />
        ),
      },
      totalAmount: {
        desktopOnly: false,
        value: (
          <TextTableCell
            text={moneyFormat(order.totalPrice)}
            align={
              isGiftCardsTab ? TableCellAlign.CENTER : TableCellAlign.RIGHT
            }
          />
        ),
      },
    };
    if (isGiftCardsTab) {
      data['action'] = {
        desktopOnly: false,
        value: (
          <ActionTabCell
            order={order}
            storeCode={storeCode}
            setSelectedOrderDetails={setSelectedOrderDetails}
          />
        ),
      };
    }
    return {
      tr: {
        class: 'gift-cards-list',
        data,
      },
    } as unknown as ITableDataProps;
  });
};

const History = (props: Props): JSX.Element => {
  const { tab, orders, storeCode, setSelectedOrderDetails } = props;
  const tableData = buildTableData(
    orders,
    storeCode,
    tab,
    setSelectedOrderDetails
  );
  const title = buildTableTitle(tab);

  return (
    <div>
      <p className="font-bold text-lg mr-3 mb-3 pl-6 md:pl-0">{title}</p>
      <div className="rounded-lg border border-gray-200 mx-6 sm:mx-0">
        <Table
          data={tableData}
          itemsName="orders"
          name="gift-card-order-history"
          elementsPerPage={50}
        />
      </div>
    </div>
  );
};

export default History;
