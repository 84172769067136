const CommentConnection = {
  name: 'CommentConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'CommentEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default CommentConnection;
