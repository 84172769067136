const ProductVariantPricePair = {
  name: 'ProductVariantPricePair',
  kind: 'OBJECT',
  fieldBaseTypes: {
    compareAtPrice: 'MoneyV2',
    price: 'MoneyV2',
  },
  implementsNode: false,
};
export default ProductVariantPricePair;
