import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { listCombinedOrders } from '@merchstores/admin/components/MerchStoreOrders/CombinedOrders';
import {
  formatLocalShortDate,
  parseIsoDate,
} from '@merchstores/admin/lib/DateTime';
import { IOrderDetailsView } from '@merchstores/admin/types/merchstoreOrder';
import { ACTIVE_ORDER_ID } from '@merchstores/admin/components/MerchStoreOrders';
import { ICombinedOrder } from '@merchstores/admin/types/shopifyCombinedOrder';
import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { Badge, BadgeType } from '@merchstores/shared/elements/Badge';
import { moneyFormat } from '@merchstores/shared/components/Money';
import { Loading } from '@merchstores/shared/components/Loading';
import { Table } from '@merchstores/shared/elements/Table';
import OrderCalculator from '@merchstores/admin/components/MerchStoreOrders/OrderCalculator';
import MerchStoreOrderListDropdown from './MerchStoreOrderListDropdown';
import './styles.scss';

export interface IMerchStoreOrderListProps {
  merchStore: IMerchStore;
  isMobile?: boolean;
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
  isLoading?: boolean;
  selectedOrderId: string;
}

export const MerchStoreOrderList: React.FC<IMerchStoreOrderListProps> = (
  props: IMerchStoreOrderListProps
) => {
  const location = useLocation();
  const history = useHistory();
  const merchStore = props.merchStore;
  const { setSelectedOrderDetails, selectedOrderId } = props;
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [combinedOrdersList, setCombinedOrdersList] = useState(null);

  const ORDERS_DISPLAY_PER_PAGE = 10;

  useEffect(() => {
    listCombinedOrders(merchStore)
      .catch((err) => {
        console.error('Error fetching orders:', err);
      })
      .then((combinedOrdersList) => {
        setCombinedOrdersList(combinedOrdersList);
        setLoading(false);
        // If the path is is /merchstores/[storeCode]/orders/[orderId] redirect to the specific Order details page
        if (selectedOrderId && combinedOrdersList) {
          const orderSelected = combinedOrdersList.orders.find(
            (order) => order.name === `#${selectedOrderId}`
          );
          if (orderSelected) {
            setSelectedOrderDetails({
              viewType: 'combined_order',
              combinedOrder: orderSelected,
              individualOrder: null,
            });
          }
        } else {
          // If the path is is /orders/active redirect to the Active orders
          const isOrdersActivePath =
            location.pathname.includes('/orders/active');
          if (isOrdersActivePath && combinedOrdersList) {
            setSelectedOrderDetails({
              viewType: 'combined_order',
              combinedOrder: combinedOrdersList.orders[0],
              individualOrder: null,
            });
          }
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchStore]);

  useEffect(() => {
    if (!combinedOrdersList) {
      return;
    }
    const storeOrdersData = combinedOrdersList.orders.map(
      (order: ICombinedOrder) => {
        const data: any = {};

        data.name = {
          desktopOnly: false,
          value: (
            <div
              className="flex items-center ml-20 w-50 md:w-200 cursor-pointer"
              onClick={() => {
                setSelectedOrderDetails({
                  viewType: 'combined_order',
                  combinedOrder: order,
                  individualOrder: null,
                });
                if (order.id === ACTIVE_ORDER_ID) {
                  history.push(
                    `/admin/merchstores/${merchStore.storeCode}/orders/active`
                  );
                } else {
                  history.push(
                    `/admin/merchstores/${merchStore.storeCode}/orders/${order.name.replace('#', '')}`
                  );
                }
              }}
            >
              {order.name}
            </div>
          ),
        };

        data.orderStatus = {
          desktopOnly: false,
          value: (
            <Badge type={order.status.toLowerCase() as BadgeType}>
              {order.status}
            </Badge>
          ),
        };

        data.creationDate = {
          desktopOnly: true,
          value: (
            <div className="mx-10">
              {order.createdAt
                ? formatLocalShortDate(parseIsoDate(order.createdAt))
                : ''}
            </div>
          ),
        };

        const isActiveOrder = order.id === ACTIVE_ORDER_ID;
        const totalPrice = isActiveOrder
          ? order.totalPrice
          : OrderCalculator.calculateSummaryTotal(order);

        data.totalPrice = {
          desktopOnly: true,
          value: <div className="text-right">{moneyFormat(totalPrice)}</div>,
        };

        const dropDownOptions =
          MerchStoreOrderListDropdown.resolveDropdownOptions(
            merchStore.storeCode,
            order,
            setSelectedOrderDetails,
            history
          );
        data.actions = {
          desktopOnly: false,
          value: <ActionDropdown title="Actions" list={dropDownOptions} />,
        };

        return {
          tr: {
            class: 'order-list',
            data,
          },
        };
      }
    );
    setOrdersData(storeOrdersData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedOrdersList]);

  if (loading) {
    return <Loading isLoading={loading as boolean} />;
  }

  return (
    <div className="order-list-container mx-6 sm:mx-0">
      {ordersData.length ? (
        <Table
          itemsName="orders"
          name="orders"
          data={ordersData}
          displayFooter={ordersData.length > ORDERS_DISPLAY_PER_PAGE}
          totalCount={ordersData.length}
          elementsPerPage={ORDERS_DISPLAY_PER_PAGE}
          usePagination={true}
        />
      ) : (
        <div className="bg-merch-background-gray px-3 py-6 lg:p-6 rounded-md flex justify-between items-center">
          <div className="text-13 italic text-merch-dark-gray opacity-50 font-medium">
            Showing 0 of 0 orders
          </div>
        </div>
      )}
    </div>
  );
};
