const CheckoutLineItemEdge = {
  name: 'CheckoutLineItemEdge',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cursor: 'String',
    node: 'CheckoutLineItem',
  },
  implementsNode: false,
};
export default CheckoutLineItemEdge;
