const __InputValue = {
  name: '__InputValue',
  kind: 'OBJECT',
  fieldBaseTypes: {
    defaultValue: 'String',
    deprecationReason: 'String',
    description: 'String',
    isDeprecated: 'Boolean',
    name: 'String',
    type: '__Type',
  },
  implementsNode: false,
};
export default __InputValue;
