const CheckoutLineItemUpdateInput = {
  name: 'CheckoutLineItemUpdateInput',
  kind: 'INPUT_OBJECT',
  inputFieldBaseTypes: {
    id: 'ID',
    variantId: 'ID',
    quantity: 'Int',
    customAttributes: 'AttributeInput',
  },
};
export default CheckoutLineItemUpdateInput;
