import { BigNumber } from 'bignumber.js';
import { IMerchStoreProps } from '.';

import { buildDropdowns } from './MerchStoreListDropdownOptions';
import { cloudinaryApplyWidthHeight } from '@merchstores/shared/components/Cloudinary';
import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import { Badge, BadgeType } from '@merchstores/shared/elements/Badge';
import { Logo } from '@merchstores/shared/elements/Logo';
import { getLocalDateText } from '@merchstores/admin/components/DateTime';
import { IMerchStoreActions } from './MerchStoreActions';
import { CTA } from '@merchstores/shared/elements/Cta';

export interface ITableDisplayColumns {
  logo?: boolean;
  groupAdmin?: boolean;
  assignedTo?: boolean;
  payments?: boolean;
  orderCount?: boolean;
  pendingOrdersCount?: boolean;
  pendingRevenue?: boolean;
  revenue?: boolean;
  closeDate?: boolean;
  status?: boolean;
  pendingOrdersIndicator?: boolean;
  actions?: boolean;
}

const PayerLabels = new Map<string, string>(
  Object.entries({
    admin_payer: 'Admin',
    individual_payer: 'Individual',
  })
);

export function makeMerchStoreRow(
  merchstore: IMerchStoreProps,
  userRole: string,
  actions: IMerchStoreActions,
  displayColumns?: ITableDisplayColumns
): any {
  displayColumns = displayColumns || {};

  const contactEmail =
    merchstore.contactInfo?.email || merchstore.customerAdmins[0] || '';

  let formattedRevenue = '$0.00';

  if (merchstore.revenue) {
    const revenueString = new BigNumber(merchstore.revenue);
    formattedRevenue = `$${revenueString.toFormat(2)}`;
  }

  let formattedPendingRevenue = '$0.00';

  if (merchstore?.individualOrdersPendingRevenue) {
    const pendingRevenue = new BigNumber(
      merchstore.individualOrdersPendingRevenue
    );
    formattedPendingRevenue = `$${pendingRevenue.toFormat(2)}`;
  }

  const numOrders = merchstore?.individualOrdersCount || 0;
  const individualOrdersPendingCount =
    merchstore?.individualOrdersPendingCount || 0;

  const dropDownOptions: any = buildDropdowns(userRole, merchstore, actions);

  const hasPendingOrder = merchstore.hasPendingOrder;

  const data: any = {};
  const optimizedLogo = cloudinaryApplyWidthHeight(
    merchstore.storeLogo,
    '72',
    '72'
  );
  data.logo = {
    desktopOnly: false,
    value: (
      <a
        href={`/admin/merchstores/${merchstore.storeCode}`}
        className="flex whitespace-nowrap w-200 items-center"
      >
        {optimizedLogo ? (
          <div className="w-12 h-12 mr-2 object-contain">
            <Logo size="small" imgUrl={optimizedLogo} />
          </div>
        ) : (
          <></>
        )}
        <div className="text-left font-bold text-merch-dark-gray truncate">
          {merchstore.name}
        </div>
      </a>
    ),
  };

  data.groupAdmin = {
    desktopOnly: true,
    value: (
      <div className="text-left pl-0 font-bold text-merch-dark-gray">
        {contactEmail}
      </div>
    ),
  };

  if (userRole === 'SUPERUSER' || userRole === 'MERCHOLOGIST') {
    data.assignedTo = {
      desktopOnly: true,
      value: (
        <div className="text-left font-bold text-merch-dark-gray opacity-50">
          {merchstore.merchologist}
        </div>
      ),
    };
  }

  data.storeId = {
    desktopOnly: true,
    value: (
      <div className="text-left pr-2 font-bold text-merch-dark-gray opacity-50">
        {merchstore.storeCode}
      </div>
    ),
  };

  data.payments = {
    desktopOnly: true,
    value: (
      <div className="font-bold text-merch-dark-gray">
        {PayerLabels.get(merchstore.payerType) || merchstore.payerType}
      </div>
    ),
  };

  data.orderCount = {
    desktopOnly: true,
    value: <div className="font-bold text-merch-dark-gray">{numOrders}</div>,
  };

  data.pendingOrdersCount = {
    desktopOnly: true,
    value: (
      <div className="font-bold text-merch-dark-gray">
        {individualOrdersPendingCount}
      </div>
    ),
  };

  data.revenue = {
    desktopOnly: true,
    value: (
      <div className="font-bold text-merch-dark-gray">{formattedRevenue}</div>
    ),
  };

  data.pendingRevenue = {
    desktopOnly: true,
    value: (
      <div className="font-bold text-merch-dark-gray">
        {formattedPendingRevenue}
      </div>
    ),
  };

  data.closeDate = {
    desktopOnly: true,
    value: (
      <div className="font-bold text-merch-dark-gray">
        {getLocalDateText(merchstore.nextProcessingDate)}
      </div>
    ),
  };

  data.status = {
    desktopOnly: false,
    value: (
      <Badge
        className="no-text-mobile"
        type={
          merchstore.isDeleted
            ? ('deleted' as BadgeType)
            : (merchstore.status as BadgeType)
        }
      >
        {merchstore.isDeleted ? 'DELETED' : merchstore.status}
      </Badge>
    ),
  };

  data.pendingOrdersIndicator = {
    desktopOnly: true,
    value: hasPendingOrder && (
      <div className="active-orders-icon orange mx-1"></div>
    ),
  };

  if (merchstore.isDeleted) {
    data.actions = {
      desktopOnly: false,
      value: (
        <CTA
          onClick={() => {
            actions.viewEditStore && actions.viewEditStore();
          }}
          size="small"
          type="secondary"
          classes="flex-none"
        >
          View Store
        </CTA>
      ),
    };
  } else {
    data.actions = {
      desktopOnly: false,
      value: (
        <ActionDropdown
          title="Actions"
          list={dropDownOptions}
          listClassName={hasPendingOrder ? 'dd-width-120' : ''}
        />
      ),
    };
  }

  for (const [columnName, displayColumn] of Object.entries(displayColumns)) {
    if (displayColumn === false) {
      delete data[columnName];
    }
  }

  return {
    tr: {
      class: `${merchstore.status} ${merchstore.isDeleted ? 'deleted' : ''}`,
      data,
    },
  };
}
