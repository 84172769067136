const UnitPriceMeasurement = {
  name: 'UnitPriceMeasurement',
  kind: 'OBJECT',
  fieldBaseTypes: {
    measuredType: 'UnitPriceMeasurementMeasuredType',
    quantityUnit: 'UnitPriceMeasurementMeasuredUnit',
    quantityValue: 'Float',
    referenceUnit: 'UnitPriceMeasurementMeasuredUnit',
    referenceValue: 'Int',
  },
  implementsNode: false,
};
export default UnitPriceMeasurement;
