import { useContext, useEffect } from 'react';
import { useState } from 'react';

import { Context } from '../../context';
import { Card } from '@merchstores/shared/elements/Card';
import { Link } from 'react-router-dom';

import './styles.scss';
import { LoginHeader } from './LoginHeader';

import { useForm, useWatch } from 'react-hook-form';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import {
  getCustomerAccessTokenMutation,
  getCustomerQuery,
} from '../../queries/storefront-graphql';
import { storefrontClient } from '../../App';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';
import { Loading } from '@merchstores/shared/components/Loading';
import axios from 'axios';
import {
  hasCustomInventoryManagementTag,
  setSessionCustomInventoryAccess,
} from '../Auth/CustomInventory';

export const Login = (props: any) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm();
  const { loggedIn, loginEmail, loginUser } = useContext(Context);
  const [rememberMe, setRememberMe] = useState(false);

  const [formDisabled, setFormDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const email = useWatch({
    control,
    name: 'email',
    defaultValue: loginEmail,
  });

  const password = useWatch({
    control,
    name: 'password',
    defaultValue: '',
  });
  useEffect(() => {
    if (loggedIn) {
      const { history } = props;
      history.push('/admin/group-orders'); // TODO: Change to /admin/merchstores in the future
    } else {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (email && password && email !== '' && password !== '') {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  }, [email, password]);

  const onSubmit = (ev: any) => {
    const accessTokenMutation = getCustomerAccessTokenMutation(
      ev.email,
      ev.password
    );
    storefrontClient.send(accessTokenMutation).then(async ({ model }: any) => {
      if (model.customerAccessTokenCreate.customerAccessToken !== null) {
        // Login Successful
        const { accessToken, expiresAt } =
          model.customerAccessTokenCreate.customerAccessToken;

        storefrontClient
          .send(getCustomerQuery(accessToken))
          .then(async ({ data }: any) => {
            const customerTags = data.customer.tags;
            let role = '';
            if (customerTags.find((t: string) => t === 'merch_store_admin'))
              role = 'ADMIN';
            if (customerTags.find((t: string) => t === 'merch_store_superuser'))
              role = 'SUPERUSER';
            if (
              customerTags.find((t: string) => t === 'merch_store_merchologist')
            )
              role = 'MERCHOLOGIST';

            if (role === '') {
              try {
                const response = await axios.post(
                  '/.netlify/functions/updateShopifyCustomerTagsByEmail',
                  {
                    customerEmail: ev.email,
                    tags: ['merch_store_admin'],
                  }
                );
                if (response?.data?.customer?.tags) {
                  if (
                    response.data.customer.tags.includes('merch_store_admin')
                  ) {
                    role = 'ADMIN';
                  }
                }
              } catch (err) {
                console.error(err);
              }
            }

            if (role !== '') {
              if (hasCustomInventoryManagementTag(customerTags)) {
                setSessionCustomInventoryAccess(true);
              }

              loginUser(accessToken, expiresAt, email, rememberMe, role);
            } else {
              setError('email', {
                type: 'manual',
                message: 'Please enter a valid email address',
              });
            }
          });
      } else {
        setError('password', {
          type: 'incorrect',
          message: 'This password is incorrect',
        });
      }
    });
  };

  if (loading) return <Loading isLoading={true} />;

  return (
    <div className="w-full h-full flex justify-center items-center ">
      <div className="flex justify-center items-center login-wrapper">
        <Card style={{ display: 'flex', flexDirection: 'column' }}>
          <LoginHeader />
          <div className="login-body">
            <h3 className="mb-4 font-bold">Sign In</h3>
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="">
                <div className="inputs-container flex flex-col grid grid-flow-row auto-rows-max gap-24">
                  <FormInput
                    name="email"
                    type="email"
                    register={register}
                    errors={errors}
                    label="Email"
                    required={true}
                    defaultValue={loginEmail}
                  />
                  <FormInput
                    name="password"
                    type="password"
                    register={register}
                    errors={errors}
                    label="Password"
                    required={true}
                  />
                  <div className="flex w-full justify-between">
                    <div className="flex items-center">
                      <Checkbox
                        onChange={(checked) => setRememberMe(checked)}
                      />
                      <label className="font-bold text-13 ml-10">
                        Remember me
                      </label>
                    </div>
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                </div>
                <div className="submit-button-container">
                  <div className="flex w-full">
                    <CTA
                      size="standard"
                      type="primary"
                      formSubmit={true}
                      classes="w-full h-11"
                      disabled={formDisabled}
                    >
                      Sign In
                    </CTA>
                  </div>
                </div>
                <div className="flex justify-center pt-24">
                  <a
                    className="link-3"
                    href="https://www.merchology.com/pages/merchstore"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Frequently Asked Questions
                  </a>
                </div>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </div>
  );
};
