import { useQuery, UseQueryResult } from 'react-query';
import { fetchShopifyOrder } from '@merchstores/admin/api/orders';
import { QueryKey } from '@merchstores/admin/types/reactQuery';
import {
  IFetchShopifyOrderParams,
  IShopifyFetchOrderData,
} from '@merchstores/admin/types/shopifyOrder';

const useOrder = (
  params: IFetchShopifyOrderParams
): UseQueryResult<IShopifyFetchOrderData> => {
  return useQuery(QueryKey.SHOPIFY_GIFT_CARD_ORDER_DETAILS, () =>
    fetchShopifyOrder(params)
  );
};

export default useOrder;
