import Modal from 'react-responsive-modal';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  buildCheckoutFields,
  IGiftCardSelection,
  requestCheckoutUrl,
} from '@merchstores/admin/components/MerchStoreGiftCards/GiftCardOrderCheckout';
import { getMerchStoreGiftCardsStatus } from '@merchstores/admin/api/merchstores/giftCards';
import { ICheckoutStatus } from '@merchstores/admin/components/MerchStoreOrderCheckout';
import { ICombinedOrder } from '@merchstores/admin/types/shopifyCombinedOrder';
import { Input } from '@merchstores/shared/elements/Input';
import { FormInputNumber } from '@merchstores/shared/elements/FormInputNumber';
import { IOrderItem } from '@merchstores/admin/types/merchstoreOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { FormSelect } from '@merchstores/shared/elements/FormSelect';
import { CTA } from '@merchstores/shared/elements/Cta';
import '@merchstores/admin/components/AdminModal/AdminModal.scss';
import { dropdownOptions } from './constants';

interface IGiftCardProps {
  open: boolean;
  onClose?(): void;
  storeCode: string;
  storeSubdomain: string;
  merchStore: IMerchStore;
  combinedOrder?: ICombinedOrder;
  isMobile: boolean;
}

interface IGiftCardVariant {
  id: string;
  title: string;
  sku: string;
  price: string;
}

interface IGiftCardValueOption {
  displayText: string;
  value: string;
  variant: IGiftCardVariant;
}

const DEFAULT_CUSTOM_VALUE = '$0';
const MAX_GIFT_CARD_VALUE = 2000;

async function addGiftCards(
  value: string,
  giftCardQuantity: number,
  merchStore: IMerchStore,
  availableGiftCardVariants: IGiftCardValueOption[]
) {
  if (value === '$' || value === DEFAULT_CUSTOM_VALUE) {
    return { error: 'Please select a gift card value' };
  }

  const giftCardValue = parseFloat(value.replace(/\$/g, ''));
  if (
    isNaN(giftCardValue) ||
    giftCardValue <= 0 ||
    giftCardValue > MAX_GIFT_CARD_VALUE
  ) {
    return {
      error: `Gift card value must be between $1 and $${MAX_GIFT_CARD_VALUE}`,
    };
  }

  const existingVariant = availableGiftCardVariants.find(
    (variant) => variant.displayText === value
  );

  let variantId, variantPrice;

  if (existingVariant) {
    variantId = existingVariant.value;
    variantPrice = existingVariant.variant.price;
  } else {
    try {
      const { data } = await axios.post(
        '/.netlify/functions/createGiftCardVariant',
        { value }
      );
      variantId = data.variantId;
      variantPrice = giftCardValue.toFixed(2);
    } catch (error) {
      console.error(error);
      return { error: 'Error, please try again.' };
    }
  }

  if (!variantId) {
    return { error: 'Error, please try again.' };
  }

  const orderItems = [{ variantId, quantity: giftCardQuantity }];
  const giftCardSelection = { giftCardValue: variantPrice, giftCardQuantity };

  proceedToCheckout(orderItems, merchStore, giftCardSelection);
}

async function proceedToCheckout(
  orderItems: IOrderItem[],
  merchStore: IMerchStore,
  giftCardSelection: IGiftCardSelection
): Promise<ICheckoutStatus> {
  try {
    const giftCardsStatus = await getMerchStoreGiftCardsStatus(
      merchStore.storeCode
    );
    const checkoutFields = buildCheckoutFields(
      orderItems,
      merchStore,
      giftCardsStatus,
      giftCardSelection
    );
    const checkoutResponse = await requestCheckoutUrl(checkoutFields);
    if (checkoutResponse.checkoutUrl) {
      window.location.assign(checkoutResponse.checkoutUrl);
    }
    return checkoutResponse;
  } catch (err) {
    console.error('Checkout Error:', err);
    return {
      checkoutUrl: '',
      errors: [{ code: 'UNEXPECTED_ERROR', field: '', message: String(err) }],
    };
  }
}

export const GiftCardModal: React.FC<IGiftCardProps> = ({
  open,
  onClose,
  merchStore,
}: IGiftCardProps) => {
  const [selectValue, setSelectValue] = useState('');
  const [customValue, setCustomValue] = useState(DEFAULT_CUSTOM_VALUE);
  const [cardQuantity, setCardQuantity] = useState(1);
  const [availableGiftCardVariants, setAvailableGiftCardVariants] = useState(
    []
  );
  const [currentCardVariant, setCurrentCardVariant] = useState(null);
  const [busy, setBusy] = useState(false);

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const setGiftCardVariants = async () => {
      try {
        const { data } = await axios.post(
          '/.netlify/functions/fetchGiftCardVariants'
        );
        const giftCardVariants = data.giftCardVariants;
        const cardsStatus = await getMerchStoreGiftCardsStatus(
          merchStore.storeCode
        );
        const currentCardVariantFound = giftCardVariants.find(
          (variant: IGiftCardVariant) =>
            variant.id === cardsStatus.currentCardVariantId
        );

        if (currentCardVariantFound) {
          setCurrentCardVariant(currentCardVariantFound);
        }

        const options = giftCardVariants.map((variant: IGiftCardVariant) => ({
          displayText: String(variant.title).trim().replace(/\.00$/g, ''),
          value: variant.id,
          variant,
        }));

        setAvailableGiftCardVariants(options);
      } catch (err) {
        console.error('Unable to fetch gift card variants', err);
      }
    };

    setGiftCardVariants();
  }, [merchStore.storeCode]);

  useEffect(() => {
    if (cardQuantity < 1) setCardQuantity(1);
  }, [cardQuantity]);

  function onCustomValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value.replace(/^\$/, '').replace(/[^0-9]/g, '');
    if (value.charAt(0) === '0') value = value.slice(1);
    setCustomValue(`$${value}`);
    setSelectValue('');
  }

  function onModalClose() {
    setSelectValue('');
    setCardQuantity(1);
    onClose?.();
  }

  async function handleSubmit() {
    if (busy) return;
    setBusy(true);
    const value =
      customValue !== DEFAULT_CUSTOM_VALUE && customValue !== '$'
        ? customValue
        : selectValue.displayText;
    if (!value) {
      toast.error('Please select a value or enter a custom value.');
      setBusy(false);
      return;
    }
    try {
      const response = await addGiftCards(
        value,
        cardQuantity,
        merchStore,
        availableGiftCardVariants
      );
      if (response?.error) {
        toast.error(response.error);
      }
    } catch (error) {
      console.error(error);
      toast.error('An unexpected error occurred.');
      setBusy(false);
    }
  }

  return (
    <Modal
      open={open}
      onClose={onModalClose}
      center
      classNames={{ modal: 'admin-modal' }}
      blockScroll
    >
      <h2 className="text-sm text-merch-dark-gray font-bold">
        {currentCardVariant ? 'Buy Additional Gift Cards' : 'Buy Gift Cards'}
      </h2>
      <p className="text-base">
        Choose your gift card value and quantity. You will be redirected to
        checkout to complete your purchase.
      </p>
      <div className="flex flex-row justify space-x-3 mt-6 mb-2">
        <FormSelect
          name="gift-card-value"
          label="Value"
          register={register}
          errors={errors}
          setValue={setValue}
          emptyText={DEFAULT_CUSTOM_VALUE}
          default={DEFAULT_CUSTOM_VALUE}
          options={dropdownOptions}
          onChange={setSelectValue}
          selectStyle="two"
          disabled={customValue !== DEFAULT_CUSTOM_VALUE && customValue !== '$'}
        />
        <FormInputNumber
          name="gift-card-quantity"
          label="Quantity"
          required={true}
          positiveOnly={true}
          integerOnly={true}
          register={register}
          errors={errors}
          defaultValue={cardQuantity}
          onChange={(value: number) => setCardQuantity(value > 0 ? value : 1)}
        />
      </div>
      {/* (Removed temporarily: Custom Gift Card Values)
      <Input
        className="mb-8"
        name="gift-card-custom-value"
        label="Custom Value"
        required={true}
        type="text"
        errors={errors}
        placeholder="$0.00"
        value={customValue}
        onChange={onCustomValueChange}
      />
      */}
      <div className="flex flex-col my-4">
        <CTA
          formSubmit={false}
          disabled={!cardQuantity || (!selectValue && !customValue) || busy}
          size={'large'}
          type={'primary'}
          classes="gift-card-add-checkout-button w-full"
          onClick={handleSubmit}
        >
          Continue to Checkout
        </CTA>
        <CTA
          formSubmit={false}
          disabled={busy}
          size={'large'}
          type={'secondary'}
          classes="gift-card-add-cancel-button w-full	mt-2"
          onClick={onModalClose}
        >
          Cancel
        </CTA>
      </div>
    </Modal>
  );
};
