import { useContext } from 'react';
import { Context } from '@merchstores/admin/context';

import {
  userHasRole,
  Role,
  ROLE_MERCHOLOGIST,
  ROLE_SUPERUSER,
} from '@merchstores/admin/context/Roles';

import { IOrderDetailsView } from '@merchstores/admin/types/merchstoreOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { OrderType } from '@merchstores/shared/components/MerchStore';
import { MerchStoreTab } from '@merchstores/admin/types/merchstore';
import { Loading } from '@merchstores/shared/components/Loading';
import useOrders from '@merchstores/admin/hooks/orders/useOrders';
import EmptyState from './EmptyState';
import History from './History';
import PreApprovedPaymentMethodCheckbox from './PreApprovedPaymentMethodCheckbox';

import { useEffect } from 'react';
import { useState } from 'react';
import { getGiftCardsSummary } from '@merchstores/admin/components/MerchStoreGiftCards/GiftCardsSummary';
import AvailableGiftCardsSummary from './GiftCardsAvailableSummary';
import GiftCardsBalanceSummary from './GiftCardsBalanceSummary';
import GiftCardCSVDownloadButton from '../../../GiftCardCSVDownloadButton';

interface Props {
  merchStore: IMerchStore;
  setSelectedOrderDetails(orderDetailsView: IOrderDetailsView): void;
}

const GiftCardsOrders = (props: Props): JSX.Element => {
  const { merchStore, setSelectedOrderDetails } = props;
  const [giftCardsSummary, setGiftCardsSummary] = useState(null);

  const { userRole } = useContext(Context);

  const { data, isLoading: isLoadingOrders } = useOrders({
    storeCode: merchStore.storeCode,
    orderType: OrderType.GIFT_CARD,
  });

  useEffect(() => {
    getGiftCardsSummary({
      storeCode: merchStore.storeCode,
    }).then((giftCardsSummaryResult) => {
      setGiftCardsSummary(giftCardsSummaryResult);
    });
  }, []);

  const giftCardsStatus = merchStore?.giftCardsStatus;

  const hasGiftCardOrders = data && data.orders?.length > 0;

  const role = new Role(userRole);

  const permissions = {
    allowSetPreApprovedPaymentMethod: userHasRole(role, [ROLE_SUPERUSER]),
    allowDownloadGiftCardsCSV: userHasRole(role, [ROLE_SUPERUSER]),
  };

  if (isLoadingOrders || !giftCardsSummary) {
    return <Loading isLoading={true} />;
  }

  return (
    <div>
      {hasGiftCardOrders && (
        <>
          <GiftCardsBalanceSummary
            balanceSummary={giftCardsSummary.balanceSummary}
          />

          {permissions.allowDownloadGiftCardsCSV && (
            <GiftCardCSVDownloadButton
              className="mb-6"
              storeCode={merchStore?.storeCode}
            />
          )}

          <div className="flex items-center mb-3 pl-6 md:pl-0">
            <p className="font-bold text-lg mr-3">Available Gift Cards</p>
            <p className="text-md opacity-50 italic pt-1">
              Available gift card values and quantities that can be assigned to
              store members
            </p>
          </div>

          <AvailableGiftCardsSummary
            giftCards={giftCardsSummary.availableGiftCards}
          />
        </>
      )}

      {giftCardsStatus && permissions.allowSetPreApprovedPaymentMethod && (
        <>
          <PreApprovedPaymentMethodCheckbox
            storeCode={merchStore?.storeCode}
            giftCardsStatus={giftCardsStatus}
          />
        </>
      )}

      {hasGiftCardOrders ? (
        <>
          <History
            orders={data?.orders}
            tab={MerchStoreTab.GIFT_CARS}
            storeCode={merchStore?.storeCode}
            setSelectedOrderDetails={setSelectedOrderDetails}
          />
        </>
      ) : (
        <EmptyState merchStore={merchStore} />
      )}
    </div>
  );
};

export default GiftCardsOrders;
