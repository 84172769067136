import { useState } from 'react';

import { getGiftCardsList } from '@merchstores/admin/components/MerchStoreGiftCards/GiftCardsList';
import { Loading } from '@merchstores/shared/components/Loading';

export default function GiftCardCSVDownloadButton({
  className,
  storeCode,
}: {
  className?: string;
  storeCode?: string | undefined | null;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  async function onDownloadClick(): Promise<void> {
    setIsLoading(true);
    try {
      const giftCardsResp = await getGiftCardsList({
        storeCode,
      });
      const flatRows = giftCardsResp.giftCardsList;
      const headerKeySet: Set<string> = new Set();
      flatRows.forEach((row: any) => {
        Object.keys(row).forEach((key) => headerKeySet.add(key));
      });
      const headers = Array.from(headerKeySet);
      const rows = [] as string[][];
      flatRows.forEach((row: any) => {
        const rowArr = [] as string[];
        Object.values(row).forEach((value: any) => rowArr.push(value));
        rows.push(rowArr);
      });
      const csvRows = [] as string[];
      csvRows.push(headers.join(','));
      csvRows.push(...rows.map((row) => row.join(',')));
      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      const date = new Date();
      const formattedDate = date.toISOString().split('T')[0];
      a.setAttribute('download', `giftcards-export-${formattedDate}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <button
      className={`btn secondary ${className} w-72`}
      onClick={onDownloadClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loading isLoading={isLoading} width={'24'} />
      ) : (
        'Download CSV'
      )}
    </button>
  );
}
