const FulfillmentLineItemConnection = {
  name: 'FulfillmentLineItemConnection',
  kind: 'OBJECT',
  fieldBaseTypes: {
    edges: 'FulfillmentLineItemEdge',
    pageInfo: 'PageInfo',
  },
  implementsNode: false,
};
export default FulfillmentLineItemConnection;
