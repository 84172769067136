const SelectedOption = {
  name: 'SelectedOption',
  kind: 'OBJECT',
  fieldBaseTypes: {
    name: 'String',
    value: 'String',
  },
  implementsNode: false,
};
export default SelectedOption;
