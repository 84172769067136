import axios from 'axios';

export const getNetlifySiteSettings = (siteId: string) => {
  const body = { siteId: siteId };
  const request = axios
    .post('/.netlify/functions/getNetlifySiteSettings', body)
    .then((response) => {
      return response;
    })
    .catch((err) => err);

  return request;
};

export const updateNetlifySiteSettings = (siteId: string, newSettings: any) => {
  const body = {
    siteId: siteId,
    newSettings: newSettings,
  };
  const request = axios
    .post('/.netlify/functions/updateNetlifySiteSettings', body)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });

  return request;
};
