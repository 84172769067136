import axios from 'axios';
import {
  IFetchShopifyOrderResponse,
  IFetchShopifyOrderParams,
  IShopifyFetchOrderData,
  IShopifyOrdersResponse,
  IShopifyOrdersParams,
  IShopifyOrdersData,
} from '@merchstores/admin/types/shopifyOrder';

export async function fetchShopifyOrders(
  params: IShopifyOrdersParams
): Promise<IShopifyOrdersData> {
  if (!params.storeCode) {
    throw new Error('storeCode parameter is required');
  }

  const response: IShopifyOrdersResponse = await axios
    .post('/.netlify/functions/fetchMerchStoreOrders', params)
    .catch((err) => {
      if (err.response && err.response.data) {
        console.error(err.response.data);
      }

      throw err;
    });
  return response.data;
}

export async function fetchShopifyOrder(
  params: IFetchShopifyOrderParams
): Promise<IShopifyFetchOrderData> {
  if (!params.storeCode) {
    throw new Error('storeCode parameter is required');
  }

  const response: IFetchShopifyOrderResponse = await axios
    .post('/.netlify/functions/fetchMerchStoreOrderById', params)
    .catch((err) => {
      if (err.response && err.response.data) {
        console.error(err.response.data);
      }

      throw err;
    });

  return response.data;
}
