import { IRebuildMerchstoreProps } from '.';

import { toast } from 'react-toastify';
import axios from 'axios';

export async function rebuildMerchstore(props: IRebuildMerchstoreProps) {
  const { subdomain } = props;
  // Check Subdomain format
  const re = /[^a-zA-Z0-9-]/;
  if (re.test(subdomain)) {
    toast.error(
      'Valid subdomains can only contain letters, numbers, or the character -'
    );
    return;
  }
  toast.info(
    `Registering ${subdomain}.merchologysolutions.com, please wait...`
  );
  axios
    .post('/.netlify/functions/createNetlifySubdomain', {
      subdomain: subdomain,
    })
    .then(() => {
      toast.success(
        `${subdomain}.merchologysolutions.com registered correctly!`
      );
    })
    .catch((err) => {
      toast.error('Store failed to be rebuilt.');
      console.log(err?.response?.data?.error);
      throw err?.response?.data?.error;
    });
}
