import { IProductResult } from '../api/products/MerchStoreProducts';

export interface ICachedProductNode {
  id: string;
}
export interface ICachedProduct {
  node: ICachedProductNode;
}

export const storeProductsCache = new Map<string, IProductResult[]>();
