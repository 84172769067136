const Order = {
  name: 'Order',
  kind: 'OBJECT',
  fieldBaseTypes: {
    cancelReason: 'OrderCancelReason',
    canceledAt: 'DateTime',
    currencyCode: 'CurrencyCode',
    currentSubtotalPrice: 'MoneyV2',
    currentTotalDuties: 'MoneyV2',
    currentTotalPrice: 'MoneyV2',
    currentTotalTax: 'MoneyV2',
    customerLocale: 'String',
    customerUrl: 'URL',
    discountApplications: 'DiscountApplicationConnection',
    edited: 'Boolean',
    email: 'String',
    financialStatus: 'OrderFinancialStatus',
    fulfillmentStatus: 'OrderFulfillmentStatus',
    id: 'ID',
    lineItems: 'OrderLineItemConnection',
    name: 'String',
    orderNumber: 'Int',
    originalTotalDuties: 'MoneyV2',
    originalTotalPrice: 'MoneyV2',
    phone: 'String',
    processedAt: 'DateTime',
    shippingAddress: 'MailingAddress',
    shippingDiscountAllocations: 'DiscountAllocation',
    statusUrl: 'URL',
    subtotalPrice: 'Money',
    subtotalPriceV2: 'MoneyV2',
    successfulFulfillments: 'Fulfillment',
    totalPrice: 'Money',
    totalPriceV2: 'MoneyV2',
    totalRefunded: 'Money',
    totalRefundedV2: 'MoneyV2',
    totalShippingPrice: 'Money',
    totalShippingPriceV2: 'MoneyV2',
    totalTax: 'Money',
    totalTaxV2: 'MoneyV2',
  },
  implementsNode: true,
};
export default Order;
