const ProductPriceRange = {
  name: 'ProductPriceRange',
  kind: 'OBJECT',
  fieldBaseTypes: {
    maxVariantPrice: 'MoneyV2',
    minVariantPrice: 'MoneyV2',
  },
  implementsNode: false,
};
export default ProductPriceRange;
