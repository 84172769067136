import './styles.scss';
import axios from 'axios';
import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ActionDropdown } from '@merchstores/shared/elements/ActionDropdown';
import { SortDropdown } from '@merchstores/shared/elements/SortDropdown';
import { InviteMerchStoreMember } from '../InviteMerchStoreMember';
import { Checkbox } from '@merchstores/shared/elements/Checkbox';
import { StoreContext } from '@merchstores/admin/context/Store';
import { MemberListAdminHeader } from './MemberListAdminHeader';
import { Table } from '@merchstores/shared/elements/Table';
import { CTA } from '@merchstores/shared/elements/Cta';
import { Context } from '@merchstores/admin/context';
import { getGiftCardsSummary } from '@merchstores/admin/components/MerchStoreGiftCards/GiftCardsSummary';

import { MerchStoreMemberListProps } from '.';
import GiftCardsModificationModal from '../MerchStoreGiftCards/GiftCardsModificationModal';
import useAssignMembersGiftCards from '@merchstores/admin/hooks/members/useAssignMembersGiftCards';
import useMemberGiftCards from '@merchstores/admin/hooks/members/useMembersGiftCards';
import useReassignMemberGiftCard from '@merchstores/admin/hooks/members/useReassignMemberGiftCard';
import useUnassignMemberGiftCards from '@merchstores/admin/hooks/members/useUnassignMemberGiftCards';
import useDisableMembersGiftCards from '@merchstores/admin/hooks/members/useDisableMembersGiftCards';
import MemberIdTableColumn from './table/MemberIdTableColumn';
import useGiftCardsStatusAsync from '@merchstores/admin/hooks/giftCards/useGiftCardsStatusAsync';

import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';

export const MerchStoreMemberList: React.FC<MerchStoreMemberListProps> = (
  props: MerchStoreMemberListProps
) => {
  const {
    uniqueId,
    merchStore,
    memberAndSubmissionDetails,
    fetchMembers,
    setSortOptionChosen,
    setMerchStoreDetails,
    handleSubmissionEditOpen,
    onContactInfoRequired,
  } = props;

  const { userRole } = useContext(Context);

  const [selectedAction, setSelectedAction] = useState();
  const [membersList, setMembersList] = useState(undefined);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [membersTableData, setMembersTableData] = useState(undefined);
  const [
    isConfirmationGiftCardsModalOpen,
    setIsConfirmationGiftCardsModalOpen,
  ] = useState(false);
  const [disabledGiftCardChanges, setDisabledGiftCardChanges] = useState(false);
  const [modalOpenedInline, setModalOpenedInline] = useState(false);
  const [giftCardsSummary, setGiftCardsSummary] = useState(null);
  const [actionDropdownKey, setActionDropdownKey] = useState(0);
  const { state } = useContext(StoreContext);
  const { isLoading } = useGiftCardsStatusAsync();
  const {
    error: assignGiftCardsError,
    isSuccess: isAssignGiftCardsSuccess,
    mutate: assignGiftCards,
    data: assignGiftCardsData,
    isLoading: isAssignGiftCardsLoading,
  } = useAssignMembersGiftCards();
  const { data: membersGiftCardsData, refetch: getMemberGiftCards } =
    useMemberGiftCards({ storeCode: merchStore.storeCode });
  const reassignMerchStoreMemberGiftCard = useReassignMemberGiftCard();
  const unassignMemberGiftCards = useUnassignMemberGiftCards();
  const disableMembersGiftCards = useDisableMembersGiftCards();

  const giftCardsModalState = {
    setIsConfirmationGiftCardsModalOpen,
    setModalOpenedInline,
    handleInlineAction: (data: { memberCode: string; action: string }) => {
      if (data.action === 'delete') {
        onDeleteClick([data.memberCode]);
      } else {
        setSelectedMembers([data.memberCode]);
        setSelectedAction(data.action);
      }
    },
  };

  useEffect(() => {
    getGiftCardsSummary({ storeCode: merchStore.storeCode }).then(
      (giftCardsSummaryResult) => {
        setGiftCardsSummary(giftCardsSummaryResult);
      }
    );
  }, [merchStore.storeCode]);

  const isSuperUserView = userHasRole(new Role(userRole), [ROLE_SUPERUSER]);
  const isMerchologistView = userHasRole(new Role(userRole), [
    ROLE_SUPERUSER,
    ROLE_MERCHOLOGIST,
  ]);

  const ACTION_DROPDOWN_OPTIONS = useMemo(() => {
    const isGiftCardsEnabled = state?.giftCardsStatus?.enabled;
    const hasMemberGiftCards = membersGiftCardsData?.memberGiftCards?.length;

    function disableDeleteMember(): boolean {
      const selectedMembersDetails = membersList?.filter((member: any) =>
        selectedMembers.includes(member.memberCode)
      );
      const membersWithGiftCards =
        membersGiftCardsData?.memberGiftCards.map((card) => card.memberEmail) ||
        [];
      const selectedMembersWithGiftCards = selectedMembersDetails?.filter(
        (member: any) => membersWithGiftCards.includes(member.email)
      );
      return selectedMembersWithGiftCards?.length > 0;
    }

    function hideAssignGiftCards(): boolean {
      return !isGiftCardsEnabled;
    }

    function hideReassignGiftCards(): boolean {
      return !hasMemberGiftCards || !isMerchologistView;
    }

    function hideUnassignGiftCards(): boolean {
      return !hasMemberGiftCards || !isMerchologistView;
    }

    function hideDisableGiftCards(): boolean {
      return !hasMemberGiftCards || !isSuperUserView;
    }

    const options = [
      {
        id: 'assign_gift_cards',
        title: 'Assign Gift Cards',
        action: () => setSelectedAction('assign_gift_cards'),
        hide: hideAssignGiftCards(),
      },
      {
        id: 'reassign_gift_cards',
        title: 'Reassign Gift Cards',
        action: () => setSelectedAction('Reassign_gift_cards'),
        hide: hideReassignGiftCards(),
      },
      {
        id: 'unassign_gift_cards',
        title: 'Unassign Gift Cards',
        action: () => setSelectedAction('unassign_gift_cards'),
        hide: hideUnassignGiftCards(),
      },
      {
        id: 'disable_gift_cards',
        title: 'Disable Gift Cards',
        action: () => setSelectedAction('disable_gift_cards'),
        hide: hideDisableGiftCards(),
      },
      {
        id: 'delete',
        title: 'Delete Members',
        action: () => setSelectedAction('delete'),
        disabled: disableDeleteMember(),
        disabledTip: 'Members with gift cards cannot be deleted.',
      },
    ];

    return options;
  }, [
    selectedMembers,
    membersList,
    membersGiftCardsData,
    state?.giftCardsStatus?.enabled,
    isSuperUserView,
  ]);

  useEffect(() => {
    const availableActionIds: string[] = ACTION_DROPDOWN_OPTIONS.filter(
      Boolean
    ).map((option: { id: string }) => option.id);
    if (selectedAction && !availableActionIds.includes(selectedAction)) {
      setSelectedAction(null);
      setActionDropdownKey((prevKey: number) => prevKey + 1);
    }
    // If an action becomes unavailable, deselect it.
    const enabledActions = ACTION_DROPDOWN_OPTIONS.filter(
      (option: any) => option.disabled != true
    ).map((option: any) => option.id);
    if (!enabledActions.includes(selectedAction) && selectedAction) {
      setSelectedAction(null);
      setActionDropdownKey((prevKey: number) => prevKey + 1);
      toast.warning(
        'Action unavailable for selected members. The action has been deselected.'
      );
    }
  }, [ACTION_DROPDOWN_OPTIONS, selectedAction, selectedMembers]);

  const sortOptions = [
    {
      title: 'Member Name A - Z',
      id: 'name_a-z',
      action: () => {
        setSortOptionChosen('nameDesc');
      },
    },
    {
      title: 'Member Name Z - A',
      id: 'name_z-a',
      action: () => {
        setSortOptionChosen('nameAsc');
      },
    },
  ];

  const showAssignGiftCardsNotification = (): void => {
    if (!isAssignGiftCardsLoading) {
      const isPlural =
        (assignGiftCardsData?.assignedMemberGiftCards?.length || 0) +
          (assignGiftCardsData?.existingMemberGiftCards?.length || 0) >
        1;
      if (
        isAssignGiftCardsSuccess &&
        assignGiftCardsData &&
        assignGiftCardsData?.assignedMemberGiftCards?.length > 0
      ) {
        toast.success(`Gift card${isPlural ? 's' : ''} assigned successfully!`);
      } else if (assignGiftCardsError) {
        toast.error(
          `There was an error trying to assign ${
            isPlural ? 'a gift card' : 'the gift cards'
          }.`
        );
      }
    }
  };

  const memberCheckboxChanged = useCallback(
    (newValue: boolean, id: string) => {
      setSelectedMembers((prevSelectedMembers: string[]) => {
        let newSelectedMembers = [...prevSelectedMembers];
        if (newValue) {
          newSelectedMembers.push(id);
        } else {
          newSelectedMembers = newSelectedMembers.filter(
            (m: string) => m !== id
          );
        }

        const isMemberWithoutGiftCard = newSelectedMembers.some(
          (memberId: string) => {
            const member = membersList.find(
              (m: any) => m.memberCode === memberId
            );
            const memberGiftCard = membersGiftCardsData?.memberGiftCards?.find(
              (giftCardData: any) => giftCardData?.memberEmail === member?.email
            );
            return !memberGiftCard;
          }
        );

        if (isMemberWithoutGiftCard) {
          setDisabledGiftCardChanges(true);
        } else {
          setDisabledGiftCardChanges(false);
        }

        return newSelectedMembers;
      });
    },
    [membersList, membersGiftCardsData]
  );

  const selectAllChanged = (newValue: boolean) => {
    if (newValue) {
      const allMemberIds = membersList.map((m: any) => m.memberCode);
      setSelectedMembers(allMemberIds);
    } else {
      setSelectedMembers([]);
    }
  };

  const onDeleteClick = (member?: Array<string>) => {
    const deleteConfirmation = window.confirm(
      'Are you sure you want to delete these members?'
    );
    const memberToDelete = member || selectedMembers;
    if (deleteConfirmation) {
      axios
        .post('/.netlify/functions/deleteMerchStoreMembers', {
          memberIds: memberToDelete,
        })
        .then((res) => {
          if (res.status === 200) {
            fetchMembers(uniqueId);
            setSelectedMembers([]);
          } else {
            alert(`An error ocurred deleting the member, please try again.`);
          }
        })
        .catch((err) => {
          alert(`An error ocurred deleting the member, please try again.`);
          console.error('An error ocurred deleting the member:', err);
        });
    }
  };

  const reassignGiftCard = async (
    storeCode: string,
    storeGiftCardCode: string,
    fromMemberCode: string,
    toMemberCode: string
  ) => {
    reassignMerchStoreMemberGiftCard
      .mutateAsync({
        storeCode,
        storeGiftCardCode,
        fromMemberCode,
        toMemberCode,
      })
      .then(() => {
        toast.success('Gift cards reassigned successfully!');
        getMemberGiftCards();
      });
  };

  const unassignGiftCards = async (
    storeCode: string,
    fromMemberCodes: Array<string>
  ) => {
    unassignMemberGiftCards
      .mutateAsync({
        storeCode,
        fromMemberCodes,
      })
      .then((res: any) => {
        const errors = res.some((obj: any) => obj.success === false);
        if (errors) {
          throw new Error('There was an error trying to unassign gift cards.');
        }
        toast.success('Gift cards unassigned successfully!');
        getMemberGiftCards();
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('There was an error trying to unassign gift cards.');
      });
  };

  const disableGiftCard = async (id: string[]) => {
    await disableMembersGiftCards
      .mutateAsync({
        id: id,
      })
      .then(() => {
        getMemberGiftCards();
      });
  };

  useEffect(() => {
    setMembersList(memberAndSubmissionDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchStore.groupOrderId, merchStore.storeCode, fetchMembers]);

  const getAdminStatus = (email: string) => {
    let status = '';
    if (merchStore.primaryAdmin === email) {
      status = 'PRIMARY ADMIN';
    } else if (merchStore.customerAdmins.includes(email)) {
      status = 'ADMIN';
    }
    return status;
  };

  useEffect(() => {
    if (
      membersList &&
      membersList.length === memberAndSubmissionDetails.length
    ) {
      const membersTableData = membersList.map((member: any) => {
        const memberGiftCards = membersGiftCardsData?.memberGiftCards?.filter(
          (giftCardData: any) => giftCardData?.memberEmail === member?.email
        );

        if (memberAndSubmissionDetails) {
          memberAndSubmissionDetails.every((memberWithSubmission: any) => {
            if (memberWithSubmission.submissionId === member.submissionId) {
              member.submission = memberWithSubmission.submission;
              return false;
            } else {
              return true;
            }
          });
        }
        const adminStatus = getAdminStatus(member.email);
        const data: Record<string, unknown> = {
          memberSelector: {
            desktopOnly: false,
            value: (
              <Checkbox
                isSelected={selectedMembers.includes(member.memberCode)}
                id={member.memberCode}
                className={`member-checkbox ${
                  selectedMembers.includes(member.memberCode)
                    ? 'seleccionado'
                    : ''
                }`}
                onChange={memberCheckboxChanged}
              />
            ),
          },
          memberEmail: {
            desktopOnly: false,
            value: (
              <span className="flex items-center gap-3">
                {member.email}{' '}
                {adminStatus && (
                  <span className="admin-label">{adminStatus}</span>
                )}
              </span>
            ),
          },
          memberId: {
            desktopOnly: false,
            value: (
              <MemberIdTableColumn
                memberCode={member.memberCode}
                memberGiftCards={memberGiftCards}
                giftCardsModalState={giftCardsModalState}
              />
            ),
          },
        };
        return { tr: { class: '', data } };
      });
      setMembersTableData(membersTableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    membersGiftCardsData?.memberGiftCards,
    memberAndSubmissionDetails,
    handleSubmissionEditOpen,
    memberCheckboxChanged,
    selectedMembers,
    membersList,
  ]);

  useEffect(() => {
    if (assignGiftCardsData?.assignedMemberGiftCards) {
      getMemberGiftCards();
      setSelectedMembers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignGiftCardsData?.assignedMemberGiftCards]);

  useEffect(() => {
    showAssignGiftCardsNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssignGiftCardsLoading]);

  const onConfirmSelection = (): void => {
    setIsConfirmationGiftCardsModalOpen(true);
  };

  const handleAssignGiftCardsCancel = () => {
    setIsConfirmationGiftCardsModalOpen(false);

    if (modalOpenedInline) {
      setSelectedAction(null);
      setSelectedMembers([]);
    }

    setModalOpenedInline(false);
  };

  const handleConfirm = (): void => {
    const actionsForConfirm = [
      'assign_gift_cards',
      'unassign_gift_cards',
      'disable_gift_cards',
    ];

    if (actionsForConfirm.includes(selectedAction)) {
      onConfirmSelection();
    } else if (selectedAction === 'delete') {
      onDeleteClick();
    }
  };

  const handleMemberAdded = (
    members: Array<{ email: string }>,
    isAssignGiftCardsSelected: boolean
  ): void => {
    if (isAssignGiftCardsSelected) {
      const memberEmails = members.map((member) => member.email);
      assignGiftCards({
        storeCode: merchStore.storeCode,
        memberEmails,
        cardValue: giftCardsSummary.availableGiftCards[0].cardValue,
      });
    }
    if (props.onMembersAdded) {
      props.onMembersAdded(members);
    }
  };

  const ctaIsDisabled: boolean =
    !selectedAction ||
    selectedMembers.length === 0 ||
    isAssignGiftCardsLoading ||
    ([
      'disable_gift_cards',
      'unassign_gift_cards',
      'reassign_gift_cards',
    ].includes(selectedAction) &&
      disabledGiftCardChanges);

  return (
    <>
      <div>
        <InviteMerchStoreMember
          order={merchStore}
          memberAndSubmissionDetails={props.memberAndSubmissionDetails}
          display={props.showAddMember}
          onMembersAdded={handleMemberAdded}
          giftCardsSummary={giftCardsSummary}
        />
        <MemberListAdminHeader
          merchStore={merchStore}
          setMerchStoreDetails={setMerchStoreDetails}
          onContactInfoRequired={onContactInfoRequired}
        />
        {membersTableData ? (
          <>
            {membersTableData.length > 0 && (
              <>
                <div
                  className={
                    'flex justify-between align-center items-start dropdowns-container px-4 md:px-0'
                  }
                >
                  <div>
                    <p className="font-bold">Members</p>
                  </div>
                </div>
                <div className="flex mb-5 items-center justify-between">
                  <div className="flex items-center">
                    <Checkbox
                      isSelected={selectedMembers && selectedMembers.length > 0}
                      label={
                        selectedMembers && selectedMembers.length > 0
                          ? `${selectedMembers.length} selected`
                          : 'Select all'
                      }
                      className="member-checkbox items-center"
                      onChange={selectAllChanged}
                    />
                    <ActionDropdown
                      key={actionDropdownKey}
                      title="Actions"
                      className="h-9 px-4 w-full text-sm ml-3"
                      list={ACTION_DROPDOWN_OPTIONS.filter(
                        (action: any) => action.id !== 'reassign_gift_cards'
                      )}
                    />
                    <div className="pl-3">
                      <CTA
                        disabled={ctaIsDisabled}
                        classes={`${ctaIsDisabled && 'cursor-default'}`}
                        stretch={true}
                        size="standard"
                        type="primary"
                        onClick={handleConfirm}
                      >
                        {isAssignGiftCardsLoading ? 'Saving...' : 'Confirm'}
                      </CTA>
                    </div>
                  </div>
                  <div className="sort-orders-container">
                    <SortDropdown
                      title="Sort"
                      list={sortOptions}
                      defaultSort="name_a-z"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="member-list-container">
              <Table
                name="member-list"
                filter={true}
                data={membersTableData}
                itemsName="members"
                elementsPerPage={50}
              />
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <GiftCardsModificationModal
        isLoading={isLoading}
        membersList={membersList}
        selectedMembers={selectedMembers}
        isOpen={isConfirmationGiftCardsModalOpen}
        selectedAction={selectedAction}
        isStoreActive={merchStore?.status === 'active'}
        memberGiftCards={membersGiftCardsData?.memberGiftCards}
        handleCancel={handleAssignGiftCardsCancel}
        giftCardsSummary={giftCardsSummary}
        assignGiftCards={assignGiftCards}
        reassignGiftCard={reassignGiftCard}
        unassignGiftCards={unassignGiftCards}
        disableGiftCard={disableGiftCard}
        storeCode={merchStore.storeCode}
      />
    </>
  );
};
