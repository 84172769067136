const ShopPolicy = {
  name: 'ShopPolicy',
  kind: 'OBJECT',
  fieldBaseTypes: {
    body: 'String',
    handle: 'String',
    id: 'ID',
    title: 'String',
    url: 'URL',
  },
  implementsNode: true,
};
export default ShopPolicy;
