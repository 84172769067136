const AvailableShippingRates = {
  name: 'AvailableShippingRates',
  kind: 'OBJECT',
  fieldBaseTypes: {
    ready: 'Boolean',
    shippingRates: 'ShippingRate',
  },
  implementsNode: false,
};
export default AvailableShippingRates;
