import { Promise as bluebird } from 'bluebird';
import { fetchShopifyOrder } from '@merchstores/admin/components/MerchStoreOrders';
import { mapLineItemToOrderItem } from '../MerchStoreOrders/ShopifyLineItem';
import { IOrderItem } from '@merchstores/admin/types/merchstoreOrder';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { IShopifyOrder } from '@merchstores/admin/types/shopifyOrder';

export const uniqueItemKey = (item: IOrderItem): string => {
  return [
    item.variantId,
    item.size,
    item.artwork,
    item.decorationType,
    item.decorationLocation,
  ]
    .map(String)
    .join('--');
};

export function compressLineItems(
  lineItems: Array<IOrderItem>
): Array<IOrderItem> {
  const itemLinesMap = new Map<string, IOrderItem>();

  for (const lineItem of lineItems) {
    const itemKey = uniqueItemKey(lineItem);
    const previousLineItem = itemLinesMap.get(itemKey);

    if (previousLineItem) {
      lineItem.quantity += previousLineItem.quantity;
    }

    itemLinesMap.set(itemKey, lineItem);
  }

  const compressedLineItems = Array.from(itemLinesMap.values());

  return compressedLineItems;
}

interface ICombineOrdersItemsOptions {
  onIndividualOrder?: (order: IShopifyOrder) => void;
  compressLineItems?: boolean;
}

export async function combineOrdersItems(
  merchStore: IMerchStore,
  orders: Array<IShopifyOrder>,
  options?: ICombineOrdersItemsOptions
): Promise<Array<IOrderItem>> {
  options = options || {};

  options = Object.assign(
    {
      compressLineItems: true,
    },
    options
  );

  let allItems: Array<IOrderItem> = [];

  let ordersConcurrency = orders.length > 150 ? 2 : 3;

  await bluebird.map(
    orders,
    async (order) => {
      const orderItems: Array<IOrderItem> = [];

      await fetchShopifyOrder({
        storeCode: merchStore.storeCode,
        orderId: order.id,
        snapshot: 'order_submission',
      })
        .catch(async () => {
          // reduce concurrency and retry after 10s on failure
          ordersConcurrency = 1;
          await bluebird.delay(10000);
          return fetchShopifyOrder({
            storeCode: merchStore.storeCode,
            orderId: order.id,
          });
        })
        .then((data) => {
          if (options && options.onIndividualOrder) {
            options.onIndividualOrder(order);
          }

          data.order.lineItems?.forEach((lineItem) => {
            if (!lineItem.variant) {
              return false;
            }

            orderItems.push(mapLineItemToOrderItem(lineItem));
          });
        });

      allItems = allItems.concat(orderItems);
    },
    { concurrency: ordersConcurrency }
  );

  allItems = options.compressLineItems ? compressLineItems(allItems) : allItems;

  return allItems;
}
