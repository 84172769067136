import { DateTime } from 'luxon';
import { chargeShippingUnlessExplicitFalse } from '@merchstores/shared/components/Shipping';
import * as storefrontQuery from '@merchstores/admin/queries/storefront-graphql';
import { resolveOriginalArtwork } from '@merchstores/shared/components/Artwork';
import {
  logoImageResizeByWidth,
  STORE_LOGO_SMALL_WIDTH,
} from '@merchstores/shared/components/Cloudinary';
import { IMerchStore } from '@merchstores/admin/components/MerchStore';
import { OrderType } from '@merchstores/shared/components/MerchStore';
import { merchstoreStorefrontClient } from '@merchstores/admin/App';
import {
  buildCheckoutCreateMutation,
  createStorefrontApiClient,
} from '@merchstores/admin/components/ShopifyStorefront';
import {
  IMailingAddressInput,
  ICustomAttribute,
  ICheckoutFields,
} from '@merchstores/admin/types/shopifyCheckout';
import { IOrderItem } from '@merchstores/admin/types/merchstoreOrder';

const SHOPIFY_CHECKOUT_DOMAIN = process.env.REACT_APP_SHOPIFY_CHECKOUT_DOMAIN;

const INVALID_SHIPPING_ADDRESS_CODES = [
  'INVALID_COUNTRY_AND_CURRENCY',
  'INVALID_FOR_COUNTRY',
  'INVALID_FOR_COUNTRY_AND_PROVINCE',
  'INVALID_PROVINCE_IN_COUNTRY',
  'INVALID_PROVINCE_IN_COUNTRY',
  'INVALID_PROVINCE_IN_COUNTRY',
  'INVALID_STATE_IN_COUNTRY',
];

export function customAttr(
  attrName: string,
  attrValue: string
): ICustomAttribute {
  return { key: attrName, value: attrValue };
}

export function buildLineItemAttributes(
  item: IOrderItem
  /*merchstore: IMerchStore*/
): Array<ICustomAttribute> {
  let attributes: Array<ICustomAttribute> = [];

  attributes = attributes.concat(
    customAttr('_Decoration type', item.decorationType || ''),
    customAttr('_Select decoration location', item.decorationLocation || ''),
    customAttr(
      '_Upload your logo (2MB max)',
      resolveOriginalArtwork(item.artwork || '')
    ),
    customAttr('_Logo Mockup Image', item.logoMockup || '')
  );

  return attributes.filter((attr) => typeof attr.value !== 'undefined');
}

export function buildCheckoutFields(
  orderItems: Array<IOrderItem>,
  merchstore: IMerchStore
): ICheckoutFields {
  const baseLineItemAttributes: Array<ICustomAttribute> = [];

  const lineItems = orderItems.map((item) => {
    const lineItemAttributes = baseLineItemAttributes.concat(
      buildLineItemAttributes(item /*, merchstore*/)
    );

    const encodedVariantId = Buffer.from(item.variantId).toString('base64');

    return {
      variantId: encodedVariantId,
      quantity: item.quantity,
      customAttributes: lineItemAttributes,
    };
  });

  const checkoutCustomizationAttributes = [
    customAttr('payer_type', merchstore.payerType || ''),
    customAttr('order_type', OrderType.COMBINED),
    customAttr(
      'ship_to_office',
      String(String(merchstore.shipToOffice) === 'true')
    ),
    customAttr(
      'charge_shipping',
      String(
        String(chargeShippingUnlessExplicitFalse(merchstore.chargeShipping)) ===
          'true'
      )
    ),
  ];

  if (lineItems.length) {
    lineItems[0].customAttributes = lineItems[0].customAttributes.concat(
      checkoutCustomizationAttributes.map((attr) => {
        return {
          key: `_${attr.key}`,
          value: attr.value,
        };
      })
    );
  }

  if (lineItems.length > 1) {
    const lastElem = lineItems.length - 1;
    lineItems[lastElem].customAttributes = lineItems[
      lastElem
    ].customAttributes.concat(
      checkoutCustomizationAttributes.map((attr) => {
        return {
          key: `_${attr.key}`,
          value: attr.value,
        };
      })
    );
  }

  const closeDate = DateTime.now().toISODate();

  const cartAttributes: Array<ICustomAttribute> = [
    customAttr('subdomain', merchstore.subdomain),
    customAttr('merchstore_code', merchstore.storeCode + ''),
    customAttr('payer_type', merchstore.payerType + ''),
    customAttr('order_type', OrderType.COMBINED),
    customAttr(
      'ship_to_office',
      String(String(merchstore.shipToOffice) === 'true')
    ),
    customAttr('close_date', closeDate),
    customAttr(
      'merchstore_logo',
      String(
        logoImageResizeByWidth(
          merchstore.storeLogo || '',
          STORE_LOGO_SMALL_WIDTH
        )
      )
    ),
    customAttr(
      'charge_shipping',
      String(
        String(chargeShippingUnlessExplicitFalse(merchstore.chargeShipping)) ===
          'true'
      )
    ),
  ].filter((attr) => typeof attr.value !== 'undefined');

  const checkoutFields: ICheckoutFields = {
    lineItems,
    customAttributes: cartAttributes,
  };

  if (merchstore.shipToOffice && merchstore.officeAddress) {
    checkoutFields.shippingAddress = {
      company: merchstore.officeAddress.company,
      firstName: merchstore.officeAddress.firstName,
      lastName: merchstore.officeAddress.lastName,
      address1: merchstore.officeAddress.address1,
      address2: merchstore.officeAddress.address2,
      province: merchstore.officeAddress.province,
      zip: merchstore.officeAddress.zip,
      city: merchstore.officeAddress.city,
      country: merchstore.officeAddress.country,
      phone: merchstore.officeAddress.phone,
    };
  }

  return checkoutFields;
}

interface IErrorInvalidItem {
  code: string;
  variantId?: string;
  message?: string;
  field?: string;
}

export async function extractCheckoutErrors(
  checkoutErrors: Array<{ code: string; message?: string }>,
  checkoutFields: ICheckoutFields
): Promise<Array<IErrorInvalidItem>> {
  let errors: Array<IErrorInvalidItem> = [];

  const invalidErrors = checkoutErrors.filter(
    (errorItem) => errorItem.code === 'INVALID'
  );
  const shippingErrors = checkoutErrors.filter((errorItem) =>
    INVALID_SHIPPING_ADDRESS_CODES.includes(errorItem.code)
  );
  const blankErrors = checkoutErrors.filter(
    (errorItem) => errorItem.code === 'BLANK'
  );

  const unexpectedErrors = checkoutErrors.filter((errorItem) => {
    return ![...INVALID_SHIPPING_ADDRESS_CODES, 'BLANK', 'INVALID'].includes(
      errorItem.code
    );
  });

  if (
    !invalidErrors.length &&
    !shippingErrors.length &&
    !blankErrors.length &&
    !unexpectedErrors.length
  ) {
    return errors;
  }

  const invalidItemErrorDetails = await Promise.all(
    checkoutFields.lineItems.map((lineItem) => {
      const errorCode = 'INVALID';
      const productVariantQuery = storefrontQuery.getProductVariantByIdQuery(
        lineItem.variantId
      );

      const checkErrorPromise = new Promise((resolve) => {
        merchstoreStorefrontClient
          .send(productVariantQuery)
          .then(({ data }: { data: { node: unknown } }) => {
            if (!data.node) {
              return resolve({
                code: errorCode,
                variantId: atob(lineItem.variantId),
              });
            }
            resolve(null);
          });
      });

      return checkErrorPromise as Promise<IErrorInvalidItem | null>;
    })
  );

  invalidItemErrorDetails.filter(Boolean).forEach((errorItem) => {
    errorItem = errorItem as IErrorInvalidItem;
    errors.push({ code: errorItem.code, variantId: errorItem.variantId });
  });

  const invalidAddressErrorsDetails = shippingErrors.map((errorItem) => {
    return { code: errorItem.code, message: errorItem.message, variantId: '' };
  });

  errors = errors.concat(
    invalidAddressErrorsDetails,
    blankErrors,
    unexpectedErrors
  );

  return errors;
}

export interface ICheckoutStatus {
  checkoutUrl: string;
  errors: Array<IErrorInvalidItem>;
}

export async function requestCheckoutUrl(
  checkoutFields: ICheckoutFields
): Promise<ICheckoutStatus> {
  const storefrontClient = createStorefrontApiClient();

  const createResponse = await storefrontClient.post(
    '',
    buildCheckoutCreateMutation(checkoutFields)
  );

  if (createResponse.data.errors) {
    throw new Error(JSON.stringify(createResponse.data.errors));
  }

  const createData = createResponse.data.checkoutCreate;

  const createErrors = createData.checkoutUserErrors;

  const errors = await extractCheckoutErrors(createErrors, checkoutFields);

  const checkoutData = createData.checkout;

  let checkoutUrl = checkoutData ? checkoutData.webUrl : '';

  if (SHOPIFY_CHECKOUT_DOMAIN) {
    checkoutUrl = checkoutUrl.replace(
      /https:\/\/[^.]+\.myshopify\.com\//,
      `https://${SHOPIFY_CHECKOUT_DOMAIN}/`
    );
  }

  return {
    checkoutUrl: checkoutUrl,
    errors: errors,
  };
}

export async function testCheckoutShippingAddress(
  orderItems: Array<IOrderItem>,
  merchStore: IMerchStore
): Promise<ICheckoutStatus> {
  const checkoutFields = buildCheckoutFields(orderItems, merchStore);

  try {
    const checkoutResponse = await requestCheckoutUrl(checkoutFields);

    return checkoutResponse;
  } catch (err: unknown) {
    console.error(err);
    return {
      checkoutUrl: '',
      errors: [
        {
          code: '1',
          variantId: '',
          message: String(err),
        },
      ],
    };
  }
}

export async function testAddress(
  address: IMailingAddressInput
): Promise<ICheckoutStatus> {
  const checkoutFields = {
    lineItems: [],
    customAttributes: [],
    shippingAddress: {
      // Explictly pick fields to avoid sending extra fields that may produce errors
      firstName: address.firstName,
      lastName: address.lastName,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      company: address.company,
      zip: address.zip,
      province: address.province,
      country: address.country,
      phone: address.phone,
    },
  };

  try {
    const checkoutResponse = await requestCheckoutUrl(checkoutFields);

    return checkoutResponse;
  } catch (err: unknown) {
    console.error(err);
    return {
      checkoutUrl: '',
      errors: [
        {
          code: '1',
          variantId: '',
          message: String(err),
        },
      ],
    };
  }
}
