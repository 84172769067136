const CheckoutCustomerDisassociateV2Payload = {
  name: 'CheckoutCustomerDisassociateV2Payload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    checkout: 'Checkout',
    checkoutUserErrors: 'CheckoutUserError',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CheckoutCustomerDisassociateV2Payload;
