const ExternalVideo = {
  name: 'ExternalVideo',
  kind: 'OBJECT',
  fieldBaseTypes: {
    alt: 'String',
    embeddedUrl: 'URL',
    host: 'MediaHost',
    id: 'ID',
    mediaContentType: 'MediaContentType',
    previewImage: 'Image',
  },
  implementsNode: true,
};
export default ExternalVideo;
