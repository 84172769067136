import {
  userHasRole,
  Role,
  ROLE_SUPERUSER,
  ROLE_MERCHOLOGIST,
} from '@merchstores/admin/context/Roles';

interface SortOption {
  title: string;
  id: string;
  action?: () => void;
}

const CreationDateNewestFirst = {
  title: 'Creation Date (Newest First)',
  id: 'sort_creation_newest',
};

const CreationDateOldestFirst = {
  title: 'Creation Date (Oldest First)',
  id: 'sort_creation_oldest',
};

const MerchStoreNameAsc = {
  title: 'MerchStore Name A-Z',
  id: 'sort_a-z',
};

const MerchStoreNameDesc = {
  title: 'MerchStore Name Z-A',
  id: 'sort_z-a',
};

const OrdersMostFirst = {
  title: 'Orders (Most First)',
  id: 'sort_orders_most',
};

const OrdersFewestFirst = {
  title: 'Orders (Fewest First)',
  id: 'sort_orders_fewest',
};

const PendingOrdersMostFirst = {
  title: 'Pending Orders (Most First)',
  id: 'sort_orders_pending_most',
};

const PendingOrdersFewestFirst = {
  title: 'Pending Orders (Fewest First)',
  id: 'sort_orders_pending_fewest',
};

const RevenueMostFirst = {
  title: 'Revenue (Most First)',
  id: 'sort_revenue_most',
};

const RevenueLeastFirst = {
  title: 'Revenue (Least First)',
  id: 'sort_revenue_least',
};

const PendingRevenueMostFirst = {
  title: 'Pending Revenue (Most First)',
  id: 'sort_revenue_pending_most',
};

const PendingRevenueLeastFirst = {
  title: 'Pending Revenue (Least First)',
  id: 'sort_revenue_pending_least',
};

const CloseDateNewestFirst = {
  title: 'Close Date (Newest First)',
  id: 'sort_close_newest',
};

const CloseDateOldestFirst = {
  title: 'Close Date (Oldest First)',
  id: 'sort_close_oldest',
};

export function getSortValue(sortOption?: SortOption): string | null {
  if (!sortOption) {
    return null;
  }

  return sortOption.id;
}

export function buildSortOptions(role: Role): SortOption[] {
  if (userHasRole(role, [ROLE_SUPERUSER, ROLE_MERCHOLOGIST])) {
    return [
      CreationDateNewestFirst,
      CreationDateOldestFirst,
      MerchStoreNameAsc,
      MerchStoreNameDesc,
      OrdersMostFirst,
      OrdersFewestFirst,
      PendingOrdersMostFirst,
      PendingOrdersFewestFirst,
      RevenueMostFirst,
      RevenueLeastFirst,
      PendingRevenueMostFirst,
      PendingRevenueLeastFirst,
      CloseDateNewestFirst,
      CloseDateOldestFirst,
    ];
  }

  return [
    CreationDateNewestFirst,
    CreationDateOldestFirst,
    MerchStoreNameAsc,
    MerchStoreNameDesc,
    OrdersMostFirst,
    OrdersFewestFirst,
    PendingOrdersMostFirst,
    PendingOrdersFewestFirst,
    CloseDateNewestFirst,
    CloseDateOldestFirst,
  ];
}
