const CheckoutCustomerAssociatePayload = {
  name: 'CheckoutCustomerAssociatePayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    checkout: 'Checkout',
    customer: 'Customer',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CheckoutCustomerAssociatePayload;
