import { IFormOptionProps } from '@merchstores/shared/elements/FormSelect';
import { IShippingPackageProps } from './MemberSubmission';

export function makePackageSizeOptions(
  selectedPackage: IShippingPackageProps
): IFormOptionProps[] {
  const sizeString = String(selectedPackage.sizes || '').trim();

  if (!sizeString) {
    return [];
  }

  const sizeSplit = sizeString.split(/\s*,\s*/g).filter(Boolean);

  return sizeSplit.map((packageSize: string) => {
    return {
      value: packageSize,
      displayText: packageSize,
    };
  });
}
