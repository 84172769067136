const UserError = {
  name: 'UserError',
  kind: 'OBJECT',
  fieldBaseTypes: {
    field: 'String',
    message: 'String',
  },
  implementsNode: false,
};
export default UserError;
