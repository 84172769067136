const CustomerAccessTokenRenewPayload = {
  name: 'CustomerAccessTokenRenewPayload',
  kind: 'OBJECT',
  fieldBaseTypes: {
    customerAccessToken: 'CustomerAccessToken',
    userErrors: 'UserError',
  },
  implementsNode: false,
};
export default CustomerAccessTokenRenewPayload;
