import Modal from 'react-responsive-modal';

import { useState, useEffect } from 'react';

import { CTA } from '@merchstores/shared/elements/Cta';

import useUpdateShippingAddress from '@merchstores/admin/hooks/address/useUpdateShippingAddress';

import './deleteModalStyle.scss';

export interface Props {
  isOpen: boolean;
  shippingAddressId: string;
  setIsOpen: (isOpen: boolean) => void;
}

export function DeleteShippingAddressModal(props: Props): JSX.Element {
  const { isOpen, shippingAddressId, setIsOpen } = props;
  const [id, setId] = useState(shippingAddressId);

  const { mutate: updateShippingAddress } = useUpdateShippingAddress();

  function handleCancel() {
    setIsOpen(false);
  }

  function handleDelete() {
    updateShippingAddress({ _id: id, isDeleted: true });
    setIsOpen(false);
  }

  useEffect(() => {
    if (shippingAddressId) {
      setId(shippingAddressId);
    }
  }, [shippingAddressId]);

  return (
    <>
      <Modal
        center
        open={isOpen}
        onClose={handleCancel}
        classNames={{ modal: 'delete-address-modal' }}
      >
        <p className="text-xl font-bold pb-7">Delete Shipping Address</p>
        <p className="text-base font-medium text-merch-dark-gray mb-3">
          Are you sure you want to delete this shipping address?
        </p>
        <p className="text-xs font-medium text-merch-dark-gray opacity-50 mb-5">
          If this is the only shipping address available, you will be prompted
          to add a new shipping address.
        </p>
        <div className="flex flex-col justify-center">
          <CTA
            type={'primary'}
            size={'standard'}
            formSubmit={true}
            classes="flex-1 h-11"
            onClick={handleDelete}
          >
            Confirm & Delete
          </CTA>
          <CTA
            type={'clean'}
            size={'standard'}
            formSubmit={true}
            classes="flex-1 mt-2"
            onClick={handleCancel}
          >
            Cancel
          </CTA>
        </div>
      </Modal>
    </>
  );
}
