interface Props {
  name?: string;
  email?: string;
}

const CustomerContact = (props: Props): JSX.Element => {
  const { name, email } = props;

  return (
    <div className="md:flex-grow mb-15">
      <div className="section-title mb-10 font-bold text-xs">
        Customer Contact
      </div>
      <div className="section-info">
        <div className="text-sm font-bold">{name || ''}</div>
        <div className="text-sm">{email || ''}</div>
      </div>
    </div>
  );
};

export default CustomerContact;
