const Media = {
  name: 'Media',
  kind: 'INTERFACE',
  fieldBaseTypes: {
    alt: 'String',
    mediaContentType: 'MediaContentType',
    previewImage: 'Image',
  },
  possibleTypes: ['ExternalVideo', 'MediaImage', 'Model3d', 'Video'],
};
export default Media;
