import { useContext, useEffect } from 'react';
import { Context } from '../../context';
import {
  getCustomerAccessTokenMultipassMutation,
  getCustomerQuery,
} from '../../queries/storefront-graphql';
import { storefrontClient } from '../../App';
import { Loading } from '@merchstores/shared/components/Loading';
import {
  hasCustomInventoryManagementTag,
  setSessionCustomInventoryAccess,
} from './CustomInventory';

export const Auth = (props: any) => {
  const { loginUser } = useContext(Context);

  useEffect(() => {
    const multipassToken = props.match.params.token;
    const pathToRedirect = props.match.params.redirect
      ? atob(props.match.params.redirect)
      : '/admin/group-orders';

    const accessTokenMutation =
      getCustomerAccessTokenMultipassMutation(multipassToken);
    storefrontClient.send(accessTokenMutation).then(async (result: any) => {
      const model = result.model;
      if (
        model.customerAccessTokenCreateWithMultipass.customerAccessToken !==
        null
      ) {
        // Multipass login Successful
        const { accessToken, expiresAt } =
          model.customerAccessTokenCreateWithMultipass.customerAccessToken;
        storefrontClient
          .send(getCustomerQuery(accessToken))
          .then(async ({ data }: any) => {
            const customerTags = data.customer.tags;
            let role = '';
            if (customerTags.find((t: string) => t === 'merch_store_admin'))
              role = 'ADMIN';
            if (customerTags.find((t: string) => t === 'merch_store_superuser'))
              role = 'SUPERUSER';
            if (
              customerTags.find((t: string) => t === 'merch_store_merchologist')
            )
              role = 'MERCHOLOGIST';
            if (role !== '') {
              if (hasCustomInventoryManagementTag(customerTags)) {
                setSessionCustomInventoryAccess(true);
              }

              loginUser(
                accessToken,
                expiresAt,
                data.customer.email,
                true,
                role
              );
              const { history } = props;
              history.push(pathToRedirect);
            } else {
              console.error(`No user role defined for ${data.customer.email}`);
            }
          });
      } else {
        console.error('Error on Multipass authentication');
        console.error(
          model.customerAccessTokenCreateWithMultipass.customerUserErrors
        );
      }
    });
  });
  return <Loading isLoading={true} />;
};
