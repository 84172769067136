/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { FormInput } from '@merchstores/shared/elements/FormInput';
import { CTA } from '@merchstores/shared/elements/Cta';
import {
  FormSelect,
  IFormOptionProps,
} from '@merchstores/shared/elements/FormSelect';
import { RiDeleteBin7Line } from 'react-icons/ri';
import { IProductSidebarMenuProps } from '.';
import { userHasRole, ROLE_GROUPADMIN } from '@merchstores/admin/context/Roles';
import { toast } from 'react-toastify';

import './styles.scss';

export const ProductEditSidebarMenu: React.FC<IProductSidebarMenuProps> = (
  props: IProductSidebarMenuProps
) => {
  const {
    product,
    register,
    errors,
    selectedDiscount,
    setSelectedDiscount,
    lowestVariantPrice,
    lowestVariantCompareAt,
    userRole,
    setSelectedProduct,
    deleteProduct,
    setValue,
    decoType,
    isDecoTypeReadOnly,
    decoTypeOptions,
    decoLocation,
    isDecoLocationReadOnly,
    decoLocationOptions,
  } = props;
  const [selectedPrice, setSelectedPrice] = useState(lowestVariantPrice);
  const isStoreAdmin = userHasRole(userRole, [ROLE_GROUPADMIN]);

  useEffect(() => {
    setSelectedPrice(lowestVariantPrice);
  }, [product, lowestVariantPrice]);

  const handleDiscountChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSelectedPrice(e.currentTarget.value);
    const currentPrice = new BigNumber(e.currentTarget.value);
    const originalPrice = new BigNumber(lowestVariantCompareAt);
    const base = new BigNumber(100);
    const discount = parseInt(
      base.minus(currentPrice.dividedBy(originalPrice).times(base)).toFormat(0)
    );
    setSelectedDiscount(isNaN(discount) ? 0 : discount);
  };
  const handleProductDelete = () => {
    deleteProduct(product.id);
    setSelectedProduct(null);
  };

  const onDecoTypeChange = (itemSelected: IFormOptionProps) => {
    if (isStoreAdmin && itemSelected.value != decoType) {
      toast.info(
        "This is a permament selection. After you update the Decoration Type you can't change it in the future."
      );
    }
  };

  const onDecoLocationChange = (itemSelected: IFormOptionProps) => {
    if (isStoreAdmin && itemSelected.value != decoLocation) {
      toast.info(
        "This is a permament selection. After you update the Decoration Location you can't change it in the future."
      );
    }
  };

  return (
    <div
      id="product-edit-sidebar-menu"
      className="p-8 bg-gray-50 lg:min-w-order-sidebar flex flex-col items-start rounded-tr-24 rounded-br-24"
    >
      {isStoreAdmin ? (
        <div className="w-full">
          <div className="flex w-full justify-between text-sm text-merch-dark-gray font-bold mb-3 opacity-50">
            <div>Base MSRP:</div>
            <div>{`$${lowestVariantCompareAt}`}</div>
          </div>
          <div className="w-full flex justify-between items-center mb-3 text-sm text-merch-dark-gray opacity-50 font-bold border-b border-gray-300 pb-4">
            <div>Discount:</div>
            <div>{`-${selectedDiscount}%`}</div>
          </div>
          <div className="flex w-full justify-between items-center text-merch-dark-gray font-bold mb-2">
            <div className="text-sm">Adjusted Price:</div>
            <div className="text-xl">{`$${lowestVariantPrice}`}</div>
          </div>
          <div className="flex w-full justify-end items-center text-merch-dark-gray opacity-50 mb-5 text-12 italic">
            <div className="w-185 text-right">
              Contact your Merchologist for custom pricing options
            </div>
          </div>
          <div className="flex w-full justify-between mb-5">
            {isDecoLocationReadOnly && isDecoTypeReadOnly ? (
              <div className="flex w-full">
                <CTA
                  stretch={true}
                  formSubmit={false}
                  onClick={() => {
                    handleProductDelete;
                  }}
                  classes="text-14 py-2.5"
                  size="standard"
                  type="secondary"
                >
                  {'Remove Product From Store'}
                </CTA>
              </div>
            ) : (
              <>
                <div className="flex w-full">
                  <CTA
                    stretch={true}
                    formSubmit={true}
                    size="standard"
                    type="primary"
                  >
                    {'Update Product'}
                  </CTA>
                </div>
                <div className="ml-4">
                  <CTA
                    formSubmit={false}
                    size="standard"
                    type="secondary"
                    icon={<RiDeleteBin7Line size="15px" />}
                    onClick={() => {
                      deleteProduct(product.id);
                    }}
                  ></CTA>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex w-full justify-between text-sm text-merch-dark-gray font-bold mb-5">
            <div>MSRP:</div>
            <div className="mx-2.5">{`$${lowestVariantCompareAt}`}</div>
          </div>
          <div className="w-full flex justify-between items-center mb-5">
            <div className="text-sm text-merch-dark-gray font-bold">
              Adjusted Price:
            </div>
            <div className="flex items-center">
              <div className="text-sm text-merch-dark-gray opacity-50">{`-${selectedDiscount}%`}</div>
              <input
                className="text-right ml-2.5 px-2.5 w-100 h-36 border border-gray-300 text-sm text-merch-dark-gray font-bold rounded-md"
                value={selectedPrice}
                type="number"
                step="0.01"
                onChange={handleDiscountChange}
              ></input>
            </div>
          </div>
          <div className="flex w-full justify-between mb-5">
            <div className="flex w-full">
              <CTA
                stretch={true}
                formSubmit={true}
                size="standard"
                type="primary"
              >
                {'Update Product'}
              </CTA>
            </div>
            <div className="ml-4">
              <CTA
                formSubmit={false}
                size="standard"
                type="secondary"
                icon={<RiDeleteBin7Line size="15px" />}
                onClick={() => {
                  deleteProduct(product.id);
                }}
              ></CTA>
            </div>
          </div>
        </div>
      )}
      <FormInput
        name="product-type"
        disabled={true}
        defaultValue={product.productType}
        type="text"
        required={false}
        register={register}
        errors={errors}
        label="Product Type"
        inputStyle="two"
      ></FormInput>
      <FormInput
        name="product-vendor"
        disabled={true}
        defaultValue={
          product.vendor
            ? product.vendor.charAt(0).toUpperCase() + product.vendor.slice(1)
            : ''
        }
        type="text"
        required={false}
        register={register}
        errors={errors}
        label="Product Vendor"
        inputStyle="two"
      ></FormInput>
      <FormSelect
        name="product-decoration-type"
        disabled={isDecoTypeReadOnly}
        register={register}
        errors={errors}
        setValue={setValue}
        label="Decoration Type"
        emptyText="Decoration Type"
        tooltip={
          'Decoration type can only be set once per product. Please reach out to Merchology ' +
          'support if you’d like to change the decoration type after your store is live.'
        }
        default={
          decoType
            ? decoType
            : decoTypeOptions.length > 0
              ? decoTypeOptions[0].value
              : ''
        }
        options={decoTypeOptions}
        onChange={onDecoTypeChange}
      />
      <FormSelect
        name="product-decoration-location"
        disabled={isDecoLocationReadOnly}
        register={register}
        errors={errors}
        setValue={setValue}
        label="Decoration Location"
        emptyText="Decoration Location"
        tooltip={
          'Decoration location can only be set once per product. Please reach out to Merchology support' +
          ' if you’d like to change the decoration location after your store is live.'
        }
        default={
          decoLocation
            ? decoLocation
            : decoLocationOptions.length > 0
              ? decoLocationOptions[0].value
              : ''
        }
        options={decoLocationOptions}
        onChange={onDecoLocationChange}
      />
    </div>
  );
};
