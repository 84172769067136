const Domain = {
  name: 'Domain',
  kind: 'OBJECT',
  fieldBaseTypes: {
    host: 'String',
    sslEnabled: 'Boolean',
    url: 'URL',
  },
  implementsNode: false,
};
export default Domain;
