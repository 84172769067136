const AppliedGiftCard = {
  name: 'AppliedGiftCard',
  kind: 'OBJECT',
  fieldBaseTypes: {
    amountUsed: 'Money',
    amountUsedV2: 'MoneyV2',
    balance: 'Money',
    balanceV2: 'MoneyV2',
    id: 'ID',
    lastCharacters: 'String',
    presentmentAmountUsed: 'MoneyV2',
  },
  implementsNode: true,
};
export default AppliedGiftCard;
