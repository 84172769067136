import { Footer } from '../Footer';
import { Header } from '../Header';
import { GroupMemberLogin } from './GroupMemberLogin';
import './styles.scss';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { GroupMemberPDP } from './GroupMemberPDP';
import { useContext } from 'react';
import { Context } from '../../context';
import { Loading } from '@merchstores/shared/components/Loading';

export const GroupMemberLayout = () => {
  const { storeUrl, storeUrl2 } = useParams<{
    storeUrl: string;
    storeUrl2: string;
  }>();
  const [groupOrder, setGroupOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requiresMemberPassword, setRequiresMemberPassword] = useState(null);
  const [requiresStorefrontPassword, setRequiresStorefrontPassword] =
    useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');

  const { groupMemberLoggedIn } = useContext(Context);

  useEffect(() => {
    setError('');
    if (groupOrder) {
      // Will be true or false, but null on first load.
      if (
        requiresMemberPassword !== null ||
        requiresStorefrontPassword !== null
      ) {
        if (
          requiresMemberPassword === true ||
          requiresStorefrontPassword === true
        ) {
          // Checks if the user is already logged in (local storage)
          if (
            groupMemberLoggedIn.groupOrderId === groupOrder.groupOrderId &&
            groupMemberLoggedIn.loggedIn === true
          ) {
            setLoggedIn(true);
            setLoading(false);
          } else {
            setLoading(false);
            setLoggedIn(false);
          }
        } else {
          // If no password required
          setLoggedIn(true);
          setLoading(false);
        }
      }
    }
  }, [
    groupMemberLoggedIn,
    groupOrder,
    requiresMemberPassword,
    requiresStorefrontPassword,
  ]);

  useEffect(() => {
    setError('');
    const searchStoreUrl = `/${storeUrl}${storeUrl2 ? '/' + storeUrl2 : ''}`;
    const fetchGroupOrder = async () => {
      // Fetch group order by store URL
      axios
        .post('/.netlify/functions/fetchOrderByStoreUrl', {
          storeUrl: searchStoreUrl,
        })
        .then((res: any) => {
          const data = res.data;
          setGroupOrder(data);

          // Checks if the password is on the group order level
          if (data.password && data.password !== '') {
            setRequiresStorefrontPassword(true);
            setRequiresMemberPassword(false);
            setLoading(false);
          } else {
            // Checks if the password is on a member level
            axios
              .post('/.netlify/functions/fetchOrderMembers', {
                orderId: data.groupOrderId,
              })
              .then((res: any) => {
                const members = res.data;
                if (members && members.length > 0) {
                  const hasUniquePassword = members.find(
                    (m: any) => m.uniquePassword && m.uniquePassword !== ''
                  );
                  if (hasUniquePassword) {
                    setRequiresMemberPassword(true);
                    setRequiresStorefrontPassword(false);
                  } else {
                    //No password required
                    setRequiresMemberPassword(false);
                    setRequiresStorefrontPassword(false);
                  }
                  setLoading(false);
                } else {
                  setLoading(false);
                  setRequiresMemberPassword(false);
                  setRequiresStorefrontPassword(false);
                }
              })
              .catch((err: unknown) => {
                setError(
                  `There was an error retrieving this group order's members: ${err}`
                );
              });
          }
        })
        .catch((err: unknown) => {
          console.error(`Group order fetch error: ${err}`);
          setError(`Group order not found, please try again.`);
        });
    };
    fetchGroupOrder();
  }, [storeUrl, storeUrl2]);

  if (error !== '') return <p>{error}</p>;

  return (
    <div className="flex justify-between items-center flex-col group-member-page-container h-full">
      <Header storeLogo={groupOrder?.storeLogo ? groupOrder.storeLogo : ''} />

      {loading ? (
        <Loading isLoading={true} />
      ) : (
        <>
          {(requiresMemberPassword === false &&
            requiresStorefrontPassword === false) ||
          loggedIn ? (
            <GroupMemberPDP groupOrder={groupOrder} />
          ) : (
            <GroupMemberLogin
              groupOrder={groupOrder}
              requiresPassword={
                requiresMemberPassword
                  ? 'member'
                  : requiresStorefrontPassword
                    ? 'storefront'
                    : 'none'
              }
            />
          )}
        </>
      )}
      <Footer />
    </div>
  );
};
