enum QueryKey {
  SHOPIFY_GIFT_CARD_ORDER_DETAILS = 'shopifyGiftCardOrderDetails',
  SHOPIFY_GIFT_CARDS_ORDERS = 'shopifyGiftCardsOrders',
  MEMBERS_GIFT_CARD_ORDERS = 'membersGiftCardsOrders',
  GIFT_CARDS_STATUS = 'giftCardsStatus',
  SHIPPING_ADDRESS_FORM_DATA = 'shippingAddressFormData',
  SHIPPING_ADRESSES = 'shippingAddresses',
}

export { QueryKey };
