import './styles.scss';

export const Footer = () => {
  // Define the actual props needed in the index file

  const year = new Date().getFullYear();

  return (
    <footer className="flex items-center justify-center flex-col">
      <p>
        Powered by <b>Merchology.com</b>
      </p>
      <p>©{year} - All rights reserved.</p>
    </footer>
  );
};
