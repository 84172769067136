import { ICombinedOrder } from './shopifyCombinedOrder';
import { IShopifyOrder } from './shopifyOrder';

export enum OrderStatus {
  pending = 'PENDING',
  active = 'ACTIVE',
  unfulfilled = 'UNFULFILLED',
  closed = 'CLOSED',
}

export enum OrderType {
  INDIVIDUAL = 'individual_order',
  COMBINED = 'combined_order',
  GIFT_CARD = 'gift_card_order',
}

export enum PayerType {
  INDIVIDUAL = 'individual_payer',
  ADMIN = 'admin_payer',
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  PRE_APPROVED_OTHER = 'pre_approved_other',
}

interface IOrderDetailsView {
  viewType: 'none' | 'combined_order' | 'individual_order' | 'gift_card_order';
  combinedOrder: ICombinedOrder | null;
  individualOrder: IMerchStoreIndividualOrder | IShopifyOrder | null;
}

interface IMerchStoreIndividualOrder {
  id: string;
  name: string;
  createdAt: string;
  closedAt?: string;
  subtotalPrice: string;
  totalPrice: string;
  totalTax: string;
  totalShippingPrice: string;
  shippingTitle: string;
  status: OrderStatus;
  email: string;
  customer: IOrderCustomer;
  tags: string[];
  itemsCount: number;
  trackingCode?: string;
}

interface IShopifyProductVariant {
  id: string;
  storefrontId: string;
  selectedOptions: Array<{ name: string; value: string }>;
}

interface IShopifyLineItem {
  quantity: number;
  variant: IShopifyProductVariant | null;
  customAttributes: Array<{ key: string; value: string }>;
}

interface IMerchStoreOrder {
  id: string;
  name: string;
  createdAt: string;
  closedAt?: string;
  totalPrice: string;
  totalTax: string;
  totalShippingPrice: string;
  shippingTitle: string;
  status: OrderStatus;
  email: string;
  customer: IOrderCustomer;
  tags: string[];
  itemsCount: number;
  shippingAddress?: IOrderAddress;
  billingAddress?: IOrderAddress;
}

interface IOrderCustomer {
  displayName: string;
  email: string;
  id?: string;
}

interface IOrderAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  province: string;
  zip: string;
}

interface IOrderItem {
  variantId: string;
  quantity: number;
  size?: string;
  artwork?: string;
  logoMockup?: string;
  decorationType?: string;
  decorationLocation?: string;
}

export type {
  IMerchStoreIndividualOrder,
  IShopifyProductVariant,
  IOrderDetailsView,
  IShopifyLineItem,
  IMerchStoreOrder,
  IOrderCustomer,
  IOrderAddress,
  IOrderItem,
};
